import React, { useState, useEffect } from 'react';
import { Button, Form } from 'react-bootstrap';
import CustomModal from '../CustomModal';

const MessageSearchFilter = ({ show, onClose, filter: initialFilter, setFilter }) => {
  const [filter, setFilterState] = useState(initialFilter);

  useEffect(() => {
    setFilterState(initialFilter);
  }, [initialFilter]);

  const closeModal = () => {
    onClose();
  };

  const handleCheckAll = () => {
    const allSelected = filter.event && filter.session && filter.personal && filter.opportunity && filter.question;
    setFilterState({
      event: !allSelected,
      session: !allSelected,
      personal: !allSelected,
      opportunity: !allSelected,
      question: !allSelected,
    });
  };

  const handleCheckboxChange = (key) => {
    setFilterState((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  };

  const handleSubmit = () => {
    setFilter(filter);
    closeModal();
  };

  const isAllChecked = filter.event && filter.session && filter.personal && filter.opportunity && filter.question;

  return (
    <CustomModal onClose={closeModal} show={show}>
      <div className="filter-wrapper filter-pad-bottom-wrap">
        <div className="filter-headings text-center">
          <h2>Convo Types</h2>
        </div>
        <div className="filter-category-list">
          <ul>
            <li className={isAllChecked ? 'active' : ''}>
              <Form.Label>
                All
                <input
                  type="checkbox"
                  onChange={handleCheckAll}
                  checked={isAllChecked}
                />
                <span />
              </Form.Label>
            </li>
            <li className={filter.event ? 'active' : ''}>
              <Form.Label>
                Meetup (In-person)
                <input
                  type="checkbox"
                  onChange={() => handleCheckboxChange('event')}
                  checked={filter.event}
                />
                <span />
              </Form.Label>
            </li>
            <li className={filter.session ? 'active' : ''}>
              <Form.Label>
                Meetup (Virtual)
                <input
                  type="checkbox"
                  onChange={() => handleCheckboxChange('session')}
                  checked={filter.session}
                />
                <span />
              </Form.Label>
            </li>
            <li className={filter.personal ? 'active' : ''}>
              <Form.Label>
                General
                <input
                  type="checkbox"
                  onChange={() => handleCheckboxChange('personal')}
                  checked={filter.personal}
                />
                <span />
              </Form.Label>
            </li>
            <li className={filter.opportunity ? 'active' : ''}>
              <Form.Label>
                Recruitment
                <input
                  type="checkbox"
                  onChange={() => handleCheckboxChange('opportunity')}
                  checked={filter.opportunity}
                />
                <span />
              </Form.Label>
            </li>
            <li className={filter.question ? 'active' : ''}>
              <Form.Label>
                Questions
                <input
                  type="checkbox"
                  onChange={() => handleCheckboxChange('question')}
                  checked={filter.question}
                />
                <span />
              </Form.Label>
            </li>
          </ul>
          <div className="text-center bg_transparent">
            <Button className="btn-dark" type="submit" onClick={handleSubmit}>
              Filter Convos
            </Button>
          </div>
        </div>
      </div>
    </CustomModal>
  );
};

export default MessageSearchFilter;
