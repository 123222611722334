import React, {useState, useEffect} from 'react';

import {
  Button,
  Col,
  Container,
  Row,
  Dropdown,
} from 'react-bootstrap';
import {addUser, download} from '../../../Constants/Images';
import {errorMessage} from '../../../Utils/ToastMessages';
import commonApi from '../../../Services/CommonService';


const SystemRoles = props => {
  const [type, setType] = useState('2');
  const [selectedRoleTitle, setSelectedRoleTitle] = useState(
    props.selectedRoleItems.map(({alias}) => alias),
  );
  const [title, setTitle] = useState('');
  const [titlesAgainstId, setTitlesAgainstId] = useState(
    props.selectedRoleItems.map(({id, alias}) => ({id, alias})),
  );

  const [deleteItem, setDeleteItem] = useState('');

  
  useEffect(() => {}, []);

  // const getRoles = () => {

  //   commonApi
  //     .get_user_roles()
  //     .then(res => {
  //       if (res.status === 200) {
  //         setRolesTypes(res.user_roles)

  //       }
  //     })
  //     .catch(err => {
  //       console.log('Err', err);
  //       return {type: 'error', message: err.message};
  //     });
  // };

  //   useEffect(() => {

  //     const userIds = props.selectedRoleItems.map(item => item.invites.split(',').map(id => `${id.trim()}`))
  //     .flat();
  //     props.setOrginalSelectedBuilders(userIds)
  //     props.setSelectedBuilders(userIds)
  // console.log(userIds,'userIds');

  //   }, [])

  // const rolesTypes = [
  //   {id: '1', name: 'Owner'},
  //   {
  //     id: '2',
  //     name: 'Executive',
  //   },
  //   {
  //     id: '3',
  //     name: 'Executive',
  //   },
  //   {
  //     id: '4',
  //     name: 'Manager',
  //   },
  //   {
  //     id: '5',
  //     name: 'Member',
  //   },
  //   {
  //     id: '6',
  //     name: 'Independent Contractor',
  //   },
  //   {
  //     id: '7',
  //     name: 'Affiliate / Partner',
  //   },
  // ];

  const addSystemRole = id => {
    console.log(id, 'iddddds');
    const filteredarray = props.rolesTypes.filter(item => item.id === id);
    let item = filteredarray[0].role_name;
    let commonTitle = filteredarray[0].default_alias;
    console.log(item, 'item');

    const titleToAdd = title ? title.toLowerCase() : commonTitle.toLowerCase();

    const filterArrayids = titlesAgainstId.filter(
      item => item.alias === titleToAdd,
    );
    console.log(filterArrayids, 'filterArrayids');

    const roleIds = filterArrayids.map(item => item.id);
    console.log(roleIds, 'roleIds');
    if (!selectedRoleTitle.includes(titleToAdd) || !roleIds.includes(id)) {
      setTitlesAgainstId(previous => [
        ...previous,
        {
          id: id,
          alias: titleToAdd,
        },
      ]);

      setSelectedRoleTitle(previous => [...previous, titleToAdd]);
      props.setSelectedRoleItems(previous => [
        ...previous,
        {
          id: id,
          role: item,
          alias: titleToAdd,
          invites: '',
        },
      ]);

      setTitle('');
    } else {
      errorMessage('You have already added this Role');
    }
  };

  const getfolowers = qry => {
    var payLoad = {
      search_query: qry,
    };
    commonApi
      .excluded_followers_list(payLoad)
      .then(res => {
        if (res.status === 200) {
          props.setFollowers(res.data);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  // const handleRoleMenus = value => {
  //   setType(value);
  //   const filteredarray = rolesTypes.filter(item => item.id === value);
  //   setMenuType('1');
  //   setRoleName(filteredarray[0].name);
  //   let menusArray = filteredarray[0].menus.split(',');
  //   console.log(menusArray, 'menusarray');

  //   // const filterData = props.menuItems.filter(item =>
  //   //   menusArray.some(dataItem => {
  //   //     return dataItem === item.id;
  //   //   }),
  //   // );

  //   // console.log(filterData, 'filterData');
  //   if (menusArray.length > 0) {
  //     // setSelectedMenuId(menusArray);
  //     // setSelectedMenuItems(filterData);
  //   }
  // };

  const handleInvite = alias => {
    if (
      props.selectedBuilders.length > 0 &&
      props.selectedBuilders.length !== props.orginalSelectedBuilders.length
    ) {
      props.setOrginalSelectedBuilders(props.selectedBuilders);
    }
    props.selectedRoleItems.forEach(role => {
      if (role.alias === alias) {
        const existingMenusArray = role.invites ? role.invites.split(',') : [];
        console.log(existingMenusArray, 'existingMenusArray');
        if (existingMenusArray.length > 0) {
          const filteredData = props.followers.filter(item =>
            existingMenusArray.includes(item.id),
          );
          console.log(filteredData, 'filteredData');

          const selectedFilterData = props.selectedBuilders.filter(
            item => !filteredData.some(miniItem => miniItem.id === item),
          );
          console.log(selectedFilterData, 'selectedFilterData');
          props.setAllBuilders(filteredData);
          props.setOriginalDataOfMod(filteredData);
          props.setSelectedBuilders(selectedFilterData);
        } else {
          props.setAllBuilders([]);
          props.setOriginalDataOfMod([]);
        }
        props.setBuilders(existingMenusArray);

        props.setSelectedInviteTitle(alias);
        props.setShowModal(true);
        // const existingMenusSet = new Set(existingMenusArray);
        // existingMenusSet.add(menuId);
        // const updatedMenus = Array.from(props.builders).join(',');
      }
    });
  };

  // const handleSelectedInvite = () => {
  //   const updatedRolesTypes = props.selectedRoleItems.map(role => {
  //     if (role.id === props.selectedInviteTitle) {
  //       // const existingMenusArray = role.invites ? role.invites.split(',') : [];
  //       // const existingMenusSet = new Set(existingMenusArray);
  //       // existingMenusSet.add(menuId);
  //       // const updatedMenus = Array.from(props.builders).join(',');
  //       props.setSelectedBuilders(previous => {
  //         const uniqueBuilders = new Set([...previous, ...props.builders]);
  //         return Array.from(uniqueBuilders);
  //       });
  //       props.setOrginalSelectedBuilders(previous => {
  //         const uniqueBuilders = new Set([...previous, ...props.builders]);
  //         return Array.from(uniqueBuilders);
  //       });

  //       // props.setSelectedBuilders((previous => [...previous, ...props.builders]))
  //       // props.setOrginalSelectedBuilders((previous => [...previous, ...props.builders]))
  //       return {...role, invites: props.builders.join(',')};
  //     }
  //     return role;
  //   });

  //   props.setSelectedRoleItems(updatedRolesTypes);
  // };

  const removeSystemRole = miniItem => {
    // const titleToAdd = title ? title : commonTitle;

    console.log(miniItem,'=======>miniItemminiItem')
    const filterArrayids = titlesAgainstId.filter(
      item => item.alias === miniItem.alias,
    );
    console.log(filterArrayids, 'filterArrayids');

    // const roleIds = filterArrayids.map(item => item.id);

    const countOwner = titlesAgainstId.filter(item => item.id === '2').length;

    console.log(countOwner, 'countOwner');

    if (miniItem.role === 'Owner') {
      if (miniItem.role === 'Owner' && miniItem.alias !=='co-founder') {
        props.selectedRoleItems.forEach(role => {
          if (role.alias === miniItem.alias && role.id === miniItem.id) {
            const existingMenusArray = role.invites
              ? role.invites.split(',')
              : [];
            const filteredData = props.selectedBuilders.filter(
              item => !existingMenusArray.includes(item),
            );
            props.setSelectedBuilders(filteredData);
            props.setOrginalSelectedBuilders(filteredData);
          }
        });
        setTitlesAgainstId(previous =>
          previous.filter(
            item => item.alias !== miniItem.alias || item.id !== miniItem.id,
          ),
        );
        setSelectedRoleTitle(previous =>
          previous.filter(alias => alias !== miniItem.alias),
        );
        props.setSelectedRoleItems(previous =>
          previous.filter(
            item => item.alias !== miniItem.alias || item.id !== miniItem.id,
          ),
        );
      } else {
        errorMessage('Co-Founder must required');
      }
    } else {
      props.selectedRoleItems.forEach(role => {
        if (role.alias === miniItem.alias && role.id === miniItem.id) {
          const existingMenusArray = role.invites
            ? role.invites.split(',')
            : [];
          const filteredData = props.selectedBuilders.filter(
            item => !existingMenusArray.includes(item),
          );
          props.setSelectedBuilders(filteredData);
          props.setOrginalSelectedBuilders(filteredData);
        }
      });
      setTitlesAgainstId(previous =>
        previous.filter(
          item => item.alias !== miniItem.alias || item.id !== miniItem.id,
        ),
      );
      setSelectedRoleTitle(previous =>
        previous.filter(alias => alias !== miniItem.alias),
      );
      props.setSelectedRoleItems(previous =>
        previous.filter(
          item => item.alias !== miniItem.alias || item.id !== miniItem.id,
        ),
      );
    }
  };

  const handleInviteLength = invites => {
    const invite = invites !=='' ? invites.split(',') :[];
    return invite.length;
  };
  // const placeholderTitle = type => {
  //   const id = Number(type);
  //   if (id == 2) {
  //     return 'i.e Co-Founder or CEO';
  //   } else if (id == 4) {
  //     return 'i.e Manager';
  //   } else if (id == 5) {
  //     return 'i.e Employee';
  //   } else if (id == 6) {
  //     return 'i.e Contractor';
  //   } else if (id == 7) {
  //     return 'i.e Partner';
  //   } else {
  //     return 'i.e CEO';
  //   }
  // };

  const aliasTitle = type => {
    console.log(type, 'type');
    const id = Number(type);
    if (id === 2) {
      return 'co-founder';
    } else if (id === 4) {
      return 'manager';
    } else if (id === 5) {
      return 'employee';
    } else if (id === 6) {
      return 'contractor';
    } else if (id === 7) {
      return 'partner';
    } else {
      return 'ceo';
    }
  };
  useEffect(() => {
    getfolowers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedRoleItems]);


  useEffect(() => {
    if(props.deleteItem){
      removeSystemRole(deleteItem)
      
    }
    props.setDeleteItem(false)
  
  }, [props.deleteItem])
  

  console.log(props.allBuilders, 'props.allBuilders');
  console.log(props.originalDataOfMod, 'props.originalDataOfMod');
  console.log(props.selectedRoleItems, 'props.selectedRoleItems');
  console.log(props.builders, 'props.builders');
  console.log(props.selectedInviteTitle, 'props.selectedInviteTitle');
  console.log(props.selectedBuilders, 'props.selectedBuilders');
  console.log(props.orginalSelectedBuilders, 'props.orginalSelectedBuilders');
  console.log(selectedRoleTitle, 'selectedRoleTitle');
  console.log(props.selectedUser, 'props.selectedUser');
  console.log(type, 'type');
  console.log(titlesAgainstId, 'titlesAgainstId');
  return (
    <>
      <div className="company-info-screen system_role_screen">
        <div>
          <div className="slider3-container">
            <div className="slider3-Top-container">
              <label>Which roles exist in your company?</label>

              <div className="svg-team">
                <img src={download} alt="" />
                <span>Download Template</span>
                <span
                  className="btn-roi-template as-link mt-3-bold"
                  onClick={() => {}}>
                  Import Team
                </span>
              </div>
            </div>

            <div>
              <label className="role-label">
                If you don’t want to change the name of the provided core roles,
                you can leave the right field blank.
              </label>
              <div className="slider2-inner-container">
                <div className="scheduler-dropdown custom-scheduler-dropdown">
                  {Object.entries(props.rolesTypes).length > 0 && (
                    <Dropdown
                      className="select-wrapper"
                      onSelect={(eventKey, event) => {
                        setType(eventKey);
                        setTitle('');
                      }}
                      drop="down">
                      <Dropdown.Toggle variant="primary">
                        {type
                          ? props.rolesTypes.find(item => item.id === type)
                              ?.role_name
                          : 'Select Role'}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        {props.rolesTypes.map((item, index) => (
                          <Dropdown.Item
                            key={`sessionCategories-${index}`}
                            eventKey={item.id}
                            active={item.id === type}>
                            {item.role_name}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  )}
                </div>

                <div className="slider3-called-title">
                  <span> We use this title</span>

                  <input
                    type="text"
                    id="companyName"
                    value={title}
                    // placeholder={placeholderTitle(type)}
                    placeholder={`i.e ${
                      props.rolesTypes.find(item => item.id === type)
                        ?.default_alias
                    }`}
                    onChange={e => {
                      setTitle(e.target.value);
                    }}
                    onKeyDown={e => {
                      if (e.key === 'Enter' && !e.shiftKey ) {
                        e.preventDefault();
                        addSystemRole(type);
                      }
                    }}
                  />
                </div>
                <div className="btn-add-type">
                  <Button
                  style={{padding:'11px 27px'}}
                    className="new-btn btn-green "
                    onClick={() => {
                      addSystemRole(type);
                    }}>
                    Add Role
                  </Button>
                </div>
              </div>
            </div>

            {props.selectedRoleItems.length > 0 && (
              <div className="slider-3-roles-conatiner">
                <label style={{fontWeight: 700}}>
                  {props.orginalSelectedBuilders.length > 0
                    ? props.orginalSelectedBuilders.length
                    : ''}{' '}
                  System Users
                </label>

                <label>
                  Your alias column is how these roles will show in ROI
                </label>

                <div className="mt-3">
                  <div className="system-role-list">
                    <Container className="mb-3">
                      <Row>
                        <Col md={4} className='f_700'> System Role</Col>

                        <Col md={3} className='f_700'>Alias </Col>
                      </Row>
                    </Container>

                    <Container>
                      {Object.entries(props.selectedRoleItems).length > 0 &&
                        props.selectedRoleItems.map((item, index) => (
                          <Row key={`r-item-${index}`}>
                            <Col md={4} className="role-row align_item_center">
                              {' '}
                              {item.role}
                            </Col>

                            <Col md={3} className="role-row align_item_center">
                              {item.alias !== ''
                                ? item.alias.toLowerCase()
                                : aliasTitle(item.id)}{' '}
                            </Col>
                            <Col
                              md={2}
                              className="role-row system_invite_users">
                              {' '}
                              {item.invites === '' ? (
                                <div
                                  onClick={() => {
                                    handleInvite(item.alias);
                                    // props.setSelectedInviteTitle(item.alias)
                                    // setShowModal(true);
                                  }}>
                                  <img
                                    className="as-link"
                                    src={addUser}
                                    alt=""
                                  />{' '}
                                </div>
                              ) : (
                                <span
                                  className="invite-length as-link "
                                  onClick={() => {
                                    handleInvite(item.alias);
                                  }}>
                                  {' '}
                                  {handleInviteLength(item.invites)} Added
                                </span>
                              )}
                            </Col>
                            <Col md={1} className="role-row">
                              {' '}
                              <span
                                className="as-link"
                                onClick={() => {
                                  
                                 if(handleInviteLength(item.invites) > 0){
                                  errorMessage("You can't delete this Role")
                                 }else{
                                  props.setShowModel('deleteItem');
                                  setDeleteItem(item)
                                 }
                                   
                                  // removeSystemRole(item);
                                }}>
                                <i
                                  class="fa-solid fa-xmark"
                                  style={{
                                    color: '#d71616',
                                    fontWeight: 'bold',
                                    fontSize: '22px',
                                  }}></i>
                              </span>{' '}
                            </Col>
                          </Row>
                        ))}
                    </Container>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* {showModal && (
        <CustomModal
          size="medium"
          onClose={() => {
            setShowModal(false);
            if (props.orginalSelectedBuilders.length !== props.selectedBuilders.length) {
              props.setSelectedBuilders(props.orginalSelectedBuilders);
            }
          }}
          removeheight={true}>
          <div className="new-session-modal-wrapper text-center createSessionModal eventBrite-Modal">
            <div className="new-session-modal">
              <Invite
                type={'refer'}
                component={'system-role'}
                followers={followers}
                setFollowers={setFollowers}
                moderator={props.builders}
                setModerator={props.setBuilders}
                selectedModData={props.allBuilders}
                setSelectedModData={props.setAllBuilders}
                props.originalDataOfMod={props.originalDataOfMod}
                props.setOriginalDataOfMod={props.setOriginalDataOfMod}
                props.selectedBuilders={props.selectedBuilders}
                contact={contact}
                setContact={setContact}
                setShowComponent={setShowComponent}
                forApiHit={showComponent}
                prevShowComponent={prevShowComponent}
                onClick={() => {
                  setShowComponent(previous => {
                    setPrevShowComponent(previous);
                    return 'inviteUser';
                  });
                }}
                handleInvite={() => {
                  handleSelectedInvite();
                  setShowModal(false);
                }}
              />
            </div>
          </div>
        </CustomModal>
      )}

      {showComponent === 'inviteUser' && (
        <SendInvitation
          setContact={setContact}
          onClose={() => {
            setShowComponent(previous => {
              setPrevShowComponent(previous);
              return '';
            });
          }}
        />
      )} */}
    </>
  );
};

export default SystemRoles;
