import React,{useState} from 'react';
import {
  Button,
  Form,
} from 'react-bootstrap';


import SlidingPanel from '../SlidingPanel';
import Scrollbars from 'react-custom-scrollbars';
import {Templates} from '../../Constants/Images';

const CreateAgreement = props => {
 
  const [tabType, setTabType] = useState('agreements');
  
  const [title, settitle] = useState('');
  const [section1description, setSection1description] = useState('');




  return (
    <>
      <SlidingPanel
        hidePanel={() => {
          props.onClose();
        }}>
        <div className="serach-filter-wrapper create-sessionEvents">
          <Scrollbars>
            <div className="create-ticket-form fix-width-forms pb-3">
              <div className="create-agreement-page">
                <div className="messages-header mb-2">
                  <div className="event-tabs tabs-style-2 mb-2">
                    {' '}
                    <ul>
                      <li>
                        <Button
                          className={tabType === 'agreements' ? 'active' : ''}
                          onClick={() => {
                            setTabType('agreements');
                          }}>
                          Agreements
                        </Button>
                      </li>

                      <li>
                        <Button
                          className={tabType === 'proposals' ? 'active' : ''}
                          onClick={() => {
                            setTabType('proposals');
                          }}>
                          Proposals
                        </Button>
                      </li>
                    </ul>
                  </div>

                  <div className="search-tabs-icons">
                    <img className="template-img" src={Templates} alt="" />
                  </div>
                </div>

                <div className="create-agreement-page-content">
                  <div className="">
                    <div className="roi-form-input">
                      <Form.Control
                        type="text"
                        value={title}
                        pattern="^\S.*$"
                        required
                        placeholder="Title"
                        onChange={e => {
                          settitle(e.target.value);
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        Title is required.
                      </Form.Control.Feedback>
                    </div>
                  </div>

                  <div className="section-1 mb-3">
                    <div className="form-black-tag mb-3">Section 1</div>

                    <div className="agreement-btns mb-3">
                      <Button
                        className="new-btn btn-green ml-2 "
                        onClick={() => {}}>
                        Add Signature Line
                      </Button>

                      <Button
                        className="new-btn btn-green ml-2 "
                        onClick={() => {}}>
                        Add Required Checklist
                      </Button>
                    </div>

                    <div className="roi-form-text-area mb-3">
                      <Form.Control
                        required
                        value={section1description}
                        as="textarea"
                        rows={6}
                        type="text"
                        placeholder="Add text for this section"
                        onChange={e => {
                          setSection1description(e.target.value);
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        Description is required.
                      </Form.Control.Feedback>
                    </div>

                    <div className="text-editor mb-3">text editor options</div>

                    <div className="preview-section">
                      <span>Preview Section</span>

                      <span>Import Options</span>
                    </div>
                  </div>

                  <div className="statement-2">
                    <div className="form-black-tag mb-3">Section 2</div>
                    <div className="form-black-tag mb-3">Section 3</div>
                    <div className="statement-btns">
                      <span>Add Section</span>
                      <span>Preview Agreement</span>
                      <span>More Options</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Scrollbars>
        </div>
      </SlidingPanel>
    </>
  );
};

export default CreateAgreement;
