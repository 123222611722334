import React, {useState, useEffect} from 'react';
import {Button, Col, Container, Form, Row} from 'react-bootstrap';
import commonApi from '../../Services/CommonService';
import img from '../../Assets/images/norecord/no-circles.png';
// import { NoCircles } from '../../Constants/Images';
import {fixName} from '../../Utils/commonUtils';
// import {DEFAULT_IMAGE} from '../../Utils/env';
import LoaderSpinner from '../Modals/LoaderSpinner';
import {labels} from '../../Constants/Translations';
import {selected_lang, DEFAULT_IMAGE} from '../../Utils/env';
import {errorMessage, successMessage} from '../../Utils/ToastMessages';
import API from '../../Services/SessionServices';
import {getItem} from '../../Utils/LocalStorage';
import Scrollbars from 'react-custom-scrollbars';

const SystemRolesInvite = props => {
  const [followers, setFollowers] = useState(
    props.followers ? props.followers : [],
  );
  const [showSlectedUser, setShowSlectedUser] = useState('');
  const [searchQry, setSearchQry] = useState('');
  const [showLoader, setshowLoader] = useState(false);

  // const prevForApiHit = useRef(props.forApiHit);

  useEffect(() => {
    setshowLoader(true);
    setTimeout(() => {
      // if (
      //   (props.component === 'system-role' && props.followers.length === 0) ||
      //   props.forApiHit !== props.prevShowComponent
      // ) {

      getfolowers('');
      // }
      if (props.component !== 'system-role') {
        setshowLoader(true);
        getfolowers('');
      }
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.forApiHit]);

  // const UpdateForState  =(miniItem)=>{
  //
  //   setSelectedModData([...selectedModData, miniItem]);
  // }

  const getfolowers = qry => {

      var payLoad = {
        search_query: qry,
      };
      commonApi
        .excluded_followers_list(payLoad)
        .then(res => {
          setshowLoader(false);

          if (res.status === 200) {
            setFollowers(res.data);
            props.setFollowers(res.data);

            if (props.contact?.length > 0) {
              const filterData = res.data.filter(
                item =>
                  item.exist_in_build === 'no' &&
                  props.contact.some(
                    dataItem =>
                      dataItem.fname === item.first_name &&
                      dataItem.lname === item.last_name &&
                      dataItem.contact === item.phone,
                  ),
              );

              if (filterData.length > 0) {
                filterData.forEach(miniItem => {
                  props.setModerator(prev => [...prev, miniItem.id]);

                  props.setSelectedModData(previous => [...previous, miniItem]);
                  props.setOriginalDataOfMod(previous => [
                    ...previous,
                    miniItem,
                  ]);

                  // setSelectedModData(updatedData)
                });
              }
            }
            if (props.setContact) {
              props.setContact([]);
            }
          }
        })
        .catch(err => {
          console.log('Err', err);
          return {type: 'error', message: err.message};
        });
  
  };

  // const getUserList = qry => {
  //   let payLoad = {id: getItem('conf_id'), search_query: qry};
  //   API.users_not_in_conference(payLoad)
  //     .then(res => {
  //       setshowLoader(false);
  //       // if (res.status === 200) {
  //       setFolowers(res.data);
  //       setNewFolowers(
  //         res.data.filter(
  //           item => !audience.includes(item.id) && !modrator.includes(item.id),
  //         ),
  //       );
  //       // }
  //     })
  //     .catch(err => {
  //       console.log('Err', err);
  //       return {type: 'error', message: err.message};
  //     });
  // };

  const AddUsersInSession = () => {
    let data = {
      conference_id: getItem('conf_id'),
      moderators: props.moderator,
      audience: props.audience,
    };
    commonApi
      .add_all_user_in_conference_dolby(data)
      .then(response => {
        if (response) {
          successMessage(response.message);

          //   GetUserNotInSession('', '');
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const ModData = mod => {
    if (!props.moderator.includes(mod.id)) {
      props.setModerator(previous => [...previous, mod.id]);
      // setModerator(previous => [...previous, mod.id]);
      props.setSelectedModData(previous => [...previous, mod]);
      props.setOriginalDataOfMod(previous => [...previous, mod]);
    } else if (
      props.alreadyAddedMod &&
      props.alreadyAddedMod.includes(mod.id)
    ) {
      errorMessage('This user is  already associated');
    } else {
      props.setModerator(previous => previous.filter(id => id !== mod.id));

      // setModerator(previous => previous.filter(id => id !== mod.id));
      props.setSelectedModData(previous =>
        previous.filter(item => item.id !== mod.id),
      );
      props.setOriginalDataOfMod(previous =>
        previous.filter(item => item.id !== mod.id),
      );
    }

    //
  };
  const AudData = item => {
    if (!props.audience.includes(item.id)) {
      props.setAudience(previous => [...previous, item.id]);
      props.setSelectedAudData(previous => [...previous, item]);
      props.setOriginalDataOfAud(previous => [...previous, item]);
    } else if (
      props.alreadyAddedAud &&
      props.alreadyAddedAud.includes(item.id)
    ) {
      errorMessage('This user is  already associated');
    } else {
      props.setAudience(previous => previous.filter(id => id !== item.id));
      props.setSelectedAudData(previous =>
        previous.filter(miniitem => miniitem.id !== item.id),
      );
      props.setOriginalDataOfAud(previous =>
        previous.filter(miniitem => miniitem.id !== item.id),
      );
    }
  };

  useEffect(() => {
    if (props.audience?.length === 0) {
      setShowSlectedUser('');
    }
  }, [props.audience]);

  useEffect(() => {
    if (props.moderator.length === 0) {
      setShowSlectedUser('');
    }
  }, [props.moderator]);

  const handleSearchQry = q => {
    getfolowers(q);
  };

  const handleSelectedSearchQryForAud = (query, array) => {
    if (!query && props.originalDataOfAud.length > 0) {
      props.setSelectedAudData(props.originalDataOfAud); // Reset to original data when query is empty
      return;
    }
    props.setSelectedAudData(
      array.filter(el => {
        // const fullName = el.first_name.toLowerCase() + el.last_name.toLowerCase();
        const fullNameSpace =
          el.first_name.toLowerCase() + ' ' + el.last_name.toLowerCase();
        return (
          el.first_name.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
          el.last_name.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
          fullNameSpace.toLowerCase().indexOf(query.toLowerCase()) !== -1
        );
      }),
    );
  };
  const handleSelectedSearchQryForMod = (query, array) => {
    if (!query && props.originalDataOfMod.length > 0) {
      props.setSelectedModData(props.originalDataOfMod);

      return;
    }

    props.setSelectedModData(
      array.filter(el => {
        // const fullName = el.first_name.toLowerCase() + el.last_name.toLowerCase();
        const fullNameSpace =
          el.first_name.toLowerCase() + ' ' + el.last_name.toLowerCase();
        return (
          el.first_name.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
          el.last_name.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
          fullNameSpace.toLowerCase().indexOf(query.toLowerCase()) !== -1
        );
      }),
    );
  };

  //

  return (
    <>
      <div className="ScheduleScreen_Style  fix-width-forms primary_panel_content">
        {props.type !== 'refer' && props.type !== 'snapshot' && (
          <div className="event-tabs tabs-style-2 mb-0">
            <ul>
              <li>
                <Button
                  className={
                    props.subTabs === 'moderator' ||
                    showSlectedUser === 'selected-moderator'
                      ? 'active'
                      : ''
                  }
                  onClick={() => {
                    setShowSlectedUser('');
                    setSearchQry('');
                    props.setSubTabs('moderator');
                  }}>
                  Moderators
                </Button>
              </li>
              <li>
                <Button
                  className={
                    props.subTabs === 'audience' ||
                    showSlectedUser === 'selected-audience'
                      ? 'active'
                      : ''
                  }
                  onClick={() => {
                    setShowSlectedUser('');
                    setSearchQry('');
                    props.setSubTabs('audience');
                  }}>
                  Audience
                </Button>
              </li>
            </ul>
          </div>
        )}
        {/* <div className="sidebar-modal with-custom-scroll d-flex flex-column"> */}

        <div className="invite-page-search p-0 m-0">
          {props.tab === 'workspace' && (
            <Form.Label>What would you like analytics on?</Form.Label>
          )}
          <div className="search-form">
            <Form.Group className="search-input">
              <Form.Control
                onChange={e => {
                  setSearchQry(e.target.value);
                  // getAllUsers(e.target.value);

                  if (showSlectedUser === 'selected-moderator') {
                    handleSelectedSearchQryForMod(
                      e.target.value,
                      props.selectedModData,
                    );
                  } else if (showSlectedUser === 'selected-audience') {
                    handleSelectedSearchQryForAud(
                      e.target.value,
                      props.selectedAudData,
                    );
                  } else {
                    handleSearchQry(e.target.value);
                  }
                }}
                value={searchQry}
                type="text"
                placeholder="Search for contacts"
              />
            </Form.Group>
          </div>
        </div>
        <div className="refferal-list-wrapper">
          {showLoader && <LoaderSpinner />}
          <Scrollbars autoHide>
            {((props.subTabs &&
              props.subTabs === 'moderator' &&
              showSlectedUser === '') ||
              (props.type === 'refer' && showSlectedUser === '') ||
              (props.type === 'snapshot' && showSlectedUser === '')) && (
              <div className="invite-persons-wrapper system-roles-invite-holder pb-5">
                <div className="invite-persons-list system-roles-invite-provider p-0">
                  <Container className="pl-3">
                    {/* {showLoader && <LoaderSpinner />} */}
                    <Row>
                      <div
                        className="invite-persons-heading "
                        style={{
                          paddingLeft: '1px',
                          paddingRight: '1px'/*paddingLeft:14px*/,
                        }}>
                        <span
                          className={
                            props.moderator.length === 0 &&
                            props.subTabs &&
                            props.subTabs === 'moderator' &&
                            showSlectedUser === ''  ? 'for-grey f_400 p-bold'
                            : props.moderator.length > 0 && showSlectedUser === '' ? 'for-grey  as-link p-bold' 
                              
                              : props.moderator.length > 0 &&
                                showSlectedUser === 'selected-moderator'
                              ? 'builder-text as-link p-bold'
                              : ` for-grey f_400 p-bold ${
                                  props.moderator.length === 0 ? '' : 'as-link'
                                } `
                          }
                          onClick={() => {
                            if (props.moderator.length > 0) {
                              setShowSlectedUser('selected-moderator');
                              setSearchQry('');
                              handleSelectedSearchQryForMod('', '');
                            }
                          }}>
                          {props.moderator.length === 0
                            ? 'Search from your contacts'
                            : props.moderator.length + ' Contacts Selected'}
                        </span>
                        {!props.hideCircle && (
                          <span
                            className="circle-link-icon"
                            onClick={props.handleCircle}>
                            {/* Circles  */}
                            <i className="icon-circles" />
                          </span>
                        )}
                      </div>
                      {Object.entries(followers).length > 0 &&
                      followers.length - props.selectedBuilders?.length !==
                        0 ? (
                        Object.entries(followers).length > 0 &&
                        followers.map(
                          (item, index) =>
                            // !moderator.includes(item.id) &&
                            //messagesCase
                            (!props.opprAudience ||
                              !props.opprAudience.includes(item.id)) &&
                            (!props.mustAudience ||
                              !props.mustAudience.includes(item.id)) &&
                            (!props.opportunityLeftAudience ||
                              !props.opportunityLeftAudience.includes(
                                item.id,
                              )) &&
                            (!props.hideCreator ||
                              !props.hideCreator.includes(item.id)) &&
                            //Snapshot case
                            (!props.pendingUsersIds ||
                              !props.pendingUsersIds.includes(item.id)) &&
                            (!props.partners ||
                              !props.partners.includes(item.id)) &&
                            (!props.members ||
                              !props.members.includes(item.id)) &&
                            (!props.leadership ||
                              !props.leadership.includes(item.id)) &&
                            //workspace

                            //system-roles
                            (!props.selectedBuilders ||
                              !props.selectedBuilders.includes(item.id)) &&
                            //otherSingleCases
                            !props.audience?.includes(item.id) && (
                              <Col key={`f-item-${index}`} md={4} xl={3}>
                                <div
                                  className="stage-list-item "
                                  key={`folowers-${index}`}>
                                  <div
                                    className={
                                      item?.linked_user_detail?.user_type ===
                                      'business'
                                        ? 'stage-person-img business as-link'
                                        : 'stage-person-img as-link'
                                    }
                                    type="checkbox"
                                    onClick={() => {
                                      if (props.type === 'snapshot') {
                                        if (
                                          props.category === '1' &&
                                          !props.moderator.includes(item.id)
                                        ) {
                                          props.setclearAllModal(true);
                                          props.setCurrentInviteUser(item);
                                        } else if (
                                          props.category === '1' &&
                                          props.tab
                                        ) {
                                          props.tab === 'Partners'
                                            ? props.handleSlectedUser(
                                                item,
                                                'Partner',
                                              )
                                            : props.handleSlectedUser(
                                                item,
                                                'Member',
                                              );
                                        } else {
                                          props.handleSlectedUser(
                                            item,
                                            'LeaderShip',
                                          );
                                        }
                                      } else if (props.tab === 'workspace') {
                                        if (
                                          !props.moderator.includes(item.id)
                                        ) {
                                          props.setclearAllModal(true);
                                          props.setCurrentInviteUser(item);
                                        } else {
                                          props.handleSlectedUser(item);
                                        }
                                      } else {
                                        ModData(item);
                                      }
                                    }}>
                                    <img
                                      className={
                                        props.moderator.includes(item.id)
                                          ? 'selercted-user'
                                          : ''
                                      }
                                      src={
                                        item.profile_img === ''
                                          ? DEFAULT_IMAGE
                                          : item.profile_img
                                      }
                                      alt=""
                                      id={item.id}
                                    />
                                  </div>
                                  <div
                                    className="stage-person-name as-link"
                                    onClick={() => {
                                      if (props.type === 'snapshot') {
                                        if (
                                          props.category === '1' &&
                                          !props.moderator.includes(item.id)
                                        ) {
                                          props.setclearAllModal(true);
                                          props.setCurrentInviteUser(item);
                                        } else {
                                          props.handleSlectedUser(
                                            item,
                                            'LeaderShip',
                                          );
                                        }
                                      } else {
                                        ModData(item);
                                      }
                                    }}>
                                    <h3>
                                      {fixName(item.first_name, item.last_name)}
                                    </h3>
                                  </div>
                                </div>
                              </Col>
                            ),
                        )
                      ) : props.hideCircle || props.noRecord ? (
                        <Col md={12} xl={12} className="text-center">
                          {props.hideCircle &&
                            labels.no_record_found[selected_lang]}
                          {props.noRecord &&
                            labels.no_user_found[selected_lang]}
                        </Col>
                      ) : (
                        <div className="no-results-wrapper no-appointments my-5">
                          <div className="img-bg-wrapper">
                            <img src={img} alt="" />
                          </div>
                          <div className="no-results-content">
                            <p className="noRecord-title">
                              {searchQry
                                ? `  We didn't find the ${searchQry} match for the name`
                                : `  We didn't find  any match `}
                            </p>

                            <div className="btn-wrapper">
                              <Button
                                className="btn btn-dark"
                                onClick={() => {
                                  props.onClick();
                                }}>
                                {searchQry ? 'Add Contact' : 'Add Contacts'}
                              </Button>
                            </div>
                          </div>
                        </div>
                      )}
                    </Row>
                  </Container>
                </div>
              </div>
            )}

            {props.subTabs &&
              props.subTabs === 'audience' &&
              showSlectedUser === '' && (
                <div className="invite-persons-wrapper">
                  <div className="invite-persons-list">
                    <Container>
                      {/* {showLoader && <LoaderSpinner />} */}
                      <Row>
                        <div className="invite-persons-heading">
                          <span
                            className={
                              props.audience.length === 0 &&
                              props.subTabs === 'audience'
                                ? 'for-grey as-link p-bold'
                                : props.audience.length > 0 &&
                                  showSlectedUser === 'selected-audience'
                                ? 'builder-text as-link p-bold'
                                : ' for-grey  as-link p-bold'
                            }
                            onClick={() => {
                              if (props.audience.length > 0) {
                                setShowSlectedUser('selected-audience');
                                setSearchQry('');
                                handleSelectedSearchQryForAud('', '');
                              }
                            }}>
                            {props.audience.length === 0
                              ? 'Search from your contacts'
                              : props.audience.length + ' Contacts Selected'}
                          </span>
                          <span
                            className="circle-link-icon"
                            onClick={props.handleCircle}>
                            {/* Circles */}
                            <i className="icon-circles" />
                          </span>
                        </div>
                        {Object.entries(followers).length > 0 ? (
                          Object.entries(followers).length > 0 &&
                          followers.map(
                            (item, index) =>
                              (!props.opportunityLeftAudience ||
                                !props.opportunityLeftAudience.includes(
                                  item.id,
                                )) &&
                              (!props.hideCreator ||
                                !props.hideCreator.includes(item.id)) &&
                              (!props.mustModerators ||
                                !props.mustModerators.includes(item.id)) &&
                              !props.moderator.includes(item.id) && (
                                <Col key={`f-again-${index}`} md={4} xl={3}>
                                  <div
                                    className="stage-list-item"
                                    key={`folow-${index}`}>
                                    <div
                                      className={
                                        item?.linked_user_detail?.user_type ===
                                        'business'
                                          ? 'stage-person-img business as-link'
                                          : 'stage-person-img as-link'
                                      }
                                      onClick={() => {
                                        AudData(item);
                                      }}>
                                      <img
                                        className={
                                          props.audience.includes(item.id)
                                            ? 'selercted-user'
                                            : ''
                                        }
                                        src={
                                          item.profile_img === ''
                                            ? DEFAULT_IMAGE
                                            : item.profile_img
                                        }
                                        alt=""
                                        id={item.id}
                                      />
                                    </div>
                                    <div
                                      className="stage-person-name as-link"
                                      onClick={() => {
                                        AudData(item);
                                      }}
                                      id={item.id}
                                      value={item.id}>
                                      <h3>
                                        {fixName(
                                          item.first_name,
                                          item.last_name,
                                        )}
                                      </h3>
                                    </div>
                                  </div>
                                </Col>
                              ),
                          )
                        ) : (
                          <div className="no-results-wrapper no-appointments my-5">
                            <div className="img-bg-wrapper">
                              <img src={img} alt="" />
                            </div>
                            <div className="no-results-content">
                              <p className="noRecord-title">
                                {searchQry
                                  ? `  We didn't find the ${searchQry} match for the name`
                                  : `  We didn't find  any match `}
                              </p>
                              {/* <p className="noRecord-desc">
              Quickly access your colleagues to
              invite, refer and connect
            </p> */}

                              <div className="btn-wrapper">
                                <Button
                                  className="btn btn-dark"
                                  onClick={() => {
                                    props.onClick();
                                  }}>
                                  {searchQry ? 'Add Contact' : 'Add Contacts'}
                                </Button>
                              </div>
                            </div>
                          </div>
                        )}
                      </Row>
                    </Container>
                  </div>
                </div>
              )}

            {showSlectedUser === 'selected-moderator' && (
              <div className="invite-persons-wrapper pb-5">
                <div className="invite-persons-list">
                  <Container>
                    {/* {showLoader && <LoaderSpinner />} */}
                    <Row>
                      <div className="invite-persons-heading">
                        <span
                          className={
                            props.moderator.length > 0
                              ? 'builder-text as-link p-bold'
                              : 'builder-text p-bold'
                          }
                          onClick={() => {
                            setShowSlectedUser('');
                            setSearchQry('');
                            getfolowers('');
                          }}>
                          {props.moderator.length === 0
                            ? ''
                            : props.moderator.length + ' Contacts Selected'}
                        </span>
                        {!props.hideCircle && (
                          <span
                            className="circle-link-icon"
                            onClick={props.handleCircle}>
                            {/* Circles */}
                            <i className="icon-circles" />
                          </span>
                        )}
                      </div>
                      {Object.entries(props.selectedModData).length > 0 ? (
                        Object.entries(props.selectedModData).length > 0 &&
                        props.selectedModData.map(
                          (item, index) =>
                            // !moderator.includes(item.id) &&
                            (!props.opprAudience ||
                              !props.opprAudience.includes(item.id)) &&
                            (!props.mustAudience ||
                              !props.mustAudience.includes(item.id)) &&
                            (!props.opportunityLeftAudience ||
                              !props.opportunityLeftAudience.includes(
                                item.id,
                              )) &&
                            (!props.hideCreator ||
                              !props.hideCreator.includes(item.id)) &&
                            !props.audience?.includes(item.id) && (
                              <Col key={`f-item-${index}`} md={4} xl={3}>
                                <div
                                  className="stage-list-item "
                                  key={`folowers-${index}`}>
                                  <div
                                    className={
                                      item?.linked_user_detail?.user_type ===
                                      'business'
                                        ? 'stage-person-img business as-link'
                                        : 'stage-person-img as-link'
                                    }
                                    type="checkbox"
                                    onClick={() => {
                                      ModData(item);
                                    }}>
                                    <img
                                      className={
                                        props.moderator.includes(item.id)
                                          ? 'selercted-user'
                                          : ''
                                      }
                                      src={
                                        item.profile_img === ''
                                          ? DEFAULT_IMAGE
                                          : item.profile_img
                                      }
                                      alt=""
                                      id={item.id}
                                    />
                                  </div>
                                  <div
                                    className="stage-person-name as-link"
                                    onClick={() => {
                                      ModData(item);
                                    }}>
                                    <h3>
                                      {fixName(item.first_name, item.last_name)}
                                    </h3>
                                  </div>
                                </div>
                              </Col>
                            ),
                        )
                      ) : props.hideCircle ? (
                        <Col md={12} xl={12} className="text-center">
                          {labels.no_record_found[selected_lang]}
                        </Col>
                      ) : (
                        <div className="no-results-wrapper no-appointments my-5">
                          <div className="img-bg-wrapper">
                            <img src={img} alt="" />
                          </div>
                          <div className="no-results-content">
                            <p className="noRecord-title">
                              {searchQry
                                ? `  We didn't find the ${searchQry} match for the name`
                                : `  We didn't find  any match `}
                            </p>
                            {/* <p className="noRecord-desc">
                Quickly access your colleagues to
                invite, refer and connect
              </p> */}

                            <div className="btn-wrapper">
                              <Button
                                className="btn btn-dark"
                                onClick={() => {
                                  props.onClick();
                                }}>
                                {searchQry ? 'Add Contact' : 'Add Contacts'}
                              </Button>
                            </div>
                          </div>
                        </div>
                      )}
                    </Row>
                  </Container>
                </div>
              </div>
            )}

            {showSlectedUser === 'selected-audience' && (
              <div className="invite-persons-wrapper">
                <div className="invite-persons-list">
                  <Container>
                    {/* {showLoader && <LoaderSpinner />} */}
                    <Row>
                      <div className="invite-persons-heading">
                        <span
                          className={
                            props.audience.length > 0
                              ? 'builder-text as-link p-bold'
                              : 'builder-text p-bold'
                          }
                          onClick={() => {
                            setShowSlectedUser('');
                            setSearchQry('');
                            getfolowers('');
                          }}>
                          {props.audience.length === 0
                            ? ''
                            : props.audience.length + ' Contacts Selected'}
                        </span>
                        <span
                          className="circle-link-icon"
                          onClick={props.handleCircle}>
                          {/* Circles */}
                          <i className="icon-circles" />
                        </span>
                      </div>
                      {Object.entries(props.selectedAudData).length > 0 ? (
                        Object.entries(props.selectedAudData).length > 0 &&
                        props.selectedAudData.map(
                          (item, index) =>
                            (!props.opportunityLeftAudience ||
                              !props.opportunityLeftAudience.includes(
                                item.id,
                              )) &&
                            (!props.hideCreator ||
                              !props.hideCreator.includes(item.id)) &&
                            (!props.mustModerators ||
                              !props.mustModerators.includes(item.id)) &&
                            !props.moderator.includes(item.id) && (
                              // !audience.includes(item.id) &&
                              <Col key={`f-again-${index}`} md={4} xl={3}>
                                <div
                                  className="stage-list-item"
                                  key={`folow-${index}`}>
                                  <div
                                    className={
                                      item?.linked_user_detail?.user_type ===
                                      'business'
                                        ? 'stage-person-img business as-link'
                                        : 'stage-person-img as-link'
                                    }
                                    onClick={() => {
                                      AudData(item);
                                    }}>
                                    <img
                                      className={
                                        props.audience.includes(item.id)
                                          ? 'selercted-user'
                                          : ''
                                      }
                                      src={
                                        item.profile_img === ''
                                          ? DEFAULT_IMAGE
                                          : item.profile_img
                                      }
                                      alt=""
                                      id={item.id}
                                    />
                                  </div>
                                  <div
                                    className="stage-person-name as-link"
                                    onClick={() => {
                                      AudData(item);
                                    }}
                                    id={item.id}
                                    value={item.id}>
                                    <h3>
                                      {fixName(item.first_name, item.last_name)}
                                    </h3>
                                  </div>
                                </div>
                              </Col>
                            ),
                        )
                      ) : (
                        <div className="no-results-wrapper no-appointments my-5">
                          <div className="img-bg-wrapper">
                            <img src={img} alt="" />
                          </div>
                          <div className="no-results-content">
                            <p className="noRecord-title">
                              {searchQry
                                ? `  We didn't find the ${searchQry} match for the name`
                                : `  We didn't find the  match `}
                            </p>
                            {/* <p className="noRecord-desc">
              Quickly access your colleagues to
              invite, refer and connect
            </p> */}

                            <div className="btn-wrapper">
                              <Button
                                className="btn btn-dark"
                                onClick={() => {
                                  props.onClick();
                                }}>
                                {searchQry ? 'Add Contact' : 'Add Contacts'}
                              </Button>
                            </div>
                          </div>
                        </div>
                      )}
                    </Row>
                  </Container>
                </div>
              </div>
            )}

            {!showLoader && props.type === 'sessionDetail' && (
              <div className="filter-btn-wrapper pt-2">
                <div className="invite-btn">
                  <Button
                    disabled={
                      showLoader ||
                      !(props.audience.length > 0 || props.moderator.length > 0)
                        ? true
                        : false
                    }
                    className="btn-dark"
                    type="button"
                    onClick={() => {
                      props.onClose();
                      AddUsersInSession();
                    }}>
                    Send Invite
                  </Button>
                </div>
              </div>
            )}
          </Scrollbars>
        </div>

        {props.component === 'system-role' &&
          !showLoader &&
          followers.length > 0 &&
          followers.length - props.selectedBuilders.length !== 0 && (
            <div className="btn-wrapper bg_transparent bottom-0 pt-2">
              <Button
                className="btn btn-green mb-2"
                onClick={() => {
                  props.handleInvite();
                }}>
                Select
              </Button>
            </div>
          )}
      </div>
      {/* </div> */}
    </>
  );
};

export default SystemRolesInvite;
