// Import the functions you need from the SDKs you need
import {initializeApp} from 'firebase/app';
import * as auth from 'firebase/auth';
import {getDatabase} from 'firebase/database';
import {getStorage} from 'firebase/storage';
import {ref} from 'firebase/storage';
import {getMessaging, getToken, onMessage} from 'firebase/messaging';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const {host} = window.location;
let databaseURL = 'https://roi-414507-live-rtdb.firebaseio.com/';
if (host.includes('localhost') || host.includes('dev')) {
  databaseURL = 'https://roi-414507-dev-rtdb.firebaseio.com/';
} else if (host.includes('staging')) {
  databaseURL = 'https://roi-414507-staging-rtdb.firebaseio.com/';
} else {
  databaseURL = 'https://roi-414507-live-rtdb.firebaseio.com/';
}

const constfirebaseConfigDev = {
  apiKey: 'AIzaSyDeZjyhICotV25eR0IV22dhvhJ_8C9MFjs',
  authDomain: 'roi-414507.firebaseapp.com',
  databaseURL: databaseURL,
  projectId: 'roi-414507',
  storageBucket: 'roi-414507.appspot.com',
  messagingSenderId: '556170493308',
  appId: '1:556170493308:web:658c88472ea909499db8f9',
  measurementId: 'G-8B4L6DKQRK',
};

// Initialize Firebase
const app = initializeApp(constfirebaseConfigDev);
const auth1 = auth.initializeAuth(app);
//const messaging = getMessaging(app);


// getToken(messaging, { vapidKey: 'BBGBqSJet7sA0wgb3SiHPa8o5wK-ndlxk5Hz1_MrULBIH9NY-9l3HR5B0vQbLYo93JDBi3HzxmVV8v7YKEBb0rU' }).then((currentToken) => {

// })
const doLogin = () => {
  auth
    .signInWithEmailAndPassword(auth1, 'pukarpukar@gmail.com', 'aab351')
    .then(function (callback) {})
    .catch(function (login_error) {});
  auth.onAuthStateChanged(auth1, user => {
    if (user) {
    } else {
      auth
        .signInWithEmailAndPassword(auth1, 'pukarpukar@gmail.com', 'aab351')
        .then(function (callback) {})
        .catch(function (login_error) {});
    }
  });
};
doLogin()
export const db = getDatabase(app);
export const storage = getStorage(app);
export const storageRef = ref;
