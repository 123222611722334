import React from 'react';
import {Button, Form} from 'react-bootstrap';
import Sidebar from '../../Pages/Sidebar';
import {useEffect, useState} from 'react';
import {Pannels} from '../../Components';
import Scrollbars from 'react-custom-scrollbars';
import CreateWorkSpace from '../../Components/WorkSpace/CreateWorkSpace';
import {SliderNonProfit} from '../../Constants/AnalyticsConstants';
import Slider from 'react-slick';

const NonProfit = props => {
  const [showComponent, setShowComponent] = useState('');
  const [isFloated, toggleFloatedPanel] = useState(false);
  const [showHiddenPanel, toggleHiddenPanel] = useState(false);
  const [workspaceData, setWorkspaceData] = useState([]);
  const [analycticsSlider, setAnalycticsSlider] = useState(false);

  const rewardSlider = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
  };

  useEffect(() => {
    if (showComponent !== '') {
      toggleHiddenPanel(true);
    } else {
      toggleHiddenPanel(false);
    }
  }, [showComponent]);

  return (
    <>
      <div className="page-wrapper">
        <Sidebar
          location={props.location}
          setShowComponent={setShowComponent}
          setWorkSpaceItem={setWorkspaceData}
          workspaceData={workspaceData}
          showComponent={showComponent}
        />
        {/* {showComponent === 'Event' && <SessionEvents eventButton={eventButton} />} */}

        <div className="page-content-wrapper">
          <div className="sessions-list-wrapper slider-screen-main">
            <div className="Reward_main analytics_slider_bg">
              <span
                className="analytics_slider_heading "
                style={{color: analycticsSlider ? '' : '#999999'}}
                onClick={() => {
                  setAnalycticsSlider(!analycticsSlider);
                }}>
                Analytics Row
              </span>

              {analycticsSlider && (
                <div className="reward-top mt-3 mb-2">
                  <Slider {...rewardSlider}>
                    {SliderNonProfit.length > 0 &&
                      SliderNonProfit.map((item, index) => (
                        <div className="reward-main">
                          <div className="reward-card">
                            <span style={{fontWeight: 700, fontSize: '13px'}}>
                              {item.name}
                            </span>
                            <span
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}>
                              <span style={{fontSize: '17px'}}>
                                {item.money}
                              </span>
                              {item.name === 'Ratings' && (
                                <img src={item.svgIcon} alt="" />
                              )}
                              {item.name === 'Conversion' ? (
                                <i
                                  className="fa-solid fa-arrow-down"
                                  style={{color: '#BD4141'}}></i>
                              ) : (
                                <i
                                  class="fa-solid fa-arrow-up"
                                  style={{color: '#52bd41'}}></i>
                              )}
                            </span>
                            <div class="message-date-info">
                              <span
                                style={{color: '#333'}}
                                class="message-date">
                                {item.time}
                              </span>
                            </div>
                          </div>
                        </div>
                      ))}
                  </Slider>
                </div>
              )}
            </div>
            <div className="add_border"></div>
            <Pannels
              isFloated={isFloated}
              toggleFloatedPanel={toggleFloatedPanel}
              showHiddenPanel={showHiddenPanel}
              updateHiddenPanelState={nState => toggleHiddenPanel(nState)}
              primary={
                <>
                  <div className="non-profit-page referal_page_content">
                    <div className="nonprofit-top ">
                      <h1>Hey, Jeff</h1>
                      <div>How much would you like to donate?</div>
                    </div>
                    <div className="refferal-list-wrapper">
                      <Scrollbars autoHide>
                        <div>
                          <div className="search-tabs-wrapper mb-4 mt-0">
                            <div className="search-form d-flex mt-0">
                              <Form.Group className="search-input-location search-nonprofit-input-location">
                                <Form.Control
                                  style={{
                                    boxShadow:
                                      ' 0px 1.003px 4.012px 0px rgba(0, 0, 0, 0.25)',
                                    borderRadius: '8.023px',
                                  }}
                                  onChange={e => {}}
                                  type="text"
                                  placeholder="Enter amount"
                                />
                              </Form.Group>
                              {/* <Button> */}
                              <div
                                style={{
                                  // paddingLeft: '66px',
                                  // paddingTop: '10px',
                                  borderRadius: '29.067px',
                                  border: '1.003px solid #E2E2E2',
                                  width: '96.282px',
                                  height: '39.114px',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  gap: '6px',
                                  marginTop: '14px',

                                  marginLeft: '40px',
                                }}>
                                <i className="fa-solid fa-calendar-days" /> Now
                              </div>

                              {/* </Button> */}
                            </div>
                          </div>
                        </div>
                        <div className="text-center mt-3">
                          <Button className={'btn btn-dark'} onClick={() => {}}>
                            Donate
                          </Button>
                        </div>
                        <div
                          style={{position: 'relative'}}
                          className="mt-4 text-center font-bold">
                          <span className="clear-btn">
                            Manage Recurring Donation
                          </span>
                        </div>
                        <h1 className="news_heading mt-3 mb-3">Recent News</h1>

                        <div className="news-card">
                          <div className="news-img"></div>

                          <span className="news-title">
                            We just painted a school
                          </span>

                          <p className="news-description">
                            Thanks to our donors we are now able to help Bethel
                            Middle School.
                          </p>

                          <span className="news-read-more-btn">Read more</span>
                        </div>
                      </Scrollbars>
                    </div>
                  </div>
                </>
              }
              secondary={<></>}
            />
            {showComponent === 'WorkSpace' && (
              <CreateWorkSpace
                onClose={() => {
                  setShowComponent('');
                }}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default NonProfit;
