import React, {useState} from 'react';
import CustomSlidingButtons from '../CustomSlidingButtons';

import {listForCustomer, listForEmployee} from '../../Constants';
import ProfileTransformation from './ProfileTransformation';
import { Button } from 'react-bootstrap';

const ProfileProjects = props => {
  console.log('---------------',props.userId)
  const listForDropDown =
    props.userData?.user_role === 'Customer' && localStorage.getItem('id') !==  props.userId
      ? listForCustomer
      : listForEmployee;

  const [areas_Filter, setAreas_Filter] = useState([listForDropDown[0].id]);
  const dropDownSelection = e => {
    const {id} = e.target;

    setAreas_Filter(id);
  };

  return (
    <>
      <div className="serach-filter-wrapper create-sessionEvents">
        {props.threeDots && 
        <div className=" tranformation_filter category-btns-wrapper category-btns-wrapper-slider mt-3 mb-3">
          <CustomSlidingButtons
            items={listForDropDown}
            activeButtons={areas_Filter}
            onItemClick={e => {
              dropDownSelection(e);
            }}
            showItems={props.userData ? 2 : 2}
          />
        </div>}
        <div className="Business_profile_page">
          {areas_Filter.includes(listForDropDown[0].id) && listForDropDown[0].title == 'Transformations' && (
          
            <ProfileTransformation
              setTransformationList={props.setTransformationList}
              transformationList={props.transformationList}
              userId={props.userId}
              companyData={props.companyData}
              handleTransFormItem={props.handleTransform}
              threeDots={props.threeDots}
              showComponent={props.showComponent}
              Profile={props.Profile}
            />
          )}
            {areas_Filter.includes(listForDropDown[0].id) && listForDropDown[0].title != 'Transformations' && (
              <div className="no-results-wrapper no-appointments my-5">
              <div className="no-results-content">
              <p className={'noRecord-desc'}>No Completed Projects</p>

             { localStorage.getItem('id') ===  props.userId && !props.companyData &&
              <div className=" filter-btn-wrapper btn-wrapper mt-3">
              <Button
                className="btn btn-dark"
                onClick={() => {
                 
                }}>
                Add Project
              </Button>
            </div>

             }
              </div>
            </div>
  
          )}
     

          {areas_Filter.includes(listForDropDown[1].id) && listForDropDown[1].title == 'Customers' && (
            <div className="no-results-wrapper no-appointments my-5">
              <div className="no-results-content">
                <p className={'noRecord-desc'}>No Customers Added</p>
                { localStorage.getItem('id') ===  props.userId && !props.companyData &&
              <div className=" filter-btn-wrapper btn-wrapper mt-3">
              <Button
                className="btn btn-dark"
                onClick={() => {
                 
                }}>
                Add Customer
              </Button>
            </div>

             }
              </div>
            </div>
          )}

{areas_Filter.includes(listForDropDown[1].id) && listForDropDown[1].title != 'Customers' &&(
            <div className="no-results-wrapper no-appointments my-5">
              <div className="no-results-content">
                <p className={'noRecord-desc'}>No Projects Pending</p>
                { localStorage.getItem('id') ===  props.userId && !props.companyData &&
              <div className=" filter-btn-wrapper btn-wrapper mt-3">
              <Button
                className="btn btn-dark"
                onClick={() => {
                 
                }}>
                Add Project
              </Button>
            </div>

             }
              </div>
            </div>
          )}

          {areas_Filter.includes(listForDropDown[2].id) && listForDropDown[2].title == 'Pending' &&(
            <div className="no-results-wrapper no-appointments my-5">
              <div className="no-results-content">
                <p className={'noRecord-desc'}>No Projects Pending</p>
                { localStorage.getItem('id') ===  props.userId && !props.companyData &&
              <div className=" filter-btn-wrapper btn-wrapper mt-3">
              <Button
                className="btn btn-dark"
                onClick={() => {
                 
                }}>
                Add Project
              </Button>
            </div>

             }
              </div>
            </div>
          )}

{areas_Filter.includes(listForDropDown[2].id) &&  listForDropDown[2].title != 'Pending' &&(
            <div className="no-results-wrapper no-appointments my-5">
              <div className="no-results-content">
                <p className={'noRecord-desc'}>No Offerings Added</p>
                { localStorage.getItem('id') ===  props.userId && !props.companyData &&
              <div className=" filter-btn-wrapper btn-wrapper mt-3">
              <Button
                className="btn btn-dark"
                onClick={() => {
                 
                }}>
                Add Offerings
              </Button>
            </div>

             }
              </div>
            </div>
          )}

          {areas_Filter.includes(listForDropDown[3].id) && listForDropDown[3].title == 'Potential' &&(
            <div className="no-results-wrapper no-appointments my-5">
              <div className="no-results-content">
                <p className={'noRecord-desc'}>No Projects</p>
                { localStorage.getItem('id') ===  props.userId && !props.companyData &&
              <div className=" filter-btn-wrapper btn-wrapper mt-3">
              <Button
                className="btn btn-dark"
                onClick={() => {
                 
                }}>
                Add Project
              </Button>
            </div>

             }
              </div>
            </div>
          )}

{areas_Filter.includes(listForDropDown[3].id) && listForDropDown[3].title == 'On Hold' &&(
            <div className="no-results-wrapper no-appointments my-5">
              <div className="no-results-content">
                <p className={'noRecord-desc'}>No Projects On Hold</p>
                { localStorage.getItem('id') ===  props.userId && !props.companyData &&
              <div className=" filter-btn-wrapper btn-wrapper mt-3">
              <Button
                className="btn btn-dark"
                onClick={() => {
                 
                }}>
                Add Project
              </Button>
            </div>

             }
              </div>
            </div>
          )}

          
        </div>
      </div>
    </>
  );
};

export default ProfileProjects;
