/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Row, Dropdown } from 'react-bootstrap';
import {
  AppointmentCalender,
  AppointmentProfileField,
  EventsItem,
  Footer,
  OppotunityItem,
  SesssionItem,
  SlidingPanel,
  //   ProfileBlock,
  //   ProfileGallery,
} from '../../Components';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import commonApi from '../../Services/CommonService';
import moment from 'moment/moment';
import { useDispatch, useSelector } from 'react-redux';
import { getApointSlots } from '../../Store/Actions/AppointmentAction';
import { DEFAULT_IMAGE } from '../../Utils/env';
import { arrayColumn, parseUrl } from '../../Utils/commonUtils';
import RenderSessions from '../../Components/HomePage/RenderSessions';
import RenderOpportunities from '../../Components/HomePage/RenderOpportunities';
import { useHistory } from 'react-router-dom';
import { setItem, getItem, removeItem } from '../../Utils/LocalStorage';
import CustomModal from '../../Components/CustomModal';
import CardDetailSimple from '../../Components/Forms/CardDetailSimple';
import { successMessage, errorMessage } from '../../Utils/ToastMessages';
import LoaderSpinner from '../../Components/Modals/LoaderSpinner';
import { callDeepLink } from '../../Store/Actions/DeepLinkAction';
import Scrollbars from 'react-custom-scrollbars';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import ProfileTabs from '../../Components/ProfileBlock/ProfileTabs';
import {
  DEFAULT_IMAGE_COMPANYBANNER,
  DEFAULT_IMAGE_COMPANYLOGO,
} from '../../Constants/Images';
import { FormattedText } from '../../Utils/Helpers';
import { labels } from '../../Constants/Translations';
import { selected_lang } from '../../Utils/env';
import $ from 'jquery';
import Slider from 'react-slick';
import { CustomTabs } from '../../Components';
import {
  FacebookShareButton,
  WhatsappShareButton,
  EmailShareButton,
  TwitterShareButton,
  LinkedinShareButton,
} from 'react-share';
import { flushSync } from 'react-dom';
import LandingPage2 from '../LandingPage/Newindex';
import LandingPageChat from '../LandingPage/LandingPageChat';

const ProfileBlockDummy = ({
  user,
  companyData,
  userProfile,
  userData,
  urlData,
  setProfileLinkModel,
}) => {
  const { pathname, search } = window.location;
  const urlParts = pathname.split('/').filter(Boolean);

  const [showMore, setShowMore] = useState(false);
  const showMoreClass = showMore ? 'show-all' : '';


  const lines = userProfile
    ? userData.bio
      ? userData.bio?.split('\n')
      : ''
    : companyData.bio
      ? companyData.bio?.split('\n')
      : '';

  return (
    <div>
      <div className="company-profile-block">
        <img
          src={
            companyData.banner
              ? companyData.banner
              : userProfile
                ? DEFAULT_IMAGE
                : DEFAULT_IMAGE_COMPANYBANNER
          }
          alt=""
        />

        <span
          className="profile-share as-link"
          onClick={() => {
            setProfileLinkModel('link_Profile_model');
          }}>
          <i className="icon-share-option" />
        </span>

        {!userProfile && (
          <div className="profile-block-img">
            <img
              src={
                companyData.company_logo
                  ? companyData.company_logo
                  : DEFAULT_IMAGE_COMPANYLOGO
              }
              alt=""
            />
          </div>
        )}

        {userProfile && urlData.userId !== '' && (
          <div className="profile-block-img">
            <img
              src={userData.profile_img ? userData.profile_img : DEFAULT_IMAGE}
              alt=""
            />
          </div>
        )}

        <div className="company-profile-block-iner-content">
          {companyData.company_name ? (
            <h1>{companyData.company_name}</h1>
          ) : userData?.first_name ? (
            <h1>{userData?.first_name + ' ' + userData?.last_name}</h1>
          ) : (
            <h1>Loving Life</h1>
          )}

          {!userProfile && urlData.userId !== '' && urlParts[1] && (
            <div className="company_user_detail">
              <img
                src={
                  userData.profile_img ? userData.profile_img : DEFAULT_IMAGE
                }
                alt=""
              />

              <span className="company_user_name">
                {userData?.first_name + ' ' + userData?.last_name}
              </span>
            </div>
          )}

          {companyData.company_type && (
            <div className="mt-2">{companyData.company_type}</div>
          )}

          {!userProfile && (
            <FormattedText
              className={`profile-description ${showMoreClass}`}
              content={companyData.bio ? companyData.bio : ''}
            />
          )}

          {userProfile && (
            <FormattedText
              className={`profile-description ${showMoreClass}`}
              content={userData.bio ? userData.bio : ''}
            />
          )}

          <span className="profile-hashtag">
            #elderly #disabilityassistance #assistedliving
          </span>

          {(companyData.bio?.length > 250 || lines?.length > 3) && (
            <>
              {' '}
              <span
                className="show_more-btn"
                onClick={() => setShowMore(!showMore)}>
                {!showMore
                  ? labels.show_more[selected_lang]
                  : labels.show_less[selected_lang]}
              </span>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const Home = props => {
  // const [loader, setLoader] = useState(false);
  const [data, setData] = useState({
    time: '',
    name: '',
    email: '',
    notes: '',
    date: '',
    eventType: '1-on-1',
    dropdownValue: '',
  });
  const [currentTab, setCurrentTab] = useState('1-on-1');
  // const [currentUser, setCurrentUser] = useState({});
  const [showProfileFields, setShowProfileFields] = useState(false);
  // const [tab2, setTab2] = useState('Two');
  const [appointmentType, setAppointmentType] = useState('1');
  const [appointmentSubType, setAppointmentSubType] = useState([]);
  const [scheduleId, setScheduleId] = useState('');
  //   const [startDate, setStartDate] = useState(new Date());
  // const [allSlots, setAllSlots] = useState([]);
  const [showLogin, setShowLogin] = useState(null);
  const [showLoginModal, setShowLoginModal] = useState('');
  const [showForm, setShowForm] = useState(false);
  const [payLoadIs, setPayLoadIs] = useState({});
  const [showModaL, setShowModel] = useState(false);
  const [showList, setShowList] = useState('');
  // const [userProfile, setUserProfile] = useState(false);
  const [cardDeatil, setCardDeatil] = useState([]);
  const [apiCallCount, setApiCallCount] = useState(0);
  const [appointmentDisable, setAppointmentDisable] = useState([]);
  const [appointmentDetail, setAppointmentDetail] = useState({
    appointment_type: '',
    cost: '',
    day: '',
    description: '',
    duration: '',
    id: '',
    qr_code: '',
    title: '',
    user_id: '',
    session_type: '',
  });
  const [dataofTransFormation, setDataofTransFormation] = useState([]);
  const [dataofRecruitment, setDataofRecruitment] = useState([]);
  const [dataofSession, setDataofSession] = useState([]);
  const [showModal, setShowModal] = useState('');
  const ticketTabs = ['Before', 'After'];
  const [tabType, setTabType] = useState('Before');
  const [currentComponent, setCurrentComponent] = useState('sessions');
  const [sessionType, setGalleryType] = useState('happening');
  const [serverTime, setServerTime] = useState('');
  const [profileLinkModel, setProfileLinkModel] = useState('');
  const [showChat, setShowChat] = useState('');
  const [avaiabaleDates, setAvaiabaleDates] = useState([]);
  const [hideSlotsInMobileView, setHideSlotsInMobileView] = useState(false);
  const [isChatHovered, setIsChatHovered] = useState(false);
  const [userDetail, setUserDetail] = useState({
    id: '',
    first_name: '',
    last_name: '',
    profile_img: DEFAULT_IMAGE,
    unique_user_name: '',
    areas: '',
    categories: '',
  });

  const imgSliderSettings = {
    dots: true,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: false,
  };
  const [showLoader, setshowLoader] = useState(false);

  const [dataItem, setDataItem] = useState({});
  const [urlForlogin, setUrlForlogin] = useState('');
  const [itemType, setItemType] = useState('');

  const [responsiveForSlots, setResponsiveForSlots] = useState(
    window.innerWidth <= 768,
  );

  const [responsive, setResponsive] = useState(window.innerWidth <= 650);

  const [showUsersList, toggleUsersModal] = useState(false);

  const urlString = String(window.location);
  // const {type, id} = useParams();
  const history = useHistory();
  const location = useLocation();

  const { pathname, search } = window.location;
  const urlParts = pathname.split('/').filter(Boolean);
  const searchParams = new URLSearchParams(search);
  let id = searchParams.get('consult');
  // const profileId = searchParams.get('id');
  const [userProfile, setUserProfile] = useState(
    /^\d+$/.test(urlParts[0]) ? true : false,
  );

  const hasConsultTypeParam = searchParams.has('consult');

  const dispatch = useDispatch();
  const appointmentTypeData = useSelector(state => state.appointmentTypes);
  const startDate = useSelector(state => state.apointCurrentDate);
  const currentUserInfo = useSelector(state => state.currentUserData);
  const companyInfo = useSelector(state => state.userCompanyData.companyDetail);
  const userInfo = useSelector(state => state.userCompanyData.userDetail);
  const urlData = parseUrl(urlString);

  const [formData, setFormData] = useState({
    name: '',
    phoneNumber: '',
    email: '',
  });
  const [formErrors, setFormErrors] = useState({});

  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    padding: '8px 15px 0 0',
    // marginBottom: "8px",
    margin: 0,

    // change background colour if dragging
    // background: isDragging ? "lightgreen" : "grey",

    // styles we need to apply on draggables
    ...draggableStyle,
  });

  const [items, setItems] = useState([
    { id: '1', name: 'Sales' },
    { id: '2', name: 'Team Productivity' },
    { id: '3', name: 'Digital Presence' },
    { id: '4', name: 'Customer Engagement' },
  ]);

  const onDragEnd = result => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const updatedItems = reorder(
      items,
      result.source.index,
      result.destination.index,
    );
    // const updatedItems = [...items];
    // const [movedItem] = updatedItems.splice(result.source.index, 1);
    // console.log(movedItem, 'movedItem');
    // updatedItems.splice(result.destination.index, 0, movedItem);
    // console.log(updatedItems, 'updatedItems');

    flushSync(() => {
      setItems(updatedItems);
    });
  };
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const sendnvitationOutIside = () => {
    let PayloadData = {
      contact: formData.phoneNumber,
      fname: formData.name,
      relationship_id: '5',
      user_type: 'individual',
      company_id: companyInfo?.company_name,
      email: formData.email,
      // appointment_id:'1573'
    };
    // setShowDateModel(true);
    commonApi
      .send_invitation_from_landingpage(PayloadData)
      .then(res => {
        if (res.success === true) {
          localStorage.setItem('token', res.user_token);
          // successMessage(res.message);
          setShowLogin(false);

          if (
            typeof cardDeatil === 'object' &&
            !Array.isArray(cardDeatil) &&
            appointmentDetail?.cost
          ) {
            // show modal
            setShowModel(true);
          } else {
            if (appointmentDetail?.cost) {
              setShowForm(true);
            } else {
              // createAppointment();
              createAppointment();
            }
          }

          setFormData({
            name: '',
            phoneNumber: '',
            email: '',
          });
        }
        if (res.success === false) {
          errorMessage(res.message);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return { type: 'error', message: err.message };
      });
  };
  function ListItem({ quote, index }) {
    return (
      <Draggable draggableId={quote.id} index={index}>
        {(provided, snapshot) => (
          <li
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={getItemStyle(
              snapshot.isDragging,
              provided.draggableProps.style,
            )}>
            <div
              className="border border-black text-black bg-white px-4 py-2 rounded  h-12 pr-4 text-center "
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              {quote.name}
            </div>
          </li>
        )}
      </Draggable>
    );
  }
  const ButtonList = React.memo(function ButtonList({ buttons }) {
    return buttons.map((item, index) => (
      <ListItem quote={item} index={index} key={item.id} />
    ));
  });

  const shareProfileUrl = window.location.href;

  console.log(shareProfileUrl, 'shareProfileUrl');

  const serverTimeCheck = () => {
    // var payLoad = {
    //   search_query: qry,
    //   user_id: localStorage.getItem('user'),
    // };
    commonApi
      .check_server_time()
      .then(res => {
        setServerTime(res.server_time);
      })
      .catch(err => {
        console.log('Err', err);
        return { type: 'error', message: err.message };
      });
  };
  const eventDetails = index => {
    // setShowDetails(sessionList.session[index]);
  };

  useEffect(() => {
    // Function to check window width and update responsive state
    const checkWindowWidth = () => {
      setResponsive(window.innerWidth <= 650);
    };

    // Add event listener for window resize
    window.addEventListener('resize', checkWindowWidth);

    // Clean up the event listener when component unmounts
    return () => {
      window.removeEventListener('resize', checkWindowWidth);
    };
  }, []);
  useEffect(() => {
    const pathSegments = location.pathname.split('/'); // Split the pathname into segments
    const consult = pathSegments[2]; // Extract "consult" value from the URL path

    const queryParams = new URLSearchParams(location.search);
    const hasId = queryParams.has('id');
    const id_slot = queryParams.get('id');

    const { pathname, search } = window.location;

    const searchParams = new URLSearchParams(search);
    const hasTypeParam = searchParams.has('type');

    let infoUrl = window.location.pathname;

    if (getItem('consultData') && getItem('consultDataPayload')) {
      setPayLoadIs(JSON.parse(getItem('consultDataPayload')));
    } else {
      if (
        hasTypeParam ||
        (pathname && (pathname.endsWith('s') || pathname.endsWith('profile')))
      ) {
      } else if (hasId && consult === 'consult') {
        if (localStorage.getItem('token') && localStorage.getItem('id')) {
          const currentUrl = window.location.href;
          history.push('/appointmentpage', {
            redirectTo: currentUrl,
            slot_id: id_slot,
          });
        } else {
          const currentUrl = window.location.href;
          history.push('/login', { redirectTo: currentUrl, slot_id: id_slot });
        }
      } else {
        const lowercasePath = infoUrl.toLowerCase();

        if (infoUrl !== '/' && !lowercasePath.includes('c')) {
        }
      }
    }
    serverTimeCheck();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (Object.keys(companyInfo).length === 0) {
      // alert('')
      setshowLoader(true);
    }

    setTimeout(() => {
      setshowLoader(false);
    }, 3000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const firstProcess = async userId => {
    const userDataRes = await commonApi.user_info({ user_id: userId });

    if (userDataRes) {
      let areas = '';
      let categories = '';
      if (userDataRes.mine_areas && userDataRes.mine_areas.length > 0) {
        areas = arrayColumn(userDataRes.mine_areas, 'title').join(' and ');
      }
      if (
        userDataRes.mine_categories &&
        userDataRes.mine_categories.length > 0
      ) {
        categories = arrayColumn(userDataRes.mine_categories, 'title').join(
          ', ',
        );
      }
      setUserDetail({
        id: userDataRes.data.id,
        first_name: userDataRes.data.first_name,
        last_name: userDataRes.data.last_name,
        profile_img: userDataRes.data.profile_img,
        unique_user_name: userDataRes.data.unique_user_name,
        areas: areas,
        categories: categories,
      });

      const userId = userDataRes.data.id;
      if (userId) {
        getUserAppointments(userId, 1);
      }
    }
  };

  const getUserAppointments = (userId, appointmentId) => {
    const paylaod = {
      user_id: userId,
      appointment_type_id: appointmentId,
    };
    commonApi
      .get_appointment_type(paylaod)
      .then(response => {
        if (response.success === true) {
          setAppointmentSubType(response.data);

          if (urlParts[2] === 'consult' && apiCallCount < 2) {
            setApiCallCount(apiCallCount + 1);

            setScheduleId(id);
          } else {
            setScheduleId(response.data[0].id);
          }
          setAppointmentDetail({
            appointment_type: response.data[0].appointment_type,
            cost: response.data[0].cost,
            day: response.data[0].day,
            description: response.data[0].description,
            duration: response.data[0].duration,
            id: response.data[0].id,
            qr_code: response.data[0].qr_code,
            title: response.data[0].title,
            session_type: response.data[0].session_type,
            user_id: response.data[0].user_id,
          });
        }
      })
      .catch(err => {
        console.log('Err', err);
        return { type: 'error', message: err.message };
      });
  };
  const getCompanyUserAppointments = (urldata, appointmentId) => {
    // const paylaod = {
    //   user_id: urldata.userId,
    //   company_name: urldata.companyName,
    //   appointment_type_id: appointmentId,
    // };

    const paylaod = {
      user_id: urlParts[0] && !urlParts[1] ? '' : urlData.userId,
      company_name: urlParts[0] ? urlParts[0].replace('-', ' ') : '',
      appointment_type_id: appointmentId,
    };
    commonApi
      .get_companyuser_appointment(paylaod)
      .then(response => {
        if (response.success === true) {
          setAppointmentSubType(response.data.appointment_types);
          // alert('!ddd');
          // if (id) {
          if (
            hasConsultTypeParam &&
            searchParams.get('consult') !== '' &&
            apiCallCount < 1
          ) {
            setApiCallCount(apiCallCount + 1);

            setScheduleId(id);
          } else {
            setScheduleId(
              response.data?.appointment_types &&
                response.data?.appointment_types.length > 0
                ? response.data?.appointment_types[0].id
                : '',
            );
          }
          if (response?.data?.appointment_types.length > 0) {
            get_first_available_appointment(response.data?.appointment_types[0].id)
            setAppointmentDetail({
              appointment_type:
                response.data.appointment_types[0].appointment_type,
              cost: response.data.appointment_types[0].cost,
              day: response.data.appointment_types[0].day,
              description: response.data.appointment_types[0].description,
              duration: response.data.appointment_types[0].duration,
              id: response.data.appointment_types[0].id,
              qr_code: response.data.appointment_types[0].qr_code,
              title: response.data.appointment_types[0].title,
              session_type: response.data.appointment_types[0].session_type,
              user_id: response.data.appointment_types[0].user_id,
            });
          } else {
            // alert('!ddd');
            setAvaiabaleDates([]);

            setAppointmentDetail({
              appointment_type: '',
              cost: '',
              day: '',
              description: '',
              duration: '',
              id: '',
              qr_code: '',
              title: '',
              user_id: '',
              session_type: '',
            });
          }

          // setAppointmentDetail({
          //   appointment_type:
          //     response.data.appointment_types[0].appointment_type,
          //   cost: response.data.appointment_types[0].cost,
          //   day: response.data.appointment_types[0].day,
          //   description: response.data.appointment_types[0].description,
          //   duration: response.data.appointment_types[0].duration,
          //   id: response.data.appointment_types[0].id,
          //   qr_code: response.data.appointment_types[0].qr_code,
          //   title: response.data.appointment_types[0].title,
          //   session_type: response.data.appointment_types[0].session_type,
          //   user_id: response.data.appointment_types[0].user_id,
          // });
        }
      })
      .catch(err => {
        console.log('Err', err);
        return { type: 'error', message: err.message };
      });
  };
  const get_first_available_appointment = id => {
    let PayloadData = {
      schedule_id: id,
    };
    // setShowDateModel(true);
    commonApi
      .get_first_available_appointment(PayloadData)
      .then(response => {
        if (response.status === 200) {
          setAvaiabaleDates(response.all_available_dates);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return { type: 'error', message: err.message };
      });
  };

  const getCompanyUserAppointmentSlots = (id, date, urldata) => {
    const dateObj = date ? new Date(date) : new Date();

    setData({
      ...data, // Copy all other properties from the existing data object
      date: dateObj,
    });

    const paylaod = {
      // user_id: urldata.userId,
      company_name: urlParts[0] ? urlParts[0].replace('-', ' ') : '',
      date: moment(dateObj).format('ll').toString(),
      day: moment(dateObj).format('dddd').toString().toLowerCase(),
      schedule_id: id,
    };
    commonApi
      .get_companyuser_consult(paylaod)
      .then(response => {
        if (response.success === true) {
          if (
            response.company_detail?.schedules &&
            response.company_detail?.schedules?.appointment_type
          ) {
            setAppointmentType(
              response.company_detail?.schedules?.appointment_type,
            );
          }
          const found = appointmentTypeData.find(
            objs =>
              objs.id === response.company_detail?.schedules?.appointment_type,
          );
          if (response?.company_detail?.schedules) {
            setAppointmentDetail({
              appointment_type: found ? found.appointment_type : 'Consult',
              cost: response.company_detail.schedules.cost,
              day: response.company_detail.schedules.day,
              description: response.company_detail.schedules.description,
              duration: response.company_detail.schedules.duration,
              id: response.company_detail.schedules.id,
              qr_code: response.company_detail.schedules.qr_code,
              title: response.company_detail.schedules.title,
              session_type: response.company_detail.schedules.session_type,
              user_id: response.company_detail.schedules.user_id,
            });
          }

          setAppointmentDisable([]);

          dispatch(
            getApointSlots(
              response?.company_detail?.appointments
                ? response?.company_detail?.appointments
                : [],
            ),
          );
        }
      })
      .catch(err => {
        console.log('Err', err);
        return { type: 'error', message: err.message };
      });
  };

  const stripePayment = data => {
    commonApi
      .stripe_payment(data)
      .then(response => {
        if (response.stripe === true) {
          createAppointment();
          getMyCards();
        } else {
          errorMessage(response.message);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return { type: 'error', message: err.message };
      });
  };

  const createAppointment = () => {
    commonApi
      .create_appointment(payLoadIs)
      .then(response => {
        if (response.success === true) {
          setAppointmentDisable(arr => [...arr, payLoadIs.slot_id]);
          successMessage(response.message);
          setPayLoadIs({});
        } else {
          if (response.occupied) {
            setAppointmentDisable(arr => [...arr, payLoadIs.slot_id]);
            setPayLoadIs({});
          }
          errorMessage(response.message);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return { type: 'error', message: err.message };
      });
  };

  const getMyCards = () => {
    commonApi
      .get_my_card()
      .then(res => {
        if (res.status === 200) {
          const deafaultCard = res.data.find(
            item => item.card_type === 'default',
          );
          setCardDeatil(deafaultCard);
        }
      })
      .catch(err => {
        errorMessage(err.response.data.message);
        console.log('Err', err);
        return { type: 'error', message: err.message };
      });
  };

  useEffect(() => { }, [userInfo]);

  useEffect(() => {
    if (currentUserInfo.id) {
      firstProcess(currentUserInfo.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUserInfo]);

  const handleCompanyUserAppointments = (a, urldata) => {
    if (urldata.userId) {
      // if(apiCallCount > 1){
      getCompanyUserAppointments(urldata, a);
      // }
    } else {
    }
  };

  const handleCompanyUserAppointmentSlots = (a, b) => {
    if (urlData.userId) {
      getCompanyUserAppointmentSlots(a, b, urlData);
    } else {
    }
  };

  useEffect(() => {
    handleCompanyUserAppointments(appointmentType, urlData);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointmentType]);

  useEffect(() => {
    if (scheduleId !== '') {
      get_first_available_appointment(scheduleId);
    }
    if (startDate !== '') {
      // if (!responsiveForSlots) {
      handleCompanyUserAppointmentSlots(scheduleId, startDate);

      // }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scheduleId, startDate]);

  useEffect(() => {
    if (showForm) {
    } else {
      getMyCards();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showForm]);

  useEffect(() => {
    dispatch(callDeepLink());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showLogin]);

  const updateData = item => {
    const tempData = { ...data, ...item };

    if (getItem('consultData')) {
      const selectedAppointment = JSON.parse(getItem('consultData'));

      setData({
        ...tempData, // Copy all other properties from the existing data object

        time: selectedAppointment.time,
      });
    } else {
      setData(tempData);
    }
    setTimeout(() => {
      removeItem('consultData');
      removeItem('consultDataPayload');
    }, 1000);
  };
  const handleDropdowndesign = subtype => {
    if (subtype.length > 0) {
      $('.profile-calendar')
        .find('.react-calendar__navigation')
        .addClass('calendar_margin');
    } else if (subtype.length === 0) {
      $('.profile-calendar')
        .find('.react-calendar__navigation')
        .removeClass('calendar_margin');
    }
  };

  useEffect(() => {
    handleDropdowndesign(appointmentSubType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointmentSubType]);

  const handleLoginModal = (item, url, itemType) => {
    if (showModal) {
      setShowList(showModal);
      setShowModal('');
    }
    setShowLoginModal('loginModal');

    setDataItem(item);
    setUrlForlogin(url);
    setItemType(itemType ? itemType : '');
  };

  const AddUserInCompany = (item, url, typefromMeetups) => {
    const payload = {
      company_name: urlParts[0] ? urlParts[0].replace('-', ' ') : '',
      user_id: localStorage.getItem('id'),
    };

    commonApi
      .add_user_in_company(payload)
      .then(res => {
        console.log(url, item);
        if (typefromMeetups) {
          history.push(url, {
            typeIs: item.event == 'no' ? 'virtual' : 'in-person',
            meetup_item: item,
          });
        } else {
          history.push(url, { typeIs: 'recruitment', opprtuntity_item: item });
        }

        // console.log(history,'history')
      })
      .catch(err => {
        console.log('Err', err);
        return { type: 'error', message: err.message };
      });
  };
  const styles = `
  body {
    ${profileLinkModel === '' && !showLogin
      ? 'overflow: hidden !important;'
      : 'overflow: hidden !important;'
    }

    ${responsive && showChat 
      ? 'overflow: hidden !important;'
      :profileLinkModel == '' && 'overflow: visible !important;'
    }
    // ${isChatHovered
    //   ? 'overflow: hidden !important;'
    //   : 'overflow: visible !important;'
    // }
    /* Add other styles here */
  }
`;

  const handleSubmit = e => {
    e.preventDefault();

    const errors = {};
    if (!formData.name.trim()) {
      errors.name = 'Name is required';
    }
    if (!formData.phoneNumber.trim()) {
      errors.phoneNumber = 'Phone number is required';
    }
    // if (!formData.email.trim()) {
    //   errors.email = 'Email is required';
    // } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
    //   errors.email = 'Email is invalid';
    // }
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
    } else {
      sendnvitationOutIside();
    }

    // Perform form validation
  };

  const handleInputChange = e => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setFormErrors({ ...formErrors, [name]: '' }); // Clear validation error on input change
  };

  const handleMouseEnter = () => {
    setIsChatHovered(true);

  };

  const handleMouseLeave = () => {
    setIsChatHovered(false);

  };

  //   const bookAppointment = slot => {
  //     const date = new Date(slot.new_start_time);
  //     const appointmentPayload = {
  //  complete_time: slot.new_start_time.replaceAll('/', '-'),
  //       date: moment(date).format('ll').toString(),
  //       day: moment(date).format('dddd').toString(),
  //       mentor_id: slot.creator_id,
  //       slot_id: slot.id,
  //       start_time: slot.start_time,
  //     }
  //     createAppointment(appointmentPayload);
  //     // setAppointmentDisable([...appointmentDisable, slot.id]);
  //     removeTime();
  //   };

  console.log(responsive, showChat, 'showchat');
  return (
    <>
      <div className="appointment-page-wrapper Consult-Page Profile_page refferal-list-wrapper">
        {showLoader && <LoaderSpinner />}

        <div className="appointment-content-wrapper">
          <Container>
            {currentTab === '1-on-1' && (
              <Row className="consult-page">
                <Col md={7} sm={7} xs={12}>
                  <>
                    <ProfileBlockDummy
                      user={currentUserInfo}
                      companyData={companyInfo}
                      userProfile={userProfile}
                      setUserProfile={setUserProfile}
                      userData={userInfo}
                      userDetail={userDetail}
                      urlData={urlData}
                      setProfileLinkModel={setProfileLinkModel}
                    />

                    {!userProfile && (
                      <>
                        {!showProfileFields && (
                          <div className="calender-wrapper">
                            {!responsive && (
                              <>
                                {appointmentTypeData &&
                                  appointmentTypeData.length > 0 &&
                                  !showLogin &&
                                  !showForm &&
                                  !showModaL &&
                                  profileLinkModel !== 'link_Profile_model' && (
                                    <div className="menus-dropdowns consult_dropdown">
                                      <Dropdown
                                        className="select-wrapper"
                                        onSelect={(eventKey, event) => {
                                          setAppointmentType(eventKey);
                                        }}
                                        drop="down">
                                        <Dropdown.Toggle variant="primary">
                                          {appointmentType
                                            ? appointmentTypeData.find(
                                              item =>
                                                item.id === appointmentType,
                                            ).appointment_type
                                            : 'Select Role'}
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                          {appointmentTypeData.map(
                                            (item, index) => (
                                              <Dropdown.Item
                                                key={`sessionCategories-${index}`}
                                                eventKey={item.id}
                                                active={
                                                  item.id === appointmentType
                                                }>
                                                {item.appointment_type}
                                              </Dropdown.Item>
                                            ),
                                          )}
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </div>
                                  )}
                                {appointmentSubType &&
                                  appointmentSubType.length > 0 &&
                                  !showLogin &&
                                  !showForm &&
                                  !showModaL &&
                                  profileLinkModel !== 'link_Profile_model' && (
                                    <div className="menus-dropdowns consult_type_dropdown">
                                      <Dropdown
                                        className="select-wrapper"
                                        onSelect={(eventKey, event) => {
                                          setScheduleId(eventKey);
                                        }}
                                        drop="down">
                                        <Dropdown.Toggle variant="primary">
                                          {scheduleId
                                            ? appointmentSubType.find(
                                              item => item.id === scheduleId,
                                            ).title
                                            : 'Select Role'}
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                          {appointmentSubType.map(
                                            (item, index) => (
                                              <Dropdown.Item
                                                key={`sessionCategories-${index}`}
                                                eventKey={item.id.toString()}
                                                active={item.id === scheduleId}>
                                                {item.title}
                                              </Dropdown.Item>
                                            ),
                                          )}
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </div>
                                  )}
                              </>
                            )}

                            {responsive && !showChat && (
                              <>
                                {appointmentTypeData &&
                                  appointmentTypeData.length > 0 &&
                                  !showLogin &&
                                  !showForm &&
                                  !showModaL &&
                                  profileLinkModel !== 'link_Profile_model' && (
                                    <div className="menus-dropdowns consult_dropdown">
                                      <Dropdown
                                        className="select-wrapper"
                                        onSelect={(eventKey, event) => {
                                          setAppointmentType(eventKey);
                                        }}
                                        drop="down">
                                        <Dropdown.Toggle variant="primary">
                                          {appointmentType
                                            ? appointmentTypeData.find(
                                              item =>
                                                item.id === appointmentType,
                                            ).appointment_type
                                            : 'Select Role'}
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                          {appointmentTypeData.map(
                                            (item, index) => (
                                              <Dropdown.Item
                                                key={`sessionCategories-${index}`}
                                                eventKey={item.id}
                                                active={
                                                  item.id === appointmentType
                                                }>
                                                {item.appointment_type}
                                              </Dropdown.Item>
                                            ),
                                          )}
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </div>
                                  )}
                                {appointmentSubType &&
                                  appointmentSubType.length > 0 &&
                                  !showLogin &&
                                  !showForm &&
                                  !showModaL &&
                                  profileLinkModel !== 'link_Profile_model' && (
                                    <div className="menus-dropdowns consult_type_dropdown">
                                      <Dropdown
                                        className="select-wrapper"
                                        onSelect={(eventKey, event) => {
                                          setScheduleId(eventKey);
                                        }}
                                        drop="down">
                                        <Dropdown.Toggle variant="primary">
                                          {scheduleId
                                            ? appointmentSubType.find(
                                              item => item.id === scheduleId,
                                            ).title
                                            : 'Select Role'}
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                          {appointmentSubType.map(
                                            (item, index) => (
                                              <Dropdown.Item
                                                key={`sessionCategories-${index}`}
                                                eventKey={item.id.toString()}
                                                active={item.id === scheduleId}>
                                                {item.title}
                                              </Dropdown.Item>
                                            ),
                                          )}
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </div>
                                  )}
                              </>
                            )}

                            <AppointmentCalender
                              data={data}
                              updateData={data => {
                                updateData(data);
                              }}
                              showProfileField={() =>
                                setShowProfileFields(true)
                              }
                              setHideSlotsInMobileView={
                                setHideSlotsInMobileView
                              }
                              hideSlotsInMobileView={hideSlotsInMobileView}
                              // setResponsive={setResponsive}
                              // responsive={responsive}
                              avaiabaleDates={avaiabaleDates}
                              setShowLogin={e => setShowLogin(e)}
                              showLogin={showLogin}
                              setShowForm={setShowForm}
                              setPayLoadIs={setPayLoadIs}
                              setShowModel={setShowModel}
                              setCardDeatil={setCardDeatil}
                              appointmentDetail={appointmentDetail}
                              appointmentSubType={appointmentSubType}
                              cardDeatil={cardDeatil}
                              appointmentDisable={appointmentDisable}
                            />
                          </div>
                        )}
                      </>
                    )}
                  </>

                  {showProfileFields && (
                    <AppointmentProfileField
                      data={data}
                      updateData={data => updateData(data)}
                      hideProfileField={() => setShowProfileFields(false)}
                    />
                  )}
                </Col>
                <Col md={4} sm={4} xs={12}>
                  <ProfileTabs
                    user={currentUserInfo}
                    companyData={companyInfo}
                    userProfile={userProfile}
                    setCurrentTab={setCurrentTab}
                    setDataofTransFormation={setDataofTransFormation}
                    setDataofRecruitment={setDataofRecruitment}
                    setDataofSession={setDataofSession}
                    setShowModal={setShowModal}
                    AddUserInCompany={AddUserInCompany}
                  />
                </Col>
                {showLogin && (
                  <div
                    id="bottom-bar"
                    className="flex justify-center"
                    style={{
                      bottom: '0',
                      position: 'sticky',
                      width: '100%',
                      zIndex: '999',
                    }}>
                    <div
                      className="mobile"
                      style={{
                        overflowY: 'scroll',
                        maxHeight: '80vh',
                      }}>
                      <div
                        style={{
                          fontFamily: 'Inter',
                          display: 'flex',
                          flexDirection: 'column',
                        }}>
                        <form onSubmit={handleSubmit}>
                          <div style={{ padding: '19px' }}>
                            <h1>
                              Contact Info
                              <span
                                className="as-link"
                                style={{ float: 'right' }}
                                onClick={() => {
                                  setShowLogin(false);
                                }}>
                                <i className="fa-solid fa-xmark"></i>
                              </span>
                            </h1>
                            <p>We will not sell or share your details</p>
                            <div className="form-group">
                              <input
                                type="text"
                                name="name"
                                className={`form-control ${formErrors.name && 'is-invalid'
                                  }`}
                                placeholder="Your Name"
                                value={formData.name}
                                onChange={handleInputChange}
                              />
                              <div className="invalid-feedback">
                                {formErrors.name}
                              </div>
                            </div>
                            <div className="form-group">
                              <input
                                type="number"
                                name="phoneNumber"
                                className={`form-control ${formErrors.phoneNumber && 'is-invalid'
                                  }`}
                                placeholder="Phone Number"
                                value={formData.phoneNumber}
                                onChange={handleInputChange}
                              />
                              <div className="invalid-feedback">
                                {formErrors.phoneNumber}
                              </div>
                            </div>
                            <div className="form-group">
                              <input
                                type="email"
                                name="email"
                                className={`form-control ${formErrors.email && 'is-invalid'
                                  }`}
                                placeholder="Email"
                                value={formData.email}
                                onChange={handleInputChange}
                              />
                              <div className="invalid-feedback">
                                {formErrors.email}
                              </div>
                            </div>
                          </div>
                          <div style={{ paddingLeft: '17px' }}>
                            <h1
                              style={{
                                fontSize: '16px',
                                fontWeight: '600',
                                lineHeight: '20px',
                                textAlign: 'left',
                                color: '#000000',
                              }}>
                              Order Your Pain Points
                            </h1>
                            <p
                              style={{
                                fontSize: '16px',
                                fontWeight: '400',
                                lineHeight: '20px',

                                textAlign: 'left',
                                paddingTop: '5px',
                                color: '#7A7A7A',
                              }}>
                              Drag boxes below to change order
                            </p>
                            <div
                              style={{
                                top: '97px',
                                left: '17px',
                                borderRadius: '8px',
                                color: '#000000',
                                listStyle: 'none',
                              }}
                              className="drag_btns">
                              {Object.entries(items).length > 0 && (
                                <DragDropContext onDragEnd={onDragEnd}>
                                  <Droppable droppableId="list">
                                    {provided => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}>
                                        <ButtonList buttons={items} />
                                        {provided.placeholder}
                                      </div>
                                    )}
                                  </Droppable>
                                </DragDropContext>
                              )}

                              <div
                                onClick={handleSubmit}
                                style={{
                                  paddingTop: '10px',
                                  paddingBottom: '16px',
                                  display: 'flex',
                                  justifyContent: 'center',
                                  paddingRight: '17px',
                                }}>
                                <a
                                  // href="https://calendly.com/kdcoe/roi-intro"
                                  className="text-white px-6 py-3 rounded-md"
                                  target="_blank"
                                  rel="noreferrer"
                                  style={{
                                    width: '329px',
                                    height: '56px',
                                    top: '695px',
                                    left: '33px',
                                    borderRadius: '5px',
                                    background: '#78B054',
                                    display: 'inline-block', // Ensure it behaves like a button
                                    textDecoration: 'none', // Remove default anchor styling
                                    textAlign: 'center', // Center text horizontally
                                    // lineHeight: '56px', // Center text vertically
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                  }}>
                                  CHOOSE A TIME
                                </a>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                )}
                {showLoginModal === 'loginModal' && (
                  <CustomModal
                    // position="bottom"
                    size="small"
                    onClose={() => setShowLoginModal('')}>
                    <div className="leave-session-content">
                      <h3>Login</h3>
                      <p>For more information please login.</p>

                      <form>
                        <div className="text-center">
                          <Button
                            className="btn-dark"
                            onClick={() => {
                              AddUserInCompany(dataItem, urlForlogin, itemType);
                            }}>
                            Login
                          </Button>
                          <Button
                            className="btn-dark"
                            onClick={() => {
                              setShowLoginModal('');
                              setShowModal(showList);
                            }}>
                            No
                          </Button>
                        </div>
                      </form>
                    </div>
                  </CustomModal>
                )}
                {showForm && payLoadIs && appointmentDetail && (
                  <CardDetailSimple
                    dataOne={payLoadIs}
                    dataTwo={appointmentDetail}
                    setShowForm={setShowForm}
                    stripePayment={stripePayment}
                    onClose={() => setShowForm(false)}
                    type={'profile_link'}
                  />
                )}
                {showModaL && (
                  <CustomModal
                    size="medium"
                    onClose={() => setShowModel(false)}
                    heightofModel={'45%'}>
                    <div className="leave-session-content text-left">
                      <h3>Please confirm you would like to purchase Consult</h3>
                      <p>
                        ${appointmentDetail.cost} will be deducted from your
                        card listed below.
                      </p>
                      <div className="mt-3">
                        <p>
                          Card Last 4 Digits:{' '}
                          <b>
                            {typeof cardDeatil === 'object' &&
                              !Array.isArray(cardDeatil)
                              ? cardDeatil.card_number
                              : ''}
                          </b>
                        </p>
                      </div>
                      <div className="">
                        <Button
                          className="btn-dark"
                          onClick={() => {
                            setShowModel(false);

                            setShowForm(true);
                          }}>
                          Use A Different Card
                        </Button>
                        <Button
                          className="btn-dark"
                          onClick={() => {
                            let data = {
                              amount: appointmentDetail.cost,
                              card: '',
                              card_id: cardDeatil.id,
                              cardholder_name: cardDeatil.cardholder_name,
                              complete_time: payLoadIs.complete_time,
                              device_type: 'web',
                              id_transaction_type: payLoadIs.slot_id,
                              stripe_token: '',
                              transaction_type: 'appointment',
                              save_card: 'no',
                            };
                            stripePayment(data);
                            setShowModel(false);
                          }}>
                          Proceed
                        </Button>
                        <Button
                          className="btn-dark"
                          onClick={() => {
                            setShowForm(false);
                            setShowModel(false);
                          }}>
                          I Changed My Mind
                        </Button>
                      </div>
                    </div>
                  </CustomModal>
                )}
                {showModal === 'tranformationDetail' &&
                  dataofTransFormation && (
                    <CustomModal
                      size="large"
                      onClose={() => {
                        setShowModal('');
                        setDataofTransFormation([]);
                      }}
                      className="profile_page_tranformation">
                      <div className="w-100 d-flex justify-center">
                        <div className="fix-width-forms primary_panel_content">
                          <div className="d-flex align-items-center justify-content-center fix-width-forms w-100">
                            <div className="create-ticket-tabs w-100">
                              <CustomTabs
                                darkmode
                                tabs={ticketTabs}
                                active={tabType}
                                onSelect={item => setTabType(item)}
                              />
                            </div>
                          </div>

                          <div className="heading mb-3">
                            <h2>{dataofTransFormation.title}</h2>
                          </div>

                          <div className="refferal-list-wrapper">
                            <Scrollbars>
                              <div className="fix-width-forms">
                                <div className="events-wrapper">
                                  <div className="events-img-slider">
                                    <Slider
                                      {...imgSliderSettings}
                                      className="snapshot-slider">
                                      {tabType === 'Before' &&
                                        Object.entries(
                                          dataofTransFormation.before_images,
                                        ).length > 0 &&
                                        dataofTransFormation.before_images.map(
                                          (item, index) => (
                                            <img
                                              src={item}
                                              alt=""
                                              key={index}
                                            />
                                          ),
                                        )}
                                      {tabType === 'After' &&
                                        Object.entries(
                                          dataofTransFormation.after_images,
                                        ).length > 0 &&
                                        dataofTransFormation.after_images.map(
                                          (item, index) => (
                                            <img
                                              src={item}
                                              alt=""
                                              key={index}
                                            />
                                          ),
                                        )}
                                    </Slider>
                                  </div>

                                  <div className="events-headings">
                                    {/* <h2>{props.item.creator_name}</h2> */}
                                    <div className="d-flex align-item-center justify-content-between">
                                      <span className="category-name">
                                        {dataofTransFormation.category_title}
                                      </span>
                                    </div>
                                  </div>

                                  <div className="events-desc-wrapper">
                                    <p>{dataofTransFormation.description}</p>
                                  </div>
                                </div>
                              </div>
                            </Scrollbars>
                          </div>
                        </div>
                      </div>
                    </CustomModal>
                  )}
                {showModal === 'sessionList' && dataofSession && (
                  <CustomModal
                    size="medium"
                    onClose={() => {
                      setShowModal('');
                      setDataofTransFormation([]);
                    }}
                    className="profile_page_tranformation">
                    <div className="w-100 d-flex justify-center">
                      <div className="fix-width-forms primary_panel_content">
                        <div className="refferal-list-wrapper">
                          <Scrollbars>
                            <div className="event-tabs tabs-style-2 mb-0">
                              <ul>
                                <li>
                                  <Button
                                    className={
                                      currentComponent === 'sessions'
                                        ? 'active'
                                        : ''
                                    }
                                    onClick={() => {
                                      if (sessionType === 'upcoming') {
                                        setGalleryType('happening');
                                      }

                                      setCurrentComponent('sessions');
                                      if (currentComponent === 'events') {
                                        //   setShowSpinner(true);
                                      }
                                    }}>
                                    Virtual
                                  </Button>
                                </li>
                                <li>
                                  <Button
                                    className={
                                      currentComponent === 'events'
                                        ? 'active'
                                        : ''
                                    }
                                    onClick={() => {
                                      if (sessionType === 'upcoming') {
                                        setGalleryType('happening');
                                      }
                                      setCurrentComponent('events');
                                      if (currentComponent === 'sessions') {
                                        // setShowSpinner(true);
                                      }
                                    }}>
                                    In-person
                                  </Button>
                                </li>
                              </ul>
                            </div>

                            <div
                              className="session-wrapper session-happening-upcoming-listing"
                              style={{ marginTop: '20px' }}>
                              {Object.entries(dataofSession).length > 0 &&
                                dataofSession.map((item, index) =>
                                  item.event == 'no' &&
                                    currentComponent === 'sessions' ? (
                                    <SesssionItem
                                      key={`session-item-${index}`}
                                      item={item}
                                      heart={sessionType}
                                      componentDecesion={''}
                                      serverTime={serverTime}
                                      type={'link_Profile'}
                                      AddUserInCompany={AddUserInCompany}
                                      handleLoginModal={handleLoginModal}
                                    />
                                  ) : (
                                    item.event == 'yes' &&
                                    currentComponent === 'events' && (
                                      <EventsItem
                                        key={`event-item-${index}`}
                                        item={item}
                                        index={index}
                                        eventDetails={eventDetails}
                                        type={'link_Profile'}
                                        AddUserInCompany={AddUserInCompany}
                                        componentDecesion={''}
                                        handleLoginModal={handleLoginModal}
                                      />
                                    )
                                  ),
                                )}
                            </div>
                          </Scrollbars>
                        </div>
                      </div>
                    </div>
                  </CustomModal>
                )}
                {showModal === 'recruitmentList' && dataofRecruitment && (
                  <CustomModal
                    size="small"
                    onClose={() => {
                      setShowModal('');
                      setDataofTransFormation([]);
                    }}
                    className="profile_page_tranformation">
                    <div className="w-100 d-flex">
                      <div className="fix-width-forms primary_panel_content">
                        <div className="refferal-list-wrapper">
                          <Scrollbars>
                            <div className="opportunities-list-wrapper">
                              {Object.entries(dataofRecruitment).length > 0 ? (
                                Object.entries(dataofRecruitment).length > 0 &&
                                dataofRecruitment.map((item, index) => (
                                  <OppotunityItem
                                    // AddUserInCompany={AddUserInCompany}
                                    key={`opportunity-item-${index}`}
                                    AddUserInCompany={AddUserInCompany}
                                    // // setShowButton={setShowButton}
                                    onShare={() => {
                                      // setProperComponent(
                                      //   'refer-opportunity',
                                      // );
                                    }}
                                    type={'link_Profile'}
                                    // // setChatData={setChatData}
                                    //handleItem={handleItem}
                                    item={item}
                                    hideRefer={true}
                                  />
                                ))
                              ) : (
                                <></>
                              )}
                            </div>
                          </Scrollbars>
                        </div>
                      </div>
                    </div>
                  </CustomModal>
                )}{' '}
              </Row>
            )}

            {currentTab === 'Meetups' && currentUserInfo.id && (
              <Container>
                <Row>
                  <Col md={6} xs={12}>
                    <RenderSessions
                      options={{ showSessions: true, showEvents: true }}
                      user={currentUserInfo}
                    />
                  </Col>
                  <Col md={6} xs={12}></Col>
                </Row>
              </Container>
            )}

            {currentTab === 'Opportunities' && currentUserInfo.id && (
              <Container>
                <Row>
                  <Col md={6} xs={12}>
                    <RenderOpportunities user={currentUserInfo} />
                  </Col>
                  <Col md={6} xs={12}></Col>
                </Row>
              </Container>
            )}
            {urlParts[0]  && urlParts[0].replace('-', ' ') != 'The Market' && !/^\d+$/.test(urlParts[0]) &&
             profileLinkModel !=='link_Profile_model' && 



              <LandingPageChat
                companyName={urlParts[0] ? urlParts[0].replace('-', ' ') : ''}
                setShowChat={setShowChat}
                showChat={showChat}
                isChatHovered={isChatHovered}
                setIsChatHovered={setIsChatHovered}
                handleMouseLeave={handleMouseLeave}
                handleMouseEnter={handleMouseEnter}
              />
            }
          </Container>
        </div>

        {showUsersList && (
          <SlidingPanel
            hidePanel={() => {
              toggleUsersModal(false);
            }}>
            <Scrollbars>
              <div className="business-profile-block">
                <div className="messages-users-list w-100">
                  <div className="message-user-item">
                    <div class="user-img as-link">
                      <img
                        src="https://s3.amazonaws.com/build-dev/group_image/1684921411184.jpg"
                        alt=""
                      />
                    </div>
                    <div class="user-content">
                      <h2>User Name</h2>
                    </div>
                  </div>
                  <div className="message-user-item">
                    <div class="user-img as-link">
                      <img
                        src="https://s3.amazonaws.com/build-dev/group_image/1684921411184.jpg"
                        alt=""
                      />
                    </div>
                    <div class="user-content">
                      <h2>User Name</h2>
                    </div>
                  </div>
                  <div className="message-user-item">
                    <div class="user-img as-link">
                      <img
                        src="https://s3.amazonaws.com/build-dev/group_image/1684921411184.jpg"
                        alt=""
                      />
                    </div>
                    <div class="user-content">
                      <h2>User Name</h2>
                    </div>
                  </div>
                  <div className="message-user-item">
                    <div class="user-img as-link">
                      <img
                        src="https://s3.amazonaws.com/build-dev/group_image/1684921411184.jpg"
                        alt=""
                      />
                    </div>
                    <div class="user-content">
                      <h2>User Name</h2>
                    </div>
                  </div>
                </div>
              </div>
            </Scrollbars>
          </SlidingPanel>
        )}

        <Footer />
      </div>

      {profileLinkModel === 'link_Profile_model' && (
        <div style={{overflow:'hidden'}}>
        <CustomModal
          position="bottom"
          className={'share-links-modal'}
          onClose={() => setProfileLinkModel('')}
        // setTabType={setTabType}
        >
          <div className="camera-options-list w-100 social-links-modal">
            <ul>
              <li className="as-link">
                <FacebookShareButton url={shareProfileUrl} title={false}>
                  <i className="fa-brands fa-facebook"></i>
                </FacebookShareButton>
              </li>
              <li className="as-link">
                <WhatsappShareButton url={shareProfileUrl}>
                  <i className="fa-brands fa-whatsapp"></i>
                </WhatsappShareButton>
              </li>
              <li className="as-link">
                <EmailShareButton url={shareProfileUrl}>
                  <i className="fa-solid fa-envelope"></i>
                </EmailShareButton>
              </li>
              <li className="as-link">
                <TwitterShareButton url={shareProfileUrl}>
                  <i className="fa-brands fa-twitter"></i>
                </TwitterShareButton>
              </li>
              <li className="as-link">
                <LinkedinShareButton url={shareProfileUrl}>
                  <i className="fa-brands fa-linkedin"></i>
                </LinkedinShareButton>
              </li>
            </ul>
          </div>
        </CustomModal>
        </div>
      )}
      <style
        dangerouslySetInnerHTML={{
          __html: styles,
        }}
      />
    </>
  );
  // }
};

export default Home;
