import React, {useState} from 'react';
import {Col, Row} from 'react-bootstrap';
import {DEFAULT_IMAGE} from '../../Utils/env';
import ProfileComponent from '../ProfilePage/ProfileComponent';
import {fixName} from '../../Utils/commonUtils';
import SlidingPanel from '../SlidingPanel';
import Scrollbars from 'react-custom-scrollbars';

const ListUsers = ({allUsers, onClose, type}) => {
  // Structure must be... (allUsers)
  //     id : id,
  //     first_name : first_name,
  //     last_name : last_name,
  //     profile_img : profile_img,
  //     type : moderator/audience (optional)

  const [showView, setShowView] = useState('default');
  const [userId, setUserId] = useState('');

  const currentUrl = window.location.href;
  const lastSlashIndex = currentUrl.lastIndexOf('/');
  const newSubstring = currentUrl.substring(lastSlashIndex + 1);

  const ListUesr = props => {
    const validStatus = ['match', 'decline', 'left'];
    return (
      <Row>
        {Object.entries(props.allUsers).length > 0 ? (
          props.allUsers.map(item => {
            return (
              (props.types.length === 0 ||
                (props.types.length > 0 &&
                  props.types.includes(item.type) &&
                  (!item.status ||
                    (item.status &&
                      (item.status === 'accepted' ||
                        (newSubstring === 'history' &&
                          item.status === 'left') ||
                        (item.user_type &&
                          validStatus.includes(item.status))))))) && (
                <Col md={4} xl={3} key={`f-item-${item.id}`}>
                  <div
                    className="stage-list-item"
                   >
                    <div className="stage-person-img"
                     >
                      <img
                      className='as-link'
                      onClick={() => {
                        //
                        setUserId(item.id);
                        setShowView('user-info');
                      }}
                        style={{marginLeft:"0px", marginRight:"0px"}}
                        alt=""
                        src={
                          item.profile_img === ''
                            ? DEFAULT_IMAGE
                            : item.profile_img
                        }
                      />
                    </div>
                    <div className="stage-person-name d-flex justify-content-center">
                      <h3
                       onClick={() => {
                        //
                        setUserId(item.id);
                        setShowView('user-info');
                      }} 
                        className='as-link w-fit'>{fixName(item.first_name, item.last_name)}
                      </h3>
                    </div>
                  </div>
                </Col>
              )
            );
          })
        ) : (
          <p className="text-center">No record found.</p>
        )}
      </Row>
    );
  };

  return (
    <>
      <SlidingPanel
        hidePanel={() => {
          onClose();
        }}>
        <div className="serach-filter-wrapper">
          <div className="headings fix-width-forms">
            <h2>&nbsp;</h2>
          </div>
          <Scrollbars>
            {/* {showView === 'default' && <span className="close as-link" onClick={() => {onClose();}}>
                <i className="fas fa-times" />
            </span>} */}
            <div className="stage-audience-wrapper w-100 fix-width-forms">
              {showView === 'default' &&
                (type ? (
                  <>
                    <div className="stage-header">
                      <h2>Moderators</h2>
                    </div>
                    <div className="stage-list-wrapper">
                      <ListUesr
                        allUsers={allUsers}
                        types={['moderator', 'creator']}
                      />
                    </div>
                    <div className="stage-header">
                      {typeof allUsers.find(
                        objs =>
                          objs.type === 'audience' &&
                          (!objs.status ||
                            (objs.status &&
                              (objs.status === 'accepted' ||
                                (newSubstring === 'history' &&
                                  ['match', 'left'].includes(objs.status))))),
                      ) === 'object' && <h2>Audience</h2>}
                    </div>
                    <div className="stage-list-wrapper">
                      <ListUesr allUsers={allUsers} types={['audience']} />
                    </div>
                  </>
                ) : (
                  <ListUesr allUsers={allUsers} types={[]} />
                ))}
            </div>
          </Scrollbars>
        </div>
      </SlidingPanel>

      {showView === 'user-info' && (
        <ProfileComponent
          userId={userId}
          show={false}
          closeModal={() => {
            setShowView('default');
          }}
          onClose={() => {
            setShowView('default');
          }}
          userData={[]}
          moderator={false}
          // setParticipantType={setParticipantType}
          VoxeetSDK={[]}
          conferenceInfo={[]}
          jdSpecialM={[]}
          jdSpecialA={[]}
        />
      )}
    </>
  );
};

export default ListUsers;
