import React from 'react'
import {useState, useRef} from 'react';
const GalleryIcons = (props) => {
  
    const [threeDots, setThreeDots] = useState(false);

    const userId = localStorage.getItem('id');
    const isCurrentUser = props.userId === userId;
    const isGeneralGallery = props.galleryType === 'general';
  
    const canRenderAddIcon =
      isCurrentUser &&
      isGeneralGallery &&
      !['property', 'payment', 'overview', 'followUp', 'meetup', 'recruitment', 'tasks', 'appointment', 'pipeline'].includes(props.type);
  
    const handleClick = () => {
      switch (props.type) {
        case 'credentials':
          props.handleCommand('', 'addNewSnapshot');
          break;
        case 'projects':
          props.handleCommand('', 'createTransform');
          break;
        case 'appointment':
          props.handleBlock && props.handleBlock(props.userData.data, 'appointment');
          break;
        case 'tasks':
          props.handleCommand && props.handleCommand('', 'create_task');
          break;
        case 'followUp':
          props.handleCommand({ followUp: 'follow_up', date: '' }, 'create_followUp');
          break;
        case 'pipeline':
          props.handleCommand('', 'pipeline');
          break;
        case 'deals':
          props.handleCommand('', 'createDeal');
          break;
        default:
          break;
      }
    };



  
    return (
        <div className="search-tabs-icons search-tabs-profile-gallery-icons">
        {props.userId === localStorage.getItem('id') &&
          props.permission &&
          (props.permission === 'allow' ||
            props.permission?.edit_company === true) &&
          props.galleryType === 'general' &&
          props.type !== 'property' &&
          props.type !== 'payment' &&
          props.type !== 'overview' &&
          props.type !== 'followUp' &&
          props.type !== 'meetup' &&
          props.type !== 'review' &&
          props.type !== 'recruitment' &&
          props.type !== 'tasks' &&
          props.type !== 'appointment' &&
          props.type !== 'pipeline' && (
            <span
              className="icon add-icon"
              onClick={() => {
                if (props.type === 'credentials') {
                  // setAddNewSnapshot(true);
                  props.handleCommand('', 'addNewSnapshot');
                } else if (props.type === 'projects') {
                  props.handleCommand('', 'createTransform');
                }
              }}>
              <i className="icon-plus" />
            </span>
          )}

        {props.userId === localStorage.getItem('id') &&
          !props.permission &&
          (props.profileGalleryType && props.profileGalleryType !== 'market') &&
          props.galleryType === 'general' &&
          props.type !== 'property' &&
          props.type !== 'overview' &&
          props.type !== 'followUp' &&
          props.type !== 'review' &&
          props.type !== 'meetup' && 
          props.type !== 'recruitment' &&
          props.type !== 'tasks' &&
          props.type !==
            'payment'(
              <span
                className="icon add-icon"
                onClick={() => {
                  if (props.type === 'credentials') {
                    // setAddNewSnapshot(true);
                    props.handleCommand('', 'addNewSnapshot');
                  } else if (props.type === 'projects') {
                    props.handleCommand('', 'createTransform');
                  }
                }}>
                <i className="icon-plus" />
              </span>,
            )}

        

        {props.type === 'projects' && (
          <span
            onClick={() => {
              props.setThreeDots(!props.threeDots);
            }}
            className={
              props.threeDots ? 'icon active threeDots' : 'icon threeDots'
            }>
            <i className="fas fa-ellipsis-vertical" />
          </span>
        )}

        {( props.type === 'recruitment' ||
          props.type === 'meetup' ||
          props.type === 'appointment' ||
          props.type === 'tasks' ||
          (props.type === 'payment' && props.fromPage !== 'business') ||
          (props.type === 'deals' &&
            props.userData.deals_permission.create) ||
          (props.type === 'pipeline' &&
            props.userData.pipeline_permission.create_pipeline)) &&
          localStorage.getItem('id') === props.userData.data.id && (
            <>
              <span
                className="icon add-icon"
                onClick={() => {
                  if (props.type === 'appointment') {
                    props.handleBlock &&
                      props.handleBlock(
                        props.userData.data,
                        'appointment',
                      );
                  }
                  if (props.type === 'tasks') {
                    props.handleCommand &&
                      props.handleCommand('', 'create_task');
                  }

                  if (props.type === 'meetup') {
                    props.handleCommand &&
                      props.handleCommand('', 'create_session');
                  }
                  
                  if (props.type === 'payment') {
                    props.handleCommand &&
                      props.handleCommand('', 'create_invoice');
                  }
                  if (props.type === 'recruitment') {
                    props.handleCommand &&
                      props.handleCommand('', 'create_recruitment');
                  }
                  if (
                    props.type === 'followUp' &&
                    localStorage.getItem('id') !==
                      props.userData.data.id
                  ) {
                    props.handleCommand(
                      {followUpType: 'follow_up', date: ''},
                      'create_followUp',
                    );
                  } else if (props.type === 'pipeline') {
                    props.handleCommand('', 'pipeline');
                  }
                  if (props.type === 'deals') {
                    props.handleCommand('', 'createDeal');
                  }
                }}>
                <i className="icon-plus" />
              </span>

              {(props.type === 'tasks'  || props.type ==='appointment' || props.type ==='tasks' || props.type === 'meetup' || props.type === 'recruitment') && (
                  <span
                    className={
                      props.threeDots
                        ? 'icon active threeDots'
                        : 'icon threeDots'
                    }onClick={() => {
                      props.setThreeDots(!props.threeDots);
                    }} >
                    <i className="fas fa-ellipsis-vertical" />
                  </span>
                )}
            </>
          )}

        {(
          props.type === 'recruitment' ||
          props.type === 'meetup' ||
          props.type === 'appointment' ||
          props.type === 'followUp' ||
          props.type === 'tasks' ||
          props.type === 'deals' || (props.type === 'payment' && props.fromPage !== 'business')) &&
          localStorage.getItem('id') !== props.userData.data.id && (
            <>
              <span
                className="icon add-icon"
                onClick={() => {
                  if (props.type === 'appointment') {
                    props.handleBlock &&
                      props.handleBlock(
                        props.userData.data,
                        'appointment',
                      );
                  }
                  if (props.type === 'tasks') {
                    props.handleCommand &&
                      props.handleCommand('', 'create_task');
                  }


                  if (props.type === 'payment') {
                    props.handleCommand &&
                      props.handleCommand('', 'create_invoice');
                  }

                  if (props.type === 'meetup') {
                    props.handleCommand &&
                      props.handleCommand('', 'create_session');
                  }
                  if (props.type === 'recruitment') {
                    props.handleCommand &&
                      props.handleCommand('', 'create_recruitment');
                  }
                  
                  if (
                    props.type === 'followUp' &&
                    localStorage.getItem('id') !==
                      props.userData.data.id
                  ) {
                    props.handleCommand(
                      {followUpType: 'follow_up', date: ''},
                      'create_followUp',
                    );
                  }

                  if (props.type === 'deals') {
                    props.handleCommand('', 'createDeal');
                  }
                }}>
                <i className="icon-plus" />
              </span>

              {props.type !== 'followUp' &&
                // props.type !== 'tasks' &&
                props.type !== 'deals' && props.type !== 'payment' &&
                props.type !== 'pipeline' && (
                  <span
                    className={
                      props.threeDots
                        ? 'icon active threeDots'
                        : 'icon threeDots'
                    } onClick={() => {
                      props.setThreeDots(!props.threeDots);
                    }}>
                    <i className="fas fa-ellipsis-vertical" />
                  </span>
                )}
            </>
          )}
      </div>
    );
  
  
}

export default GalleryIcons