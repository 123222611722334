import React from 'react';
import {Button, Form, Dropdown, Accordion} from 'react-bootstrap';
import {selected_lang} from '../../Utils/env';
import {labels} from '../../Constants/Translations';
import {errorMessage} from '../../Utils/ToastMessages';
import {
  formatNumberWithTwoDecimals,
  formatMinutesTime,
  checkSubscriptionType,
  getNameOfDuration,
  getNameFromDurationValue,
  formatPaymentDescription,
  formatPaymentMilestone,
  isPaymentMilestoneDuplicate,
  isWithinBudget,
  isDateMatched,
  compareDates,
  generatePaymentType,
  blockInvalidChar,
  filterExpenseIds,
} from '../../Utils/commonUtils';
import {useState, useEffect, useRef} from 'react';
import CustomDropdown from '../CustomDropdown';
import {monthDays} from '../../Utils/commonUtils';
import MultiSelectionDropdown from '../MultiSelectionDropdown';
import moment from 'moment';

const InvoiceProduct = ({
  item,
  ItemIndex,
  setSelectedProduct,
  handleDataChange,
  selectedProduct,
  setDeleteCatalog,
  setShowComponent,
  showOutOfStockError,
  outOfStockItems,
  expensesList = [],
}) => {
  const [productData, setProductData] = useState({
    name: '',
    subscripton: '0.00',
    subtotal: '0.00',
    tax: '0.00',
    discount: '0.00',
    shipping: '0.00',
    total: '0.00',
    payment_Schedule: '',
  });

  const [total, setTotal] = useState('');

  const [tax, setTax] = useState(item.taxes_fee ? item.taxes_fee : '0.00');
  const [discount, setDiscount] = useState(
    item.discount ? item.discount : '0.00',
  );
  const [shipping, setShipping] = useState(
    item.shipping_handling ? item.shipping_handling : '0.00',
  );

  const [lateFee, setLateFee] = useState(
    item.late_fee ? item.late_fee : '0.00',
  );

  const [gracePeriod, setGracePeriod] = useState(
    item.grace_period ? item.grace_period : '',
  );

  const [itemCost, setItemCost] = useState(item.cost ? item.cost : '');

  const [levels, setLevels] = useState([]);
  const [paymentTypeId, setPaymentTypeId] = useState(
    item.selected_payment_type
      ? item.selected_payment_type
      : item.payment_type && item.payment_type.length > 0
      ? item.payment_type[0]
      : 'one_time',
  );
  const [durations, setDuartions] = useState([]);
  const [paymentTypes, setPaymentTypes] = useState(
    item.payment_type && item.payment_type.length > 0 ? item.payment_type : [],
  );
  const [levelType, setLevelType] = useState('');
  const [durationType, setDurationType] = useState('');
  const [paymentMilestoneId, setPaymentMilestoneId] = useState(
    item.payment_milestone_id === ''
      ? []
      : typeof item.payment_milestone_id === 'string'
      ? item.payment_milestone_id.split(',')
      : item.payment_milestone_id,
  );

  const [paymentMilestone, setPaymentMilestone] = useState({
    date: '',
    amount: '',
  });

  const [expensesIds, setExpensesIds] = useState(
    item.expense_ids && item.expense_ids.length > 0 ? item.expense_ids :
    expensesList.length > 0 ? [expensesList[0].id] : [],
  );
  const [durationItem, setDurationItem] = useState({});
  const [levelItem, setLevelItem] = useState({});
  const [moreOptions, setMoreOptions] = useState(false);

  const [width, setWidth] = useState(34);
  const [shippingWidth, setShippingW] = useState(34);
  const [discountWidth, setDiscountW] = useState(34);
  const [lateFeeWidth, setLateFeeW] = useState(34);
  const taxRef = useRef(null);
  const discountRef = useRef(null);
  const shippingRef = useRef(null);
  const lateFeeRef = useRef(null);
  const [initialRender, setInitialRender] = useState(false);
  const [expenseToggle, setExpenseToggle] = useState(item.expense_ids && item.expense_ids.length > 0 ?  true :false);
  const RadioButtonName = [
    {id: '1', label: 'Monthly'},
    {id: '3', label: 'Quarterly'},
    {id: '12', label: 'Annually'},
  ];
  const [subcribtionIdvalue, setSubcribtionIdvalue] = useState(
    item.subscription_month !== '' ? item.subscription_month : '',
  );
  let plusIconStyle = {
    height: '25px',
    padding: '6px',
  };

  const todayDate = moment().format('YYYY-MM-DD');
  useEffect(() => {
    if (!initialRender) {
      if (taxRef.current) {
        setWidth(taxRef.current.offsetWidth + 2);
      }
      if (discountRef.current) {
        setDiscountW(discountRef.current.offsetWidth + 2);
      }
      if (shippingRef.current) {
        setShippingW(shippingRef.current.offsetWidth + 2);
      }
      if (lateFeeRef.current) {
        setLateFeeW(lateFeeRef.current.offsetWidth + 2);
      }
    }
  }, [tax, discount, shipping, lateFee, moreOptions]);

  useEffect(() => {
    if (item.catalog_type === 'membership') {
      console.log(
        item.membership_levels,
        'item.membership_levelsitem.membership_levels',
      );
      const filterLevel =
        item.membership_levels.length > 0 &&
        item.membership_levels.filter(item => item.selected === true);
      if (filterLevel.length > 0) {
        console.log(filterLevel, '=====>filterLevelfilterLevel');
        const selectedLevel = filterLevel.length > 0 ? filterLevel[0] : {};
        setLevelItem(selectedLevel);
        setLevels(
          item.membership_levels && item.membership_levels.length > 0
            ? item.membership_levels
            : [],
        );
        setLevelType(selectedLevel.id);
      } else {
        setLevelItem(
          item.membership_levels && item.membership_levels.length > 0
            ? item.membership_levels[0]
            : [],
        );
        setLevels(
          item.membership_levels && item.membership_levels.length > 0
            ? item.membership_levels
            : [],
        );
        setLevelType(
          item.membership_levels && item.membership_levels.length > 0
            ? item.membership_levels[0].id
            : '',
        );
      }
    }

    // if (item.payment_milestones && item.payment_milestones.length > 0) {
    //   setPaymentMilestoneId([item.payment_milestones[0].id]);
    // }
  }, []);

  useEffect(() => {
    if (item.catalog_type === 'membership' && levelType !== '') {
      const selectedLevel = levels.filter(item => item.id === levelType);
      console.log(selectedLevel, 'selectedLevelselectedLevel');
      setDuartions(
        item.membership_levels.length > 0 &&
          selectedLevel[0].frequencies.length > 0
          ? selectedLevel[0].frequencies
          : [],
      );

      if (selectedLevel.length > 0 && selectedLevel[0].selected) {
        // setDurationType(item?.level?.frequency?.id)
        const filterFrequency =
          selectedLevel[0].frequencies.length > 0 &&
          selectedLevel[0].frequencies.filter(item => item.selected === true);
        setDurationType(filterFrequency.length > 0 && filterFrequency[0].id);
        handleAmount(filterFrequency[0]);
      } else {
        setDurationType(
          item.membership_levels.length > 0 &&
            selectedLevel[0].frequencies.length > 0
            ? selectedLevel[0].frequencies[0].id
            : '',
        );
        handleAmount(selectedLevel[0].frequencies[0]);
      }
    }
  }, [levelType]);

  const handleInputChange = e => {
    const {name, value} = e.target;

    if (parseFloat(value) > 1000000000000000) {
      return;
    }

    const regex = /^(?!0(?!\.))\d*(\.\d{0,2})?$/;
    // const regex = /^[1-9]\d*(\.\d{0,2})?$|^0\.\d{0,2}$/;
    // const regex = /^(?!0\d)\d*(\.\d{0,2})?$/;
    if (value === '' || regex.test(value) || value === '0') {
      if (name === 'discount') {
        const discountValue = parseFloat(value);
        const subtotalValue = parseFloat(item.subtotal);
        const taxValue = parseFloat(tax);
        const shippingValue = parseFloat(shipping);
        const productCost = parseFloat(itemCost);
        const lateFeeValue = parseFloat(lateFee);
        if (
          discountValue >=
          productCost + taxValue + shippingValue + lateFeeValue
        ) {
          errorMessage('Discount cannot exceed total.');
          return;
        }
      }

      const currentValue = parseFloat(value ? value : 0);
      const discountValue = parseFloat(discount);
      const taxValue = parseFloat(tax);
      const shippingValue = parseFloat(shipping);
      const productCost = parseFloat(itemCost);
      const lateFeeValue = parseFloat(lateFee);

      if (name === 'tax') {
        const total =
          currentValue +
          productCost +
          shippingValue +
          lateFeeValue -
          discountValue;
        if (total <= 0) {
          errorMessage('Invalid Amount.');
          return;
        }

        setTax(value);
      } else if (name === 'discount') {
        setDiscount(value);
      } else if (name === 'shipping') {
        const total =
          currentValue + productCost + taxValue + lateFeeValue - discountValue;

        if (total <= 0) {
          errorMessage('Invalid Amount');
          return;
        }
        setShipping(value);
      } else if (name === 'lateFee') {
        const total =
          currentValue + productCost + taxValue + shippingValue - discountValue;
        if (total <= 0) {
          errorMessage('Invalid Amount');
          return;
        }
        setLateFee(value);
      }

      // console.log(newData[index],'newData[index]newData[index]')

      // setSelectedProduct()
      // setInVoiceData({...item, [name]: value === '' ? '' : value});
    }
  };

  const handleBlur = event => {
    const {name, value} = event.target;
    let parsedValue = parseFloat(value.trim());
    let formattedValue = isNaN(parsedValue) ? '0.00' : parsedValue.toFixed(2);

    if (name === 'tax') {
      setTax(formattedValue);
    } else if (name === 'discount') {
      setDiscount(formattedValue);
    } else if (name === 'shipping') {
      setShipping(formattedValue);
    } else if (name === 'lateFee') {
      setLateFee(formattedValue);
    }
    // setInVoiceData({...invoiceData, [name]: formattedValue});
  };

  useEffect(() => {
    const updatedLevel = {
      ...levelItem,
      frequency: durationItem,
    };

    const {frequencies, ...levelWithoutFrequencies} = updatedLevel;
    const {id, catalog_membership_id, frequency} = levelWithoutFrequencies;

    const updatedProducts = selectedProduct.map((item, index) => {
      if (index === ItemIndex) {
        return {
          ...item,
          shipping_handling: shipping,
          discount: discount,
          taxes_fee: tax,
          late_fee: lateFee,
          grace_period: gracePeriod,
          total: total.toString(),
          cost: itemCost,
          expense_exist:expenseToggle,
          subscription_month: subcribtionIdvalue,
          selected_payment_type: paymentTypeId,
          // payment_milestone_id: paymentMilestoneId.toString(),
          expense_ids: expenseToggle && expensesIds.length > 0 ? expensesIds :  expensesList.length > 0 ?  expensesList[0].id : [],
          ...(item.catalog_type === 'membership' && {
            payment_milestones: frequency.payment_milestones,
          }),
          ...(item.catalog_type === 'membership' && {
            payment_type:
              frequency.payment_type && frequency.payment_type.length > 0
                ? frequency.payment_type
                : [],
          }),
          ...(item.catalog_type === 'membership' && {
            level: {
              id,
              catalog_membership_id,
              frequency: {
                id: frequency.id,
                duration: frequency.duration,
                payment_frequency: frequency.payment_frequency,
                payment_amount: frequency.payment_amount,
                renewal: frequency.renewal,
                payment_milestones: frequency.payment_milestones,
                subscription: subcribtionIdvalue,
                payment_type: frequency.payment_type,
              },
            },
          }),
        };
      }
      return item;
    });

    
    // if (item.catalog_type === 'membership') {
    //   setSubcribtionIdvalue(durationItem.subscription);
    // }

    console.log(total, 'totaltotaltotaltotal');
    setSelectedProduct(updatedProducts);
  }, [
    tax,
    discount,
    shipping,
    lateFee,
    gracePeriod,
    total,
    itemCost,
    durationType,
    levelType,
    subcribtionIdvalue,
    paymentMilestoneId,
    expensesIds,
    paymentTypeId,
    expenseToggle
  ]);

  useEffect(() => {
    if(!expenseToggle && expensesList.length > 0){
      setExpensesIds([expensesList[0].id])
    }
  }, [expenseToggle])
  
  useEffect(() => {
    setPaymentMilestone({
      date: '',
      amount: '',
    });
  }, [paymentTypeId]);

  useEffect(() => {
    setTotal(
      Number(itemCost) +
        Number(lateFee) +
        Number(tax) +
        Number(shipping) -
        Number(discount),
    );
  }, [tax, discount, shipping, lateFee, itemCost]);

  const handleAmount = MiniItem => {
    setDurationItem(MiniItem ? MiniItem : {});
    if (MiniItem.payment_amount && MiniItem.payment_amount !== '') {
      setItemCost(MiniItem.payment_amount);
    } else {
      setItemCost('0.00');
    }

    setSubcribtionIdvalue(
      item.hasOwnProperty('catalog_status')
        ? item.subscription_month
        : MiniItem.subscription,
    );
    setPaymentTypeId(
      item.hasOwnProperty('catalog_status')
        ? item.selected_payment_type
        : MiniItem.payment_type[0],
    );
    setPaymentTypes(MiniItem.payment_type);
  };

  // useEffect(() => {

  //   if( item.catalog_type === 'membership'){
  //     const newSelectedProduct = [...selectedProduct];
  //     const catalogItem = newSelectedProduct[ItemIndex];

  //     catalogItem.payment_milestones.push(...catalogItem.payment_milestones);
  //     setSelectedProduct(newSelectedProduct);
  //   }

  // }, [durationItem])

  useEffect(() => {}, [outOfStockItems]);

  const handleChange = e => {
    setSubcribtionIdvalue(e.target.value);
  };

  const handlePaymentMileStone = (type, index) => {
    // console.log(
    //   paymentMilestone.amount,
    //   catalogData.payment_milestones,
    //   catalogData.cost,
    //   'dddddddddddd',
    // );

    if (
      (paymentMilestone.date === '' || paymentMilestone.amount === '') &&
      type === 'add'
    ) {
      errorMessage(
        paymentMilestone.date === ''
          ? 'Please select date'
          : 'Please add amount',
      );
      return;
    } else if (
      !isWithinBudget(
        paymentMilestone.amount,
        item.payment_milestones,
        itemCost,
      ) &&
      type !== 'remove'
    ) {
      // errorMessage(`please select equal and less than $${itemCost} `);
      errorMessage('Sum of payment milestones must be equal to cost');
      return;
    }

    const isValid = isPaymentMilestoneDuplicate(
      item.payment_milestones,
      paymentMilestone,
    );

    console.log(isValid, '-------->isValid');
    if (type === 'add' && isValid) {
      const newSelectedProduct = [...selectedProduct];
      const catalogItem = newSelectedProduct[ItemIndex];

      catalogItem.payment_milestones.push(paymentMilestone);
      setSelectedProduct(newSelectedProduct);
    } else if (type !== 'remove') {
      errorMessage('You have already added this date');
      return;
    }

    setPaymentMilestone({
      date: '',
      amount: '',
    });
  };

  useEffect(() => {

    
    const filterId = filterExpenseIds(expensesIds,expensesList)
    if(expensesList.length > expenseToggle && filterId.length !== expensesIds.length ){
    if(filterId.length > 0){
      setExpensesIds(filterId)
    }else{
      setExpensesIds([expensesList[0]?.id])
    }
  }


    
    
  }, [expensesList])
  

  const handleDurationMileStone = (type, index) => {
    if (
      (paymentMilestone.date === '' || paymentMilestone.amount === '') &&
      type === 'add'
    ) {
      errorMessage(
        paymentMilestone.date === ''
          ? 'Please select date'
          : 'Please add amount',
      );
      return;
    } else if (
      !isWithinBudget(
        paymentMilestone.amount,
        item.payment_milestones,
        itemCost,
      ) &&
      type !== 'remove'
    ) {
      // errorMessage(
      //   `please select equal and less than $${frequencyData.payment_amount} `,
      // );

      errorMessage('Sum of payment milestones must be equal to cost');

      return;
    }

    const isValid = isPaymentMilestoneDuplicate(
      item.payment_milestones,
      paymentMilestone,
    );
    const validateDate = compareDates(
      getNameFromDurationValue(durationItem.duration),
      paymentMilestone.date,
    );
    const dateIsValid = isDateMatched(
      item.payment_milestones,
      paymentMilestone.date,
    );
    console.log(validateDate, '----------date validation');
    console.log(isValid, '-------->isValid');
    if (type === 'add' && isValid && validateDate && !dateIsValid) {
      const newSelectedProduct = [...selectedProduct];
      const catalogItem = newSelectedProduct[ItemIndex];

      catalogItem.payment_milestones.push(paymentMilestone);
      // catalogItem.level.frequency.payment_milestones.push(paymentMilestone)
      setSelectedProduct(newSelectedProduct);
    } else if (type !== 'remove' && !isValid) {
      errorMessage('You have already added this date');
      return;
    } else if (type !== 'remove' && !validateDate) {
      errorMessage('Invalid date');
      return;
    } else if (type !== 'remove' && dateIsValid) {
      errorMessage('Date is repeated');
      return;
    }

    setPaymentMilestone({
      date: '',
      amount: '',
    });
  };

  // console.log(levels, 'levelslevels');
  // console.log(durations, 'durationsdurations');
  // console.log(levelType, 'levelType');
  // console.log(durationType, 'durationType');
  // console.log(levelItem,'levelItemlevelItem')

  console.log(paymentMilestoneId, 'paymentMilestoneId');

  console.log(item.subscription, 'item.subscription');

  let dropdownWidth = {
    width: '181.55px',
  };

  let milestoneDropdown = {
    maxHeight: '49px',
    display: 'inherit',
  };
  console.log(expensesIds, '-------->expensesIds');
  console.log(expensesList, '-------->expensesList');
  // console.log(generatePaymentType(paymentTypes), 'generatePaymentType(item.payment_type)');
  return (
    <div className="invoice_catalog_item">
      <div
        key={item.id + ItemIndex}
        className="d-flex justify-content-between"
        style={{marginBottom: '20px'}}>
        <h1 className="text-break">{item.title}</h1>

        <span
          className=" as-link invoice_delete"
          onClick={() => {
            // removeProduct(item, index);
            setDeleteCatalog({
              item: item,
              index: ItemIndex,
            });
            setShowComponent('deleteCatalog');
          }}>
          <i className="fa fa-trash-can" />
        </span>
      </div>

      <div className="template-flex">
        {/* {item.catalog_type === 'service' && ( */}
        <span className="template-text">
          {item.duration ? formatMinutesTime(item.duration) : ''}
        </span>
        {/* )} */}

        {item.catalog_type === 'product' && (
          <div
            className={`d-flex gap-2 ${
              item.catalog_type === 'product' ? 'flex-1' : ''
            }`}>
            {showOutOfStockError(item, ItemIndex)?.not_allowed?.includes(
              ItemIndex,
            ) &&
              outOfStockItems.length > 0 && (
                <span className="out_of_stock">Out of Stock</span>
              )}
            {/* <span className='out_of_stock'>Out of Stock</span> */}
          </div>
        )}

        {item.catalog_type === 'membership' && (
          <div
            className={`d-flex gap-2 ${
              item.catalog_type === 'membership' ? 'flex-1' : ''
            }`}>
            {item.catalog_type === 'membership' && levels.length > 0 && (
              <div className="mb-3 recruitment_dropdown" style={dropdownWidth}>
                <Form.Label controlid="validationCustom01">Levels </Form.Label>
                <Dropdown
                  className="select-wrapper"
                  onSelect={(eventKey, event) => {
                    var item = JSON.parse(eventKey);
                    setLevelItem(item);
                    setLevelType(item.id);
                  }}
                  drop="down">
                  <Dropdown.Toggle variant="primary" className="shadow-none">
                    <div className="text-container">
                      {levelType
                        ? levels.find(item => item.id === levelType).level_name
                        : 'Select Role'}
                    </div>
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {levels.map((item, index) => (
                      <Dropdown.Item
                        key={`st-${index}`}
                        eventKey={JSON.stringify(item)}
                        active={item.id === levelType}>
                        {item.level_name}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            )}

            {item.catalog_type === 'membership' &&
              levels.length > 0 &&
              durations.length > 0 && (
                <div
                  className="mb-3 recruitment_dropdown"
                  style={dropdownWidth}>
                  <Form.Label controlid="validationCustom01">
                    Durations{' '}
                  </Form.Label>
                  <Dropdown
                    className="select-wrapper"
                    onSelect={(eventKey, event) => {
                      var item = JSON.parse(eventKey);
                      handleAmount(item);
                      setDurationType(item.id);
                    }}
                    drop="down">
                    <Dropdown.Toggle variant="primary" className="shadow-none">
                      <div className="text-container">
                        {formatPaymentDescription(
                          durationType
                            ? durations.find(item => item.id === durationType)
                            : 'Select Role',
                        )}
                      </div>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {durations.map((item, index) => (
                        <Dropdown.Item
                          key={`st-${index}`}
                          eventKey={JSON.stringify(item)}
                          // eventKey={item.id.toString()}
                          active={item.id === durationType}>
                          {formatPaymentDescription(item)}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              )}
          </div>
        )}

        <div
          className={`d-flex gap-1 ${
            itemCost.length > 4 ? 'flex-column' : ''
          }`}>
          <span
            className={`template-text ${
              itemCost.length > 4 ? 'text-end' : ''
            }`}>
            {item.catalog_type === 'service' ||
            item.catalog_type === 'membership'
              ? '(Subscription)'
              : '(Product)'}{' '}
          </span>
          <span
            className={`${itemCost.length > 4 ? 'service_subscription' : ''}`}>
            ${formatNumberWithTwoDecimals(itemCost)}
          </span>
        </div>
      </div>
      {expensesList.length > 0 && (  <div className="payment_milestone_flex">
        <div className="market_toogle expense_toogle d-flex mb-2">
          <div className='mr-2'>Expenses</div>
          <Form.Check
          style={{paddingBottom:'2px'}}
            className=""
            type="switch"
            id="custom-switch"
            // label={'Multiple Locations'}
            value={expenseToggle}
            checked={expenseToggle}
            onClick={() => {
              setExpenseToggle(!expenseToggle);
            }}
          />
        </div>

    
            {expenseToggle && expensesList.length > 0 && (
              <MultiSelectionDropdown
                setDropdownIds={setExpensesIds}
                dropdownIds={expensesIds}
                DropDownValues={expensesList}
                itemName={['title']}
                ErrorMessage={labels.expenseError[selected_lang]}
                shadow={true}
              />
            )}
        
      </div>
      )}

      {Array.isArray(paymentTypes) &&
        paymentTypes.length > 0 &&
        !paymentTypes.includes('') && (
          <div className="mb_12">
            <Form.Label controlid="validationCustom01">
              Select Payment Type
            </Form.Label>
            {/* <MultiSelectionDropdown
          setDropdownIds={setPaymentTypeId}
          dropdownIds={paymentTypeId}
          DropDownValues={generatePaymentType(item.payment_type)}
          itemName={['title']}
          ErrorMessage={labels.paymentTypeError[selected_lang]}
          // className={'mb-3 recruitment_dropdown'}
        /> */}

            <CustomDropdown
              setDropdownId={setPaymentTypeId}
              dropdownId={paymentTypeId}
              DropDownValues={generatePaymentType(paymentTypes)}
              itemName={['title']}
              // className={'recruitment_dropdown'}
            />
          </div>
        )}

      {paymentTypeId === 'subscription' &&
        // paymentTypeId.includes('subscription') &&
        item.subscription_month &&
        subcribtionIdvalue !== '' && (
          <div className="template-flex">
            <div className="input-group ">
              <Form.Label controlid="validationCustom01">
                Subscription
              </Form.Label>

              <div className="catalog-radio-btns customized-catalog-radio-btns">
                {Object.entries(RadioButtonName).length > 0 &&
                  RadioButtonName.map((item, index) => (
                    <Form.Check
                      key={item.id + index}
                      type="radio"
                      // name="radioGroup"

                      label={item.label}
                      id={item.id}
                      value={item.id}
                      checked={subcribtionIdvalue === item.id}
                      onChange={handleChange}
                    />
                  ))}
              </div>
            </div>
          </div>
        )}

      {paymentTypeId === 'milestone' && (
        // paymentTypeId.includes('milestone') &&
        // item.payment_milestones &&
        // item.payment_milestones.length > 0 &&
        <div className="payment_milestone_flex">
          {/* <Form.Label controlid="validationCustom01">
              Select Payment Milestone
            </Form.Label> */}

          <Accordion
            className="create-catalogue-accord-holder"
            defaultActiveKey={null}>
            <Accordion.Item eventKey="0">
              <Accordion.Header> Payment Milestones</Accordion.Header>
              <Accordion.Body>
                <div className="mb-1 mr-1 d-flex justify-content-end">
                  <div className="triger_plus_main">
                    <div
                      className="triger_plus"
                      onClick={() => {
                        if (item.catalog_type === 'membership') {
                          handleDurationMileStone('add');
                        } else {
                          handlePaymentMileStone('add');
                        }
                      }}
                      style={plusIconStyle}>
                      <i className="fa-solid fa-plus"></i>
                    </div>
                  </div>
                </div>
                <div className="d-flex gap-4">
                  <div className="flex-1 payment_milestone_label">
                    <div className="input-group roi-form-input">
                      <Form.Label controlid="validationCustom01">
                        Date
                      </Form.Label>

                      <Form.Control
                        type="date"
                        // style={{marginLeft:'1px'}}
                        className="shadow-none m-0"
                        value={paymentMilestone.date}
                        pattern="^\S.*$"
                        min={todayDate}
                        placeholder=""
                        onChange={e => {
                          setPaymentMilestone({
                            ...paymentMilestone,
                            date: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="flex-1">
                    <div className="input-group payment_milestone_label roi-form-input">
                      <Form.Label controlid="validationCustom01">
                        Amount
                      </Form.Label>

                      <Form.Control
                        type="number"
                        value={paymentMilestone.amount}
                        className="shadow-none"
                        pattern="^\S.*$"
                        placeholder=""
                        onKeyDown={blockInvalidChar}
                        onChange={e => {
                          const {name, value} = e.target;
                          const regex = /^(?!0(?!\.))\d*(\.\d{0,2})?$/;

                          if (
                            value === '' ||
                            regex.test(value) ||
                            value === '1'
                          ) {
                            setPaymentMilestone({
                              ...paymentMilestone,
                              amount: e.target.value,
                            });
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>

                {item.payment_milestones && item.payment_milestones.length > 0 && (
                  <div className="mt-3 mb-3">
                    {Object.entries(item.payment_milestones).length > 0 &&
                      item.payment_milestones.map((item, index) => {
                        return (
                          <div
                            className="d-flex catalog_product_item payment_milestone_item mb-2"
                            key={`level${index}`}>
                            <p className="input-label m-0">
                              {moment(item.date).format('MM/DD/YYYY')} {'  '}
                              (${item.amount})
                            </p>
                            <div className="text-right mb-2">
                              <div className="d-flex gap-2 ">
                                <span
                                  className="poc_delete as-link"
                                  onClick={() => {
                                    setDeleteCatalog({
                                      item: item,
                                      itemIndex: ItemIndex,
                                      mileStoneIndex: index,
                                    });
                                    setShowComponent('deleteMilestone');
                                  }}>
                                  <i className="fa-solid fa-trash-can" />
                                </span>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                )}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>

          {/* <MultiSelectionDropdown
            setDropdownIds={setPaymentMilestoneId}
            dropdownIds={paymentMilestoneId}
            DropDownValues={item?.payment_milestones}
            customFunction={formatPaymentMilestone}
            ErrorMessage={labels.milestoneError[selected_lang]}
          /> */}
          {/* <Dropdown
            className="select-wrapper customized-select-wrapper"
            onSelect={(eventKey, event) => {
              if (
                paymentMilestoneId.length === 1 &&
                paymentMilestoneId.includes(eventKey)
              ) {
                errorMessage('Atleast one Payment Milestone is required');
              } else {
                const groupIds = [...paymentMilestoneId];
                if (!groupIds.includes(eventKey)) {
                  groupIds.push(eventKey);
                } else {
                  const index = groupIds.indexOf(eventKey);
                  groupIds.splice(index, 1);
                }
                setPaymentMilestoneId(groupIds);
              }
            }}
            drop="down">
            <Dropdown.Toggle variant="primary">
              {paymentMilestoneId.length > 0
                ? paymentMilestoneId
                    .map(groupId =>
                      formatPaymentMilestone(
                        item?.payment_milestones?.find(
                          item => item.id === groupId,
                        ),
                      ),
                    )
                    .join(', ')
                : ' '}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {item?.payment_milestones.map((item, index) => (
                <Dropdown.Item
                  key={`sessionCategories-${index}`}
                  eventKey={item.id}
                  active={paymentMilestoneId.includes(item.id)}>
                  {formatPaymentMilestone(item)}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown> */}
        </div>
      )}

      {!moreOptions && (
        <div
          className="more-option template-text as-link fit_content mb-3"
          onClick={() => {
            setMoreOptions(!moreOptions);
          }}>
          More Options
        </div>
      )}

      {moreOptions && (
        <>
          {/* <div className="template-flex">
                                <span>Subtotal</span>

                                <span>
                                  $
                                  {formatNumberWithTwoDecimals(
                                    invoiceData.subtotal,
                                  )}
                                </span>
                              </div> */}

          <div className="template-flex">
            <span>Taxes and Fees</span>

            <div className="d-flex ">
              <span className="invoice_cost f_600">$</span>
              <span id="invoice_cost_span" ref={taxRef}>
                {tax}
              </span>

              <Form.Control
                name="tax"
                type="number"
                className="invoice_input_field template-text f_600"
                min="0.00"
                step="0.01"
                style={{width}}
                // style={{ width: `${cost.length}ch` }}
                value={tax}
                onChange={handleInputChange}
                onBlur={handleBlur}
              />
            </div>
          </div>

          <div className="template-flex">
            <span>Discount</span>

            <div className="d-flex ">
              <span className="invoice_cost f_600">$</span>
              <span id="invoice_cost_span" ref={discountRef}>
                {discount}
              </span>

              <Form.Control
                name="discount"
                type="number"
                className="invoice_input_field template-text f_600"
                min="0.00"
                step="0.01"
                style={{width: discountWidth}}
                // style={{ width: `${cost.length}ch` }}
                value={discount}
                onChange={handleInputChange}
                onBlur={handleBlur}
              />
            </div>
          </div>

          {(item.catalog_type === 'service' ||
            item.catalog_type === 'membership') && (
            <div className="template-flex">
              <span>Late Fee</span>

              <div className="d-flex ">
                <span className="invoice_cost f_600">$</span>
                <span id="invoice_cost_span" ref={lateFeeRef}>
                  {lateFee}
                </span>

                <Form.Control
                  name="lateFee"
                  type="number"
                  className="invoice_input_field template-text f_600"
                  min="0.00"
                  step="0.01"
                  style={{width: lateFeeWidth}}
                  // style={{ width: `${cost.length}ch` }}
                  value={lateFee}
                  onChange={handleInputChange}
                  onBlur={handleBlur}
                />
              </div>
            </div>
          )}

          {item.catalog_type === 'product' && (
            <div className="template-flex">
              <span>Shipping & handling</span>

              <div className="d-flex ">
                <span className="invoice_cost f_600">$</span>
                <span id="invoice_cost_span" ref={shippingRef}>
                  {shipping}
                </span>

                <Form.Control
                  name="shipping"
                  type="number"
                  className="invoice_input_field template-text f_600"
                  min="0.00"
                  step="0.01"
                  style={{width: shippingWidth}}
                  // style={{ width: `${cost.length}ch` }}
                  value={shipping}
                  onChange={handleInputChange}
                  onBlur={handleBlur}
                />
              </div>
            </div>
          )}

          {(item.catalog_type === 'service' ||
            item.catalog_type === 'membership') &&
            item.grace_period !== '' && (
              <div className="mb-3">
                <Form.Label controlid="validationCustom01">
                  Grace Period{' '}
                </Form.Label>
                <Dropdown
                  className="select-wrapper"
                  onSelect={(eventKey, event) => {
                    setGracePeriod(eventKey);
                  }}
                  drop="down">
                  <Dropdown.Toggle variant="primary">
                    {monthDays
                      ? monthDays.find(item => item === gracePeriod)
                      : 'Select Role'}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {monthDays.map((item, index) => (
                      <Dropdown.Item
                        key={`st-${index}`}
                        eventKey={item}
                        active={item === gracePeriod}>
                        {item}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            )}

          {/* {item.catalog_type === 'membership' && levels.length > 0 && (
            <div className="mb-3">
              <Form.Label controlid="validationCustom01">Levels </Form.Label>
              <Dropdown
                className="select-wrapper"
                onSelect={(eventKey, event) => {
                  var item = JSON.parse(eventKey);
                  setLevelItem(item)
                  setLevelType(item.id);
                  
                }}
                drop="down">
                <Dropdown.Toggle variant="primary">
                  {levelType
                    ? levels.find(item => item.id === levelType).level_name
                    : 'Select Role'}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {levels.map((item, index) => (
                    <Dropdown.Item
                      key={`st-${index}`}
                      eventKey={JSON.stringify(item)}
                      active={item.id === levelType}>
                      {item.level_name}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          )} */}

          {/* {item.catalog_type === 'membership' &&
            levels.length > 0 &&
            durations.length > 0 && (
              <div className="mb-3">
                <Form.Label controlid="validationCustom01">
                  Durations{' '}
                </Form.Label>
                <Dropdown
                  className="select-wrapper"
                  onSelect={(eventKey, event) => {
                    var item = JSON.parse(eventKey);
                    handleAmount(item)
                    setDurationType(item.id);
                    
                   
                  }}
                  drop="down">
                  <Dropdown.Toggle variant="primary">
                    {formatPaymentDescription(
                      durationType
                        ? durations.find(item => item.id === durationType)
                            
                        : 'Select Role',
                    )}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {durations.map((item, index) => (
                      <Dropdown.Item
                        key={`st-${index}`}
                        eventKey={JSON.stringify(item)}
                        // eventKey={item.id.toString()}
                        active={item.id === durationType}>
                        {formatPaymentDescription(item)}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            )} */}

          {/* {item.catalog_type === 'membership' && item.membership_levels.length > 0 &&
            (
              <div className="mb-3">
                <Form.Label controlid="validationCustom01">
                  Levels{' '}
                </Form.Label>
                <Dropdown
                  className="select-wrapper"
                  onSelect={(eventKey, event) => {
                    setLevelType(eventKey);
                  }}
                  drop="down">
                  <Dropdown.Toggle variant="primary">
                    {monthDays
                      ? item.membership_levels.find(item => item === levelType)
                      : 'Select Role'}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {item.membership_levels.map((item, index) => (
                      <Dropdown.Item
                        key={`st-${index}`}
                        eventKey={item.id}
                        active={item === levelType}>
                        {item}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            )} */}

          <div className="template-flex-total">
            <span className="template-text">Subtotal</span>

            <span>${formatNumberWithTwoDecimals(total)}</span>
          </div>
        </>
      )}

      {moreOptions && (
        <div
          className="more-option template-text as-link mb-3"
          onClick={() => {
            setMoreOptions(!moreOptions);
          }}>
          Less Options
        </div>
      )}
    </div>
  );
};

export default InvoiceProduct;
