import React, {useState,  useEffect,useRef} from 'react';
import Scrollbars from 'react-custom-scrollbars';
import commonApi from '../../Services/CommonService';
import {Button, Col, Row} from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import {successMessage} from '../../Utils/ToastMessages';
import ListUsers from '../Common/ListUsers';
import {DEFAULT_IMAGE} from '../../Utils/env';
import moment from 'moment';
import SlidingPanel from '../SlidingPanel';
import LoaderSpinner from '../Modals/LoaderSpinner';
import {RenderHTMLBlock} from '../../Utils/Helpers';
import {fixName} from '../../Utils/commonUtils';
import {useDispatch, useSelector} from 'react-redux';
import {getItem} from '../../Utils/LocalStorage';
import { managFavourite } from '../../Store/Actions/DeepLinkAction';

const SessionDetail = ({item, onClose, type, refer, eventImages, rsvp}) => {
  const [showLoader, setshowLoader] = useState(false);
  const [total, setTotal] = useState(0);
  const [show, setShow] = useState(false);
  const [userData, setUserData] = useState([]);
  const [favourite, setFavourite] = useState(false);
  const [showComponent, setShowComponent] = useState('');
  const newFavourite = useSelector(state => state.newFavourite);
  const heartClass = favourite === true ? 'fas' : 'far';
  const dispatch = useDispatch();
  const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);
  let data = item.creator_detail;
  const isFirstRender = useRef(true);
  // 

  const manageCurrentItem = id => {
    if (id === item.id) {
      const loginUserData = {
        id: getItem('id'),
        first_name: getItem('firstName'),
        last_name: getItem('lastName'),
        profile_img: getItem('profilePic'),
      };
      // checkFavourite();
      setFavourite(prev => !prev);
      if (userData.find(objs => objs.id === loginUserData.id)) {
        setUserData(prev => prev.filter(item => item.id !== loginUserData.id));
        setTotal(prev => prev - 1);
      } else {
        setUserData(prev => [...prev, loginUserData]);
        setTotal(prev => prev + 1);
      }
    }
  };

  useEffect(() => {
    checkFavourite();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);

  useEffect(() => {
    if (!isFirstRender.current) {
      manageCurrentItem(newFavourite.id);
    } else {
      // Set the flag to false after the first render
      isFirstRender.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newFavourite]);

  const checkFavourite = () => {
    // setshowLoader(true);
    var payloadCheckFavourite = {
      conference_id: refer ? item.refer_type_id : item.id,
    };
    commonApi
      .check_favourite(payloadCheckFavourite)
      .then(res => {
        if (res.status === 200) {
          
          // setTotal(res.data);
          setTotal(res.data.total);
          setUserData(res.data.users);
          setFavourite(res.data.favourite);
          setshowLoader(false);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const manageFavourites = () => {
    if (!eventImages) {
      // dispatch(getSessionListing(item.id));
      dispatch(managFavourite(item.id));
      setFavourite(prev => !prev);
    }

    manageCurrentItem(item.id);
    // setshowLoader(true);
    var payloadManageFavourite = {
      conference_id: refer ? item.refer_type_id : item.id,
    };
    commonApi
      .manage_favourite(payloadManageFavourite)
      .then(res => {
        if (res.status === 200) {
          setshowLoader(false);
          successMessage(res.message);
          checkFavourite();
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  
console.log(favourite,'favouritefavourite')
  return (
    <>
      {showLoader && <LoaderSpinner />}
      {showComponent === 'ListUser' && (
        <ListUsers
          allUsers={rsvp ? rsvp : userData}
          onClose={() => {
            setShowComponent('');
          }}
          type={false}
        />
      )}
      {showComponent === '' && (
        <>
          <SlidingPanel hidePanel={() => onClose()}>
            <Scrollbars>
              <div className="serach-filter-wrapper">
                <div className="fix-width-forms">
                  <div className="sidebar-heading-wrapper mt-0 w-100">
                    <div className="events-headings w-100 mt-0">
                      {eventImages && (
                        <div className="events-img-slider">
                          {eventImages &&
                            eventImages.map((item, index) => (
                              <img src={item} alt="" key={index} />
                            ))}
                        </div>
                      )}
                      <div className="events-icons-wrapper mb-3 mt-0">
                        <h2>{item.session_title}</h2>
                        <div className="right-icons as-link">
                          <span
                            className={`${heartClass} fa-heart`}
                            onClick={() => {
                              manageFavourites();
                            }}></span>
                        </div>
                      </div>

                      <div className="d-flex align-item-center justify-content-between">
                        <span className="category-name">
                          {item.category_name}
                        </span>
                        <span className="category-name">
                          {item.start_time === '0000-00-00 00:00:00'
                            ? moment(item.created_on).format('MM/DD/YY h:mmA')
                            : moment(item.start_time).format('MM/DD/YY h:mmA')}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="fix-width-forms">
                  <div className="reminder-screen-wrapper">
                    <div className="event-happening mb-4">
                      <div className="user-img">
                        {type === 'session' && data ? (
                          <>
                            <img
                              src={
                                data.profile_img === ''
                                  ? DEFAULT_IMAGE
                                  : data.profile_img
                              }
                              alt=""
                            />

                            {fixName(
                              data.creator_first_name,
                              data.creator_last_name,
                            )}
                          </>
                        ) : (
                          <>
                            <img
                              src={
                                item.creator_img === ''
                                  ? DEFAULT_IMAGE
                                  : item.creator_img
                              }
                              alt=""
                            />

                            {fixName(
                              item.creator_first_name,
                              item.creator_last_name,
                            )}
                          </>
                        )}
                      </div>
                      {rsvp ? (
                        <span
                          className={rsvp.length > 0 ? ' as-link' : ''}
                          onClick={() => {
                            if (rsvp.length > 0) {
                              setShowComponent('ListUser');
                            }
                          }}>
                          RSVP ({rsvp.length})
                        </span>
                      ) : (
                        <span
                          className={total > 0 ? ' as-link' : ''}
                          onClick={() => {
                            if (total > 0) {
                              setShowComponent('ListUser');
                            }
                          }}>
                          RSVP ({total})
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="skillset-desired-bg-wrapper">
                    {/* <span className="d-block mb-2">
                          <b>Skillset Summary</b>
                        </span> ${showMoreClassSkill} */}
                    <RenderHTMLBlock
                      className={`profile-description`}
                      container={'p'}
                      content={item.session_description}
                    />

                    {/* {item.skill_set > 100 && (
                          <span
                            className="show-more"
                            onClick={() =>
                              toggleShowMoreSkillset(!showMoreSkillset)
                            }>
                            {!showMoreSkillset
                              ? labels.read_more[selected_lang]
                              : labels.read_less[selected_lang]}
                          </span>
                        )} */}
                  </div>
                </div>
                {item.address && (
                  <span className="address as-link">
                    <i className="fas fa-location-dot"></i> {item.address}
                  </span>
                )}
                {!eventImages && (
                  <div className="filter-btn-wrapper pt-3">
                    <div className="invite-btns p-0 text-center">
                      {favourite === true ? (
                        <Button
                          className="btn-dark"
                          onClick={() => {
                            manageFavourites();
                          }}>
                          Yes Remove Reminder
                        </Button>
                      ) : (
                        <>
                          <Button
                            className="btn-dark"
                            onClick={() => {
                              manageFavourites();
                            }}>
                            Yes Remind Me
                          </Button>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </Scrollbars>
          </SlidingPanel>
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Images</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <>
                <div className="gallery-imgs">
                  <Row>
                    {Object.entries(userData).length > 0 &&
                      userData.map((item, index) => {
                        return (
                          <Col md={4} sm={4} key={`userData-${index}`}>
                            <div className="gallery-item">
                              <img src={item.profile_img} alt="" />
                            </div>
                            <span className="category-name">
                              {item.first_name + '' + item.last_name}
                            </span>
                          </Col>
                        );
                      })}
                  </Row>
                </div>
              </>
            </Modal.Body>
          </Modal>
        </>
      )}
    </>
  );
};

export default SessionDetail;
