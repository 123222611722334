import React, {useEffect, useState} from 'react';
import {Button, Col, Form, Row} from 'react-bootstrap';
import Scrollbars from 'react-custom-scrollbars';
import commonApi from '../../Services/CommonService';
import SaveCard from '../Payments/SaveCard';
import CustomModal from '../CustomModal';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import {Dropdown} from 'react-bootstrap';

const CardDetailSimple = props => {
  const [formData, setFormData] = useState({
    amount: props.dataTwo.cost,
    card: {number: '', expMonth: 10, expYear: 2024, cvc: ''},
    card_id: '',
    cardholder_name: '',
    complete_time: props.dataOne.complete_time,
    device_type: 'web',
    id_transaction_type: props.dataOne.slot_id,
    stripe_token: '',
    transaction_type: 'appointment',
    save_card: 'no',
  });
  const [validated, setValidated] = useState(false);
  const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  const [defaultCard, setDefaultCard] = useState([]);
  const [showComponent, setShowComponent] = useState('');

  // year function
  // const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const currentYear = new Date().getFullYear();
  const yearRange = 10;
  const startYear = currentYear + yearRange;
  const endYear = currentYear;
  const years = [];

  const [showDropdown, setShowDropdown] = useState(false);
  const [showDropdownMonth, setShowDropdownMonth] = useState(false);

  

  for (let i = endYear; i <= startYear; i++) {
    years.push(i);
  }
  // year function end

  const submitForm = event => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();

      setValidated(true);
    } else {
      event.preventDefault();

      setValidated(false);
      props.stripePayment(formData);
      props.setShowForm(false);
    }
  };

  useEffect(() => {
    getMyCard();
    setFormData({
      amount: props.dataTwo.cost,
      card: {
        number: '',
        expMonth: 10,
        expYear: 2023,
        cvc: '',
      },
      card_id: '',
      cardholder_name: '',
      complete_time: props.dataOne.complete_time,
      device_type: 'web',
      id_transaction_type: props.dataOne.slot_id,
      stripe_token: '',
      transaction_type: 'appointment',
      save_card: 'no',
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getMyCard = () => {
    commonApi
      .get_my_card()
      .then(res => {
        if (res.status === 200) {
          setDefaultCard(res.data);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  useEffect(() => {
    getMyCard();
  }, []);

  const handleYearChange = year => {
    // setSelectedYear(year);
    setShowDropdown(false); // Close the dropdown after selecting a year
    setFormData({
      ...formData,
      card: {
        ...formData['card'],
        expYear: year,
      },
    });
  };

  const handleMonthChange = (month) => {
    setFormData({
      ...formData,
      card: {
        ...formData['card'],
        expMonth: month,
      },
    })
    setShowDropdownMonth(false); // Close the dropdown after selecting a month
  }

  return (
    <>
      {showComponent === 'saveCard' && (
        <CustomModal
          className="fixed-modal"
          style={{height: '100%'}}
          size="medium"
          onClose={() => setShowComponent('')}>
          <Scrollbars>
            <SaveCard defaultCard={defaultCard} getMyCard={getMyCard} />
          </Scrollbars>
        </CustomModal>
      )}

      {showComponent === '' && (
        <CustomModal
          className="fixed-modal"
          size="medium"
          onClose={() => props.onClose()}>
          <div className="appointment-payment-wrapper mt-0 mb-4 w-100">
            {/* <Scrollbars> */}
            <div className="d-flex align-items-center mb-4">
              <div className="payment-heading">
                <h4>{props.dataTwo.title}</h4>
                <span className="appointment-cost">${props.dataTwo.cost}</span>
              </div>
            </div>
            <div className="appointment-form">
              <Form noValidate validated={validated} onSubmit={submitForm}>
                <Form.Group className="fieldset">
                  <Form.Label>Cardholder Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Name"
                    pattern="([a-zA-Z]+)|([a-zA-Z]+( [a-zA-Z]+)+)"
                    required
                    value={formData.cardholder_name}
                    onChange={e =>
                      setFormData({
                        ...formData,
                        cardholder_name: e.target.value,
                      })
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    Cardholder name is required.
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="fieldset">
                  <Form.Label>Card Number</Form.Label>
                  <Form.Control
                    type="tel"
                    placeholder="Card Number"
                    inputMode="numeric"
                    pattern="[0-9]{13,19}"
                    autoComplete="cc-number"
                    maxLength="19"
                    required
                    value={formData.card.number}
                    onChange={e =>
                      setFormData({
                        ...formData,
                        card: {
                          ...formData['card'],
                          number: e.target.value,
                        },
                      })
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    Card number is required.
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="fieldset">
                  <Form.Label>Expiration Date</Form.Label>
                  <Row>
                    <Col md={6} style={{
                      paddingBottom: '10px', 
                    }}>
                      {props.type ? 
                      <Dropdown show={showDropdownMonth} onClick={() => setShowDropdownMonth(!showDropdownMonth)}>
                      <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                        {formData?.card?.expMonth} {/* Adjusting index to month */}
                      </Dropdown.Toggle>
                
                      <Dropdown.Menu style={{ width: '100%' }}>
                        {months.map((month, index) => (
                          <Dropdown.Item key={month} onClick={() => handleMonthChange(month)}>
                            {month}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                     : <select
                        value={formData.card.expMonth}
                        onChange={e =>
                          setFormData({
                            ...formData,
                            card: {
                              ...formData['card'],
                              expMonth: e.target.value,
                            },
                          })
                        }>
                        {months.map((month, index) => (
                          <option key={index} value={index + 1}>
                            {month}
                          </option>
                        ))}
                      </select>}
                    </Col>
                    <Col md={6}>
                      {props.type ? (
                        <Dropdown
                          show={showDropdown}
                          onClick={() => setShowDropdown(!showDropdown)}>
                          <Dropdown.Toggle
                            variant="secondary"
                            id="dropdown-basic">
                            {formData?.card?.expYear}
                          </Dropdown.Toggle>

                          <Dropdown.Menu style={{width: '100%'}}>
                            {years.map(year => (
                              <Dropdown.Item
                                key={year}
                                onClick={() => handleYearChange(year)}>
                                {year}
                              </Dropdown.Item>
                            ))}
                          </Dropdown.Menu>
                        </Dropdown>
                      ) : (
                        <select
                          className="selectpicker"
                          value={formData.card.expYear}
                          onChange={e =>
                            setFormData({
                              ...formData,
                              card: {
                                ...formData['card'],
                                expYear: e.target.value,
                              },
                            })
                          }>
                          {years.map(year => (
                            <option key={year} value={year}>
                              {year}
                            </option>
                          ))}
                        </select>
                      )}
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group className="fieldset">
                  <Form.Label>CVV</Form.Label>
                  <Row>
                    <Col md={6}>
                      <Form.Control
                        type="text"
                        placeholder="CVV"
                        required
                        value={formData.card.cvc}
                        onChange={e =>
                          setFormData({
                            ...formData,
                            card: {
                              ...formData['card'],
                              cvc: e.target.value,
                            },
                          })
                        }
                        maxLength="3"
                        pattern="[0-9]{3}"
                      />
                      <Form.Control.Feedback type="invalid">
                        CCV is required.
                      </Form.Control.Feedback>
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group className="fieldset col-md-6 d-flex align-items-center">
                  <Form.Check
                    onChange={e =>
                      setFormData({
                        ...formData,
                        save_card: e.target.checked ? 'yes' : 'no',
                      })
                    }
                    type={'checkbox'}
                    id="save-my-card"
                    label="Save card for future purchases"
                    value={formData.save_card}
                  />
                  {defaultCard.length > 0 && (
                    <span
                      className="icon as-link cardIcon"
                      onClick={() => {
                        setShowComponent('saveCard');
                      }}>
                      <i className="fa-sharp fa-solid fa-credit-card" />
                    </span>
                  )}
                </Form.Group>
                <Form.Group className="fieldset mt-5">
                  <div className="payment-btn">
                    <Button
                      type="submit"
                      className={props.type === 'profile_link' ? 'btn-dark' : 'green' }>
                      Pay
                    </Button>
                  </div>
                </Form.Group>
              </Form>
            </div>
            <br />
            {/* </Scrollbars> */}
          </div>
        </CustomModal>
      )}
    </>
  );
};

export default CardDetailSimple;
