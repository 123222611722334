import React, {useState, useEffect} from 'react';
import {Button, Form} from 'react-bootstrap';
import {errorMessage, successMessage} from '../../Utils/ToastMessages';
import SlidingPanel from '../SlidingPanel';

import Navbar from '../../Pages/Starter/Navbar';
import SliderComponent1 from '../../Pages/Starter/SliderComponent1';
import SliderComponent2 from '../../Pages/Starter/SliderComponent2';
import SliderComponent3 from '../../Pages/Starter/SliderComponent3';
import commonApi from '../../Services/CommonService';
import Scrollbars from 'react-custom-scrollbars';
import SendInvitation from '../../Pages/Front/StaticPages/SendInvitation';

const CreateWorkSpace = props => {
  const [profileImg, setProfileImg] = useState('');
  const [companyData, setCompanyData] = useState({
    firstName: '',
    lastName: '',
    name: '',
    contact: '',
    email: '',
    teamMembers: '',
    billing_email: '',
  });
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [customerData, setCustomerData] = useState({
    firstName: '',
    lastName: '',
  });

  const [name, setName] = useState('');

  const [currentStep, setStep] = useState(1);

  const [validated, setValidated] = useState(false);
  const [systemRoles, setSystemRoles] = useState([]);
  const [workSpaceTypes, setWorkSpaceTypes] = useState([]);
  const [selectedCompanyDescription, setSelectedCompanyDescription] = useState(
    [],
  );
  const [showComponent, setShowComponent] = useState('');
  const [contact, setContact] = useState([]);
  const [button, setButton] = useState('');
  const [companyNameExist, setCompanyNameExist] = useState(false);
  const [ticketImg, setImageCreate] = useState('');
  const [companyLogo, setCompanyLogo] = useState('');

  const [isCheck, setIsCheck] = useState([]);
  const [catError, setCatError] = useState(false);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheckValue, setIsCheckValue] = useState([]);

  useEffect(() => {
    check_company_name();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyData.name]);

  const check_company_name = () => {
    const payload = {
      company_name: companyData.name,
    };

    commonApi
      .check_company_name(payload)
      .then(res => {
        if (res.status === 200) {
          setCompanyNameExist(res.company_exist);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  const getSystemRoles = () => {
    commonApi
      .get_user_roles()
      .then(res => {
        if (res.status === 200) {
          if (res.user_roles?.length > 0) {
            const rolesWithInvites = res.user_roles.map(role => ({
              ...role,
              invites: '',
            }));
            setSystemRoles(rolesWithInvites);
          }
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const updateImage = e => {
    // setProfileImg();
    const imgData = e.target.files[0];
    if (!/^image\//.test(imgData.type)) {
      errorMessage(`File is not an image.`);
      return false;
    } else {
      const objectUrl = URL.createObjectURL(imgData);

      let reader = new FileReader();
      reader.readAsDataURL(imgData);
      reader.onload = e => {
        const tempData = {
          ...customerData,
          profile_img: e.target.result,
        };
        setCustomerData(tempData);
      };
      //
      setProfileImg(objectUrl);
    }
  };

  // const getMenuItems = () => {
  //   commonApi
  //     .get_workspace_menu_item()
  //     .then(res => {
  //       if (res.status === 200) {
  //         if (res.menu_items?.length > 0) {
  //           setMenuItems(res.menu_items);
  //         }
  //       }
  //     })
  //     .catch(err => {
  //       console.log('Err', err);
  //       return {type: 'error', message: err.message};
  //     });
  // };
  const worSpaceType = () => {
    commonApi
      .get_workspace_types()
      .then(res => {
        if (res.status === 200) {
          if (res.workspace_types?.length > 0) {
            setWorkSpaceTypes(res.workspace_types);
            setSelectedCompanyDescription(res.workspace_types[0].id)
          }
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  // const registerUser = () => {
  //   var payload = {
  //     name : name,
  //     email : email
  //   }
  //   commonApi
  //     .get_workspace_types(payload)
  //     .then(res => {
  //       if (res.status === 200) {
  //         setStep(2)
  //       }
  //     })
  //     .catch(err => {
  //       console.log('Err', err);
  //       return {type: 'error', message: err.message};
  //     });
  // };

  const registerNewCompany = (type) => {
    if (isButtonDisabled) return;
    setIsButtonDisabled(true);



    const transformedArray = systemRoles
      .filter(role => role.invites !== '') // Filter out objects with empty "invites"
      .map(({id, invites, default_alias}) => ({
        user_id: invites,
        role_type_id: id,
        alias: default_alias,
      }));

    const payload = {
      company_name: companyData.name,
      poc_name: companyData.contact,
      email: companyData.email,
      no_of_members: companyData.teamMembers,
      company_type_id: selectedCompanyDescription[0],
      category_ids: isCheck.toString(''),
      members: type ==='skip' ? [] : transformedArray.length > 0 ? transformedArray : [],
      image: ticketImg?.length > 0 ? ticketImg.toString() : '',
      company_logo: companyLogo,
      billing_email: companyData.billing_email,
    };
    commonApi
      .create_company(payload)
      .then(response => {
        if (response.status === 200) {
          successMessage(response.message);

          props.onClose();
          setIsButtonDisabled(false)
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const handleNext = () => {
    if (button === 'business') {
      setStep(1);
    } else {
      setStep('companyLink');
    }
  };
  useEffect(() => {
    getSystemRoles();
    worSpaceType();
  }, []);

  const handleValidation = event => {
    const form = event.currentTarget;

    if (
      form.checkValidity() === false ||
      (currentStep === 2 && selectedCompanyDescription.length === 0) || (currentStep === 2 && isCheck.length < 3) ||
      companyNameExist
      // || (currentStep === 1 && ticketImg == '')
    ) {
      event.preventDefault();
      event.stopPropagation();
      // if (currentStep === 2) {
      //   errorMessage('Please Select that describe your Company');
      // }

      if (currentStep === 2 && isCheck.length < 3) {
       setCatError(true)
      }
      // if (currentStep === 1 && ticketImg == '') {
      //   errorMessage('Please Select image');
      // }

      setValidated(true);
    } else {
      //
      setValidated(false);

      if (currentStep === 'name') {
        event.preventDefault();
        // registerUser();
        setStep(1);
      } else if (currentStep === 1) {
        event.preventDefault();
        // registerUser();
        setStep(2);
      } else if (currentStep === 2) {
        event.preventDefault();
        setStep(3);
      }
      // else if (currentStep == 3){
      //   event.preventDefault();
      //   registerNewCompany()
      // }

      // else if (currentStep == 3) {
      //   setStep(4);
      // } else if (currentStep == 4) {
      //   setStep(5);
      // } else if (currentStep == 5) {
      //   setStep(6);
      // } else if (currentStep == 6) {
      //   setStep(7);
      // }
    }
  };
  const handleCustomerValidation = event => {
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
    } else {
      //
      setValidated(false);
      event.preventDefault();
      event.stopPropagation();
      // registerCustomerUser();
    }
  };

  const handleBackbtn = () => {
    setStep(currentStep - 1);
  };

  const handleSkipbtn = () => {
    if (currentStep < 3) {
      setStep(currentStep + 1);
    }
    // if(currentStep ===3 ){
    //   registerNewCompany()
    // }
  };

  const stepName = () => {
    return (
      <>
        <div
          className="user-detail"
          style={{
            textAlign: 'center',
            paddingTop: '30vh',
          }}>
          <div className="input-container1 text-left">
            {/* <div className="input-group">
              

              <Form.Label controlid="validationCustom01">First Name</Form.Label>

              <Form.Control
                type="text"
                value={name}
                pattern="^\S.*$"
                required
                placeholder=""
                onChange={e => {
                  setName(e.target.value);
                }}
              />
              <Form.Control.Feedback type="invalid">
                Name is required.
              </Form.Control.Feedback>
            </div>

            <div className="input-group">
            

              <Form.Label controlid="validationCustom01">Last Name</Form.Label>

              <Form.Control
                type="text"
                value={email}
                pattern="^\S.*$"
                required
                placeholder=""
                onChange={e => {
                  setEmail(e.target.value);
                }}
              />
              <Form.Control.Feedback type="invalid">
                Email is required.
              </Form.Control.Feedback>
            </div> */}
            <div className="input-group">
              <h3 className="mb-3">Which are you?</h3>
              {/* <Form.Label controlid="validationCustom01">Which are you?</Form.Label> */}
              <Button
                className="new-btn_login btn-white"
                onClick={() => {
                  setButton('business');
                }}>
                Business
              </Button>
              <Button
                className="new-btn_customer btn-white mt-3"
                onClick={() => {
                  setButton('customer');
                }}>
                Customer
              </Button>
            </div>
          </div>

          <Button
            className="new-btn btn-green"
            onClick={() => {
              handleNext();
            }}>
            Next
          </Button>
          {/* <Button
          type="submit"
          style={{display: 'none'}}
          ref={buttonRef}></Button> */}
        </div>
      </>
    );
  };

  const stepCustomer = () => {
    return (
      <>
        <div
          className="user-detail"
          style={{
            textAlign: 'center',
            paddingTop: '30vh',
          }}>
          <div className="input-container1 text-left">
            <div className="input-group">
              <Form.Label controlid="validationCustom01">
                Enter Your Company Link
              </Form.Label>

              <Form.Control
                type="text"
                value={name}
                pattern="^\S.*$"
                required
                placeholder=""
                onChange={e => {
                  setName(e.target.value);
                }}
              />
              <Form.Control.Feedback type="invalid">
                Name is required.
              </Form.Control.Feedback>
            </div>
          </div>

          <Button
            className="new-btn btn-green"
            onClick={() => {
              setStep('customerDeatils');
            }}>
            Next
          </Button>
          {/* <Button
          type="submit"
          style={{display: 'none'}}
          ref={buttonRef}></Button> */}
        </div>
      </>
    );
  };

  const stepCustomerProfilePage = () => {
    return (
      <div
        className="user-detail"
        style={{
          textAlign: 'center',
          paddingTop: '10vh',
        }}>
        <div className="input-container1 text-left">
          <Form
            noValidate
            validated={validated}
            onSubmit={handleCustomerValidation}>
            <div className="onboarding-profile create-profile">
              <div className="profile-img">
                <input
                  type="file"
                  accept="image/*"
                  name="profile_img"
                  onChange={e => updateImage(e)}
                />
                {profileImg && <img src={profileImg} alt="" />}
                {!profileImg && <i className="fas fa-camera" />}
              </div>
              <h3>What’s your name?</h3>
              <p>Yes, we use real names.</p>

              <Form.Group className="fieldset mt-3">
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  name="fname"
                  pattern="[^\s ]+"
                  value={customerData.firstName}
                  onChange={e =>
                    setCustomerData({
                      ...customerData,
                      firstName: e.target.value,
                    })
                  }
                  placeholder="First Name"
                  required
                />
                <Form.Control.Feedback type="invalid">
                  First Name is required.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="fieldset">
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  pattern="[^\s ]+"
                  name="lname"
                  value={customerData.lastName}
                  onChange={e =>
                    setCustomerData({...customerData, lastName: e.target.value})
                  }
                  placeholder="Last Name"
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Last Name is required.
                </Form.Control.Feedback>
              </Form.Group>

              <Button className="new-btn btn-green" type="submit">
                Next
              </Button>
            </div>
          </Form>
        </div>
      </div>
    );
  };

  let firstDivStyle = {
    padding: 0
  };

  return (
    <>
      <div className="create_company_in_app z_index">
        <SlidingPanel
          hidePanel={() => {
            props.onClose();
          }}
          component="create-company "
          firstDivStyle={firstDivStyle}>
          <div className="serach-filter-wrapper">
            <Scrollbars>
              <div className="new-page-wrapper">
                {currentStep === 'customerDeatils' && stepCustomerProfilePage()}
                <Form
                  noValidate
                  validated={validated}
                  onSubmit={handleValidation}
                  style={{position: 'relative'}}
                  className="register_company_form">
                  {currentStep === 'name' && stepName()}

                  {currentStep === 'companyLink' && stepCustomer()}

                  {currentStep !== 'name' &&
                    currentStep !== 'companyLink' &&
                    currentStep !== 'customerDeatils' && (
                      <Navbar
                        handleBackbtn={handleBackbtn}
                        currentStep={currentStep}
                        handleSkipbtn={handleSkipbtn}
                        registerNewCompany={registerNewCompany}
                        ticketImg={ticketImg}
                        isButtonDisabled={isButtonDisabled}
                        setIsButtonDisabled={setIsButtonDisabled}
                      />
                    )}

                  {currentStep === 1 && (
                    <SliderComponent1
                      companyData={companyData}
                      setCompanyData={setCompanyData}
                      hide={true}
                      setImageCreate={setImageCreate}
                      ticketImg={ticketImg}
                      companyNameExist={companyNameExist}
                      companyLogo={companyLogo}
                      setCompanyLogo={setCompanyLogo}
                      currentStep={currentStep}
                      setStep={setStep}
                      // companyImageUpload={companyImageUpload}
                    />
                  )}
                  {currentStep === 2 && (
                    <SliderComponent2
                      isCheck={isCheck}
                      setIsCheck={setIsCheck}
                      catError={catError}
                      workSpaceTypes={workSpaceTypes}
                      selectedCompanyDescription={selectedCompanyDescription}
                      setSelectedCompanyDescription={
                        setSelectedCompanyDescription
                      }
                      hide={true}
                      currentStep={currentStep}
                      setStep={setStep}
                      setIsCheckValue={setIsCheckValue}
                      isCheckValue={isCheckValue}
                      isCheckAll={isCheckAll}
                      setIsCheckAll={setIsCheckAll}
                    />
                  )}
                  {currentStep === 3 && (
                    <SliderComponent3
                      systemRoles={systemRoles}
                      setSystemRoles={setSystemRoles}
                      contact={contact}
                      setContact={setContact}
                      showComponent={showComponent}
                      setShowComponent={setShowComponent}
                      hide={true}
                      currentStep={currentStep}
                      setStep={setStep}
                    />
                  )}
                  <div
                    className={`responsive_next_btn text-center ${
                      currentStep === 'name' ? '' : 'navbar_bg'
                    }`}
                    style={{width: '100%', position: 'absolute'}}>
                    <Button className="new-btn btn-green " type="submit">
                      Next
                    </Button>
                  </div>
                </Form>
              </div>
            </Scrollbars>
          </div>
        </SlidingPanel>
      </div>

      {showComponent === 'inviteUser' && (
        <SendInvitation
          setContact={setContact}
          contact={contact}
          onClose={() => {
            setShowComponent('');
          }}
        />
      )}
    </>
  );
};
export default CreateWorkSpace;
