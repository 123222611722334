export const accomplishmentCategories = (state = [], action) => {
  switch (action.type) {
    case 'FILTER/ACCOMPLISHMENT_CATEGORY':
      return action.payload;
    default:
      return state;
  }
};

export const appointmentTypes = (state = [], action) => {
  switch (action.type) {
    case 'FILTER/APPOINTMENT_TYPE':
      return action.payload;
    default:
      return state;
  }
};

export const opportunityTypes = (state = [], action) => {
  switch (action.type) {
    case 'FILTER/OPPORTUNITY_TYPE':
      return action.payload;
    default:
      return state;
  }
};

// export const opportunityApi = (state = 1, action) => {
//   switch (action.type) {
//     case 'FILTER/API':
//       return state + 1;
//     default:
//       return state;
//   }
// };
