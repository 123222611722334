import React, {useState, useEffect} from 'react';
import {Button, Form} from 'react-bootstrap';
import commonApi from '../../Services/CommonService';
import {errorMessage, successMessage} from '../../Utils/ToastMessages';
import Scrollbars from 'react-custom-scrollbars';
import {labels} from '../../Constants/Translations';
import {selected_lang, DEFAULT_IMAGE} from '../../Utils/env';
import ReferNotes from './ReferNotes';
import SelectedReferUser from './SelectedReferUser';
import SlidingPanel from '../SlidingPanel';
import CircleListBuilders from '../../Pages/Front/StaticPages/CircleListBuilders';
import {fixName} from '../../Utils/commonUtils';
import {amplitudeEvent} from '../../Services/AmplitudeServices';
import {ENV_HOST} from '../../Utils/env';
import {useLocation} from 'react-router-dom';
import LoaderSpinner from '../Modals/LoaderSpinner';
import SendInvitation from '../../Pages/Front/StaticPages/SendInvitation';
import CustomTabs from '../CustomTabs';
import ProfileComponent from '../ProfilePage/ProfileComponent';
import Invite from '../SingleComponent/Invite';
import {ShareablesBlackIcon} from '../../Constants/Images';
import CustomModal from '../CustomModal';
import {
  FacebookShareButton,
  WhatsappShareButton,
  EmailShareButton,
  TwitterShareButton,
  LinkedinShareButton,
} from 'react-share';

const ReferProfile = props => {
  const [refer, setRefer] = useState('');
  const [referId, setReferId] = useState('');
  const [builders, setBuilders] = useState('');
  const [showLoader, setshowLoader] = useState(false);
  const [intrestedFor, toggleIntrestedFor] = useState('');
  const [allBuilders, setAllBuilders] = useState([]);
  const [notes, setNotes] = useState('');
  const [showComponent, setShowComponent] = useState('');
  const [tabType, setTabType] = useState('Details');
  const [profileId, setProfileId] = useState('');
  const [contact, setContact] = useState([]);
  const [originalDataOfMod, setOriginalDataOfMod] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState('1');

  const [inviteLoader, setInviteLoader] = useState(true);
  const [inviteFollowers, setInviteFollowers] = useState([]);
  const [followersList, setFollowersList] = useState([]);
  const [inviteQry, setInviteQry] = useState('');

  const locationWeb = useLocation();

  const submit = () => {
    if (builders.length === 0) {
      errorMessage('Select  recipient');
    } else if (referId === '') {
      errorMessage('Select purpose ');
    } else {
      setshowLoader(true);
      referProfile();
    }
  };

  const referProfile = () => {
    var payloadReferProfile = {
      purpose_id: referId,
      referred_id: props.userData.id,
      refer_notes: notes,
      user_ids: builders,
      contacts: contact,
      status: selectedStatus,
      // [{ contact: '', fname: '', lname: '' }, { contact: '', fname: '', lname: '' }]
    };
    commonApi
      .refer_profile(payloadReferProfile)
      .then(res => {
        if (res.status === 200) {
          amplitudeEvent('REFER_PERSON');
          props.onClose();
          successMessage(res.message);
        }
        setshowLoader(false);
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const getRefer = () => {
    var payloadRefer = {
      type: 'create',
    };
    commonApi
      .get_refer_purpose(payloadRefer)
      .then(res => {
        if (res.status === 200) {
          setRefer(res.purpose);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const name = props.userData.unique_user_name;
  // const lowerName = name.toLowerCase();

    // const lowerName = name.toLowerCase();
    let lowerName = '';
    let creator_id = '';
    let companyNameFromLocal = localStorage.getItem('activeWorkspaceItem')
      ? JSON.parse(localStorage.getItem('activeWorkspaceItem'))
      : [];
  
    // Check if lowerName is not empty and has at least one item
    if (companyNameFromLocal.length > 0) {
      // Access the first item in the array and get the company_name property
      let companyName = companyNameFromLocal[0].company_name;
      creator_id = companyNameFromLocal[0]?.created_by
      // Replace spaces with hyphens
      lowerName = companyName.replace(/\s+/g, '-');
  
      console.log(lowerName); // Output the formatted company name
    }
  
  const storeReferral = () => {
    var payloadAllUser = {
      referral_id: props.userData.id,
      referral_key:
        'https://' +
        ENV_HOST +
        'roiforpros.com/' +
        lowerName +
        '/profile?id=' +
        props.userData.id,
      referral_type: 'profile',
    };

    commonApi
      .store_referral(payloadAllUser)
      .then(res => {
        if (res.status === 200) {
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const excludeFollowersList = qry => {
    var payloadFolower = {
      chat_room_id: '',
      circle_id: '',
      opportunity_id: '',
      search_query: qry,
      user_id: props.userData.id,
    };
    commonApi
      .excluded_followers_list(payloadFolower)
      .then(res => {
        setshowLoader(false);
        if (res.status === 200) {
          // setFollowing(res.data.filter((item) => !builders.includes(item.id)));
          // setNewFollowers(res.data);
          // setFollowing(res.data.filter(item => !builders.includes(item.id)));
          // setNewFollowers(res.data.filter(item => !builders.includes(item.id)));
          // setNewFollowers(res.data);
          // setFollowing(res.data);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  useEffect(() => {
    getRefer();
    excludeFollowersList();
    setshowLoader(true);
    handleNotificationDirection();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // const mergedArray = [...following, ...newFollowers];

  const handleNotificationDirection = () => {
    const {state} = locationWeb;

    if (state && state.typeIs === 'referEvent') {
    }
    if (state && state.typeIs === 'referQuestion') {
    }
    if (state && state.typeIs === 'feedback') {
    }
    if (state && state.typeIs === 'referOpportunity') {
    }
    if (state && state.typeIs === 'referSession') {
    }
    if (state && state.typeIs === 'referBuilder') {
    }
  };

  // useEffect(() => {
  //   setFollowing(newFollowers.filter((item) => !builders.includes(item.id)));
  //   setAllBuilders(newFollowers.filter((item) => builders.includes(item.id)));
  // }, [builders]);

  const CircleUserListData = item => {
    if (item.length > 0) {
      item.forEach((miniItem, index) => {
        if (!builders.includes(miniItem.id)) {
          setBuilders(previous => [...previous, miniItem.id]);
          setAllBuilders(previous => [...previous, miniItem]);
          setOriginalDataOfMod(previous => [...previous, miniItem]);
        }
      });
    }
  };

  const handleProfileComponent = userId => {
    setProfileId(userId);
    setShowComponent('profileComponent');
  };

  // useEffect(() => {
  //   if (builders.length === 0) {
  //     setShowSlectedUser('');
  //   }
  // }, [builders]);

  const handleCircle = () => {
    setShowComponent('CircleList');
  };

  const dealStatus = [
    {
      status: '1',
      color: '#3597C2',
    },
    {
      status: '2',
      color: '#FEBE13',
    },

    {
      status: '3',
      color: '#49B038',
    },
  ];

  useEffect(() => {
    
    if(contact.length > 0){
      setInviteLoader(true)
      setInviteQry('')
    }
  }, [contact])


  const renderReferProfileButtons = profile => (
    <>
      
        <div className="ganjaaaa">
          <div>
            <div
              className="share-btn-black-img-holder as-link"
              onClick={() => {setShowComponent('profileComponent')}}>
              <img
                style={{filter: 'brightness(6)'}}
                src={ShareablesBlackIcon}
                alt=""
                className="as-link"
                // onClick={() => {
                //   setShowCompanyShareModel('show_model');
                // }}
              />
              <span>Share Externally</span>
            </div>
          </div>
        </div>
      
    </>
  );

  const linkForShare ='https://'+ENV_HOST+'roiforpros.com/'+props.userData.id
  return (
    <>
      <SlidingPanel
        header={true}
        showBlackHeader={true}
        lastDivStyle={{
          borderRadius: '35px',
          background: '#F7F6F2',
          // marginBottom: '20px',
        }}
        renderData={renderReferProfileButtons}
        firstDivStyle={{
          padding: 0,
          background: 'linear-gradient(to bottom, #424242, #424242, #f7f6f2 50%)',
        }}
        hidePanel={() => props.onClose()}
        marginTop={props.marginTop ? props.marginTop : false}>
        <div
          className="reffer-profile-scroll-block reffer-profile-scroll-holder"
          style={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            paddingLeft: '24px',
          }}>
          <Scrollbars>
            <div className="fix-width-forms  fix-width-refferal-form">
              {/* {showLoader && <LoaderSpinner />} */}
              <div className="sidebar-heading-wrapper">
                {/* <div className="heading">
                  <h2>
                    {fixName(
                      props.userData.first_name,
                      props.userData.last_name,
                    )}
                  </h2>
                </div> */}
                {/* <div className="persons-img-list m-0">
                  <div
                    className="img-item as-link m-0"
                    onClick={() => {
                      handleProfileComponent(props.userData.id);
                    }}>
                    <img
                      className="m-0"
                      src={
                        props.userData.profile_img
                          ? props.userData.profile_img
                          : DEFAULT_IMAGE
                      }
                      alt=""
                    />
                  </div>
                </div> */}

                {/* <div className="search-tabs-icons">
                  <span
                    className="icon as-link"
                    onClick={() => {
                      setShowComponent('ReferNotes');
                    }}>
                    <i className="fa-sharp fa-solid fa-book"></i>
                  </span>
                </div> */}
              </div>

              <div className="d-flex align-items-center justify-content-center  w-100">
                <div className="create-ticket-tabs text-center">
                  <CustomTabs
                    darkmode
                    tabs={['Details', 'Recipient']}
                    active={tabType}
                  onSelect={item => {
                      setTabType(item);
                      if (item === 'Details' && inviteQry !== '') {
                        setInviteFollowers(followersList);
                        setInviteQry('');
                      }
                    }}
                  />
                </div>
              </div>
              {tabType === 'Details' && (
                <>
                  {props.type !== 'eventRefer' && (
                    <div className="intrested-for-wrapper">
                      <Form.Group className="">
                        <Form.Label>
                          {' '}
                          {/* {fixName(
                            props.userData.first_name,
                            props.userData.last_name,
                          )} */}
                          <h2
                            style={{
                              marginBottom: '15px',
                            }}>
                            Being Referred
                          </h2>
                          <div className="messages-profile-heading message-profile-heading-nospace no-space">
                            <div
                              className="profile-img as-link"
                              onClick={() => {
                                handleProfileComponent(props.userData.id);
                              }}>
                              {' '}
                              <img
                                style={{
                                  boxShadow: 'unset',
                                }}
                                src={
                                  props.userData.profile_img
                                    ? props.userData.profile_img
                                    : DEFAULT_IMAGE
                                }
                                alt=""
                              />
                            </div>
                            <div className="profile-content">
                              <div className="user-name mb-0">
                                <h2 className="as-link">
                                  {' '}
                                  {fixName(
                                    props.userData.first_name,
                                    props.userData.last_name,
                                  )}
                                </h2>
                              </div>
                              <div className="profile-links mt-1 customized-profile-links">
                                <span></span>
                              </div>
                            </div>
                          </div>
                          <h2
                            style={{
                              marginBottom: '15px',
                            }}>
                            Reason
                          </h2>
                        </Form.Label>

                        <div
                          className="d-flex flex-column"
                          style={{
                            maxWidth: '93%',
                            justifyContent: 'center',
                            margin: '0 auto',
                          }}>
                          {Object.entries(refer).length > 0 &&
                            refer.map((item, index) => (
                              <Button
                                key={`refer-item-${index}`}
                                className={
                                  intrestedFor === item.title ? 'active' : ''
                                }
                                id={item.id}
                                onClick={e => {
                                  toggleIntrestedFor(item.title);
                                  setReferId(e.target.id);
                                }}>
                                {item.title}
                              </Button>
                            ))}
                        </div>
                      </Form.Group>
                    </div>
                  )}
                  <div>
                    <h2>Likelihood</h2>
                  </div>

                  <div className="likelihood_color_flex mb-2">
                    {dealStatus.map((status, index) => (
                      <div
                        style={{
                          border:
                            selectedStatus === status.status
                              ? '3px solid #424242'
                              : '',
                        }}
                        className={`deal_status_border ${
                          selectedStatus === status.status ? 'white' : ''
                        }  `}>
                        <div
                          key={index}
                          className="deal_status"
                          style={{
                            backgroundColor: status.color,
                          }}
                          onClick={() => {
                            setSelectedStatus(status.status);
                          }}></div>
                      </div>
                    ))}
                  </div>
                  <div className="serach-filter-wrapper">
                    <div className="sidebar-heading-wrapper">
                      <div className="heading">
                        <label>Notes (optional)</label>
                      </div>
                    </div>

                    <div className="fix-width-forms">
                      <div className="form-outline">
                        <Form>
                          <textarea
                            // disabled={props.disabled}
                            className="form-control"
                            id="textAreaExample1"
                            rows="12"
                            placeholder="Write a note..."
                            value={notes}
                            onChange={e => {
                              setNotes(e.target.value);
                            }}></textarea>
                        </Form>
                      </div>
                    </div>
                    <div className="invite-persons-heading mt-3">
                      <span
                        className="builder-text as-link"
                        onClick={() => {
                          navigator.clipboard.writeText(
                            'https://' +
                              ENV_HOST +
                              'roiforpros.com/' +
                              lowerName + '/'+ creator_id +
                              '/profile?id=' +
                              props.userData.id,
                          );
                          storeReferral();
                          successMessage(labels.copy_link[selected_lang]);
                        }}>
                        Copy Profile Link
                      </span>
                    </div>
                  </div>
                </>
              )}
              {tabType === 'Recipient' && (
                <>
                
 <div className="refferal-list-wrapper">
                  {inviteLoader && (<div className="invite_loader_hieght">
                  <LoaderSpinner   className={'curved-spinner-bg'}/>
                  </div>
                 )}
                 {!inviteLoader && (
                   <div className="invite-persons-heading justify-content-center">
                   <span
                     className="builder-text as-link "
                     onClick={() => {
                       setShowComponent('ReferOutSideBuild');
                       setTimeout(() => {
                        if (contact.length === 0 && inviteQry !== '') {
                          setInviteFollowers(followersList);
                          setInviteQry('');
                        }
                      }, 1000);
                     }}>
                     Refer Outside ROI
                   </span>
                 </div>
                 )}
                 
                  <Invite
                    type={'refer'}
                    moderator={builders}
                    setModerator={setBuilders}
                    selectedModData={allBuilders}
                    setSelectedModData={setAllBuilders}
                    contact={contact}
                    setContact={setContact}
                    originalDataOfMod={originalDataOfMod}
                    setOriginalDataOfMod={setOriginalDataOfMod}
                    handleCircle={handleCircle}
                    forApiHit={showComponent}
                    onClick={() => {
                      setShowComponent('inviteUser')
                      setTimeout(() => {
                        if(contact.length ===0 && inviteQry !==''){
                          setInviteFollowers(followersList)
                                setInviteQry('')
                        } 
                      }, 1000);
                     
                    }}
                    showLoader={inviteLoader}
                    setshowLoader={setInviteLoader}
                    followers={inviteFollowers}
                    setFollowers={setInviteFollowers}
                    setFollowersList={setFollowersList}
                    followersList={followersList}
                    setSearchQry={setInviteQry}
                    searchQry={inviteQry}
                  />
                   </div>
                </>
              )}
            </div>
          </Scrollbars>
          {tabType === 'Details' && (
            <div className="filter-btn-wrapper fix-width-forms invite-btns text-center px-0 bg_transparent bottom-0">
              <Button type="submit" onClick={submit}>
                Send Referral
              </Button>
            </div>
          )}
        </div>
      </SlidingPanel>

      {showComponent === 'profileComponent' && (
        <CustomModal
          position="bottom"
          className={'share-links-modal'}
          onClose={() => setShowComponent('')}
          // setTabType={setTabType}
        >
          <div className="camera-options-list w-100 social-links-modal">
            <ul>
              <li className="as-link">
                <FacebookShareButton url={linkForShare} title={false}>
                  <i className="fa-brands fa-facebook"></i>
                </FacebookShareButton>
              </li>
              <li className="as-link">
                <WhatsappShareButton url={linkForShare}>
                  <i className="fa-brands fa-whatsapp"></i>
                </WhatsappShareButton>
              </li>
              <li className="as-link">
                <EmailShareButton url={linkForShare}>
                  <i className="fa-solid fa-envelope"></i>
                </EmailShareButton>
              </li>
              <li className="as-link">
                <TwitterShareButton url={linkForShare}>
                  <i className="fa-brands fa-twitter"></i>
                </TwitterShareButton>
              </li>
              <li className="as-link">
                <LinkedinShareButton url={linkForShare}>
                  <i className="fa-brands fa-linkedin"></i>
                </LinkedinShareButton>
              </li>
            </ul>
          </div>
        </CustomModal>
      )}
      {showComponent === 'profileComponent' && profileId && (
        <ProfileComponent
          userId={profileId}
          show={false}
          closeModal={() => {
            setShowComponent('');
          }}
          onClose={() => {
            setShowComponent('');
          }}
          userData={[]}
          moderator={false}
          // setParticipantType={setParticipantType}
          VoxeetSDK={[]}
          conferenceInfo={[]}
          jdSpecialM={[]}
          jdSpecialA={[]}
        />
      )}
      {builders.length + contact.length > 0 &&
        showComponent === 'SelectedUsers' && (
          <SelectedReferUser
            selectedAllUser={allBuilders}
            users={builders}
            setUsers={setBuilders}
            setSelectedAllUser={setAllBuilders}
            onClose={() => setShowComponent('')}
            alreadyAddedUser={[]}
            referOutSide={contact}
            setReferOutSide={setContact}
          />
        )}

      {showComponent === 'CircleList' && (
        <CircleListBuilders
          onClose={() => {
            setShowComponent('');
          }}
          // setInviteCircle={setInviteCircle}
          hide={true}
          CircleUserListData={CircleUserListData}
        />
      )}

      {showComponent === 'ReferNotes' && (
        <ReferNotes
          setNotes={setNotes}
          notes={notes}
          onClose={() => {
            setShowComponent('');
          }}
        />
      )}
      {showComponent === 'ReferOutSideBuild' && (
        <SendInvitation
          id={props.userData.id}
          referType={'profile'}
          setContact={setContact}
          onClose={() => setShowComponent('')}
        />
      )}
      {showComponent === 'inviteUser' && (
        <SendInvitation
          setContact={setContact}
          onClose={() => setShowComponent('')}
        />
      )}
    </>
  );
};
export default ReferProfile;
