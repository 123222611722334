import React, {useState,useEffect} from 'react';
import {Col, Form, Row, Dropdown} from 'react-bootstrap';
// import {listIconGreen} from '../../Constants/Images';

import Scrollbars from 'react-custom-scrollbars';

import {
  FireIcon,
  MachineIcon,
  WhitePenIcon,
  timeIcon,
  davisLogo,
} from '../../../Constants/Images';

import SlidingPanel from '../../SlidingPanel';
import {DEFAULT_IMAGE} from '../../../Utils/env';
import EditPencilSvg from '../../../Svg/EditPencilSvg';
import { checkPermissions } from '../../../Utils/commonUtils';
import { useSelector } from 'react-redux';

const SecondaryPocs = props => {

  const [deleteItem, setDeleteItem] = useState({});
  const menuPermesions = useSelector(state => state.menuPermesionList);

  const BusinnesProfilePermessions =
    menuPermesions && checkPermissions(menuPermesions, 'Business Profile');

    useEffect(() => {
      if(props.deleteItem){
        props.deletePocs(deleteItem);
      }
      props.setDeleteItem(false)
      setDeleteItem({})
    }, [props.deleteItem])
    
  return (
    <div className="create_company_in_app">
      <SlidingPanel
        hidePanel={() => {
          props.onClose();
        }}>
        <div style={{display: 'flex', flex: 1}}>
          <div
            className="cutsomers-page-listing secondary_pocs_listing primary_panel_content "
            style={{padding: '25px 20px 15px', background: 'white'}}>
            <div className="mt-4 customers-list refferal-list-wrapper">
              <Scrollbars>
                <Row className="mb-3 mt-2">
                  <Col md={1} className="role-head icons-row-2">
                    {' '}
                    Title
                  </Col>

                  <Col md={2} className="role-head icons-row-2 ">
                    Alias{' '}
                  </Col>
                  <Col md={4} className=" role-head">
                    {' '}
                    POCs
                  </Col>
                  <Col md={3} className="role-head ">
                    {' '}
                  </Col>
                </Row>

                {props.pocDetails.length > 0 &&
                  props.pocDetails.map((item, index) => (
                    <Row key={`r-item-${index}`}>
                      <Col md={3} className="role-row icons-row-2 ">
                        {item.title}
                      </Col>
                      <Col md={3} className="role-row icons-row-2 ">
                        {item.alias}
                      </Col>
                      <Col md={5} className="role-row icons-row">
                        <div className="customer_imgs">
                          <>
                            {Object.entries(item.user_details).length > 0 &&
                              item.user_details
                                .slice(0, 5)
                                .map((item, index) => (
                                  <img
                                    src={
                                      item.profile_img
                                        ? item.profile_img
                                        : DEFAULT_IMAGE
                                    }
                                    alt=""
                                  />
                                ))}
                            {item.user_details.length > 5 && (
                              <div className="remaining_div">
                                +{item.user_details.length - 5}
                              </div>
                            )}
                          </>
                        </div>
                      </Col>

                      {BusinnesProfilePermessions &&
                (BusinnesProfilePermessions === 'allow' ||
                  BusinnesProfilePermessions?.add_poc === true) && (
                      <Col md={1} className="role-row ">
                        <div className="d-flex gap-2 ">
                          {/* {BusinnesProfilePermessions &&
                                          (BusinnesProfilePermessions ===
                                            'allow' ||
                                            BusinnesProfilePermessions?.edit_company ===
                                              true) && (
                                            <> */}
                          <span
                            className=" as-link"
                            onClick={() => {
                              props.setSelectedPoc(item);
                              props.setShowComponent('editPocs');
                            }}>
                            <EditPencilSvg fill={'#52bd41'} />
                          </span>

                          <span
                            className="poc_delete as-link"
                            onClick={() => {
                              props.setShowModel('deleteItem');
                              setDeleteItem(item)
                              // props.deletePocs(item);
                            }}>
                            <i className="fa-solid fa-trash-can" />
                          </span>
                          {/* </>
                                          )} */}
                          {/* <div
                                          className="poc_send_icon as-link"
                                          onClick={() => {
                                            create_personal_group_chat_only(
                                              item,
                                              '',
                                              '',
                                            );
                                          }}>
                                          <i className="fa-solid icon-plane"></i>
                                        </div> */}
                        </div>
                      </Col>
                  )}
                    </Row>
                  ))}
              </Scrollbars>
            </div>
          </div>
        </div>
      </SlidingPanel>
    </div>
  );
};

export default SecondaryPocs;
