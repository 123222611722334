import React, {useState, useEffect} from 'react';
import {Button, Form} from 'react-bootstrap';
import commonApi from '../../Services/CommonService';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import {geocodeByPlaceId} from 'react-google-places-autocomplete';
import {getLatLng} from 'react-google-places-autocomplete';
import FocusModel from './FocusModel';
import SlidingPanel from '../SlidingPanel';
import Scrollbars from 'react-custom-scrollbars';
import LoaderSpinner from './LoaderSpinner';
import $ from 'jquery';
import PrefListItems from '../Preferences/PrefListItems';
import {labels} from '../../Constants/Translations';
import {selected_lang} from '../../Utils/env';

const RefferalSearchFilter = props => {
  // const [show, setShow] = useState(props.show);
  const [categoriesValue, setCategoriesValue] = useState([]);
  const [appointmentValue, setAppointmentValue] = useState([]);
  const [showLoader, setshowLoader] = useState(false);
  const [searchFilter, setSearchFilter] = useState(false);
  const [persnalTypes, setPersnalTypes] = useState([]);
  const [idenntifier_Filter_Values, setIdenntifier_Filter_Values] = useState(
    [],
  );

  const [numToShow, setNumToShow] = useState(5); // default value to show 5 items

  const handleClickMoreLess = e => {
    e.preventDefault();
    setNumToShow(numToShow === 5 ? categoriesValue.length : 5); // toggle between showing 5 or all items
  };

  // const [active, setActive] = useState(false);
  // const geocoder = new window.google.maps.Geocoder();

  const onClose = () => {
    // setShow(false);
    props.onClose();
  };

  useEffect(() => {
    getAreasCategories();
    getAppointmentTypes();
  }, []);

  const handle = e => {
    e.preventDefault();

    // var place_id = !location ? '' : location.value.place_id;
    let place_id =
      typeof props.locationS === 'object'
        ? props.locationS.value.place_id
        : typeof props.locationS === 'string'
        ? ''
        : '';
    if (props.networkFilter) {
      if (place_id) {
        geocodeByPlaceId(place_id)
          .then(results => getLatLng(results[0]))
          .then(({lat, lng}) => {
            addObjectFilter(lat, lng);
          });
      } else {
        addObjectFilter();
      }
    } else {
      if (place_id) {
        geocodeByPlaceId(place_id)
          .then(results => getLatLng(results[0]))
          .then(({lat, lng}) => {
            if (props.builderData) {
              getBuilders(lat, lng);
            } else {
              getReferrals(lat, lng);
            }

            // handleResetForm();
          });
      } else {
        if (props.builderData) {
          getBuilders('', '');
        } else {
          getReferrals('', '');
        }
      }
    }

    props.onClose();
  };

  const addObjectFilter = (lat, lng) => {
    props.setLatitude(lat);
    props.setLongitude(lng);
    let Lng =
      typeof props.locationS === 'object'
        ? lng
        : typeof props.locationS === 'string'
        ? props.longitude
        : '';
    let Lat =
      typeof props.locationS === 'object'
        ? lat
        : typeof props.locationS === 'string'
        ? props.latitude
        : '';
    let adress =
      typeof props.locationS === 'object'
        ? props.locationS.label
        : typeof props.locationS === 'string'
        ? props.locationS
        : '';
    //  let Lat = typeof props.locationS === 'object'
    //       ? lat
    //       : typeof props.locationS === 'string'
    //       ? props.latitude
    //       : '';
    props.SetNetworkFilter({
      allies: props.active ? 'yes' : 'no',
      // gender: props.gender.toString(),
      gender: '',
      otherIdentifires: props.identiferId.toString(),
      // otherIdentifires: props.identiferId.toString(),
      // ethnicities: props.ethnicityId.toString(),
      ethnicities: '',
      city_states: props.focusLocations,

      on_boarding_areas: props.persnalTypesId,
      appointment_types: props.appointmentId_Focus,
      on_boarding_categories: props.categoriesId_Focus,

      on_boarding_areas_filter: props.areas_Filter.toString(),
      other_identifier_filter: props.idenntifier_Filter.toString(),

      appointment_type_ids: props.appointmentId,
      areas: props.isCheckArea,
      categories: props.categoriesId,
      hometown_location: adress,
      search_query: '',
      travel_location: adress,
      latitude: Lat,
      longitude: Lng,
    });
  };
  const getBuilders = (lat, lng) => {
    props.setLatitude(lat);
    props.setLongitude(lng);
    let Lng =
      typeof props.locationS === 'object'
        ? lng
        : typeof props.locationS === 'string'
        ? props.longitude
        : '';
    let Lat =
      typeof props.locationS === 'object'
        ? lat
        : typeof props.locationS === 'string'
        ? props.latitude
        : '';
    let adress =
      typeof props.locationS === 'object'
        ? props.locationS.label
        : typeof props.locationS === 'string'
        ? props.locationS
        : '';
    //  let Lat = typeof props.locationS === 'object'
    //       ? lat
    //       : typeof props.locationS === 'string'
    //       ? props.latitude
    //       : '';
    var data = {
      allies: props.active ? 'yes' : 'no',
      // gender: props.gender.toString(),
      gender: '',
      otherIdentifires: props.identiferId.toString(),
      // otherIdentifires: props.identiferId.toString(),
      // ethnicities: props.ethnicityId.toString(),
      ethnicities: '',
      city_states: props.focusLocations,

      on_boarding_areas: props.persnalTypesId,
      appointment_types: props.appointmentId_Focus,
      on_boarding_categories: props.categoriesId_Focus,

      on_boarding_areas_filter: props.areas_Filter.toString(),
      other_identifier_filter: props.idenntifier_Filter.toString(),

      appointment_type_ids: props.appointmentId,
      areas: props.isCheckArea,
      categories: props.categoriesId,
      hometown_location: adress,
      search_query: '',
      travel_location: adress,
      latitude: Lat,
      longitude: Lng,
    };

    commonApi
      .get_builders(data)
      .then(response => {
        if (response) {
          props.builderData(response.discover_builder);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  const getReferrals = (lat, lng) => {
    props.setLatitude(lat);
    props.setLongitude(lng);
    let Lng =
      typeof props.locationS === 'object'
        ? lng
        : typeof props.locationS === 'string'
        ? props.longitude
        : '';
    var adress =
      typeof props.locationS === 'object'
        ? props.locationS.label
        : typeof props.locationS === 'string'
        ? props.locationS
        : '';
    let Lat =
      typeof props.locationS === 'object'
        ? lat
        : typeof props.locationS === 'string'
        ? props.latitude
        : '';
    setshowLoader(true);
    var payload = {
      builder_filter_data: {
        allies: props.active ? 'yes' : 'no',
        gender: props.gender.toString(),
        otherIdentifires: props.identiferId.toString(),
        // ethnicities: props.ethnicityId.toString(),
        ethnicities: '',
        city_states: props.focusLocations,
        appointmentTypes: props.appointmentId,
        backgrounds: props.categoriesId,
        characteristics: [],
        homeLatitude: Lat,
        homeLongitude: Lng,
        homeTown: adress,
        travelLatitude:
          typeof props.locationS === 'object'
            ? lat
            : typeof props.locationS === 'string'
            ? props.latitude
            : '',
        travelLocation: adress,
        travelLongitude: Lng,
      },
      category_ids: '',
      opportunity_filter_data: {
        address: adress,
        allies: props.active ? 'yes' : 'no',
        // gender: props.gender.toString(),
        gender: '',
        otherIdentifires: props.identiferId.toString(),
        // ethnicities: props.ethnicityId.toString(),
        ethnicities: '',
        city_states: props.focusLocations,
        amount: props.amount,
        frequencyIds: props.freq,
        latitude: Lat,
        longitude: Lng,
        opportunityIds: props.areas_Filter,
        vaccinated: props.covid,
        virtual: props.virtual,
      },
      page: 1,
      referred_by_me: props.arrowButton,
      search_query: '',
      type: props.subTabType,
    };
    commonApi

      .get_referrals(payload)
      .then(res => {
        if (res.status === 200) {
          props.data(res.data);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  const handleClickB = e => {
    const {id, checked} = e.target;
    props.setCategoriesId([...props.categoriesId, id]);
    if (!checked) {
      props.setCategoriesId(props.categoriesId.filter(item => item !== id));
    }
  };
  const handleClick = e => {
    const {id, checked} = e.target;
    props.setAppointmentId([...props.appointmentId, id]);
    if (!checked) {
      props.setAppointmentId(props.appointmentId.filter(item => item !== id));
    }
  };

  const getAppointmentTypes = () => {
    setshowLoader(true);
    commonApi
      .get_appointment_types()
      .then(res => {
        if (res.status === 200) {
          setAppointmentValue(res.appointment_type);
          setshowLoader(false);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const getAreasCategories = () => {
    commonApi
      .get_areas_categories()
      .then(res => {
        if (res.status === 200) {
          setCategoriesValue(res.categories);
          setPersnalTypes(res.areas);
          setIdenntifier_Filter_Values(res.identifer);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  let jugar =
    typeof props.locationS === 'object'
      ? props.locationS.label
      : typeof props.locationS === 'string' && props.locationS !== ''
      ? props.locationS
      : 'Type city and state';

  const clearAll = () => {
    props.setLocationS('');
    props.setLatitude('');
    props.setLongitude('');

    $('.css-1uccc91-singleValue').html('Type city and state');
  };

  return (
    <>
      <SlidingPanel hidePanel={() => onClose()}>
        {/* {showLoader && <LoaderModal show={showLoader} />} */}
        {showLoader && <LoaderSpinner />}

        {!searchFilter && (
          <div className="serach-filter-wrapper">
            <div className="sidebar-heading-wrapper mt-0 text-right fix-width-forms">
              {/* <div className="ticket-icons-options">
                <ul>
                  <li
                    className={props.active ? 'active' : ''}
                    onClick={() => {
                      setSearchFilter(true);
                    }}>
                    <i className="icon-bullseye" />
                  </li>
                </ul>
              </div> */}
            </div>

            <Scrollbars>
              <div className="filter-wrapper fix-width-forms">
                <div className="filter-category-list">
                  <Form>
                    <div className="location-box">
                      <div className="location-heading mb-2">
                        <h2>Offered 1-on-1s</h2>
                      </div>

                      <ul>
                        {Object.entries(appointmentValue).length > 0 ? (
                          Object.entries(appointmentValue).length > 0 &&
                          appointmentValue.map((item, index) => (
                            <li
                              key={`ff-${index}`}
                              className={
                                props.appointmentId.includes(item.id)
                                  ? 'active'
                                  : ''
                              }>
                              <Form.Label>
                                {item.appointment_type}{' '}
                                <input
                                  type="checkbox"
                                  id={item.id}
                                  value={item.id}
                                  onChange={handleClick}
                                  checked={props.appointmentId.includes(
                                    item.id,
                                  )}
                                />{' '}
                                <span />
                              </Form.Label>
                            </li>
                          ))
                        ) : (
                          <></>
                        )}
                      </ul>
                    </div>

                    <div className="location-box">
                      <div className="location-heading">
                        <h2>
                          Location <i className="fas fa-location-arrow" />
                        </h2>
                        {props.locationS !== '' && (
                          <span
                            className="clear-btn"
                            onClick={() => {
                              clearAll();
                            }}>
                            Clear
                          </span>
                        )}
                      </div>
                      <div className="location-field form-control">
                        <GooglePlacesAutocomplete
                          selectProps={{
                            onChange: props.setLocationS,
                            name: 'city',
                            placeholder: jugar,
                          }}
                        />
                      </div>
                    </div>
                    {/* <div className="location-box mt-4">
                      <div className="location-heading mb-2">
                        <h2>Background</h2>
                        {props.categoriesId.length > 0 && (
                          <span
                            className="clear-btn as-link"
                            onClick={() => {
                              props.setCategoriesId([]);
                            }}>
                            Clear All
                          </span>
                        )}
                      </div>

                      <ul>
                        {Object.entries(categoriesValue).length > 0 ? (
                          Object.entries(categoriesValue).length > 0 &&
                          categoriesValue
                            .slice(0, numToShow)
                            .map((item, index) => (
                              <li
                                key={`ff-again-${index}`}
                                className={
                                  props.categoriesId.includes(item.id)
                                    ? 'active'
                                    : ''
                                }>
                                <Form.Label>
                                  {item.title}
                                  <input
                                    type="checkbox"
                                    id={item.id}
                                    value={item.id}
                                    onChange={handleClickB}
                                    checked={props.categoriesId.includes(
                                      item.id,
                                    )}
                                  />{' '}
                                  <span />
                                </Form.Label>
                              </li>
                            ))
                        ) : (
                          <></>
                        )}
                      </ul>
                      <div className="read-more-read-less">
                        <button
                          className="btn show-more"
                          onClick={handleClickMoreLess}
                          style={{margin: 'auto'}}>
                          {' '}
                          {numToShow === 5
                            ? labels.show_more[selected_lang]
                            : labels.show_less[selected_lang]}
                        </button>
                      </div>
                     
                    </div> */}
                    {/* <div className="checkbox-list">
                      <h2>Personality Types</h2>

                      <PrefListItems
                        items={persnalTypes}
                        active={props.areas_Filter}
                        updateActive={newItems =>
                          props.setAreas_Filter(newItems)
                        }
                      />
                    </div> */}
                    {/* <div className="checkbox-list">
                      <h2>Self Identifiers</h2>

                      <PrefListItems
                        items={idenntifier_Filter_Values}
                        active={props.idenntifier_Filter}
                        updateActive={newItems =>
                          props.setIdenntifier_Filter(newItems)
                        }
                      />
                    </div> */}
                  </Form>
                </div>
              </div>
            </Scrollbars>
            <div className="filter-btn-wrapper pt-3 text-center">
              <Button className="btn-dark" onClick={handle}>
                Filter
              </Button>
            </div>
          </div>
        )}
      </SlidingPanel>

      {searchFilter && (
        <FocusModel
          active={props.active}
          setActive={props.setActive}
          show={searchFilter}
          setFocusLocations={props.setFocusLocations}
          setIdentiferId={props.setIdentiferId}
          setEthnicityId={props.setEthnicityId}
          setGender={props.setGender}
          setPersnalTypesId={props.setPersnalTypesId}
          setCategoriesId={props.setCategoriesId_Focus}
          setAppointmentId={props.setAppointmentId_Focus}
          onClose={() => {
            setSearchFilter(false);
          }}
        />
      )}
    </>
  );
};

export default RefferalSearchFilter;
