import React, {useState, useRef} from 'react';
import {Container, Row, Col, Button, Form, Card} from 'react-bootstrap';
import {CustomSlidingButtons, Pannels} from '../../Components';
import Sidebar from '../Sidebar';
import {GPTIcon, rewardStar} from '../../Constants/Images';
import {labels} from '../../Constants/Translations';
import {selected_lang} from '../../Utils/env';
import CreateWorkSpace from '../../Components/WorkSpace/CreateWorkSpace';
import Slider from 'react-slick';
import DetailBuildGpt from './DetailBuildGpt';
import Scrollbars from 'react-custom-scrollbars';

import {prompts} from '../../Constants';

const BuildGPT = props => {
  const [workspaceData, setWorkspaceData] = useState([]);
  const [showComponent, setShowComponent] = useState('');
  const buttonRef = useRef(null);
  const [analycticsSlider, setAnalycticsSlider] = useState(false);

  const rewardSlider = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
  };

  const rewardSliderData = [
    {
      name: 'Cost',
      money: '23',
      time: 'versus last month',
      svgIcon: rewardStar,
    },

    {
      name: 'Helpfulness ',
      money: '4.8',
      time: 'versus last month',
      svgIcon: rewardStar,
      icon: 'fa-solid fa-star',
    },

    {
      name: 'Accuracy ',
      money: '23%',
      time: 'versus last month',
      svgIcon: rewardStar,
    },
    {
      name: 'Rating',
      money: '4.8',
      time: 'versus last month',
      svgIcon: rewardStar,
    },
  ];

  const listForDropDown = [
    {title: 'Leadership', id: 'leadership'},
    {title: 'Operations', id: 'operations'},
    {title: 'Accounting', id: 'accounting'},
    {title: 'Marketing', id: 'marketing'},
    {title: 'Sales', id: 'sales'},
    {title: 'HR', id: 'hr'},
    {title: 'Customer Service', id: 'customer_service'},
    {title: 'IT', id: 'it'},
    {title: 'Legal', id: 'legal'},
    {title: 'Compliance', id: 'compliance'},
  ];
  const [areas_Filter, setAreas_Filter] = useState([listForDropDown[0].id]);

  const dropDownSelection = e => {
    const {id} = e.target;

    setAreas_Filter(id);
  };
  return (
    <div className="page-wrapper">
      <Sidebar
        setWorkSpaceItem={setWorkspaceData}
        workspaceData={workspaceData}
        location={props.location}
        setShowComponent={setShowComponent}
        showComponent={showComponent}
      />
      <div className="page-content-wrapper">
        <div className="sessions-list-wrapper slider-screen-main">
          <div className="Reward_main analytics_slider_bg">
            <span
              className="analytics_slider_heading "
              style={{color: analycticsSlider ? '' : '#999999'}}
              onClick={() => {
                setAnalycticsSlider(!analycticsSlider);
              }}>
              Analytics Row
            </span>

            {analycticsSlider && (
              <div className="reward-top mt-3 mb-2">
                <Slider {...rewardSlider}>
                  {rewardSliderData.length > 0 &&
                    rewardSliderData.map((item, index) => (
                      <div className="reward-main">
                        <div className="reward-card">
                          <span style={{fontWeight: 700, fontSize: '13px'}}>
                            {item.name}
                          </span>
                          <span
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}>
                            <span style={{fontSize: '17px'}}>{item.money}</span>
                            {item.name === 'Ratings' && (
                              <img src={item.svgIcon} alt="" />
                            )}
                            {item.name === 'Conversion' ? (
                              <i
                                className="fa-solid fa-arrow-down"
                                style={{color: '#BD4141'}}></i>
                            ) : (
                              <i
                                class="fa-solid fa-arrow-up"
                                style={{color: '#52bd41'}}></i>
                            )}
                          </span>
                          <div class="message-date-info">
                            <span style={{color: '#333'}} class="message-date">
                              {item.time}
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                </Slider>
              </div>
            )}
          </div>
          <div className="add_border"></div>
          <Pannels
            //   isFloated={isFloated}
            //   showHiddenPanel={showHiddenPanel}
            //   updateHiddenPanelState={nState => toggleHiddenPanel(nState)}
            primary={
              <Scrollbars autoHide>
                <Container className="">
                  <div className="category-btns-wrapper category-btns-wrapper-slider gpt_sliding_btns mt-3 mb-4">
                    <CustomSlidingButtons
                      items={listForDropDown}
                      activeButtons={areas_Filter}
                      onItemClick={e => {
                        dropDownSelection(e);
                      }}
                      showItems={3}
                    />
                  </div>
                  <Row>
                  <h1 style={{fontSize:'17px'}} className='mb-3'>New Prompt</h1>
                    <Form.Group controlId="newPrompt">
                      <Form.Control
                        as="textarea"
                        rows={7}
                        placeholder="Write your question or make a request about sales here as if I was a real person..."
                      />
                    </Form.Group>
                    <div
                      className="mt-2"
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}>
                      <div className="btn-wrapper mt-3">
                        <Button
                          className="btn btn-dark"
                          onClick={() => {
                            setShowComponent('detail');
                          }}>
                          Start Chat
                        </Button>
                      </div>
                      <span
                        className="text-right"
                        style={{
                          fontWeight: '600',
                          color: '#52bd41',
                        }}>
                        Upload Document
                      </span>
                    </div>
                  </Row>
                  <Row className="mt-4">
                    <Col md={12}>
                      <h5 style={{fontSize:'17px'}} className='mb-3'>Pre-built Prompts</h5>
                      {prompts[areas_Filter].map((prompt, index) => (
                        <div className="btn-wrapper my-2" key={index}>
                          <Button
                          style={{background:'#000000', border:'none'}}
                            className="btn btn-dark w-100 m-0"
                            onClick={() => {}}>
                            {prompt}
                          </Button>
                        </div>
                      ))}
                    </Col>
                  </Row>
                </Container>
              </Scrollbars>
            }
            secondary={
              <>
                {showComponent === 'detail' && (
                  <DetailBuildGpt
                    onClose={() => {
                      setShowComponent('');
                    }}
                  />
                )}
              </>
            }
          />
          {showComponent === 'WorkSpace' && (
            <CreateWorkSpace
              onClose={() => {
                setShowComponent('');
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};
export default BuildGPT;
