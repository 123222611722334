import React, { useState,useImperativeHandle } from 'react';
import { Button, Col } from 'react-bootstrap';
import { NoGoogleCalendar } from '../../Constants/Images';
import { errorMessage } from '../../Utils/ToastMessages';
import CryptoJS from 'crypto-js';
import commonApi from '../../Services/CommonService';
import { ENCRYPTION_KEY } from '../../Utils/env';
import { NoOutlookCalendar } from '../../Constants/Images';
import { generateOutlookData, isTokenAndEmailUnique } from '../../Utils/commonUtils';
// Microsoft OAuth 2.0 Details
const CLIENT_ID = '9e10c793-a214-47b3-9b6a-9b0d2dd34cdb'; // Replace with your client ID
const REDIRECT_URI = window.location.origin; // The same redirect URI in your Azure App
const AUTHORIZATION_ENDPOINT = 'https://login.microsoftonline.com/common/oauth2/v2.0/authorize';
const TOKEN_ENDPOINT = 'https://login.microsoftonline.com/common/oauth2/v2.0/token';
const SCOPES = 'openid profile email offline_access User.Read calendars.readwrite';

const generateRandomString = (length) => {
  const charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~';
  let result = '';
  for (let i = 0; i < length; i++) {
    result += charset.charAt(Math.floor(Math.random() * charset.length));
  }
  return result;
};

const base64UrlEncode = (arrayBuffer) => {
  return btoa(String.fromCharCode.apply(null, new Uint8Array(arrayBuffer)))
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=+$/, '');
};

const sha256 = async (plain) => {
  const encoder = new TextEncoder();
  const data = encoder.encode(plain);
  return await crypto.subtle.digest('SHA-256', data);
};

const generateCodeChallenge = async (verifier) => {
  const hashed = await sha256(verifier);
  return base64UrlEncode(hashed);
};

const OutlookCalendar = ({
  outlookClenderData,
  deleteCalender,
  setOutLookListForModel,
  setShowCustomModel,
  setOutlookToken,
  refresTokensArray,
  integrationRef,
  setshowLoader
}) => {
  const [userName, setUserName] = useState(null);
  // const [accessToken, setAccessToken] = useState(null);

  // Microsoft Sign In (Open Popup and Authenticate User)
  const signIn = async () => {
    const codeVerifier = generateRandomString(128);
    const codeChallenge = await generateCodeChallenge(codeVerifier);

    sessionStorage.setItem('pkce_code_verifier', codeVerifier); // Store PKCE verifier
    const authUrl = `${AUTHORIZATION_ENDPOINT}?client_id=${CLIENT_ID}&response_type=code&redirect_uri=${encodeURIComponent(
      REDIRECT_URI,
    )}&response_mode=query&scope=${encodeURIComponent(SCOPES)}&code_challenge=${codeChallenge}&code_challenge_method=S256`;

    // Open Microsoft login popup
    const popup = window.open(authUrl, '_blank', 'width=600,height=600');

    const pollTimer = window.setInterval(() => {
      try {
        if (popup.location.href.includes(REDIRECT_URI)) {
          window.clearInterval(pollTimer);

          const params = new URLSearchParams(popup.location.search);
          const code = params.get('code');
          popup.close();
          setshowLoader(true)
          

          if (code) {
            // After authentication, exchange the code for an access token
            exchangeCodeForToken(code);
          }
        }
      } catch (error) {
        console.error('Error reading popup location:', error);
      }
    }, 500);
  };

  // Exchange Authorization Code for Access Token
  const exchangeCodeForToken = async (code) => {
    const codeVerifier = sessionStorage.getItem('pkce_code_verifier'); // Retrieve PKCE verifier

    const body = new URLSearchParams({
      client_id: CLIENT_ID,
      grant_type: 'authorization_code',
      code: code,
      redirect_uri: REDIRECT_URI,
      code_verifier: codeVerifier, // PKCE code verifier
    });

    try {
      const response = await fetch(TOKEN_ENDPOINT, {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: body.toString(),
      });

      const tokenData = await response.json();
      if (tokenData.access_token) {
        // Store access token
        console.log(tokenData,'tokenDatatokenDatatokenData')
        sessionStorage.setItem('access_token', tokenData.access_token);
        setOutlookToken(tokenData.access_token);
        // setAccessToken()
        fetchUserInfo(tokenData.access_token,tokenData.refresh_token)

        // fetchCalendars(tokenData.access_token,tokenData.refresh_token);
      } else {
        console.error('Token exchange failed:', tokenData);
      }
    } catch (error) {
      console.error('Error during token exchange:', error);
    }
  };

  // Fetch User Calendar Data from Microsoft Graph API
  const fetchCalendars = async (userEmail,accessToken,refreshToken) => {

    const ciphertext = CryptoJS.AES.encrypt(
      refreshToken,
      ENCRYPTION_KEY,
    ).toString();




    const calendarUrl = 'https://graph.microsoft.com/v1.0/me/calendars';

    const headers = {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    };

    try {
      const response = await fetch(calendarUrl, { headers });
      const data = await response.json();

      if (data.value && data.value.length > 0) {

        const newToken = isTokenAndEmailUnique(refresTokensArray,userEmail)

        if(newToken){
          const body = {email: userEmail, type: 'outlook',refresh_token: ciphertext};
          await commonApi.add_refresh_token(body);
        }
        



        const filterData = generateOutlookData(data.value,outlookClenderData,userEmail,refreshToken)
        if (filterData.length > 0) {
          setOutLookListForModel(filterData);
          setShowCustomModel('show-outlook-calender-model');
          setshowLoader(false)
        } else {
          return [];
        }
      } else {
        errorMessage('No more Calendars Found');
      }
    } catch (error) {
      console.error('Error fetching calendars:', error);
    }
  };

  async function fetchUserInfo(accessToken,refreshToken) {
    const userInfoEndpoint = 'https://graph.microsoft.com/v1.0/me'; // Endpoint to get user info from Microsoft Graph API

    try {
        const response = await fetch(userInfoEndpoint, {
            headers: {
                'Authorization': `Bearer ${accessToken}`
            }
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const userData = await response.json();
        // const email = userData.mail || userData.userPrincipalName; // Depending on which email field is available
        console.log('User Email:', userData);
        const userEmail = userData.mail;
        fetchCalendars(userEmail,accessToken,refreshToken);

        // Store email for use in your application
      
    } catch (error) {
        console.error('Error fetching user info:', error);
    }
}

useImperativeHandle(integrationRef, () => ({
  login() {
    signIn()
  }

}))



  return (
    <div className="integration-list">
      {outlookClenderData && Object.entries(outlookClenderData).length > 0 ? (
        outlookClenderData.map((item, index) => (
          <div className="fieldset card as-link row" key={index}>
            <Col md={10} sm={10} className="p-0">
              <h2 className="title mb-1">{item.summary}</h2>
            </Col>
            <Col md={2} sm={2} className="p-0">
              <div
                className="add-icon trash-icon poc_delete as-link text-right"
                onClick={() => {
                  deleteCalender(item.id);
                }}>
                <i class="fa-solid fa-trash-can"></i>
              </div>
            </Col>
          </div>
        ))
      ) : (
        <div className="no-results-wrapper no-appointments mt-5">
          <div className="img-bg-wrapper">
            <img src={NoOutlookCalendar} alt="" />
          </div>
          <div className="no-results-content">
            <p className="noRecord-title">No Calendars Added</p>
            <p className="noRecord-desc">
              Connect with your Outlook Calendar to identify scheduling
              conflicts
            </p>

            <div className="btn-wrapper mt-3">
              <Button
                className={'btn btn-dark'}
                onClick={signIn}>
                Add Outlook Calendar
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default OutlookCalendar;
