import React, {useState, useEffect} from 'react';

const MineSlidingPannel = ({children, hidePanel, slideFrom,showCloseIcon}) => {
  const [animateClass, setClass] = useState('');

  const [inOutClass, setInOut] = useState({
    inClass: 'animate__fadeInUp',
    outClass: 'animate__fadeOutDown',
  });

  const togglePanel = () => {
    if (!animateClass) {
      setClass(inOutClass.inClass);
    } else {
    //   setClass(inOutClass.outClass);
      setTimeout(() => {
        hidePanel();
      }, 320);
    }
  };

  const assignInOut = () => {
    const temp = {
      inClass: 'animate__fadeInUp',
      outClass: 'animate__fadeOutDown',
    };

    if (slideFrom && slideFrom === 'left') {
      temp.inClass = 'animate__fadeInLeft';
      temp.outClass = 'animate__fadeOutLeft';
    }
    if (slideFrom && slideFrom === 'right') {
      temp.inClass = 'animate__fadeInRight';
      temp.outClass = 'animate__fadeOutRight';
    }
    if (slideFrom && slideFrom === 'top') {
      temp.inClass = 'animate__fadeInDown';
      temp.outClass = 'animate__fadeOutUp';
    }
    setInOut(temp);
    togglePanel();
  };

  useEffect(() => {
    assignInOut();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={`sliding-panel-wrapper animate__animated ${animateClass}`}>
      {showCloseIcon === "yes" && 
      <span className="close as-link" onClick={() => togglePanel()}>
        <i className="fas fa-times" />
      </span>}
      <div className="sliding-panel-content">{children}</div>
    </div>
  );
};

export default MineSlidingPannel;
