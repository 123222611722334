import React from 'react';
import {Col, Row, Form} from 'react-bootstrap';
import commonApi from '../../Services/CommonService';
import moment from 'moment';
import {useEffect, useState} from 'react';
import SlidingPanel from '../SlidingPanel';
import Scrollbars from 'react-custom-scrollbars';
import {amplitudeEvent} from '../../Services/AmplitudeServices';
import LoaderSpinner from '../Modals/LoaderSpinner';
import {fixName, formatAmount} from '../../Utils/commonUtils';
import { DEFAULT_IMAGE } from '../../Utils/env';
// import Multiselect from 'multiselect-react-dropdown';

const AppointmentPayments = props => {
 
  const [showLoader, setshowLoader] = useState(false);
  const [appointmentInfo, setAppointmentInfo] = useState([]);
  const [startDate, setStartDate] = useState(
    moment(props.startDate).format('YYYY-MM-DD'),
  );
  const [endDate, setEndDate] = useState(
    moment(props.endDate).format('YYYY-MM-DD'),
  );
  const [responseOfApi, setResponseOfApi] = useState(null);
  var firstDate = moment(startDate).format('YYYY-MM-DD');
  var lastDate = moment(endDate).format('YYYY-MM-DD');

  const getPaymentDetail = () => {
    setshowLoader(true);
    var payLoad = {
      //   created: subTabType,
      from: firstDate,
      //   payment_type: type,
      status: props.status,
      to: lastDate,
    };
    commonApi
      .get_appointment_payment_detail(payLoad)
      .then(res => {
        if (res.status === 200) {
          setAppointmentInfo(res.data.appointment_info);
          setResponseOfApi(res);
          setshowLoader(false);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  // let divStyle = {
  //   color: '#C48E1D',
  // };

  useEffect(() => {
    getPaymentDetail();
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endDate, startDate]);
  useEffect(() => {
    getPaymentDetail();
    amplitudeEvent('VISIT_PAYMENT_PENDING');
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SlidingPanel hidePanel={() => props.onClose()}>
      <div className="serach-filter-wrapper">
        {showLoader && <LoaderSpinner />}

        <div className="filter-wrapper">
          <div className="filter-headings">
            {/* <h2>Pending Payments</h2> */}
            {props.status === 'pending' && <h2> Pending Payout</h2>}
            {props.status === 'collected' && <h2> Total Collected</h2>}
            {props.status === 'future' && <h2> Future Payment</h2>}
          </div>
        </div>

        <Scrollbars>
          <div className="fix-width-forms">
            <div className="container">
              <Form>
                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>Start</Form.Label>
                      <input
                        type="date"
                        className="form-control"
                        name="trip-start"
                        value={startDate}
                        onChange={e => {
                          setStartDate(e.target.value);
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label>End</Form.Label>
                      <input
                        type="date"
                        className="form-control"
                        name="trip-start"
                        value={endDate}
                        onChange={e => {
                          setEndDate(e.target.value);
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
            </div>

            <div className="no-results-wrapper mt-4">
              <div className="no-results-content appointments_pendings">
                {props.status === 'pending' && (
                  <h2>
                    Pending Payout{' '}
                    {responseOfApi && responseOfApi.pending
                      ? formatAmount(responseOfApi.pending)
                      : ' $0'}
                  </h2>
                )}
                {props.status === 'collected' && (
                  <h2>
                    Total Collected{' '}
                    {responseOfApi && responseOfApi.collected
                      ? formatAmount(responseOfApi.collected)
                      : ' $0'}
                  </h2>
                )}
                {props.status === 'future' && (
                  <h2>
                    Future Payment{' '}
                    {responseOfApi && responseOfApi.future
                      ? formatAmount(responseOfApi.future)
                      : ' $0'}
                  </h2>
                )}
                {Object.entries(appointmentInfo).length > 0 ? (
                  appointmentInfo.map(item => {
                    return (
                      <div
                        key={`oooooooappointment3` + item.id}
                        className={
                          'message-user-item appointments_payment p-0 mt-3'
                        }
                        // onClick={() =>{}}
                      >
                        <div className="user-img">
                          <img
                            src={
                              item.user_details.profile_img === ''
                                ? DEFAULT_IMAGE
                                : item.user_details.profile_img
                            }
                            alt=""
                          />
                        </div>
                        <div className="user-content p-0">
                          <h2 className="p-bold m-0 text-left">
                            {fixName(
                              item.user_details.first_name,
                              item.user_details.last_name,
                            )}
                          </h2>
                          <p className="p-reg m-0 mb-2 text-left">
                            {item.output_date}
                          </p>
                          <div className="d-flex align-items-center justify-content-btween">
                            <p className="p-reg m-0">${item.cost}</p>
                            <div className="search-tabs-icons message-other-users">
                              <span className="icon cursor-default ">
                                <i className="fa-solid fa-video"></i>
                              </span>
                              <span className="icon m-0 cursor-default ">
                                <i className="fa-solid fa-phone"></i>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="mt-5 mb-0">
                    <p className="text-center"> No record found</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Scrollbars>
      </div>
    </SlidingPanel>
  );
};

// PendingPayment.propTypes = {};

export default AppointmentPayments;
