import React from 'react';

import SlidingPanel from '../SlidingPanel';

const ViewFile = ({selectedFile, onClose}) => {


  return (
    <>
      <SlidingPanel
        hidePanel={() => {
          onClose();
        }}>
        <div className="serach-filter-wrapper create-sessionEvents ">
          {selectedFile.extension === 'pdf' ? (
            <iframe
              src={`${selectedFile.file_url}#toolbar=0`}
              frameborder="0"
              style={{
                height: '-webkit-fill-available',
                width: '600px',
              }}></iframe>
          ) : (
            <iframe
              src={`https://view.officeapps.live.com/op/embed.aspx?src=${selectedFile.file_url}`}
              frameborder="0"
              style={{
                height: '-webkit-fill-available',
                width: '600px',
              }}></iframe>
          )}

          {/* <embed src={selectedFile} width="100%" height="600px" /> */}
        </div>
      </SlidingPanel>
    </>
  );
};

export default ViewFile;
