import React, {useState, useEffect} from 'react';
import Sidebar from '../../Pages/Sidebar';
import {selected_lang} from '../../Utils/env';
import Pannels from '../../Components/Pannels';
import {arrow_up, rewardStar} from '../../Constants/Images';
import {labels} from '../../Constants/Translations';
import {Button} from 'react-bootstrap';
import Slider from 'react-slick';
import CreateWorkSpace from '../../Components/WorkSpace/CreateWorkSpace';
import Scrollbars from 'react-custom-scrollbars';

const RoadMap = props => {
  const [isFloated, toggleFloatedPanel] = useState(false);
  const [showHiddenPanel, toggleHiddenPanel] = useState(false);
  const [showComponent, setShowComponent] = useState('');
  const [tabType, setTabType] = useState('list');
  const [threeDots, setThreeDots] = useState('');
  const [analycticsSlider, setAnalycticsSlider] = useState(false);
  const [workspaceData, setWorkspaceData] = useState([]);

  const TaskList = [
    {
      id: '342',
      title: 'New Task',
      priority: 'medium',
      due_date: '2024-01-19 21:09:00',
      due_time: '21:09:00',
      description: 'Anything',
      association_id: '2515',
      association_type: 'profile',
      assigne: '1737',
      watchers: '',
      workspace_ids: '172',
      personal: 'yes',
      status: 'pending',
      images: '',
      created_by: '2515',
      created_at: '2024-01-01 05:10:12',
      updated_by: '0',
      updated_at: '2024-01-01 05:10:12',
      creator_detail: {
        id: '2515',
        first_name: 'Hello',
        last_name: 'World',
      },
      assigne_detail: {
        id: '1737',
        first_name: 'Free',
        last_name: 'Tickets',
      },
      watchers_detail: [],
      association_name: 'helloworld879321',
    },
  ];

  useEffect(() => {
    if (showComponent !== '') {
      toggleHiddenPanel(true);
    } else {
      toggleHiddenPanel(false);
    }
  }, [showComponent]);

  const rewardSlider = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
  };
  const rewardSliderData = [
    {
      name: 'Completion',
      money: '2.4%',
      time: 'versus last month',
      svgIcon: rewardStar,
    },

    {
      name: 'High Impact',
      money: '85%',
      time: 'versus last month',
      svgIcon: rewardStar,
      // icon: 'fa-solid fa-star',
    },

    {
      name: 'Customer',
      money: '45%',
      time: 'versus last month',
      svgIcon: rewardStar,
    },
  ];

  const dynamicStyle = {
    padding: 0,
  };

  return (
    <div className="page-wrapper">
      <Sidebar
        location={props.location}
        showComponent={showComponent}
        setShowComponent={setShowComponent}
        setWorkSpaceItem={setWorkspaceData}
        workspaceData={workspaceData}
      />
      <div className="page-content-wrapper">
        <div className="sessions-list-wrapper slider-screen-main">
          <div className="Reward_main analytics_slider_bg">
            <span
              className="analytics_slider_heading "
              style={{color: analycticsSlider ? '' : '#999999'}}
              onClick={() => {
                setAnalycticsSlider(!analycticsSlider);
              }}>
              Analytics Row
            </span>

            {analycticsSlider && (
              <div className="reward-top mt-3 mb-2">
                <Slider {...rewardSlider}>
                  {rewardSliderData.length > 0 &&
                    rewardSliderData.map((item, index) => (
                      <div className="reward-main">
                        <div className="reward-card">
                          <span style={{fontWeight: 700, fontSize: '13px'}}>
                            {item.name}
                          </span>
                          <span
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}>
                            <span style={{fontSize: '17px'}}>{item.money}</span>
                            {item.name === 'Ratings' && (
                              <img src={item.svgIcon} alt="" />
                            )}
                            {item.name === 'Conversion' ? (
                              <i
                                className="fa-solid fa-arrow-down"
                                style={{color: '#BD4141'}}></i>
                            ) : (
                              <i
                                class="fa-solid fa-arrow-up"
                                style={{color: '#52bd41'}}></i>
                            )}
                          </span>
                          <div class="message-date-info">
                            <span style={{color: '#333'}} class="message-date">
                              {item.time}
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                </Slider>
              </div>
            )}
          </div>
          <div className="add_border"></div>

          {tabType === 'list' && (
            <Pannels
              isFloated={isFloated}
              toggleFloatedPanel={toggleFloatedPanel}
              showHiddenPanel={showHiddenPanel}
              updateHiddenPanelState={nState => toggleHiddenPanel(nState)}
              customCss={dynamicStyle}
              primary={
                <>
                  <div className="task-page primary_panel_wraper primary_panel_content">
                    <div className="messages-header">
                      <div className="event-tabs tabs-style-2 mb-0">
                        {' '}
                        {/* Merging */}
                        <ul className="LabelTabs">
                          <li className="ml-20 d-flex align-items-center">
                            <Button
                              className={tabType === 'list' ? 'active' : ''}
                              onClick={() => {
                                setTabType('list');
                              }}>
                              Road Map
                            </Button>
                          </li>

                          <li className="ml-20 d-flex align-items-center">
                            <Button
                              className={tabType === 'board' ? 'active' : ''}
                              onClick={() => {
                                // setTabType('board');
                              }}>
                              Pending
                            </Button>
                          </li>
                        </ul>
                      </div>

                      {/* <span className="add-icon">
                         <i
                           className="icon-plus"
                           onClick={() => setSecondComponent("group-component")}
                         />
                       </span>
                       <span className="add-icon">
                         <i
                           className="icon-filter-icon"
                           onClick={() => setSearchFilterModal(true)}
                         />
                       </span> */}

                      <div className="search-tabs-icons">
                        <span
                          className={
                            showComponent === 'addAction'
                              ? 'icon active'
                              : 'icon'
                          }>
                          <i
                            className="icon-plus"
                            onClick={() => {
                              if (showComponent === 'addAction') {
                                setShowComponent('');
                              } else {
                                setShowComponent('addAction');
                              }
                            }}
                          />
                        </span>

                        <span
                          onClick={() => {
                            setThreeDots(!threeDots);
                          }}
                          className={threeDots ? 'icon active' : 'icon'}>
                          <i className="fas fa-ellipsis-vertical" />
                        </span>
                      </div>
                    </div>
                    <div className="refferal-list-wrapper mt-4">
                      <Scrollbars autoHide>
                        <div className="task-list-waraper">
                          {Object.entries(TaskList).length > 0 ? (
                            TaskList.map((item, index) => (
                              <>
                                <div
                                  className="refferals-list action-listing as-link"
                                  onClick={() => {
                                    props.setDataOfAction(item);
                                    props.setShowComponent('actionDetails');
                                  }}>
                                  <div className="task-item">
                                    <div
                                      className="red-bar"
                                      style={{backgroundColor: 'green'}}></div>
                                    <h2 className="truncate_2_lines actions-title mb-2">
                                      {/* {item.title} */}
                                      Task Title
                                    </h2>
                                    <div className="d-flex align-items-center session-cam mb-2">
                                      {/* <img src={VideoGreyIcon} alt="video-cam" /> */}
                                      <p className="p-reg m-0 single-line-text">
                                        Pending (November)
                                      </p>
                                    </div>

                                    <div
                                      className="d-flex align-items-center justify-content-between"
                                      style={{alignItems: 'center'}}>
                                      <div className="d-flex align-items-center">
                                        {/* {assigneDetail(item)} */}
                                        <p className="p-reg m-0 actionsCategories single-line-text">
                                          Customer Submitted
                                        </p>
                                      </div>

                                      <div className="arrow-up">
                                        <img
                                          height={20}
                                          width={20}
                                          src={arrow_up}
                                          alt="checkmark"
                                        />
                                        <span>21</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="refferals-list action-listing as-link"
                                  onClick={() => {
                                    props.setDataOfAction(item);
                                    props.setShowComponent('actionDetails');
                                  }}>
                                  <div className="task-item">
                                    <div className="red-bar"></div>
                                    <h2 className="truncate_2_lines actions-title mb-2">
                                      {/* {item.title} */}
                                      Task Title
                                    </h2>
                                    <div className="d-flex align-items-center session-cam mb-2">
                                      {/* <img src={VideoGreyIcon} alt="video-cam" /> */}
                                      <p className="p-reg m-0 single-line-text">
                                        TBD
                                      </p>
                                    </div>

                                    <div
                                      className="d-flex align-items-center justify-content-between"
                                      style={{alignItems: 'center'}}>
                                      <div className="d-flex align-items-center">
                                        {/* {assigneDetail(item)} */}
                                        <p className="p-reg m-0 actionsCategories single-line-text">
                                          Customer Submitted
                                        </p>
                                      </div>

                                      <div className="arrow-up">
                                        <img
                                          height={20}
                                          width={20}
                                          src={arrow_up}
                                          alt="checkmark"
                                        />
                                        <span>21</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            ))
                          ) : (
                            <div className="no-results-wrapper payment-no-results no-record-notification">
                              <div className="img-bg-wrapper">
                                <img
                                  src={require('../../Assets/images/norecord/no-actions.png')}
                                  alt=""
                                />
                              </div>

                              <div className="no-results-content">
                                <h2>{labels.Actions_Pending[selected_lang]}</h2>
                                <p>{labels.NoAction_Desc[selected_lang]}</p>
                                <div className="btn-wrapper mt-3">
                                  <Button
                                    className="btn btn-dark"
                                    onClick={() => {
                                      props.setShowComponent('addAction');
                                    }}>
                                    Create Action
                                  </Button>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </Scrollbars>
                    </div>
                  </div>

                  {/* Filter Modal */}
                </>
              }
              secondary={<></>}
            />
          )}

          {showComponent === 'WorkSpace' && (
            <CreateWorkSpace
              onClose={() => {
                setShowComponent('');
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default RoadMap;
