import React, {useState, useRef, useEffect} from 'react';
import { Button, Col,  Form, Row} from 'react-bootstrap';
import commonApi from '../../Services/CommonService';
import {successMessage} from '../../Utils/ToastMessages';
import Modal from 'react-bootstrap/Modal';
import {DEFAULT_IMAGE} from '../../Utils/env';
import SlidingPanel from '../SlidingPanel';
import Scrollbars from 'react-custom-scrollbars';
import {fixName} from '../../Utils/commonUtils';

const AddPraiseCaution = props => {
  const [plusType, setType] = useState(props.category);
  const [selectOne, setSelectOne] = useState([]);
  // const [zzz, setZzz] = useState({
  //   path: '',
  //   type: '',
  // });
  const [buttonType, setButtonType] = useState('discription');
  const [ticketImg, setTicketImg] = useState('');
  const [imagePath, setImagePath] = useState([]);
  // const [showLoader, setshowLoader] = useState(false);
  const [discriptions, setDiscription] = useState('');
  const [topic, setTopic] = useState('');
  const [category, setCategory] = useState('');
  const [discriptionErr, setDiscriptionErr] = useState(false);
  const [userData, setUserData] = useState({});
  const [show, setShow] = useState(false);
  // const [blobURL, setblobURL] = useState('');
  const [subTabs, setSubTabs] = useState('praise');
  const [viewCategory, setViewCategory] = useState('meetup(in-person)');
  



  const start = () => {
    
    
    // setrecordstate(RecordState.START);
  };
  const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);
  const stop = () => {
    
    // setrecordstate(RecordState.STOP);
  };

  // const onStops = audioData => {
    
  //   setblobURL(audioData.blob);
  //   setZzz({...zzz, ['path']: audioData.blob, ['type']: 'audio'});
    
    
  // };

  const intervalRef = useRef(null);
  // const [videoLimit, setVideoLimit] = useState('');
  const ref = useRef(null);
  let countTime = 0;
  const resetCounter = () => {
    clearInterval(intervalRef.current);
    intervalRef.current = null;
  };

  // audio video

  const submitData = event => {
    event.preventDefault();

    if (buttonType === 'discription' && discriptions.length > 0) {
      boiya();
    }
    if (buttonType === 'discription' && discriptions.length === 0) {
      setDiscriptionErr(true);
    }
    // } else if (
    //   buttonType === 'audio' &&
    //   topic.length > 0 &&
    //   zzz.type === 'audio'
    // ) {
    //   boiya();
    // } else if (
    //   buttonType === 'video' &&
    //   topic.length > 0 &&
    //   zzz.type === 'video'
    // ) {
    //   boiya();
    // } else {
    //   errorMessage('Please select options ');
    // }
  };

  // const changeTicketImg = e => {
  //   // If no file selected, return
  //   if (e.target.files.length === 0) return false;
  //   const file = e.target.files[0];

  //   // If no image selected, return
  //   if (!/^image\//.test(file.type)) {
  //     errorMessage(`File is not an image.`);
  //     return false;
  //   } else {
  //     const filePath = e.target.files[0];
  //     const objectUrl = URL.createObjectURL(filePath);
  //     let reader = new FileReader();
  //     reader.readAsDataURL(filePath);
  //     reader.onload = e => {
  //       //uploadPraiseCoution(e.target.result);
  //     };

  //     setTicketImg(objectUrl);
  //   }

  //   const imgTypes = ['image/gif', 'image/jpeg', 'image/png'];
  // };
  const addFeedBcak = (type, p, audioVideo) => {
    var payloadAddFeedBcak = {
      feedback_category: buttonType,
      feedback_description: discriptions === '' ? topic : discriptions,
      feedback_id: '',
      feedback_type: type,
      file_url: audioVideo,
      images: imagePath,
      status: 'feedback',
      type: p,
      type_id: category,
      user_id: props.userData.id,
    };
    commonApi
      .add_feedback(payloadAddFeedBcak)
      .then(res => {
        if (res.status === 200) {
          successMessage(res.message);
          props.onClose();
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  const getConsultSession = pType => {
    var payloadGetConsultSession = {
      user_id: props.userData.id,
      type: pType,
    };
    commonApi
      .get_consult_session_for_praise(payloadGetConsultSession)
      .then(res => {
        if (res.status === 200) {
          setSelectOne(res.data);
          setCategory(res.data[0].id);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  // const uploadPraiseCoution = img => {
  //   setshowLoader(true);
  //   var bodyFormData = new FormData();
  //   bodyFormData.append(
  //     'images',
  //     img.replace(/^data:image\/[a-z]+;base64,/, ''),
  //   );

  //   commonApi
  //     .upload_praise_caution(bodyFormData)
  //     .then(res => {
  //       if (res.status === 200) {
  //         setImagePath(current => [...current, res.image_path]);
          
  //         setshowLoader(false);
  //       }
  //     })
  //     .catch(err => {
  //       console.log('Err', err);
  //       return {type: 'error', message: err.message};
  //     });
  // };

  useEffect(() => {
    getUserData();
    getConsultSession(plusType);
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plusType]);
  useEffect(() => {
   
  }, []);

  // const {
  //   // status,
  //   // startRecording,
  //   // stopRecording,
  //   // mediaBlobUrl,
  //   // previewStream,
  //   // clearBlobUrl,
  // } = null;
  // const [showStream, setShowStream] = useState('');
  // const videoRef = useRef();
  // console.log("wwwwwwwwwwwwwwww",blob)
  // useEffect(() => {
  //   if (videoRef.current && previewStream) {
  //     videoRef.current.srcObject = previewStream;
  //   } else {
  //   }
  // }, [previewStream]);

  

  const getUserData = () => {
    var data = {user_id: props.userData.id};
    commonApi
      .user_info(data)
      .then(res => {
        if (res) {
          setUserData(res.data);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  // const uploadVideo = () => {
  //   fetch(mediaBlobUrl)
  //     .then((r) => r.blob())
  //     .then((blob) => {

  //       setZzz({ ...zzz, ["path"]: blob, ["type"]: "video" });
  //       
  //       
  //     });
  // };

  const boiya = () => {
    addFeedBcak(subTabs, plusType, '');
    return false;

    // var bodyFormData = new FormData();
    // bodyFormData.append(zzz.type, zzz.path);

    // commonApi
    //   .upload_praise_caution(bodyFormData)
    //   .then(res => {
    //     addFeedBcak(
    //       props.subTabs,
    //       plusType,
    //       zzz.type === 'video' ? res.video_path : res.audio_path,
    //     );
    //   })
    //   .catch(err => {
    //     console.log('Err', err);
    //     return {type: 'error', message: err.message};
    //   });
  };
  
  const handleViewCategory = (value) =>{

    if(value==='event'){
      setViewCategory('meetup(in-person)')
    } else if(value==='consult'){
      setViewCategory(value)
    }  else if(value==='session'){
      setViewCategory('meetup(virtual)')
    } else if(value==='opportunity'){
      setViewCategory('recruitment')
    }
    
  }

  return (
    <>
      {/* {showLoader && <LoaderSpinner show={showLoader} />} */}

      <SlidingPanel hidePanel={() => props.onClose()}
      //temporary state for remove warnings
      setButtonType={setButtonType}
      setTopic={setTopic}
      ticketImg={ticketImg}
      >
        <Scrollbars>
          <div className="fix-width-forms">
            <div className="sidebar-heading-wrapper">
              <div className="heading">
                <h2>
                  {/* {capitalizeFirst(props.userData.first_name)}  {capitalizeFirst(props.userData.last_name)} */}
                  {userData &&
                    userData.first_name &&
                    fixName(userData.first_name, userData.last_name)}
                </h2>
                {/* <span>{props.category}</span> */}

                <span>{viewCategory}</span>
              </div>
              <div className="with-user-img">
                <div className="user-profile-img">
                  <img
                    src={
                      userData &&
                    userData.profile_img &&
                     userData.profile_img === ''
                        ? DEFAULT_IMAGE
                        :userData.profile_img
                    }
                    alt=""
                  />
                </div>
                {/* <span
                  className="close as-link"
                  onClick={() => props.setPlusButton(false)}>
                  <i className="fas fa-times" />
                </span> */}
              </div>
            </div>

            <div className="add-praise-form-wrapper">
              <Form>
                <span className="question">
                  <i className="icon-applaud" /> Add Feedback
                </span>
                <span className="question">
                  This is me{' '}
                  {/* {capitalizeFirst(localStorage.getItem('firstName'))}{' '}
                  {capitalizeFirst(localStorage.getItem('lastName'))} */}
                  {fixName(
                    localStorage.getItem('firstName'),
                    localStorage.getItem('lastName'),
                  )}{' '}
                  saying something amazing about{' '}
                  { userData &&
                    userData.first_name &&
                    fixName(userData.first_name, userData.last_name)}
                  working with me.
                </span>
                <div className="event-tabs tabs-style-2 mb-0">
                  <ul>
                    <li>
                      <Button
                        className={subTabs === 'praise' ? 'active' : ''}
                        onClick={() => {
                          setSubTabs('praise');
                        }}>
                        <i className="icon-like-icon" />
                      </Button>
                    </li>
                    <li>
                      <Button
                        className={subTabs === 'caution' ? 'active' : ''}
                        onClick={() => {
                          setSubTabs('caution');
                        }}>
                        <i className="icon-unlike" />
                      </Button>
                    </li>
                  </ul>
                </div>
                <Form.Group className="fieldset">
                  <Form.Label>Choose a feedback type</Form.Label>
                  <div className="search-form">
                    <div className="select-wrapper">
                      <select
                        value={props.category}
                        className="form-control"
                        onChange={e => {
                          props.setCategory(e.target.value);
                         handleViewCategory(e.target.value)
                          setCategory(e.target.value);
                          setType(e.target.value);
                        }}>
                        <option value="event">Meetup (In-person)</option>
                        <option value="consult">Consult</option>
                        <option value="session">Meetup (Virtual)</option>
                        <option value="opportunity">Recruitment</option>
                      </select>
                    </div>
                  </div>
                </Form.Group>
                <Form.Group className="fieldset">
                  {selectOne.length > 0 && (
                    <>
                      <div className="search-form">
                        <div className="select-wrapper">
                          <select
                            onChange={e => {
                              
                              setCategory(e.target.value);
                            }}>
                            {Object.entries(selectOne).length > 0 ? (
                              Object.entries(selectOne).length > 0 &&
                              selectOne.map((item, index) => (
                                <option
                                  id={item.id}
                                  value={item.id}
                                  key={`selectOne-${index}`}>
                                  {item.title}
                                </option>
                              ))
                            ) : (
                              <></>
                            )}
                          </select>
                        </div>
                      </div>
                      {/* <Form.Label>Choose a feedback type</Form.Label> */}
                    </>
                  )}
                </Form.Group>
                {/* {buttonType === "audio" ||  buttonType === "video" ? (
            <>
              <div className="invite-page-search">
                <div className="search-form">
                  <Form.Group className="search-input">
                    Topic
                    <Form.Control
                      type="text"
                      placeholder=""
                      value={topic}
                      onChange={(e) => {
                        setTopic(e.target.value);
                        
                      }}
                    />
                  </Form.Group>
                </div>
              </div>
            </>
          ) : (
            <></>
          )} */}

                {/* <div className="event-tabs tabs-style-2 mb-0">
                <ul>
                  <li>
                    <Button
                      className={buttonType === "discription " ? "active" : ""}
                      onClick={() => setButtonType("discription")}
                    >
                      <i className="icon-bars-2" />
                    </Button>
                  </li>
                  <li>
                    <Button
                      className={buttonType === "audio" ? "active" : ""}
                      onClick={() => {setButtonType("audio");
                      setDiscription(""); setTopic("");}}
                      
                    >
                      <i className="icon-sound-bars" />
                    </Button>
                  </li>
                  <li>
                    <Button
                      className={buttonType === "video" ? "active" : ""}
                      onClick={() => {setButtonType("video");
                      setDiscription(""); setTopic("");}}
                    >
                      <i className="icon-video-camera" />
                    </Button>
                  </li>
                </ul>
              </div> */}

                {buttonType === 'discription' ? (
                  <>
                    <Form.Group className="fieldset">
                      {/* <Form.Label>Choose a {plusType}</Form.Label> */}
                      <textarea
                        required
                        rows={8}
                        className="form-control"
                        placeholder="Share your experience"
                        onChange={e => {
                          setDiscription(e.target.value);
                        }}></textarea>
                      {discriptions.length === 0 && discriptionErr === true && (
                        <div className="text-danger textforerror">
                          Description is required.
                        </div>
                      )}
                    </Form.Group>
                  </>
                ) : (
                  <></>
                )}

                {/* <Form.Group className="fieldset">
              <div className="images-list">
                <div className="images-list-item">
                  <div className="camera-icon">
                    <i className="icon-camera-outline" />
                    <input type="file" name="my-image" id="image" accept="image/*"  onChange={(e) => {changeTicketImg(e);
                    e.target.value=null}} />

                   
                  </div>
                 {imagePath.length > 0 &&
                  <span onClick={handleShow}>Preview Images</span>
                } 
                </div>
                
       
                            
              </div>
            </Form.Group> */}

                <Form.Group className="fieldset">
                  {props.subTabs === 'caution' ? (
                    <>
                      <Button
                        className="btn-dark"
                        type="submit"
                        disabled={
                          selectOne.length > 0 && discriptions === ''
                        }
                        onClick={submitData}>
                        Submit
                      </Button>
                    </>
                  ) : (
                    <></>
                  )}
                  {props.subTabs === 'praise' ? (
                    <>
                      <Button
                        className="btn-dark"
                        disabled={selectOne.length > 0 && discriptions === ''}
                        onClick={submitData}>
                        Submit
                      </Button>
                    </>
                  ) : (
                    <></>
                  )}
                </Form.Group>
              </Form>
              {buttonType === 'video' ? (
                <>
                  {' '}
                  <div>
                    Video
                    <div>
                      <p ref={ref} id="mastId">
                        {/* {status} */}
                      </p>

                      <button
                        onClick={() => {
                          // startRecording();
                          intervalRef.current = setInterval(() => {
                            if (ref && ref.current.innerText === 'recording') {
                              countTime++;

                              if (countTime === 30) {
                                // stopRecording();
                                resetCounter();
                                // uploadVideo();
                              }
                            }
                          }, 1000);
                        }}>
                        Start Recording
                      </button>
                      <button
                        onClick={() => {
                          // stopRecording();
                          resetCounter();

                          // uploadVideo();
                        }}>
                        Stop Recording
                      </button>
                      {/* <button onClick={clearBlobUrl}>
                Delete Recording
              </button> */}
                      {/* {!(previewStream && previewStream.active) && (
                        <video src={mediaBlobUrl} controls autoPlay loop />
                      )} */}
                      {/* {previewStream && previewStream.active && (
                        <video
                          ref={videoRef}
                          width={300}
                          height={300}
                          autoPlay
                          controls
                        />
                      )} */}
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}

              {buttonType === 'audio' ? (
                <>
                  <div className="m-4">
                    <Form.Label>Record Audio</Form.Label>
                    <button onClick={start}>Start</button>
                    <button
                      onClick={() => {
                        stop();
                      }}>
                      Stop
                    </button>
                    <div className="col-2">
                      {/* <AudioReactRecorder
                        state={recordstate}
                        onStop={onStops}
                      /> */}
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </Scrollbars>
      </SlidingPanel>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Images</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <div className="gallery-imgs">
              <Row>
                {Object.entries(imagePath).length > 0 ? (
                  Object.entries(imagePath).length > 0 &&
                  imagePath.map((item, index) => {
                    return (
                      <Col md={4} sm={4} key={`image-${index}`}>
                        <span
                          className="text-center ml-5 pl-5"
                          onClick={() => {
                            
                            setImagePath(prevImgs => prevImgs.slice(1));
                            setTicketImg('');
                            
                              imagePath.length === 0 && handleClose();
                            
                          }}>
                          <i className="fas fa-times" />
                        </span>
                        <div className="gallery-item">
                          <div className="mt-5">
                            <img src={item} alt="" />
                          </div>
                        </div>
                      </Col>
                    );
                  })
                ) : (
                  <p className="text-center">No Images Found</p>
                )}
              </Row>
            </div>
          </>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default AddPraiseCaution;
