import React, {useEffect, useState} from 'react';

const CustomModal = ({
  children,
  onClose,
  position,
  size = 'full',
  style,
  className,
  removeheight,
  heightofModel,
  paddingBottomZero,
  iconStyle
}) => {
  //const [showContent, toggleShowContent] = useState(false);
  const [animationClass, setAnimationClass] = useState('');
  const contentPos = position ? position : '';

  const toggleContent = () => {
    if (!animationClass) {
      //toggleShowContent(true);
      setAnimationClass('animate__fadeInUp show');
    } else {
      //toggleShowContent(false);
      setAnimationClass('animate__fadeOutDown');
      setTimeout(() => {
        onClose();
      }, 320);
    }
  };

  useEffect(() => {
    toggleContent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      style={{display: removeheight ? 'inline-block' : '',
      height:heightofModel ? heightofModel : '100%'}}
      className={`custom-modal ${size} ${contentPos} ${className}`} 
     >
      {onClose && (
        <span className="close as-link" onClick={() => toggleContent()} style={iconStyle || {}}>
          <i className="fas fa-times" />
        </span>
      )}

      <div
        className={`custom-modal-content custom-chat-content-modal animate__animated custom-modal-content-square ${animationClass} ${paddingBottomZero ? 'pb-0' : ''}`}
        style={{...style}}>
        {children}
      </div>
    </div>
  );
};

export default CustomModal;
