import React, {useState, useEffect} from 'react';
import {Button, Col, Form, Row} from 'react-bootstrap';
import commonApi from '../../../Services/CommonService';
import {CustomModal, SlidingPanel} from '../../../Components';

// import LoaderModal from '../../../Components/Modals/LoaderModal';
import LoaderSpinner from '../../../Components/Modals/LoaderSpinner';
import CreatCircleList from '../../../Components/Circle/CreatCircleList';
import {errorMessage, successMessage} from '../../../Utils/ToastMessages';
import SendInvitation from '../StaticPages/SendInvitation';
import {DEFAULT_IMAGE} from '../../../Utils/env';
import {useHistory, useLocation} from 'react-router-dom';
import Scrollbars from 'react-custom-scrollbars';
import {fixName} from '../../../Utils/commonUtils';

const CircleListBuilders = props => {
  const [tabType, setTabType] = useState('circles');
  const [tabTypeSub, setTabTypeSub] = useState('exstingBuilders');
  const [circleId, setCircleId] = useState('');
  const [showModaL, setShowModel] = useState(false);
  const [circles, setcircles] = useState([]);
  const [circleUser, setcircleUser] = useState([]);
  const [newCircleUser, setNewCircleUser] = useState([]);
  const [currentCircle, setCurrentCircle] = useState('');
  const [addToCircleUsers, setAddToCircleUsers] = useState([]);
  const [newToCircleUsers, setNewToCircleUsers] = useState([]);
  const [addToCircleUserList, setAddToCircleUserList] = useState([]);
  const [showLoader, setshowLoader] = useState(false);
  const [showSpinner, setShowSpinner] = useState(true);
  const [showUserSpinner, setShowUserSpinner] = useState(false);
  const [userData, setUserData] = useState([]);
  const history = useHistory();
  const locationWeb = useLocation();

  const [showHiddenPanel, toggleHiddenPanel] = useState(false);
  const [showComponent, setShowComponent] = useState('');

  const getCircles = () => {
    // setshowLoader(true);
    setShowSpinner(true);
    commonApi
      .get_circles()
      .then(response => {
        if (response) {
          setcircles(response.data);
          // setshowLoader(false);
          if (response.data.length) {
            setCircleId(response.data[0].circle_id);
            getCirclesUsers(response.data[0].circle_id);
          }
          else{
            setShowSpinner(false);
          }
        }
        // setShowSpinner(false);
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const getCirclesUsers = id => {
    // setshowLoader(true);
    // setShowSpinner(true);
    setShowUserSpinner(true);
    setCurrentCircle(id);
    setAddToCircleUserList([]);
    var data = {
      circle_id: id,
      creator_Id: props.creatorId
        ? props.creatorId
        : props.sessionData
        ? props.sessionData.creator_id
        : '',
      event_id: props.eventId ? props.eventId : '',
      session_id: props.sessionData ? props.sessionData.id : '',
    };
    commonApi
      .get_circle_users(data)
      .then(response => {
        if (response) {
          setcircleUser(response.data);
          setNewCircleUser(response.data);
          // setshowLoader(false);
        }
       
        setShowUserSpinner(false);
        setShowSpinner(false);
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const getAddToCircleUsers = circleId => {
    // setshowLoader(true);
    // setShowSpinner(true);
    setShowUserSpinner(true);
    var data = {
      chat_room_id: '',
      circle_id: circleId,
      search_query: '',
      user_id: localStorage.getItem('id'),
      event_id: props.eventId ? props.eventId : '',
    };
    commonApi
      .excluded_followers_list(data)
      .then(res => {
        if (res) {
          setAddToCircleUsers(res.data);
          setNewToCircleUsers(res.data);
          // setshowLoader(false);
        }
        // setShowSpinner(false);
        setShowUserSpinner(false);
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  const addUserInCircle = () => {
    if (addToCircleUserList.length > 0) {
      var data = {
        circle_id: currentCircle,
        circle_name: '',
        user_ids: addToCircleUserList,
        workspace_ids: localStorage.getItem('workspaceIds'),
      };
      commonApi
        .create_circle(data)
        .then(res => {
          if (res) {
            setAddToCircleUserList([]);
            successMessage(res.message);
          }
        })
        .catch(err => {
          console.log('Err', err);
          return {type: 'error', message: err.message};
        });
    } else {
      errorMessage('Select user first');
    }
  };

  const handleChangeSearch = (array, query) => {
    setcircleUser(
      array.filter(el => {
        return el.first_name.toLowerCase().indexOf(query.toLowerCase()) !== -1;
      }),
    );
  };
  const handleChangeSearchAdd = (array, query) => {
    setAddToCircleUsers(
      array.filter(el => {
        return el.first_name.toLowerCase().indexOf(query.toLowerCase()) !== -1;
      }),
    );
  };

  const handleUpdateCircleUsers = userId => {
    setAddToCircleUsers(addToCircleUsers.filter(item => item.id !== userId));
    if (!addToCircleUserList.includes(userId)) {
      setAddToCircleUserList([...addToCircleUserList, userId]);
    }
  };

  useEffect(() => {
    getCircles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); //createCircle

  useEffect(() => {
    if (
      locationWeb &&
      locationWeb.state &&
      locationWeb.state.typeIs === 'search'
    ) {
      if (
        history.location &&
        history.location.state &&
        history.location.state.typeIs
      ) {
        const state = {...history.location.state};
        delete state.typeIs;
        history.replace({...history.location, state});
      }
      // locationWeb.state = undefined;
      setTabType('search');
      // getApiSessionList(sessionType, "", true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const removeCircleUser = () => {
    var data = {
      circle_id: circleId,
      user_ids: userData.id,
    };
    commonApi
      .remove_circle_user(data)
      .then(res => {
        if (res) {
        
          getCirclesUsers(circleId);
          successMessage(res.message);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  useEffect(() => {
    if (showComponent !== '') {
      toggleHiddenPanel(true);
    } else {
      toggleHiddenPanel(false);
    }
  }, [showComponent]);

 

  return (
    <>
      {' '}
      <div className="page-wrapper">
        {/* {showLoader && <LoaderModal show={showLoader} />} */}
        <SlidingPanel
          hidePanel={() => {
            props.onClose();
          }}
          lastDivStyle={{marginTop:props.marginTop ? '15px' : ''}}
          >
          <div
            className="serach-filter-wrapper"
            style={{padding: props.padding ? '25px' : ''}}>
            {showSpinner && <LoaderSpinner />}

            <div className="fix-width-forms">
              <div className="sidebar-heading-wrapper mt-0">
                

                <div className="search-tabs-icons createCircle d-flex gap-2">
                  <span
                    className="icon mt-0"
                    onClick={() => {
                      setShowComponent('createCircle');
                    }}>
                    <i className="icon-plus"></i>
                  </span>
                  <div className="heading " >
                    <h2 style={{color:'#9b9787'}}>Circle</h2>
                  </div>
                </div>
              </div>
            </div>

            {!showSpinner &&
              (tabType === 'circles' && circles.length > 0 ? (
                <div className="create-circle-panel mt-0 fix-width-forms primary_panel_content">
                  <div className="invite-page-search">
                    <div className="search-form">
                      <Form.Group>
                        <div className="select-wrapper">
                          <select
                            className="form-control mb-3"
                            onChange={e => {
                              setCircleId(e.target.value);
                              getCirclesUsers(e.target.value);
                              setTabTypeSub('exstingBuilders');
                            }}
                            disabled={circles.length > 0 ? false : true}>
                            {Object.entries(circles).length > 0 &&
                              circles.map((item, index) => (
                                <option
                                  value={item.circle_id}
                                  key={`stage--${index}`}>
                                  {item.circle_name}
                                </option>
                              ))}
                          </select>
                        </div>
                      </Form.Group>

                      <div className="event-tabs tabs-style-2">
                        <ul>
                          <li>
                            <Button
                              className={
                                tabTypeSub === 'exstingBuilders' ? 'active' : ''
                              }
                              onClick={() => {
                                if(tabTypeSub !=='exstingBuilders'){
                                  getCirclesUsers(currentCircle);
                                  setTabTypeSub('exstingBuilders');
                                }
                                
                              }}>
                              Existing Builders
                            </Button>
                          </li>
                          <li>
                            <Button
                              className={
                                tabTypeSub === 'addToCircle' ? 'active' : ''
                              }
                              onClick={() => {
                                if(tabTypeSub !=='addToCircle'){
                                  setTabTypeSub('addToCircle');
                                  getAddToCircleUsers(currentCircle);
                                }
                                
                              }}>
                              Add To Circle
                            </Button>
                          </li>
                        </ul>
                      </div>

                      {tabTypeSub === 'exstingBuilders' && (
                        <Form.Group className="search-input">
                          <Form.Control
                            onChange={e => {
                              handleChangeSearch(newCircleUser, e.target.value);
                            }}
                            type="text"
                            placeholder="Who are you looking for?"
                          />
                        </Form.Group>
                      )}
                      {tabTypeSub === 'addToCircle' && (
                        <Form.Group className="search-input">
                          <Form.Control
                            onChange={e => {
                              handleChangeSearchAdd(
                                newToCircleUsers,
                                e.target.value,
                              );
                            }}
                            type="text"
                            placeholder="Who are you looking for?"
                          />
                        </Form.Group>
                      )}
                    </div>
                  </div>

                  

                  {/* <div className="invite-persons-wrapper"> */}
                  {/* <div className="invite-persons-heading">
                                  <span className="builder-text">30 Builders Removed</span>
                                  <span className="circle-link-icon" onClick={() => ""}>
                                      2 Added <i className="icon-circles" />
                                  </span>
                              </div> */}
                  <div className="refferal-list-wrapper">
                  {!showSpinner && showUserSpinner && <LoaderSpinner />}
                    <Scrollbars className="custom-scroll">
                      {!showUserSpinner &&
                        (tabTypeSub === 'exstingBuilders' ? (
                          <div className="invite-persons-list persons-imgs-wrapper custom-invite-persons-listt">
                            <Row>
                              {Object.entries(circleUser).length > 0 ? (
                                Object.entries(circleUser).length > 0 &&
                                circleUser.map((item, index) => (
                                  <Col md={4} xl={3} key={`s-${index}`}>
                                    <div className="stage-list-item">
                                      <div
                                        className={
                                          item?.linked_user_detail
                                            ?.user_type === 'business'
                                            ? 'stage-person-img business as-link'
                                            : 'stage-person-img as-link'
                                        }
                                        onClick={() => {
                                          setUserData(item);
                                          setShowModel(true);
                                        }}>
                                        <img
                                          src={
                                            item.profile_img
                                              ? item.profile_img
                                              : DEFAULT_IMAGE
                                          }
                                          alt=""
                                        />
                                      </div>
                                      <div
                                        className="stage-person-name as-link"
                                        onClick={() => {
                                          setUserData(item);
                                          setShowModel(true);
                                        }}>
                                        <h3>
                                          {fixName(
                                            item.first_name,
                                            item.last_name,
                                          )}
                                        </h3>
                                      </div>
                                    </div>
                                  </Col>
                                ))
                              ) : !showLoader ? (
                                <div className="no-results-wrapper no-appointments my-5">
                                  <div className="img-bg-wrapper">
                                    <img
                                      src={require('../../../Assets/images/norecord/no-circles.png')}
                                      alt=""
                                    />
                                  </div>
                                  <div className="no-results-content">
                                    <p className="noRecord-title">
                                      Add your Network
                                    </p>
                                    <p className="noRecord-desc">
                                      Quickly access your colleagues to invite,
                                      refer and connect
                                    </p>

                                    <div className="btn-wrapper mt-3">
                                      <Button
                                        className="btn btn-dark"
                                        onClick={() => {
                                          setTabTypeSub('addToCircle');
                                        }}>
                                        Add Contact
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <></>
                              )}
                            </Row>
                          </div>
                        ) : (
                          <div className="invite-persons-list persons-imgs-wrapper custom-invite-persons-listt">
                            <Row>
                              {Object.entries(addToCircleUsers).length > 0 ? (
                                Object.entries(addToCircleUsers).length > 0 &&
                                addToCircleUsers.map((item, index) => (
                                  <Col md={4} xl={3} key={`per-${index}`}>
                                    <div className="stage-list-item">
                                      <div
                                        className={
                                          item?.linked_user_detail
                                            ?.user_type === 'business'
                                            ? 'stage-person-img business as-link'
                                            : 'stage-person-img as-link'
                                        }
                                        onClick={() => {
                                          handleUpdateCircleUsers(item.id);
                                        }}>
                                        <img
                                          src={
                                            item.profile_img
                                              ? item.profile_img
                                              : DEFAULT_IMAGE
                                          }
                                          alt=""
                                        />
                                      </div>
                                      <div
                                        className="stage-person-name as-link"
                                        onClick={() => {
                                          handleUpdateCircleUsers(item.id);
                                        }}>
                                        <h3>
                                          {fixName(
                                            item.first_name,
                                            item.last_name,
                                          )}
                                        </h3>
                                      </div>
                                    </div>
                                  </Col>
                                ))
                              ) : !showLoader ? (
                                <div className="no-results-wrapper no-appointments my-5">
                                  <div className="img-bg-wrapper">
                                    <img
                                      src={require('../../../Assets/images/norecord/no-circles.png')}
                                      alt=""
                                    />
                                  </div>
                                  <div className="no-results-content">
                                    <p className="noRecord-title">
                                      Add your Network
                                    </p>
                                    <p className="noRecord-desc">
                                      Quickly access your colleagues to invite,
                                      refer and connect
                                    </p>

                                    <div className="btn-wrapper mt-3">
                                      <Button
                                        className="btn btn-dark"
                                        onClick={() => {
                                          setShowComponent('inviteuser');
                                        }}>
                                        Add Contact
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <></>
                              )}
                            </Row>
                          </div>
                        ))}
                    </Scrollbars>
                  </div>

                  {tabTypeSub === 'exstingBuilders' &&
                    props.hide &&
                    circleUser.length > 0 && (
                      <div className="bg_transparent bottom-0">
                      <div className="invite-btns">
                        <Button
                          onClick={() => {
                            if (circleUser.length > 0) {
                              props.CircleUserListData(circleUser);
                              props.onClose();
                            } else {
                              errorMessage('Please add first user in circle');
                            }
                          }}>
                          Invite Circle
                        </Button>
                      </div>
                      </div>
                    )}

                  {tabTypeSub === 'addToCircle' &&
                    addToCircleUsers.length > 0 &&
                    !showUserSpinner &&
                    tabTypeSub === 'addToCircle' && (
                      <div className="bg_transparent bottom-0">
                        <div className="invite-btns">
                          <Button
                            disabled={
                              addToCircleUsers.length > 0 ||
                              addToCircleUserList.length > 0
                                ? false
                                : true
                            }
                            onClick={() => {
                              addUserInCircle();
                            }}>
                            Update Circle
                          </Button>
                        </div>
                      </div>
                    )}
                </div>
              ) : (
                <>
                  {tabType === 'circles' && (
                    <div className="no-results-wrapper mt-0 fix-width-forms">
                      <div className="img-bg-wrapper">
                        <img
                          src={require('../../../Assets/images/norecord/no-circles.png')}
                          alt=""
                        />
                      </div>
                      <div className="no-results-content">
                        <h2>Manage circle</h2>
                        <p>You haven't created any circle yet.</p>

                        <div className="btn-wrapper mt-3">
                          <Button
                            className="btn btn-dark"
                            onClick={() => {
                              setShowComponent('createCircle');
                            }}>
                            Create Circle
                          </Button>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              ))}
          </div>
        </SlidingPanel>

        {showModaL && (
          <CustomModal onClose={() => setShowModel(false)}>
            <div className="remove-user-text">
              <p>
                Are you sure you want to remove{' '}
                <b>{fixName(userData.first_name, userData.last_name)}</b>?
              </p>
              <div>
                <Button
                  className="btn-dark m-0"
                  onClick={() => {
                    !props.hide
                      ? removeCircleUser()
                      : setcircleUser(
                          circleUser.filter(item => item.id !== userData.id),
                        );

                    setShowModel(false);
                  }}>
                  Yes
                </Button>
                {/* <Button
                className="btn-dark"
                onClick={() => {
                  setShowModel(false);
                }}>
                No Thanks!
              </Button> */}

                <p
                  className="text-yellow mt-3 mb-0"
                  style={{fontSize: 14}}
                  onClick={() => {
                    setShowModel(false);
                  }}>
                  No Thanks!
                </p>
              </div>
            </div>
          </CustomModal>
        )}
        {showComponent === 'inviteuser' && (
          <SendInvitation
            onClose={() => {
              setShowComponent('');
            }}
            setshowLoader={setshowLoader}
            showHiddenPanel={showHiddenPanel}
          />
        )}

        {showComponent === 'createCircle' && (
          <CreatCircleList
            onClose={type => {
              if (type) {
                getCircles();
              }
              setShowComponent('');
            }}
          />
        )}
      </div>
    </>
  );
};

export default CircleListBuilders;
