import React, {useEffect} from 'react';

import {useDispatch, useSelector} from 'react-redux';
import {DEFAULT_IMAGE} from '../../Utils/env';

import {fixName} from '../../Utils/commonUtils';
import {Button} from 'react-bootstrap';

import {Swiper, SwiperSlide} from 'swiper/react';
import { Navigation} from 'swiper';
import {AddContact} from '../../Constants/Images';
import {
  getAllWatchesList,
} from '../../Store/Actions/SessionAction';
// import {useState} from 'react';
import {getItem} from '../../Utils/LocalStorage';

const DiscoverBuilders = props => {
  const dispatch = useDispatch();
  // var builders

  // if(props.type=='appointment'){

  //    builders = useSelector(state => state.AllBuilderList);
  // }
  // else{
  //    builders = useSelector(state => state.builderList)
  // }

  // 

  // const [builders, setBuilders] = useState([]);
  // const [showLoader, setshowLoader] = useState(false);

  // const [searchQry, setSearchQry] = useState('');

  const searchQry = ""
  // const builders = useSelector(state =>
  //   props.type === 'appointment' ? state.AllBuilderList : state.builderList,
  // );

  //   let builders = [];

  const getBuilders = () => {
    // alert('')
    var data = {
      user_id: getItem('id'),
      search_query: props.searchqueryWatches ? props.searchqueryWatches : '',
    };

    dispatch(getAllWatchesList(data));

    // if(response){
    //   props.setshowLoader(false);
    // }

    // commonApi
    //   .followers_list(data)
    //   .then(response => {
    //     if (response) {
    //       setBuilders(response.data);
    //     }
    //   })
    //   .catch(err => {
    //     console.log('Err', err);
    //     return {type: 'error', message: err.message};
    //   });
  };

  useEffect(() => {
    // props.setshowLoader(true);
    if (!props.searchqueryWatches) {
      getBuilders();
    }

    if (props.type === 'Create_Action') {
      if (builders.length === 0) {
        props.setshowLoader(true);
      }
      setTimeout(() => {
        props.setshowLoader(false);
      }, 2000);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.contact, props.apiHit]);
  useEffect(() => {
    // props.setshowLoader(true);
    if (
      (props.searchqueryWatches && !props.showSelected) ||
      props.searchqueryWatches === ''
    ) {
      getBuilders();
    }
    if (props.showSelected) {
      selectedWachSearcQry(props.searchqueryWatches, props.buildersItem);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.searchqueryWatches]);
  const selectedWachSearcQry = (query, array) => {
    if (!query && props.originalWatchesItem.length > 0) {
      props.setWatchesItem(props.originalWatchesItem); // Reset to original data when query is empty
      return;
    }
    props.setWatchesItem(
      array.filter(el => {
        // const fullName = el.first_name.toLowerCase() + el.last_name.toLowerCase();
        const fullNameSpace =
          el.first_name.toLowerCase() + ' ' + el.last_name.toLowerCase();
        return (
          el.first_name.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
          el.last_name.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
          fullNameSpace.toLowerCase().indexOf(query.toLowerCase()) !== -1
        );
      }),
    );
  };
  useEffect(() => {
    if (props.originalWatchesItem.length === 0) {
      props.setShowSelected(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.originalWatchesItem]);

  const builders = useSelector(state => state.AllWatchesList);
  
  //   builders = useSelector(state => state.AllBuilderList);
  const renderDiscoverBuilder = index => {
    // Function to render DiscoverBuilder component
    return (
      <SwiperSlide key={`discover-builder-${index}`}>
        <div
          className="discover-item search as-link"
          onClick={() => {
            props.buildersHandle('AllBuilders');
          }}>
          <div className="img-wrapper">
            <i className="fas fa-magnifying-glass" />
          </div>

          <h3>Search</h3>
          <p>Network</p>
        </div>
      </SwiperSlide>
    );
  };
  // useEffect(() => {
  //   if (props.assigneIds && builders.length > 0 ) {
  //     // setBuilders
  //     // alert('')
  //     setBuilders(previous =>
  //       previous.filter(miniItem => miniItem.id != props.assigneIds.toString(',')),
  //     );
  //   }
  // }, [props.assigneIds]);

  return (
    <>
      <div className="discover-builders-wrapper">
        <div className="discover-builders">
          <div className="discover-builders-slider wacthes_builder_slider">
            <Swiper
              slidesPerView={'auto'}
              spaceBetween={20}
              navigation={true}
              modules={[Navigation]}
              // className="mySwiper"
              className={`mySwiper ${
                builders.length > 6
                  ? 'addwidthDiscoverBuilders'
                  : 'onlyBuildersSlider'
              }`}

              
              >
              {!props.type && (
                <SwiperSlide>
                  <div
                    className="discover-item search as-link"
                    onClick={() => {
                      props.buildersHandle('inviteOutSideTheBuild');
                    }}>
                    <div className="img-wrapper">
                      <img src={AddContact} alt="" />
                    </div>

                    <h3>Add</h3>
                    <p>Contact</p>
                  </div>
                </SwiperSlide>
              )}

              {props.type &&
                props.type !== 'Create_Action' &&
                (builders.length === 0 || props.type !== 'poll') && (
                  <React.Fragment>
                    <SwiperSlide>
                      <div
                        className="discover-item search as-link"
                        onClick={() => {
                          props.buildersHandle('AllBuilders');
                        }}>
                        <div className="img-wrapper">
                          <i className="fas fa-magnifying-glass" />
                        </div>

                        <h3>Search</h3>
                        <p>Network</p>
                      </div>
                    </SwiperSlide>
                  </React.Fragment>
                )}
              {builders.length === 0 && !props.type && (
                <React.Fragment>
                  <SwiperSlide>
                    <div
                      className="discover-item search as-link"
                      onClick={() => {
                        props.buildersHandle('AllBuilders');
                      }}>
                      <div className="img-wrapper">
                        <i className="fas fa-magnifying-glass" />
                      </div>

                      <h3>Search</h3>
                      <p>Network</p>
                    </div>
                  </SwiperSlide>
                </React.Fragment>
              )}
              {props.showMessagesUsers && !props.searchqueryWatchesCheck
                ? Object.entries(props.allChatUsers).length > 0 &&
                  props.allChatUsers.map((item, index) => {
                    return (
                      <React.Fragment key={`discover-${index}`}>
                        <SwiperSlide key={`discover-item-${index}`}>
                          <div
                            className="discover-item as-link"
                            onClick={() => {
                              if (
                                props.type === 'appointment' ||
                                props.type === 'poll' ||
                                props.type === 'Create_Action'
                              ) {
                                props.buildersHandle('profileComponent', item);
                              } else {
                                props.buildersHandle(
                                  'profileComponent',
                                  item.id,
                                );
                              }
                            }}>
                            <div className="img-wrapper">
                              <img
                                className={
                                  (props.type === 'appointment' &&
                                    props.builders.includes(item.id)) ||
                                  (props.type === 'poll' &&
                                    props.builders.includes(item.id)) ||
                                  (props.type === 'Create_Action' &&
                                    props.builders.includes(item.id))
                                    ? 'selercted-user'
                                    : ''
                                }
                                src={
                                  item.profile_img
                                    ? item.profile_img
                                    : DEFAULT_IMAGE
                                }
                                alt=""
                              />
                            </div>
                            <h3>{fixName(item.first_name, item.last_name)}</h3>
                            <p>
                              {item.title ? item.title : ''}
                              {parseInt(item.total) ? ' +' + item.total : ''}
                            </p>
                          </div>
                        </SwiperSlide>
                        {!props.type &&
                          (index === builders.length - 1 &&
                          builders.length <= 10
                            ? renderDiscoverBuilder()
                            : index > 0 &&
                              index % 10 === 0 &&
                              renderDiscoverBuilder(index))}
                      </React.Fragment>
                    );
                  })
                : !props.showSelected && Object.entries(builders).length > 0
                ? builders.map((item, index) => {
                    return (
                      <React.Fragment key={`discover-${index}`}>
                        <SwiperSlide key={`discover-item-${index}`}>
                          <div
                            className="discover-item as-link"
                            onClick={() => {
                              if (
                                props.type === 'appointment' ||
                                props.type === 'poll' ||
                                props.type === 'Create_Action'
                              ) {
                                props.buildersHandle('profileComponent', item);
                              } else {
                                props.buildersHandle(
                                  'profileComponent',
                                  item.id,
                                );
                              }
                            }}>
                            <div className="img-wrapper">
                              <img
                                className={
                                  (props.type === 'appointment' &&
                                    props.builders.includes(item.id)) ||
                                  (props.type === 'poll' &&
                                    props.builders.includes(item.id)) ||
                                  (props.type === 'Create_Action' &&
                                    props.builders.includes(item.id))
                                    ? 'selercted-user'
                                    : ''
                                }
                                src={
                                  item.profile_img
                                    ? item.profile_img
                                    : DEFAULT_IMAGE
                                }
                                alt=""
                              />
                            </div>
                            <h3>{fixName(item.first_name, item.last_name)}</h3>
                            <p>
                              {item.title ? item.title : ''}
                              {parseInt(item.total) ? ' +' + item.total : ''}
                            </p>
                          </div>
                        </SwiperSlide>
                        {!props.type &&
                          (index === builders.length - 1 &&
                          builders.length <= 10
                            ? renderDiscoverBuilder()
                            : index > 0 &&
                              index % 10 === 0 &&
                              renderDiscoverBuilder(index))}
                      </React.Fragment>
                    );
                  })
                : props.showSelected &&
                  Object.entries(props.buildersItem).length > 0
                ? props.buildersItem.map((item, index) => {
                    return (
                      <React.Fragment key={`discover-${index}`}>
                        <SwiperSlide key={`discover-item-${index}`}>
                          <div
                            className="discover-item as-link"
                            onClick={() => {
                              if (
                                props.type === 'appointment' ||
                                props.type === 'poll' ||
                                props.type === 'Create_Action'
                              ) {
                                props.buildersHandle('profileComponent', item);
                              } else {
                                props.buildersHandle(
                                  'profileComponent',
                                  item.id,
                                );
                              }
                            }}>
                            <div className="img-wrapper">
                              <img
                                className={
                                  props.type === 'appointment' ||
                                  (props.type === 'Create_Action' &&
                                    props.builders.includes(item.id)) ||
                                  (props.type === 'poll' &&
                                    props.builders.includes(item.id))
                                    ? 'selercted-user'
                                    : ''
                                }
                                src={
                                  item.profile_img
                                    ? item.profile_img
                                    : DEFAULT_IMAGE
                                }
                                alt=""
                              />
                            </div>
                            <h3>{fixName(item.first_name, item.last_name)}</h3>
                            <p>
                              {item.title ? item.title : ''}
                              {parseInt(item.total) ? ' +' + item.total : ''}
                            </p>
                          </div>
                        </SwiperSlide>
                        {!props.type &&
                          (index === builders.length - 1 &&
                          builders.length <= 10
                            ? renderDiscoverBuilder()
                            : index > 0 &&
                              index % 10 === 0 &&
                              renderDiscoverBuilder(index))}
                      </React.Fragment>
                    );
                  })
                : null}
            </Swiper>
          </div>
          {props.showMessagesUsers && !props.searchqueryWatchesCheck ? (
            Object.entries(props.allChatUsers).length > 0 &&
            props.allChatUsers.forEach((item, index) => {
              <></>;
            })
          ) : !props.showSelected && Object.entries(builders).length > 0 ? (
            builders.forEach((item, index) => {
              <></>;
            })
          ) : props.showSelected &&
            Object.entries(props.buildersItem).length > 0 ? (
            props.buildersItem.forEach((item, index) => {
              <></>;
            })
          ) : (
            <div className="w-100">
              <div className="no-results-wrapper no-appointments my-5">
                <div className="no-results-content">
                  <p className="noRecord-title">
                    {props.searchqueryWatches
                      ? `  We didn't find the ${props.searchqueryWatches} match for the name`
                      : `  We didn't find  any match `}
                  </p>

                  <div className="btn-wrapper">
                    <Button
                      className="btn btn-dark"
                      onClick={() => {
                        // props.onClick();
                      }}>
                      {searchQry ? 'Add Contact' : 'Add Contacts'}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default DiscoverBuilders;
