/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect} from 'react';
import {labels} from '../../Constants/Translations';
import {selected_lang} from '../../Utils/env';
import {FormattedText} from '../../Utils/Helpers';
import {DEFAULT_IMAGE} from '../../Utils/env';
import ProfileMap from './ProfileMap';

// import { successMessage} from '../../Utils/ToastMessages';
// import {Button} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import commonApi from '../../Services/CommonService';
// import {amplitudeEvent} from '../../Services/AmplitudeServices';

import EditProfile from './EditProfile';
import AddNewSnapShot from '../Messages/AddNewSnapShot';
import {setItem, getItem} from '../../Utils/LocalStorage';
import {fixName} from '../../Utils/commonUtils';
import PurchaseTicket from '../Referral/PurchaseTicket';
import {connect} from 'react-redux';
import Slider from 'react-slick';
import {EditPencilYellow, ProfileNotes} from '../../Constants/Images';
import {errorMessageGuest} from '../../Utils/ToastMessages';
import {
  ArrowIcon,
  FireIcon,
  MachineIcon,
  WhitePenIcon,
  timeIcon,
  bagIcon,
  heartIcon,
  Bandage,
} from '../../Constants/Images';

import TimeClock from '../../Svg/TagsIcon/TimeClock';
import FlameSvg from '../../Svg/TagsIcon/FlameSvg';

import ArrowSvg from '../../Svg/TagsIcon/ArrowSvg';
import MachineIconSvg from '../../Svg/TagsIcon/MachineIconSvg';
import BandageSvg from '../../Svg/TagsIcon/BandageSvg';
import PlusBagSvg from '../../Svg/TagsIcon/PlusBagSvg';
import HeartSvg from '../../Svg/TagsIcon/HeartSvg';

import BadgeCheck from '../../Svg/BadgeCheck';

const ProfileBlock = ({
  profile,
  handleBlock,
  average,
  checkAppoinmentData,
  noshow,
  setEditProfile,
  submit,
  typeModAud,
  showButton,
  serverTime,
  sessionData,
  windowWidth,
  withHeadingSpace,
  parentCompoent,
  type,
  tagsListing,
}) => {
  const [showMore, setShowMore] = useState(false);
  const [showComponent, setShowComponent] = useState('');
  // const [userSnapShot, setUserSnapShot] = useState('');
  // const [userFollow, setUserFollow] = useState(profile.data.followed);
  const [allTickets, setAllTickets] = useState([]);
  const [eventDetails, setEventDetails] = useState([]);
  const [allDataOfTicket, setAllDataOfTicket] = useState([]);
  // const [threeDots, setThreeDots] = useState(false);

  const showMoreClass = showMore ? 'show-all' : '';
  const myProfile = profile.data.id === localStorage.getItem('id');

  const [viewDetails, setViewDetails] = useState(false);

  //

  const pathSegments = window.location.pathname.split('/');
  const lastSegment = pathSegments[pathSegments.length - 1];

console.log(lastSegment,'pathSegmentspathSegments')

  const imgSliderSettings = {
    dots: true,
    arrows: false,
    infinite: false,
    speed: 500,
    // slidesToShow: ,
    slidesToScroll: 1,
    adaptiveHeight: true,
  };

  // useEffect(() => {
  //   if (profile) {
  //     setUserFollow(profile.data.followed);
  //   }
  // }, [profile]);
  useEffect(() => {
    if (localStorage.getItem('id') === profile.data.id) {
      getShareTickets();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showComponent]);
  // useEffect(() => {
  //   getSnapshotOfUser();
  // }, [showComponent]);
  useEffect(() => {
    setTimeout(() => {
      if (getItem('consult')) {
        handleBlock(profile.data, 'appointment');
      }
    }, 500);
    setTimeout(() => {
      // if (getItem('consult') === 'consults') {
      setItem('consult', '');
      // }
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // let gray = {
  //   color: '#808080',
  // };
  // let black = {
  //   color: 'black',
  // };
  //
  // const getSnapshotOfUser = () => {
  //   var data = {
  //     user_id: localStorage.getItem('user'),
  //   };
  //   commonApi
  //     .get_user_sess_opp_accom(data)
  //     .then(res => {
  //       if (res.status === 200) {
  //         setUserSnapShot(res.exist_accomplishment);
  //       }
  //     })
  //     .catch(err => {
  //       console.log('Err', err);
  //       return {type: 'error', message: err.message};
  //     });
  // };
  // const manageFollowings = () => {
  //   var data = {
  //     follow_to: profile.data.id,
  //   };
  //   commonApi
  //     .manage_followings(data)
  //     .then(res => {
  //       if (res.status === 200) {
  //         if (userFollow) {
  //           amplitudeEvent('UNFOLLOW_USER');
  //         } else {
  //           amplitudeEvent('FOLLOW_USER');
  //         }
  //         setUserFollow(res.follow);
  //         successMessage(res.message);
  //       }
  //     })
  //     .catch(err => {
  //       console.log('Err', err);
  //       return {type: 'error', message: err.message};
  //     });
  // };
  const getShareTickets = () => {
    commonApi
      .get_share_tickets()
      .then(res => {
        if (res.status === 200) {
          setAllTickets(res.all_tickets);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  let CategoryOfUser = [];

  if (Object.entries(profile.mine_categories).length > 0) {
    profile.mine_categories.forEach(item => {
      CategoryOfUser.push(item.title);
    });
  }

  let mineAreaUser = [];

  if (Object.entries(profile.mine_areas).length > 0) {
    profile.mine_areas.forEach(item => {
      mineAreaUser.push(item.title);
    });
  }

  const openEventDetail = (eventItem, ticketItem) => {
    setEventDetails(eventItem);
    setAllDataOfTicket(ticketItem);
    setShowComponent('PurchaseTicket');
  };

  const lines =
    parentCompoent === 'widgets'
      ? profile.data.bio
      : profile?.data?.bio?.split('\n');

  const settings = {
    dots: false,
    infinite: false,
    centerMode: false,
    variableWidth: true,
    speed: 500,
    arrows: false,
    slidesToShow:
      tagsListing?.tags_details?.length === 1
        ? 1
        : tagsListing?.tags_details?.length === 2
        ? 2
        : tagsListing?.tags_details?.length === 3
        ? 3
        : tagsListing?.tags_details?.length > 3 && lastSegment!== 'profile' ?  4 : 3,
    slidesToScroll: lastSegment!== 'profile' ?  4 : 3,
  };

  const showPlusButtonDecesion = (ownerP, aliasP) => {
    console.log(ownerP, 'ownerP', aliasP, 'aliasP');
    if (myProfile && (ownerP === true || ownerP === false)) {
      return ownerP;
    } else {
      return aliasP;
    }
  };

  return (
    <>
      {showComponent === 'homeMap' && (
        <ProfileMap
          profile={profile}
          home={true}
          onClose={() => {
            setShowComponent('');
          }}
        />
      )}
      {showComponent === 'PurchaseTicket' && (
        <PurchaseTicket
          onClose={() => {
            setShowComponent('');
          }}
          // getReferrals={getReferrals}
          // arrowButton={arrowButton}
          acceptTicket={true}
          item={eventDetails}
          allTickets={allDataOfTicket}
          // type={'refer'}
        />
      )}

      {showComponent === 'currentMap' && (
        <ProfileMap
          profile={profile}
          home={false}
          onClose={() => {
            setShowComponent('');
          }}
        />
      )}

      {showComponent === 'EditProfile' && (
        <EditProfile
          profile={profile}
          loginUser={true}
          onClose={() => {
            setShowComponent('');
          }}
        />
      )}

      {showComponent === 'Addsnapshot' && (
        <AddNewSnapShot
          // profile={profile}
          // home={false}
          onClose={() => {
            setShowComponent('');
          }}
        />
      )}
      {/* {showComponent === 'ProfileComponent' && (
        <ProfileComponent
          userId={inviteUserId}
          show={false}
          closeModal={() => {
            setShowComponent('');
          }}
          userData={[]}
          moderator={false}
          VoxeetSDK={[]}
          conferenceInfo={[]}
          jdSpecialM={[]}
          jdSpecialA={[]}
          onClose={() => {
            setShowComponent('');
          }}
        />
      )} */}

      {/* {(localStorage.getItem('bio') === '' &&
        profile.data.id != localStorage.getItem('user') &&
        showComponent === '') ||
      (showComponent === '' &&
        userSnapShot === false &&
        profile.data.id != localStorage.getItem('user')) &&(
        <MineModel size="medium" showCloseIcon="no">
          <div className="new-session-modal-wrapper text-center">
            <div className="new-session-modal">
              <h3>Let's grow together!</h3>
              <p>
                Update your preferences to unlock features involving other
                users.{' '}
              </p>

              <div className="new-session-btn-wrapper">
                {localStorage.getItem('bio') === '' && (
                  <Button
                    className="btn-dark"
                    onClick={() => setShowComponent('EditProfile')}>
                    Update Profile
                  </Button>
                )}
                {!userSnapShot && (
                  <Button
                    className="btn-dark"
                    onClick={() => setShowComponent('Addsnapshot')}>
                    Add Snapshot
                  </Button>
                )}

               
              </div>
            </div>
          </div>
        </MineModel>
      ) } */}

      <div className="card profile-block-wrapper profile-from-leftPanel">
        {noshow && (
          <>
            <Slider {...imgSliderSettings}>
              {Object.entries(allTickets).length > 0 &&
                allTickets.map((item, index) => (
                  <div
                    className="events-wrapper mt-3 fix-width-forms"
                    key={index + 'jj'}>
                    <div className="claim-ticket claim-ticket-item">
                      <p>
                        {item.sender_detail &&
                          fixName(
                            item.sender_detail.first_name,
                            item.sender_detail.last_name,
                          )}{' '}
                        send you a ticket to{' '}
                        {item.event_details && item.event_details.session_title}
                        <br />
                        Claim to attend this event
                      </p>
                      <div className="text-center">
                        <Button
                          className="btn-dark btn btn-primary"
                          onClick={() => {
                            openEventDetail(item.event_details, item);
                          }}>
                          {' '}
                          Claim Ticket
                        </Button>
                      </div>
                    </div>
                  </div>
                ))}
            </Slider>
          </>
        )}

        <div className="profile-img-wrapper">
          <div className="profile-img position-relative">
            <img
              src={
                profile && profile.data.profile_img
                  ? profile.data.profile_img
                  : DEFAULT_IMAGE
              }
              alt=""
            />

            <div className="user_badge_check">
              <BadgeCheck fill={'#E0A168'} />
            </div>
          </div>

          <div className="profile-name">
            <div
              className={`profile-name-headings ${
                withHeadingSpace || windowWidth < 1300 ? 'with-panel' : ''
              }`}>
              <div className="">
                <h2>
                  {profile.data.first_name + ' ' + profile.data.last_name}
                </h2>
                {/* <h3>{mineAreaUser.join(', ')}</h3> */}
              </div>
              {!typeModAud &&
                showButton === true &&
                new Date(serverTime) < new Date(sessionData.end_time) &&
                new Date(serverTime) >
                  new Date(sessionData.session_start_time) && (
                  <div className="btns-wrapper">
                    <Button className="btn-dark" type="submit" onClick={submit}>
                      Mark Attendance
                    </Button>
                  </div>
                )}
            </div>
            {/* {type !=='otherUser' &&  <div className="events-icon dark">
              <ul>

                <li>
                <span
                    className=" as-link"
                    onClick={() => {
                      setEditProfile
                        ? setEditProfile('editProf')
                        : handleBlock(profile.data, 'editProfile');
                    }}>
                    <img height={16} src={EditPencilYellow} alt="edit" />
                  </span>
                </li>

<li>
<span
                    className={'as-link icon'}
                    onClick={() => {
                      handleBlock(profile.data, 'follow-up');
                    }}>
                    <i className="fa-solid fa-bell"></i>
                  </span>
</li>

                <li>
                <span
                    onClick={() => {
                      handleBlock(profile.data, 'share');
                    }}>
                    <i className="fas fa-share" />
                  </span>
                </li>



                {profile.data.exist_in_build !== 'no' && (
                  <li>
                    <span
                      className=" as-link"
                      onClick={() => {
                        handleBlock(profile.data, 'appointment');
                      }}>
                      <i className="fas fa-calendar-days" />
                    </span>
                  </li>
                )}

                {profile.data.exist_in_build !== 'no' && (
                  <li>
                    <span
                      className={
                        profile.can_consult_add_feedback === '' &&
                        profile.can_even_add_feedback === '' &&
                        profile.can_oppor_add_feedback === '' &&
                        profile.can_sess_add_feedback === '' &&
                        profile.data.id !== localStorage.getItem('id')
                          ? 'disabled-Class'
                          : ' as-link'
                      }
                      onClick={() => {
                        handleBlock(profile.data, 'praise');
                      }}>
                      <i className="icon-applaud" />
                    </span>
                    {average > 0 && (
                      <span className="event-icon-text">{`${average}%`}</span>
                    )}
                  </li>
                )}

                {!myProfile && profile.data.exist_in_build !== 'no' && (
                  <li>
                    <span
                      className={
                        getItem('userRole') === 'guest'
                          ? 'grey-class'
                          : 'as-link icon'
                      }
                      onClick={() => {
                        if (getItem('userRole') === 'guest') {
                          errorMessageGuest(
                            labels.errorMessage_for_uninvite_User[
                              selected_lang
                            ],
                          );
                        } else {
                          handleBlock(profile.data, 'chat');
                        }
                      }}>
                      <i className="icon-plane" />
                    </span>
                  </li>
                )}
                <li>
                  <span
                    className={'as-link'}
                    onClick={() => {
                      handleBlock(profile.data, 'profile-notes');
                    }}>
                    <img height={15} width={15} src={ProfileNotes} alt="" />
                  </span>
                </li>
              </ul>
              {profile.data.id === localStorage.getItem('id') ? (
                <div className="profile-share-icon d-flex align-items-center">
                  <span
                    className=" as-link"
                    onClick={() => {
                      setEditProfile
                        ? setEditProfile('editProf')
                        : handleBlock(profile.data, 'editProfile');
                    }}>
                    <img height={16} src={EditPencilYellow} alt="edit" />
                  </span>

                  <span
                    onClick={() => {
                      handleBlock(profile.data, 'share');
                    }}>
                    <i className="fas fa-share" />
                  </span>
                </div>
              ) : (
                <div className="profile-share-icon d-flex align-items-center">
                  <>
                    {profile.data.id !== localStorage.getItem('id') && (
                      <span
                        className="as-link"
                        onClick={() => {
                          handleBlock(profile.data, 'circle');
                        }}>
                        <i className="icon-circles" />
                      </span>
                    )}

                    <span
                      className="as-link"
                      onClick={() => {
                        handleBlock(profile.data, 'share');
                      }}>
                      <i className="fas fa-share" />
                    </span>
                  </>
                </div>
              )}
            </div>

} */}
          </div>
        </div>

        {/* {type ==='otherUser' && ( */}
        <div className="events-icon dark">
          <div className="event-icon-profile-head">
            {!viewDetails &&
              (CategoryOfUser.length > 0 ||
                profile.data.home_location ||
                profile.data.bio ||
                profile.data.linkedin ||
                profile.data.web ||
                profile.data.youtube ||
                profile.data.twitter ||
                profile.data.instagram ||
                localStorage.getItem('id') === profile.data.id) && (
                <span
                  className="profile_view_more"
                  onClick={() => {
                    setViewDetails(!viewDetails);
                  }}>
                  View Details
                </span>
              )}

            {viewDetails && (
              <span
                className="profile_view_more"
                onClick={() => {
                  setViewDetails(!viewDetails);
                }}>
                Hide Details
              </span>
            )}
          </div>

          {/* {profile.data.id === localStorage.getItem('id') ? (
              <div className="profile-share-icon d-flex align-items-center">
                <span
                  className=" as-link"
                  onClick={() => {
                    setEditProfile
                      ? setEditProfile('editProf')
                      : handleBlock(profile.data, 'editProfile');
                  }}>
                  <img height={16} src={EditPencilYellow} alt="edit" />
                </span>
                <span
                      className={
                        getItem('userRole') === 'guest'
                          ? 'grey-class'
                          : ' as-link'
                      }
                      onClick={() => {
                        if (getItem('userRole') === 'guest') {
                          errorMessageGuest(
                            labels.errorMessage_for_uninvite_User[
                              selected_lang
                            ],
                          );
                        } else {
                          setEditProfile('Favr');
                        }
                      }}>
                      <i className="far fa-heart" />
                    </span>
                <span
                  onClick={() => {
                    handleBlock(profile.data, 'share');
                  }}>
                  <i className="fas fa-share" />
                </span>
                <span
                      className="ToggleRightArrow"
                      onClick={() => {
                        handleBlock(profile.data, 'show-gallery');
                      }}>
                      <i class="fa-solid fa-angles-left"></i>
                      <i class="fa-solid fa-angles-left"></i>
                      <i class="fa-solid fa-angles-left"></i>
                    </span>
              </div>
            ) : ( */}
          <div className="profile-share-icon d-flex align-items-center">
            <>
              {profile.data.exist_in_build !== 'no' && profile.schedules && (
                <span
                  className=" as-link"
                  onClick={() => {
                    handleBlock(profile.data, 'appointment');
                  }}>
                  <i className="fas fa-calendar-days" />
                </span>
              )}

              <span
                className={'as-link'}
                onClick={() => {
                  handleBlock(profile.data, 'profile-notes');
                }}>
                <img height={15} width={15} src={ProfileNotes} alt="" />
              </span>

              {/* {!myProfile && profile.data.exist_in_build !== 'no' && (
                    <span
                      className={
                        getItem('userRole') === 'guest'
                          ? 'grey-class'
                          : 'as-link icon'
                      }
                      onClick={() => {
                        if (getItem('userRole') === 'guest') {
                          errorMessageGuest(
                            labels.errorMessage_for_uninvite_User[
                              selected_lang
                            ],
                          );
                        } else {
                          handleBlock(profile.data, 'chat');
                        }
                      }}>
                      <i className="icon-plane" />
                    </span>
                  )} */}

              {/* <span
                    className={'as-link icon'}
                    onClick={() => {
                      handleBlock(profile.data, 'follow-up');
                    }}>
                    <i className="fa-solid fa-bell"></i>
                  </span> */}

              {/* {profile.data.id !== localStorage.getItem('id') && (
                    <span
                      className="as-link"
                      onClick={() => {
                        handleBlock(profile.data, 'circle');
                      }}>
                      <i className="icon-circles" />
                    </span>
                  )} */}

              {/* <span
                    className="as-link"
                    onClick={() => {
                      handleBlock(profile.data, 'share');
                    }}>
                    <i className="fas fa-share" />
                  </span> */}
            </>
          </div>
          {/* )} */}
        </div>
        {/* )} */}

        {viewDetails && (
          <div className="profile-content-infos profile-content-social-infos">
            <div className="skills">{CategoryOfUser.join(', ')}</div>

            <div className="other-infos">
              {(profile.data.id === localStorage.getItem('id') ||
                (profile.data.id !== localStorage.getItem('id') &&
                  profile.data.home_location)) && (
                <div
                  className={
                    profile.data.id === localStorage.getItem('id')
                      ? 'profile-address as-link'
                      : 'profile-address'
                  }
                  onClick={() => {
                    profile.data.id === localStorage.getItem('id') &&
                      // setShowComponent('homeMap');
                      handleBlock(profile.data, 'home-map');
                    // setEditProfile('homeMap');
                  }}>
                  <i className="fas fa-home" />{' '}
                  {profile.data.home_location
                    ? profile.data.home_location
                    : 'Add Location'}
                </div>
              )}
              {(profile.data.id === localStorage.getItem('id') ||
                (profile.data.id !== localStorage.getItem('id') &&
                  profile.data.current_location)) && (
                <div
                  className={
                    profile.data.id === localStorage.getItem('id')
                      ? 'profile-address as-link'
                      : 'profile-address'
                  }
                  onClick={() => {
                    if (profile.data.id === localStorage.getItem('id')) {
                      // setShowComponent('currentMap');
                      // setEditProfile('currentMap');
                      handleBlock(profile.data, 'current-map');
                    }
                  }}>
                  <i className="icon-aroplane" />{' '}
                  {profile.data.current_location
                    ? profile.data.current_location
                    : 'Add Location'}
                </div>
              )}
            </div>
            {profile.data.bio ? (
              // <RenderHTMLBlock
              //   className={`profile-description ${showMoreClass}`}
              //   container={'div'}
              //   content={profile.data.bio}
              // />
              <FormattedText
                className={`profile-description ${showMoreClass}`}
                content={profile.data.bio}
              />
            ) : (
              profile.data.id === localStorage.getItem('id') &&
              lastSegment === 'profile' && (
                <div className="no-results-wrapper mt-3">
                  {/* <div className="img-bg-wrapper">
                            <img src={QA} alt="" />
                          </div> */}

                  <div className="no-results-content">
                    {/* <h2>What represents your life in a snapshot?</h2> */}
                    <p>No bio is added, tap to add bio</p>

                    <div className="btn-wrapper">
                      <Button
                        className="btn btn-dark"
                        onClick={() => {
                          setEditProfile('editProf');
                        }}>
                        Add Bio
                      </Button>
                    </div>
                  </div>
                </div>
              )
            )}

            {(profile?.data?.bio?.length > 250 || lines?.length > 3) && (
              <>
                {' '}
                <span
                  className="show-more-link"
                  onClick={() => setShowMore(!showMore)}>
                  {!showMore
                    ? labels.read_more[selected_lang]
                    : labels.read_less[selected_lang]}
                  {/* {profile.data.bio.length} */}
                </span>
              </>
            )}

            <div className="social-icons">
              <ul style={{marginLeft: '1px'}}>
                {profile.data.instagram && (
                  <li>
                    <a
                      // href={profile.data.instagram}
                      href={`https://www.instagram.com/${profile.data.instagram.replace(
                        /(http(s?):\/\/)?(www\.)?instagram\.com\//,
                        '',
                      )}`}
                      target="_blank"
                      rel="noopener noreferrer">
                      <i className="fab fa-instagram" />
                    </a>
                  </li>
                )}
                {profile.data.linkedin && (
                  <li>
                    <a
                      // href={profile.data.linkedin}
                      href={`https://www.linkedin.com/in/${profile.data.linkedin.replace(
                        /(http(s)?:\/\/)?(www\.)?linkedin\.com\/(in\/)?/,
                        '',
                      )}`}
                      target="_blank"
                      rel="noopener noreferrer">
                      <i className="fa-brands fa-linkedin" />
                    </a>
                  </li>
                )}
                {profile.data.twitter && (
                  <li>
                    <a
                      // href={profile.data.twitter}
                      href={`https://twitter.com/${profile.data.twitter.replace(
                        /(http(s?):\/\/)?(www\.)?twitter\.com\//,
                        '',
                      )}`}
                      target="_blank"
                      rel="noopener noreferrer">
                      <i className="fab fa-twitter" />
                    </a>
                  </li>
                )}
                {profile.data.youtube && (
                  <li>
                    <a
                      // href={profile.data.youtube}
                      href={`https://www.youtube.com/${profile.data.youtube.replace(
                        /(http(s?):\/\/)?(www\.)?(m\.)?youtube\.com\/(channel\/)?/,
                        '',
                      )}`}
                      target="_blank"
                      rel="noopener noreferrer">
                      <i className="fab fa-youtube" />
                    </a>
                  </li>
                )}

                {profile.data.web && (
                  <li>
                    <a
                      // href={profile.data.web}
                      href={`https://${profile.data.web.replace(
                        /(http(s)?:\/\/|http(s)?:\/)?/,
                        '',
                      )}`}
                      target="_blank"
                      rel="noopener noreferrer">
                      <i className="icon-link" />
                    </a>
                  </li>
                )}
                {/* <span
                            onClick={() => {
                                // saveMessage();
                            }}
                            className="form-icon send-btn"
                        > */}

                {/* </span> */}
              </ul>
            </div>
          </div>
        )}

        {/* {type && !viewDetails && (CategoryOfUser.length > 0 || profile.data.home_location || profile.data.bio || profile.data.linkedin || profile.data.web || profile.data.youtube || profile.data.twitter || profile.data.instagram) && (
          <span
            className="profile_view_more"
            onClick={() => {
              setViewDetails(!viewDetails);
            }}>
            View Details
          </span>
        )} */}
        {profile.data.exist_in_build !== 'no' && (
          <div className="roi-tags-icons flex items-center roi-profile-block-tags-icons">
            {showPlusButtonDecesion(
              tagsListing?.tag_permission?.own_profile_permission,
              tagsListing?.tag_permission?.show_tag,
            ) === true && (
              <div
                className="tag-icon as-link cursor-pointer"
                onClick={() => {
                  handleBlock('', 'view_tag');
                }}>
                <i className="icon-plus"></i>
              </div>
            )}

            <div className="flex-grow overflow-x-auto">
              <Slider {...settings} className="whitespace-nowrap">
                {tagsListing?.tags_details?.length > 0 &&
                  tagsListing?.tags_details.map((item, index) => (
                    <div className="inline-block px-2" key={index}>
                      <div className="tag-icon-time flex items-center space-x-2">
                        <div
                          className="newClass flex items-center space-x-2"
                          style={{
                            paddingLeft: '3px',
                          }}>
                          {item.icon && item.icon !== '' && (
                            <img src={item.icon} alt="" className="w-6 h-6" />
                          )}
                          <span className="text-sm multi-line-ellipsis">
                            {item.title}
                          </span>
                        </div>
                      </div>
                    </div>
                  ))}
              </Slider>
            </div>
          </div>
        )}
        {profile?.notes_available > 0 && (
          <div
            className="user_notes as-link"
            onClick={() => {
              handleBlock(profile.data, 'profile-notes');
            }}>
            <span className="fw-bold">Notes</span>

            <span className="fw-bold">{profile?.notes_available}</span>
          </div>
        )}
      </div>

      {/* {profile.data &&
        profile.data.invited_by &&
        profile.data.invited_by.first_name && (
          <div className="invited-by-wrapper">
            <div
              className="user-img as-link"
              onClick={() => {
                // setInviteUserId(profile.data.invited_by.id);
                // setShowComponent('ProfileComponent');
                handleBlock(profile.data.invited_by.id, 'Profile_view');
              }}>
              <img
                src={
                  profile.data.invited_by.profile_img
                    ? profile.data.invited_by.profile_img
                    : DEFAULT_IMAGE
                }
                alt=""
              />
            </div>
            <div className="user-info">
              <span className="info-name">
                Invited by{' '}
                <b>
                  {profile.data.invited_by.first_name +
                    ' ' +
                    profile.data.invited_by.last_name}
                </b>
              </span>
              <span className="info-date">
                {moment(profile.data.invited_by.invitation_date).format(
                  'MM/DD/YY',
                )}
              </span>
            </div>
          </div>
        )} */}

      {/* 
      {threeDots && (
        <div className="payment-search-wrapper mb-0 messages-users-wrapper mb-4">
          <div className="search-form d-flex">
           <Form.Group className="fieldset">
        <div className="select-wrapper">
          <select className="form-control">
            {Object.entries(topic).length > 0 &&
              topic.map((item, index) => (
            <option>Before and After</option>
            ))} 
          </select>
        </div>
      </Form.Group>
            <Button>
              <i
                className="icon-filter-icon"
                // onClick={() => setSearchFilterModal(true)}
              />
            </Button>
          </div>
        </div>
      )} */}

      {/* <div className="payment-card qa-list-item">
        <div className="messages-users-list">
          <div className={`payment-user-item no-link`}>
            <div className="invoice-img as-link">
              {item.chat_type === 'question' && (
                <span>
                  <i className="fa-solid fa-q"></i>
                </span>
              )}
              {item.chat_type === 'opportunity' && (
                <span className="icon icon-posts"></span>
              )}
              {item.chat_type === 'event' && (
                <span className="icon-tickets"></span>
              )}
              {item.chat_type === 'session' && (
                <span className="icon-video-camera-2"></span>
              )}
             
            </div>
            <div className="user-content as-link">
              <span>Invoice Title</span>

              <div className="service-info ">
                <span className="message-date">Service</span>
              </div>
            </div>
          </div>
        </div>

        <div className="">
          <div className="payment-card-subCard">
            <h3>$456</h3>

            <div className="buy-btn-wrapper">
              <Button className="new-btn btn-green " onClick={() => {}}>
                Pay
              </Button>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

const mapStateProps = state => ({
  windowWidth: state.windowWidth,
});

export default connect(mapStateProps, {})(ProfileBlock);
