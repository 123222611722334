import React, {useState, useEffect} from 'react';
import {Button, Form} from 'react-bootstrap';
import Scrollbars from 'react-custom-scrollbars';
import commonApi from '../../Services/CommonService';
import {errorMessage, successMessage} from '../../Utils/ToastMessages';
import ReferNotes from './ReferNotes';
import {labels} from '../../Constants/Translations';
import {selected_lang} from '../../Utils/env';
import SlidingPanel from '../SlidingPanel';
import LoaderSpinner from '../Modals/LoaderSpinner';
import CircleListBuilders from '../../Pages/Front/StaticPages/CircleListBuilders';
import {amplitudeEvent} from '../../Services/AmplitudeServices';
import {ENV_HOST} from '../../Utils/env';
import {connect} from 'react-redux';
import SendInvitation from '../../Pages/Front/StaticPages/SendInvitation';
import CustomTabs from '../CustomTabs';
import Invite from '../SingleComponent/Invite';

const ReferOpportunity = props => {
  // const [following, setFollowing] = useState([]);
  const [builders, setBuilders] = useState([]);
  const [showLoader, setshowLoader] = useState(false);
  const [allBuilders, setAllBuilders] = useState([]);
  // const [newFollowers, setNewFollowers] = useState([]);
  const [showComponent, setShowComponent] = useState('');
  // const [showSlectedUser, setShowSlectedUser] = useState('');
  const [notes, setNotes] = useState('');
  const [tabType, setTabType] = useState(
    props.type === 'share_notes_with_others' ? 'Recipient' : 'Details',
  );
  const [contact, setContact] = useState([]);
  // const [originalData, setOriginalData] = useState([]);
  const [originalDataOfMod, setOriginalDataOfMod] = useState([]);
  // const[selected,setSelected] = useState([]);

  //for Invites
  const [inviteLoader, setInviteLoader] = useState(true);
  const [inviteFollowers, setInviteFollowers] = useState([]);
  const [folowersList, setFollowersList] = useState([]);
  const [inviteQry, setInviteQry] = useState('');

  // const lowerName = name.toLowerCase();
  let lowerName = '';
  let companyNameFromLocal = localStorage.getItem('activeWorkspaceItem')
    ? JSON.parse(localStorage.getItem('activeWorkspaceItem'))
    : [];

  // Check if lowerName is not empty and has at least one item
  if (companyNameFromLocal.length > 0) {
    // Access the first item in the array and get the company_name property
    let companyName = companyNameFromLocal[0].company_name;

    // Replace spaces with hyphens
    lowerName = companyName.replace(/\s+/g, '-');

    console.log(lowerName); // Output the formatted company name
  }

  const type = props.type === 'appointment' ? 'schedule' : props.type === 'conference' ? 'virtual' : props.type === 'event' ?  'in-person' : props.type === 'group_chat' ?  'group-chat' : props.type === 'opportunity' ?  'recruitment' : props.type;
  const title =
    props.type === 'recruitment'
      ? props.userData.title
      : props.type === 'virtual'
      ? props.userData.session_title
      : props.type === 'in-person'
      ? props.userData.session_title
      : props.type === 'document'
      ? props.userData.title
      : '';

  const submit = () => {
    if (builders.length === 0) {
      errorMessage('Select recipient');
    } else {
      if (props.type === 'virtual') {
        referSession('session');
        props.onClose();
      } else if (props.type === 'in-person') {
        referSession('event');
        props.onClose();
      }
      if (props.type === 'recruitment') {
        referOpportunity();
        props.onClose();
      }
      if (props.type === 'question') {
        referQuestion();
        props.onClose();
      }
      if (props.type === 'appointment') {
        referAppointment();
        props.onClose();
      }
      if (props.type === 'message') {
        referGroupChat();
        props.onClose();
      }
      if (props.type === 'share_notes_with_others') {
        share_notes_with_others();
        
        props.onClose();
      }
      if (props.type === 'document') {
        refer_document();
        props.onClose();
      }
    }
  };

  // const handleFollowerSearch = (array, query) => {
  //   if (!query) {
  //     setAllBuilders(originalData); // Reset to original data when query is empty
  //     return;
  //   }
  //   setAllBuilders(
  //     array.filter(el => {
  //       // const fullName = el.first_name.toLowerCase() + el.last_name.toLowerCase();
  //       const fullNameSpace =
  //         el.first_name.toLowerCase() + ' ' + el.last_name.toLowerCase();
  //       return (
  //         el.first_name.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
  //         el.last_name.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
  //         fullNameSpace.toLowerCase().indexOf(query.toLowerCase()) !== -1
  //       );
  //     }),
  //   );
  // };

  const followersList = qry => {
    var payloadFollowersList = {
      chat_room_id: '',
      circle_id: '',
      opportunity_id: '',
      search_query: qry,
    };
    commonApi
      .excluded_followers_list(payloadFollowersList)
      .then(res => {
        if (res.status === 200) {
          setshowLoader(false);
          // setFollowing(res.data.filter(item => !builders.includes(item.id)));
          // setNewFollowers(res.data.filter(item => !builders.includes(item.id)));
          // setFollowing(res.data);
          // setNewFollowers(res.data);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const referGroupChat = () => {
    var payloadreferOpportunity = {
      chatroom_id: props.userData.id,
      refer_notes: notes,
      // refer_type: 'session',
      contacts: contact,
      user_ids: builders,
    };
    commonApi
      .refer_group_chat(payloadreferOpportunity)
      .then(res => {
        if (res.status === 200) {
          successMessage(res.message);
          // amplitudeEvent('REFER_QUESTION');
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  const share_notes_with_others = () => {
    var payloadreferOpportunity = {
      note_id: props.userData.id,
      // refer_notes: notes,
      // refer_type: 'session',
      // user_ids: contact,
      user_ids: builders.toString(','),
    };
    commonApi
      .share_notes_with_others(payloadreferOpportunity)
      .then(res => {
        if (res.status === 200) {
          successMessage(res.message);
          props.onCloseHitApi && props.onCloseHitApi()
          // amplitudeEvent('REFER_QUESTION');
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  const refer_document = () => {
    var payloadreferOpportunity = {
      user_ids: builders.toString(','),
      file_id: props.userData.id,
      refer_type: 'document_file',
      refer_notes: notes,
    };
    commonApi
      .refer_document(payloadreferOpportunity)
      .then(res => {
        if (res.status === 200) {
          successMessage(res.message);
          // amplitudeEvent('REFER_QUESTION');
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  const referAppointment = () => {
    var payloadreferOpportunity = {
      appointment_id: props.userData.id,
      refer_notes: notes,
      // refer_type: 'session',
      contacts: contact,
      user_ids: builders,
    };
    commonApi
      .refer_appointment(payloadreferOpportunity)
      .then(res => {
        if (res.status === 200) {
          successMessage(res.message);
          // amplitudeEvent('REFER_QUESTION');
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  const referQuestion = () => {
    var payloadreferOpportunity = {
      question_id: props.userData.id,
      refer_notes: notes,
      // refer_type: 'session',
      contacts: contact,
      user_ids: builders,
    };
    commonApi
      .refer_question(payloadreferOpportunity)
      .then(res => {
        if (res.status === 200) {
          successMessage(res.message);
          amplitudeEvent('REFER_QUESTION');
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  const referSession = type => {
    var payloadreferOpportunity = {
      conference_id: props.userData.id,
      refer_notes: notes,
      refer_type: type,
      user_ids: builders,
      contacts: contact,
    };
    commonApi
      .refer_session(payloadreferOpportunity)
      .then(res => {
        if (res.status === 200) {
          successMessage(res.message);
          if (type === 'session') {
            amplitudeEvent('REFER_SESSION');
          }
          if (type === 'event') {
            amplitudeEvent('REFER_EVENT');
          }
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  const referOpportunity = qry => {
    var payloadreferOpportunity = {
      user_ids: builders,
      refer_notes: notes,
      opportunity_id: props.userData.id,
    };
    commonApi
      .refer_opportunity(payloadreferOpportunity)
      .then(res => {
        if (res.status === 200) {
          successMessage(res.message);
          amplitudeEvent('REFER_OPPORTUNITY');
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const storeReferral = () => {
    var payloadAllUser = {
      referral_id: props.userData.id,
      referral_key:
        'https://' +
          ENV_HOST +
          'roiforpros.com/' +
          lowerName +
          '/' +
          type +
          type ==
          'profile' || type == 'document'
          ? ''
          : '/invite?id=' + props.userData.id,
      referral_type: type,
    };
    // "www.roiforpros.com/", props.userData.first_name, "/profile?id=", props.userData.id,
    commonApi
      .store_referral(payloadAllUser)
      .then(res => {
        if (res.status === 200) {
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  // useEffect(() => {
  //   // setFollowing(following.filter((item) => !builders.includes(item.id)));
  //   // setFollowing(following.filter((item) => !builders.includes(item.id)));
  //   setFollowing(newFollowers.filter(item => !builders.includes(item.id)));
  //   // setAllBuilders(newFollowers.filter(item => builders.includes(item.id)));
  // }, [builders]);

  useEffect(() => {
    followersList();

    setshowLoader(true);
    setNotes('');
  }, [props.userData]);

  // useEffect(() => {
  //   if (builders.length === 0) {
  //     setShowSlectedUser('');
  //   }
  // }, [builders]);

  // const handleSelectedFollower = item => {
  //   if (!builders.includes(item.id)) {
  //     setBuilders(current => [...current, item.id]);
  //     setAllBuilders(previous => [...previous, item]);
  //     setOriginalData(previous => [...previous, item]);
  //   } else {
  //     setBuilders(previous => previous.filter(id => id !== item.id));
  //     setAllBuilders(previous =>
  //       previous.filter(miniItem => miniItem.id !== item.id),
  //     );
  //     setOriginalData(previous =>
  //       previous.filter(miniItem => miniItem.id !== item.id),
  //     );
  //   }
  // };
  const CircleUserListData = item => {
    //
    // let count = 0;

    if (item.length > 0) {
      item.forEach((miniItem, index) => {
        if (!builders.includes(miniItem.id)) {
          // alert('why me')
          // count=count + 1

          setBuilders(previous => [...previous, miniItem.id]);
          setAllBuilders(previous => [...previous, miniItem]);
          setOriginalDataOfMod(previous => [...previous, miniItem]);
        }
      });
    }
  };

  //   var payLoad = {
  //     search_query: qry,
  //   };
  //   API.all_users(payLoad)
  //     .then(res => {
  //       setshowLoader(false);
  //       if (res.status === 200) {
  //         // setFolowers(res.data);
  //         setFollowing(res.data.filter(item => !builders.includes(item.id)));
  //         setNewFollowers(res.data.filter(item => !builders.includes(item.id)));
  //       }
  //     })
  //     .catch(err => {
  //       console.log('Err', err);
  //       return {type: 'error', message: err.message};
  //     });
  // };

  useEffect(() => {
    if (contact.length > 0) {
      setInviteLoader(true);
      setInviteQry('');
    }
  }, [contact]);

  const handleCircle = () => {
    setShowComponent('CircleList');
  };

  //
  //
  //

  console.log(props.type,'props.typeprops.typeprops.type')
  console.log(props.userData,'props.userDataprops.userData')
  return (
    <>
      {showLoader && props.type !== 'share_notes_with_others' && <LoaderSpinner />}

      <SlidingPanel
        hidePanel={() => {
          props.onClose();
        }}>
        <div className="serach-filter-wrapper create-tickets-wrapper">
          <Scrollbars>
            <div className="heading fix-width-forms">
              <h2>{title}</h2>
            </div>
            {props.type !== 'share_notes_with_others' && (
              <div className="d-flex align-items-center justify-content-center fix-width-forms w-100">
                <div className="create-ticket-tabs text-center fix-width-forms">
                  <CustomTabs
                    darkmode
                    tabs={['Details', 'Recipient']}
                    active={tabType}
                    onSelect={item => {
                      setTabType(item);
                      if (item === 'Details' && inviteQry !== '') {
                        setInviteFollowers(folowersList);
                        setInviteQry('');
                      }
                    }}
                  />
                </div>
              </div>
            )}
            <div
              className={`sidebar-heading-wrapper mt-0 fix-width-forms ${
                props.windowWidth < 1300 ? 'with-panel' : ''
              }`}>
              {/* <div className="search-tabs-icons">
                <span
                  className="icon as-link"
                  onClick={() => {
                    setShowComponent('ReferNotes');
                  }}>
                  <i className="fa-sharp fa-solid fa-book"></i>
                </span>
               
              </div> */}
            </div>

            {tabType === 'Details' &&
              props.type !== 'share_notes_with_others' && (
                <>
                  <div className="serach-filter-wrapper fix-width-forms">
                    <div className="sidebar-heading-wrapper">
                      <div className="heading">
                        <label>Notes (optional)</label>
                      </div>
                    </div>

                    <div className="fix-width-forms">
                      <div className="form-outline">
                        <Form>
                          <textarea
                            // disabled={props.disabled}
                            className="form-control"
                            id="textAreaExample1"
                            rows="12"
                            placeholder="Write a note..."
                            value={notes}
                            onChange={e => {
                              setNotes(e.target.value);
                            }}></textarea>
                        </Form>
                      </div>
                    </div>
                  </div>
                  {props.type !== 'document' && (
                    <div className="invite-persons-heading mt-2">
                      <span
                        className="builder-text as-link"
                        onClick={() => {
                          navigator.clipboard.writeText(
                            'https://' +
                              ENV_HOST +
                              'roiforpros.com/' +
                              lowerName +
                              '/' +
                              (type === 'recruitment' || type ==='schedule' ? props.userData.creator_details.id : type === 'snapshot' ? props.userData.created_by :  props.userData?.creator_id)  
                               + '/' +
                              type +
                              (type ===
                              'profile' || type == 'document'
                              ? ''
                              : '/invite?id=' + props.userData.id),
                          );
                          storeReferral();
                          successMessage(labels.copy_link[selected_lang]);
                        }}>
                        Copy{' '}
                        {type.charAt(0).toUpperCase() +
                          type.slice(1)}{' '}
                        Link{' '}
                      </span>
                    </div>
                  )}
                </>
              )}
            {tabType === 'Recipient' && (
              <>
                <div className="refferal-list-wrapper fix-width-forms">
                  {inviteLoader && (
                    <div className="invite_loader_hieght">
                      <LoaderSpinner />
                    </div>
                  )}
                  {props.type !== 'share_notes_with_others' &&
                    !inviteLoader && (
                      <div className="invite-persons-heading ">
                        <span
                          className="builder-text as-link"
                          onClick={() => {
                            setShowComponent('ReferOutSideBuild');
                            setTimeout(() => {
                              if (contact.length === 0 && inviteQry !== '') {
                                setInviteFollowers(folowersList);
                                setInviteQry('');
                              }
                            }, 1000);
                          }}>
                          Refer Outside ROI
                        </span>
                      </div>
                    )}
                  <div className="fix-width-forms mb-5">
                    {props.type !== 'share_notes_with_others' &&
                      !inviteLoader && (
                        <div className="intrested-for-wrapper">
                          <Form.Group className="">
                            <Form.Label>
                              Who would you like to refer to?
                            </Form.Label>
                          </Form.Group>
                        </div>
                      )}

                    <Invite
                      type={'refer'}
                      moderator={builders}
                      setModerator={setBuilders}
                      selectedModData={allBuilders}
                      setSelectedModData={setAllBuilders}
                      contact={contact}
                      setContact={setContact}
                      originalDataOfMod={originalDataOfMod}
                      setOriginalDataOfMod={setOriginalDataOfMod}
                      // setContactId= {setContactId}
                      // newFollowers={newFollowers}
                      // setSelected={setSelected}
                      // setNewFollowers={setNewFollowers}
                      forApiHit={showComponent}
                      handleCircle={handleCircle}
                      onClick={() => {
                        setShowComponent('inviteUser');
                        setTimeout(() => {
                          if (contact.length === 0 && inviteQry !== '') {
                            setInviteFollowers(folowersList);
                            setInviteQry('');
                          }
                        }, 1000);
                      }}
                      showLoader={inviteLoader}
                      setshowLoader={setInviteLoader}
                      followers={inviteFollowers}
                      setFollowers={setInviteFollowers}
                      setFollowersList={setFollowersList}
                      followersList={folowersList}
                      setSearchQry={setInviteQry}
                      searchQry={inviteQry}
                    />
                  </div>
                </div>
              </>
            )}
          </Scrollbars>
          {inviteFollowers.length > 0 && (
            <div className="filter-btn-wrapper ">
              <div className="invite-btns fix-width-forms text-center px-0 bg_transparent bottom-0 pt-2">
                <Button className="btn-dark" onClick={submit}>
                  {props.type !== 'share_notes_with_others'
                    ? 'Send Referral'
                    : 'Share'}
                </Button>
              </div>
            </div>
          )}
        </div>
      </SlidingPanel>

      {/* {showComponent === 'SelectedUsers' && (
        <SelectedReferUser
          users={builders}
          setUsers={setBuilders}
          selectedAllUser={allBuilders}
          setSelectedAllUser={setAllBuilders}
          onClose={() => setShowComponent('')}
          alreadyAddedUser={[]}
          referOutSide={contact}
          setReferOutSide={setContact}
        />
      )} */}
      {showComponent === 'inviteUser' && (
        <SendInvitation
          onClose={() => {
            setShowComponent('');
          }}
          setContact={setContact}
        />
      )}
      {showComponent === 'ReferOutSideBuild' && (
        <SendInvitation
          id={props.userData.id}
          referType={props.type === 'conference' ? 'session' : props.type}
          onClose={() => setShowComponent('')}
          setContact={setContact}
        />
      )}

      {showComponent === 'CircleList' && (
        <CircleListBuilders
          onClose={() => {
            setShowComponent('');
          }}
          // setInviteCircle={setInviteCircle}
          hide={true}
          CircleUserListData={CircleUserListData}
        />
      )}

      {showComponent === 'ReferNotes' && (
        <ReferNotes
          setNotes={setNotes}
          notes={notes}
          onClose={() => {
            setShowComponent('');
          }}
        />
      )}
    </>
  );
};
const mapStateProps = state => ({
  windowWidth: state.windowWidth,
});
export default connect(mapStateProps, {})(ReferOpportunity);
