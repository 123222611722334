import React from 'react';
import {Button, Form} from 'react-bootstrap';
import Sidebar from '../Sidebar';
import {useState} from 'react';
import {CustomModal, Pannels} from '../../Components';
import CreateWorkSpace from '../../Components/WorkSpace/CreateWorkSpace';
import Scrollbars from 'react-custom-scrollbars';
import {
  ArrowIcon,
  FireIcon,
  MachineIcon,
  WhitePenIcon,
  timeIcon,
  bagIcon,
  heartIcon,
  Bandage,
  rewardStar,
} from '../../Constants/Images';
import CreateAgreement from '../../Components/AgreementProposal/CreateAgreement';
import Slider from 'react-slick';

const AgreementProposal = props => {
  const [threeDots, setThreeDots] = useState(false);
  const [chatSearchQry, setChatSearchQry] = useState('');
  const [tabType, setTabType] = useState('agreements');
  const [showComponent, setShowComponent] = useState('');
  const [currentComponent, setCurrentComponent] = useState('');
  const [workspaceData, setWorkspaceData] = useState([]);
  const [showHiddenPanel, toggleHiddenPanel] = useState(false);
  const [isFloated, toggleFloatedPanel] = useState(false);
  const [showCustomModel, setShowCustomModel] = useState(false);
  const [analycticsSlider, setAnalycticsSlider] = useState(false);

  const CustomModelStyle = {
    color: '#262626',
    textAlign: 'center',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '24px',
    paddingBottom: '14px',
  };
  const CustomModelStyle2 = {
    color: '#262626',
    // textAlign: 'center',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '24px',
    textAlign: 'left',
    paddingLeft: '9px',
    paddingBottom: '8px',
  };
  const CustomModelStyleDes = {
    color: '#262626',
    // textAlign: 'center',
    fontFamily: 'Inter',
    fontSize: '15px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '24px',
    textAlign: 'left',
    paddingLeft: '14px',
  };
  const CustomModelBg = {
    borderRadius: '5px',
    border: '1px solid #F1EDED',

    background: '#FFF',
  };

  const dynamicStyle = {
    padding: '0px 0px 15px',
    // Add other styles as needed
  };
  const data = [
    {
      label: 'Email, phone number and address',
      value: 'example@email.com',
      type: 'Personal',
    },

    {
      label: 'Resume and portfolio',
      value: 'Link to resume',
      type: 'Professional',
    },

    {
      label: 'Education and certifications',
      value: 'Your Education',
      type: 'Professional',
    },

    {label: 'Emergency Contact', value: 'Emergency Person', type: 'Emergency'},
    {label: 'Medication', value: 'Medication details', type: 'Medical'},
    {
      label: 'Medical History',
      value: 'Medical history details',
      type: 'Medical',
    },
    {label: 'Medical Insurance', value: 'Insurance details', type: 'Medical'},
    {label: 'Property', value: 'Property details', type: 'Business'},
    {
      label: 'Business POC',
      value: 'Business Point of Contact details',
      type: 'Business',
    },
    {label: 'Business Traction', value: 'Traction details', type: 'Business'},
  ];

  const rewardSlider = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
  };

  const rewardSliderData = [
    {
      name: 'Potential',
      money: '$2.4k',
      time: 'versus last month',
      svgIcon: rewardStar,
    },

    {
      name: 'On-Time',
      money: '85%',
      time: 'versus last month',
      svgIcon: rewardStar,
    },

    {
      name: 'Conversion',
      money: '45%',
      time: 'versus last month',
      svgIcon: rewardStar,
    },
    {
      name: 'Rating',
      money: '4.8',
      time: 'versus last month',
      svgIcon: rewardStar,
    },
  ];

  return (
    <>
      <div className="page-wrapper">
        <Sidebar
          location={props.location}
          setShowComponent={setShowComponent}
          setWorkSpaceItem={setWorkspaceData}
          workspaceData={workspaceData}
          showComponent={showComponent}
        />
        <div className="page-content-wrapper">
          <div className="sessions-list-wrapper slider-screen-main">
            <div className="Reward_main analytics_slider_bg">
              <span
                className="analytics_slider_heading "
                style={{color: analycticsSlider ? '' : '#999999'}}
                onClick={() => {
                  setAnalycticsSlider(!analycticsSlider);
                }}>
                Analytics Row
              </span>

              {analycticsSlider && (
                <div className="reward-top mt-3 mb-2">
                  <Slider {...rewardSlider}>
                    {rewardSliderData.length > 0 &&
                      rewardSliderData.map((item, index) => (
                        <div className="reward-main">
                          <div className="reward-card">
                            <span style={{fontWeight: 700, fontSize: '13px'}}>
                              {item.name}
                            </span>
                            <span
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}>
                              <span style={{fontSize: '17px'}}>
                                {item.money}
                              </span>
                              {item.name === 'Ratings' && (
                                <img src={item.svgIcon} alt="" />
                              )}
                              {item.name === 'Conversion' ? (
                                <i
                                  className="fa-solid fa-arrow-down"
                                  style={{color: '#BD4141'}}></i>
                              ) : (
                                <i
                                  class="fa-solid fa-arrow-up"
                                  style={{color: '#52bd41'}}></i>
                              )}
                            </span>
                            <div class="message-date-info">
                              <span
                                style={{color: '#333'}}
                                class="message-date">
                                {item.time}
                              </span>
                            </div>
                          </div>
                        </div>
                      ))}
                  </Slider>
                </div>
              )}
            </div>
            <div className="add_border"></div>
            <Pannels
              isFloated={isFloated}
              toggleFloatedPanel={toggleFloatedPanel}
              showHiddenPanel={showHiddenPanel}
              updateHiddenPanelState={nState => toggleHiddenPanel(nState)}
              customCss={dynamicStyle}
              primary={
                <>
                  <div className="agreements-proposals-page primary_panel_wraper primary_panel_content">
                    <div className="messages-header mb-2">
                      <div className="event-tabs tabs-style-2 mb-2">
                        {' '}
                        <ul>
                          <li>
                            <Button
                              className={
                                tabType === 'agreements' ? 'active' : ''
                              }
                              onClick={() => {
                                setTabType('agreements');
                              }}>
                              Agreements
                            </Button>
                          </li>

                          <li>
                            <Button
                              className={
                                tabType === 'proposals' ? 'active' : ''
                              }
                              onClick={() => {
                                setTabType('proposals');
                              }}>
                              Proposals
                            </Button>
                          </li>
                        </ul>
                      </div>

                      <div className="search-tabs-icons">
                        <span
                          className={
                            currentComponent === 'create-form'
                              ? 'icon active'
                              : 'icon'
                          }>
                          <i
                            className="icon-plus"
                            onClick={() => setCurrentComponent('create-form')}
                          />
                        </span>
                        <span
                          onClick={() => {
                            setThreeDots(!threeDots);
                          }}
                          className={
                            threeDots
                              ? 'icon active threeDots'
                              : 'icon threeDots'
                          }>
                          <i className="fas fa-ellipsis-vertical" />
                        </span>
                      </div>
                    </div>

                    {threeDots && (
                      <div className="payment-search-wrapper mb-0 messages-users-wrapper mb-4">
                        <div className="search-form d-flex">
                          <Form.Group className="search-input">
                            <Form.Control
                              type="text"
                              placeholder="Who are you looking for?"
                              value={chatSearchQry}
                              onChange={e => {
                                setChatSearchQry(e.target.value);
                              }}
                            />
                          </Form.Group>
                          <Button>
                            <i
                              className="icon-filter-icon"
                              // onClick={() => setSearchFilterModal(true)}
                            />
                          </Button>
                        </div>
                      </div>
                    )}

                    <div className="refferal-list-wrapper">
                      <Scrollbars autoHide>
                        <div className="agreements-proposals-content">
                          <div className="agreement-card-main">
                            <div className="agrement-card">
                              <h1>Agreement title</h1>
                              <span className="service-title">
                                Services / products
                              </span>

                              <div className="roi-service-icons">
                                <div className="service-icon">
                                  <img src={WhitePenIcon} alt="" />
                                </div>

                                <div className="service-icon-time">
                                  <img className="" src={timeIcon} alt="" />
                                  <span>5D</span>
                                </div>

                                <div className="service-icon w-25">
                                  <img src={FireIcon} alt="" />
                                </div>

                                <div className="service-icon w-29">
                                  <img src={MachineIcon} alt="" />
                                </div>

                                <div className="service-icon w-29">
                                  <img src={ArrowIcon} alt="" />
                                </div>

                                <div className="service-icon w-29">
                                  <img src={Bandage} alt="" />
                                </div>

                                <div className="service-icon w-28">
                                  <img src={heartIcon} alt="" />
                                </div>

                                <div className="service-icon w-29">
                                  <img src={bagIcon} alt="" />
                                </div>
                                <div className="w-29">
                                  <i
                                    className="fa-solid fa-ellipsis"
                                    style={{
                                      color: '#0a0a0a',
                                      paddingTop: '15px',
                                    }}></i>
                                </div>
                              </div>
                            </div>

                            <div className="agreement-card-bottom">
                              <span
                                style={{
                                  color: '#262626',

                                  fontFamily: 'Inter',
                                  fontSize: '15px',
                                  fontStyle: 'normal',
                                  fontWeight: '600',
                                  lineHeight: 'normal',
                                }}>
                                {' '}
                                15 Days Remaining
                              </span>

                              <div>
                                <span
                                  style={{
                                    color: '#262626',

                                    fontFamily: 'Inter',
                                    fontSize: '15px',
                                    fontStyle: 'normal',
                                    fontWeight: '600',
                                    lineHeight: 'normal',
                                  }}>
                                  $575
                                </span>
                                <Button
                                  className="new-btn btn-green ml-2"
                                  style={{
                                    width: '70px',
                                    borderRadius: '2.579px',
                                    background: '#52BD41',
                                  }}
                                  onClick={() => {
                                    setShowCustomModel(true);
                                  }}>
                                  Sign
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Scrollbars>
                    </div>
                  </div>
                </>
              }
              secondary={
                <>
                  {currentComponent === 'create-form' && (
                    <CreateAgreement
                      onClose={() => {
                        setCurrentComponent('');
                      }}
                    />
                  )}
                </>
              }
            />

            {showComponent === 'WorkSpace' && (
              <CreateWorkSpace
                onClose={() => {
                  setShowComponent('');
                }}
              />
            )}
            {showCustomModel && (
              <div className="max_height">
                <CustomModal
                  size="medium"
                  onClose={() => {
                    setShowCustomModel('');
                  }}
                  style={{maxHeight: '100% !important'}}>
                  <div>
                    <h1 style={CustomModelStyle}>
                      [Company Name] wants to access your ROI Account
                    </h1>
                    <div style={CustomModelBg}>
                      <p style={CustomModelStyle2} className="comany_title">
                        Make sure you trust [Company Name]
                      </p>
                      {data.map((field, index) => (
                        <div key={index} className="info-container">
                          {/* <h2>{field.type} Information</h2> */}
                          <ul
                            className="no_style"
                            style={{
                              listStyle: 'inherit !important',
                              paddingLeft: '31px',
                              color: '#262626',

                              fontFamily: 'Inter',
                              fontSize: '16px',
                              fontStyle: 'normal',
                              fontWeight: '500',
                              lineHeight: '24px',
                            }}>
                            <li>{field.label}</li>
                          </ul>
                        </div>
                      ))}
                      <div className="mt-2 ">
                        <h1 style={CustomModelStyle2}>
                          Make sure you trust [Company Name]
                        </h1>
                        <p style={CustomModelStyleDes}>
                          You may be sharing sensitive info with this business.
                          Learn about how [company name] will handle your data
                          by reviewing its terms of service and privacy
                          policies. You can always remove access in your{' '}
                          <spn
                            style={{
                              color: '#52BD41',
                              fontfamily: 'Inter',
                              fontSize: '15px',
                              fontStyle: 'normal',
                              fontWeight: '600',
                              lineHeight: '24px',
                            }}>
                            ROI Account
                          </spn>
                        </p>
                        <h1
                          style={{
                            color: '#52BD41',
                            fontfamily: 'Inter',
                            fontSize: '15px',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            lineHeight: '24px',
                            paddingTop: '10px',
                            paddingBottom: '10px',
                            paddingLeft: '14px',
                          }}>
                          Learn about risks
                        </h1>
                      </div>
                      <div style={{marginTop: '2px'}}>
                        <div
                          style={{
                            borderRadius: '0px 0px 3px 3px',
                            background: '#F5F9F0',
                            flexShrink: '0',
                            width: '595px',
                            height: '45px',
                            marginLeft: '1px',
                            marginBottom: '10px',
                          }}>
                          <div
                            className="d-flex justify-content-between"
                            style={{marginTop: '2px'}}>
                            <span
                              style={{
                                color: '#262626',
                                fontFamily: 'Inter',
                                fontSize: '15px',
                                fontstyle: 'normal',
                                fontWeight: '600',
                                lineHeight: 'normal',
                                // marginLeft: '17px',
                                marginTop: '12px',
                                margin: '11px',
                              }}>
                              Cancel
                            </span>
                            <Button
                              style={{
                                // color: '#FFF',
                                fontFamily: 'Inter',
                                fontSize: '15px',
                                fontStyle: 'normal',
                                fontWeight: '600',
                                lineHeight: 'normal',
                                width: ' 81px',
                                marginLeft: '418px',
                                height: '38px',
                                paddingTop: '9px',
                                paddingLeft: '19px',
                                margin: '4px',
                              }}>
                              Allow
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </CustomModal>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AgreementProposal;
