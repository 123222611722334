import React, {useState} from 'react';
import {Scrollbars} from 'react-custom-scrollbars';
import {DiscoverBuilders} from '../../Components/';

import Slider from 'react-slick';
import Sidebar from '../../Pages/Sidebar.js';
import {SliderRideShare} from '../../Constants/AnalyticsConstants';
import {Pannels} from '../../Components';
import {Form} from 'react-bootstrap';
import CreateWorkSpace from '../../Components/WorkSpace/CreateWorkSpace';
import {GoogleMap} from '@react-google-maps/api';
import RideDetail from './RideDetail.js';

const RideShareHome = props => {
  const [refresh, setRefresh] = useState([]);
  const [showComponent, setShowComponent] = useState('');
  const [analycticsSlider, setAnalycticsSlider] = useState(false);

  const [showHiddenPanel, toggleHiddenPanel] = useState(false);

  const rewardSlider = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
  };

  const dynamicStyle = {
    padding: 0,
  };

  const defaultCenter = {
    lat: 31.5497,
    lng: 74.3436,
  };
  const options = {
    disableDefaultUI: true,
    zoomControl: false,
  };
  const mapContainerStyle = {
    width: '100%',
    height: 350,
  };
  return (
    <div className="page-wrapper">
      {!props.eventButton && (
        <Sidebar
          // setWorkSpaceItem={setWorkSpaceItem}
          setWorkSpaceItem={setRefresh}
          refresh={refresh}
          location={props.location}
          setShowComponent={setShowComponent}
          showComponent={showComponent}
        />
      )}

      <div className="page-content-wrapper">
        <div className="sessions-list-wrapper slider-screen-main ride_share_screen">
          <div className="Reward_main analytics_slider_bg">
            <span
              className="analytics_slider_heading "
              style={{color: analycticsSlider ? '' : '#999999'}}
              onClick={() => {
                setAnalycticsSlider(!analycticsSlider);
              }}>
              Analytics Row
            </span>

            {analycticsSlider && (
              <div className="reward-top mt-3 mb-2">
                <Slider {...rewardSlider}>
                  {SliderRideShare.length > 0 &&
                    SliderRideShare.map((item, index) => (
                      <div className="reward-main">
                        <div className="reward-card">
                          <span style={{fontWeight: 700, fontSize: '13px'}}>
                            {item.name}
                          </span>
                          <span
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}>
                            <span style={{fontSize: '17px'}}>{item.money}</span>
                            {item.name === 'Ratings' && (
                              <img src={item.svgIcon} alt="" />
                            )}
                            {item.name === 'Conversion' ? (
                              <i
                                className="fa-solid fa-arrow-down"
                                style={{color: '#BD4141'}}></i>
                            ) : (
                              <i
                                class="fa-solid fa-arrow-up"
                                style={{color: '#52bd41'}}></i>
                            )}
                          </span>
                          <div class="message-date-info">
                            <span style={{color: '#333'}} class="message-date">
                              {item.time}
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                </Slider>
              </div>
            )}
          </div>

          <>
            <div className="add_border"></div>
            <Pannels
              isFloated={false}
              showHiddenPanel={showHiddenPanel}
              updateHiddenPanelState={nState => toggleHiddenPanel(nState)}
              customCss={dynamicStyle}
              primary={
                <>
                  {/* {showComponent === 'firstVist' && (
                        <OpportunityScreen
                          onClose={() => {
                            setShowComponent('');
                          }}
                          type={'session_event'}
                          setButton={setButton}
                          setPlusButton={setPlusButton}
                        />
                      )} */}
                  {showComponent !== 'firstVist' && (
                    <>
                      <div className="primary_panel_content">
                        <div className="refferal-list-wrapper">
                          <Scrollbars autoHide>
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                              }}>
                              <div className="serach-filter-wrapper ">
                                {/* <div className='sidebar-modal_zero_padding'> */}
                                <GoogleMap
                                  mapContainerStyle={mapContainerStyle}
                                  zoom={10}
                                  center={defaultCenter}
                                  options={options}
                                  // onLoad={onMapLoad}
                                  // onUnmount={() => setMap(null)}
                                ></GoogleMap>
                              </div>

                              <div className="rider_container">
                                <div
                                  style={{
                                    // position: 'absolute',
                                    // top: '310px',
                                    borderRadius: '10.029px 10.029px 0px 0px',
                                    background:
                                      'linear-gradient(180deg, #fbfffb 0%, #fdfefc 100%)',
                                    /* box-shadow: 0px 4.012px 25.073px 1.003px rgba(0, 0, 0, 0.56); */
                                    width: '100%',
                                  }}>
                                  <div style={{paddingTop: ''}}>
                                    {/* <div className=""> */}
                                    <DiscoverBuilders />
                                    {/* </div> */}
                                  </div>
                                  <div
                                    style={{
                                      marginLeft: '22px',
                                      paddingBottom: '25px',
                                    }}>
                                    <div style={{position: 'relative'}}>
                                      <h3>Hey Jeff</h3>
                                    </div>
                                    <div
                                      style={{position: 'relative'}}
                                      className="mt-2">
                                      <span>
                                        Where would you like Jack to take you?
                                      </span>
                                    </div>
                                    <div>
                                      <div className="search-tabs-wrapper  mt-0">
                                        <div className="search-form d-flex mt-0">
                                          <Form.Group className="search-input-location">
                                            <Form.Control
                                              style={{
                                                boxShadow:
                                                  ' 0px 1.003px 4.012px 0px rgba(0, 0, 0, 0.25)',
                                                borderRadius: '8.023px',
                                              }}
                                              onChange={e => {}}
                                              type="text"
                                              placeholder="Enter location"
                                            />
                                          </Form.Group>
                                          {/* <Button> */}
                                          <div
                                            style={{
                                              // paddingLeft: '66px',
                                              // paddingTop: '10px',
                                              borderRadius: '29.067px',
                                              border: '1.003px solid #E2E2E2',
                                              width: '96.282px',
                                              height: '39.114px',
                                              display: 'flex',
                                              alignItems: 'center',
                                              justifyContent: 'center',
                                              gap: '6px',
                                              marginTop: '14px',

                                              marginLeft: '52px',
                                            }}>
                                            <i className="fa-solid fa-calendar-days" />{' '}
                                            Now
                                          </div>

                                          {/* </Button> */}
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      style={{position: 'relative'}}
                                      className="mt-2"
                                      onClick={() => {
                                        setShowComponent('rideDetail');
                                      }}>
                                      <span className="clear-btn">
                                        Schedule Round Trip
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Scrollbars>
                        </div>
                      </div>
                    </>
                  )}
                </>
              }
              secondary={<>
              
              {showComponent === 'rideDetail' && (
                        
                        <RideDetail
                          onClose={() => {
                            setShowComponent('');
                          }}
                        />
                       
                      )}
              
              </>}
            />

            {showComponent === 'WorkSpace' && (
              <CreateWorkSpace
                onClose={() => {
                  setShowComponent('');
                }}
              />
            )}
          </>

          {/* </div> */}
        </div>
      </div>
    </div>
  );
};

export default RideShareHome;
