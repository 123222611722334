import React, {useState, useEffect} from 'react';

import {Button, Form, Dropdown} from 'react-bootstrap';
import commonApi from '../../../Services/CommonService';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import {geocodeByPlaceId} from 'react-google-places-autocomplete';
import {getLatLng} from 'react-google-places-autocomplete';
import $ from 'jquery';
import {errorMessage} from '../../../Utils/ToastMessages';
import LoaderSpinner from '../../Modals/LoaderSpinner';
import {useSelector} from 'react-redux';
import {checkPermissions} from '../../../Utils/commonUtils';

const General = props => {
  const [locationTitle, setLocationTitle] = useState('');
  const [location, setLocation] = useState('');
  const [companyRoles, setCompanyRoles] = useState([]);
  const [industryList, setIndustryList] = useState(
    props.industryListfromRoiTemplate ? props.industryListfromRoiTemplate : [],
  );
  const [validated, setValidated] = useState(false);
  const [catError, setCatError] = useState(false);
  const [locationItem, setLocationItem] = useState('');
  const menuPermesions = useSelector(state => state.menuPermesionList);

  const BusinnesProfilePermessions =
    menuPermesions && checkPermissions(menuPermesions, 'Business Profile');

  const [isCheckValue, setIsCheckValue] = useState([]);

  const removeLocation = id => {
    props.setLocationArray(
      props.locationArray.filter(item => item.latitude !== id),
    );
  };

  console.log('secondaryPOCs', props.secondaryPOCs);
  console.log('props.poc', props.secondaryPOCs);
  const addSecondaryPOC = () => {
    // alert('')

    props.setShowModel('addPocs');
    // const newPOC = {poc_name: '', poc_email: '', poc_phone: '', poc_role: '1'}; // Default values
    // props.setSecondaryPOCs([...props.secondaryPOCs, newPOC]);
  };

  // useEffect(() => {
  //   if (location.hasOwnProperty('label') && locationTitle != '') {
  //     locationData();
  //   } else {
  //     if (locationTitle == '' && location.hasOwnProperty('label')) {
  //       errorMessage('Please enter the title ');
  //     }
  //   }
  // }, [location]);

  const locationData = () => {
    var place_id = !location ? '' : location.value.place_id;
    // console.log("place_id place_id place_id ",place_id+""+ )
    var perfectAddress = !location.label ? '' : location.label;
    if (place_id && perfectAddress) {
      geocodeByPlaceId(place_id)
        .then(results => getLatLng(results[0]))
        .then(({lat, lng}) => {
          // console.log("Successfully got latitude and longitude", { lat,-lng });
          var adress = {
            location: perfectAddress,
            latitude: lat,
            longitude: lng,
            title: locationTitle,
          };

          props.setLocationArray(curr => [...curr, adress]);
          props.setCompanyData(prevData => ({
            ...prevData,
            location: [...prevData.location, adress],
          }));

          setLocation('');
          setLocationTitle('');
          $('.css-1uccc91-singleValue').html('Type city and state');
        });
    }
  };

  const handleClick = e => {
    const {id, value, checked} = e.target;
    props.setIsCheck([...props.isCheck, id]);
    setIsCheckValue([...isCheckValue, value]);
    if (!checked) {
      props.setIsCheck(props.isCheck.filter(item => item !== id));
    }
    if (!checked) {
      setIsCheckValue(isCheckValue.filter(item => item !== value));
    }
  };

  useEffect(() => {}, []);

  useEffect(() => {
    // props.setshowLoader(true);
    setTimeout(() => {
      // getCompany();

      getSystemRoles();
      if (props.industryListfromRoiTemplate.length === 0) {
        get_workspace_types();
      }
    }, 1000);
  }, [props.hitApi]);
  const get_workspace_types = () => {
    commonApi
      .get_workspace_types()
      .then(res => {
        if (res.status === 200) {
          setIndustryList(res.workspace_types);
          // setTimeout(() => {
          //   if (props.companyData.industry == '') {
          //     props.setCompanyData({
          //       ...props.companyData,
          //       industry: res.workspace_types[0].id,
          //     });
          //   }
          // }, 5000);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  const getSystemRoles = () => {
    commonApi
      .get_user_roles()
      .then(res => {
        if (res.status === 200) {
          if (res.user_roles?.length > 0) {
            setCompanyRoles(res.user_roles);
            // setTimeout(() => {
            //   if (props.pocData.role == '') {
            //     props.setPocData({
            //       ...props.pocData,
            //       role: res.user_roles[0].id,
            //     });
            //   }
            //   if (props.secData.role == '') {
            //     props.setSecData({
            //       ...props.secData,
            //       role: res.user_roles[0].id,
            //     });
            //   }
            // }, 5000);
          }
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  useEffect(() => {
    if (!props.isChecked) {
      props.setLocationArray([]);
      setLocation('');
      setLocationTitle('');
      props.setCompanyData(prevData => ({
        ...prevData,
        location: [],
      }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isChecked]);
  useEffect(() => {}, [props.hitApiData]);

  const handle = event => {
    const form = event.currentTarget;
    if (
      form.checkValidity() === false ||
      props.companyNameExist ||
      props.isCheck.length < 3
    ) {
      event.preventDefault();
      event.stopPropagation();
      console.log('------------------------');

      if (props.isCheck.length < 3) {
        setCatError(true);
      }

      setValidated(true);
    } else {
      setCatError(false);
      event.preventDefault();
      props.handleSave();

      // referOutside();
      // alert('d')
      // checkInputType();

      //
    }
  };

  // const getCompany = () => {
  //   var payload = {
  //     page: '1',
  //   };
  //   commonApi
  //     .get_company(payload)
  //     .then(res => {
  //       setTimeout(() => {
  //         setshowLoader(false);
  //       }, 1000);
  //       // console.log('_getCompany-----------------', res);
  //       if (res.data?.length > 0) {
  //         const activeItems = res.data.filter(
  //           item => item.user_status === 'active',
  //         );
  //         console.log('activeItems', activeItems);
  //         props.setCompanyData({
  //           name: activeItems[0].company_name,
  //           industry:
  //             activeItems[0].company_type_id == ''
  //               ? '1'
  //               : activeItems[0].company_type_id,
  //           no_of_employes: activeItems[0].no_of_members,
  //           billing_email: activeItems[0].company_email,
  //           location: activeItems[0].locations,
  //           id: activeItems[0].id,
  //           cc_user_ids:
  //             activeItems[0].cc_user_ids == ''
  //               ? []
  //               : activeItems[0].cc_user_ids.split(','),
  //           show_cc_user_array: activeItems[0].company_users,
  //         });
  //         if (activeItems[0].locations.length > 0) {
  //           props.setIsChecked(true);
  //           props.setLocationArray(activeItems[0].locations);
  //         }
  //         props.setPocData({
  //           name: activeItems[0].poc_name,
  //           email: activeItems[0].email,
  //           phone_no: activeItems[0].poc_phone,
  //           role: activeItems[0].poc_role == '' ? '1' : activeItems[0].poc_role,
  //         });

  //         props.setSecData({
  //           name: activeItems[0].s_poc_name,
  //           email: activeItems[0].s_email,
  //           phone_no: activeItems[0].s_poc_phone,
  //           role:
  //             activeItems[0].s_poc_role == '' ? '1' : activeItems[0].s_poc_role,
  //         });
  //         if (
  //           activeItems[0].s_poc_name != '' ||
  //           activeItems[0].s_poc_name != '' ||
  //           activeItems[0].s_poc_phone != '' ||
  //           activeItems[0].s_poc_role != ''
  //         ) {
  //           props.setSecondary(true);
  //         }
  //         props.setCompanydetail(activeItems);
  //       }
  //     })
  //     .catch(err => {
  //       console.log('Err', err);
  //       return {type: 'error', message: err.message};
  //     });
  // };

  const handleSecondaryPOCChange = (index, field, value) => {
    const updatedPOCs = [...props.secondaryPOCs];
    updatedPOCs[index][field] = value;
    props.setSecondaryPOCs(updatedPOCs);
  };

  const deleteSecondaryPOC = (index, item) => {
    // console.log(item,index)
    // alert(index)

    const updatedPOCs = [...props.secondaryPOCs];
    updatedPOCs.splice(index, 1);
    props.setSecondaryPOCs(updatedPOCs);

    if (item && item.id) {
      // Check if item exists and has an id
      props.setDeleteIDs([...props.deleteIDs, item.id]); // Add ID to delete list
    }
  };
  const check_company_name = name => {
    const payload = {
      company_name: name,
      company_id: props.companyData.id,
    };

    commonApi
      .check_company_name(payload)
      .then(res => {
        if (res.status === 200) {
          props.setCompanyNameExist(res.company_exist);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  useEffect(() => {
    if (props.deleteItem) {
      removeLocation(locationItem);
    }

    setLocationItem('');
    props.setDeleteItem(false);
  }, [props.deleteItem]);

  // console.log('companyData', props.companyData.id);
  // console.log('props.secondaryPOCs', props.secondaryPOCs);
  // console.log('props.companyRoles', props.companyRoles);
  // console.log('props.isCheck', props.isCheck);
  // console.log('pprops.isCheckValue', props.isCheckValue);
  // console.log('props.companyRoles', props.isCheckValue.length === props.categories.length);

  return (
    <>
      <div className="fix-width-template">
        {props.showLoader && <LoaderSpinner />}

        <Form noValidate validated={validated} onSubmit={handle}>
          <div className="general">
            <div className="company-details mt-4">
              <div className="general-top ">
                <h1>Company Details</h1>

                <div className="">
                  Multiple Locations
                  <Form.Check
                    // disabled={props.id ? true : false}
                    className=""
                    type="switch"
                    id="custom-switch"
                    // label={'Multiple Locations'}
                    value={props.isChecked}
                    checked={props.isChecked}
                    onClick={() => {
                      props.setIsChecked(!props.isChecked);
                    }}
                  />
                </div>
              </div>
              <div className="general-input-container mt-4">
                <div className="input-group">
                  <Form.Label controlid="validationCustom01">
                    Company Name
                  </Form.Label>

                  <Form.Control
                    type="text"
                    value={props.companyData.name}
                    pattern="^\S.*$"
                    required
                    placeholder=""
                    onChange={e => {
                      props.setCompanyData({
                        ...props.companyData,
                        name: e.target.value,
                      });
                      check_company_name(e.target.value);
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    Company name is required.
                  </Form.Control.Feedback>
                  {props.companyNameExist === true && (
                    <div className="text-danger textforerror">
                      Company name already exist
                    </div>
                  )}
                </div>
                <div className="input-group industry_dropdown">
                  <Form.Label> Industry</Form.Label>
                  <Dropdown
                    className="select-wrapper"
                    onSelect={(eventKey, event) => {
                      props.setCompanyData({
                        ...props.companyData,
                        industry: eventKey,
                      });
                      props.handleCategories(eventKey);
                      setCatError(false);
                    }}
                    drop="down">
                    <Dropdown.Toggle variant="primary">
                      {props.companyData.industry &&
                        industryList?.length > 0 &&
                        industryList.find(
                          item => item.id === props.companyData.industry,
                        )?.name}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {industryList.map((item, index) => (
                        <Dropdown.Item
                          key={`sessionCategories-${index}`}
                          eventKey={item.id}
                          active={item.id === props.companyData.industry}>
                          {item.name}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>

                {/* <div className="input-group">
                <Form.Label controlid="validationCustom01">Industry</Form.Label>

                <Form.Control
                  type="text"
                  value={props.companyData.industry}
                  pattern="^\S.*$"
                  required
                  placeholder=""
                  onChange={e => {
                    props.setCompanyData({
                      ...props.companyData,
                      industry: e.target.value,
                    });
                  }}
                />
                <Form.Control.Feedback type="invalid">
                  Industry is required.
                </Form.Control.Feedback>
              </div> */}
              </div>
              <div className="general-input-container mt-4">
                <div className="input-group ">
                  <Form.Label>Select Categories</Form.Label>
                  <div className="d-flex align-items-center justify-content-between cutsom-control-menu">
                    <Form.Control
                      className="as-link"
                      readOnly={'readOnly'}
                      // value={isCheckValue.length > 0 ? isCheckValue.join(', ') : "" }
                      defaultValue={
                        props.isCheckValue.length > 0
                          ? props.isCheckValue.join(',')
                          : ''
                      }
                      required
                      placeholder="Select Categories"
                      onClick={() => props.setShowComponent('categories')}
                    />
                    <span className="drop-icon my-custom-drop-icon-holder as-link"
                    onClick={() => props.setShowComponent('categories')}>
                      <i class="fa-solid fa-chevron-down"></i>
                    </span>
                  </div>
                  {((props.isCheckValue.length === 0 && catError === true) ||
                    (props.isCheckValue.length < 3 && catError === true)) && (
                    <div className="text-danger textforerror">
                      {props.isCheck.length > 0
                        ? 'At least 3 Categories is required'
                        : 'Categories is required'}
                    </div>
                  )}
                </div>
                <div className="input-group">
                  <Form.Label controlid="validationCustom01">
                    No of Employees
                  </Form.Label>

                  <Form.Control
                    style={{marginBottom: catError ? '22px' : ''}}
                    type="number"
                    value={props.companyData.no_of_employes}
                    pattern="^\S.*$"
                    // required
                    placeholder=""
                    onChange={e => {
                      props.setCompanyData({
                        ...props.companyData,
                        no_of_employes: e.target.value,
                      });
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    Employees is required.
                  </Form.Control.Feedback>
                </div>
              </div>

              <div
                className="general-input-container mt-4"
                style={{width: '50%'}}>
                <div className="input-group">
                  <div className="email-title">
                    <Form.Label controlid="validationCustom01">
                      Billing Email
                    </Form.Label>

                    <span
                      className="as-link"
                      onClick={() => {
                        props.setShowComponent('ccUser');
                      }}>
                      Add CC
                    </span>
                  </div>

                  <Form.Control
                    type="emial"
                    value={props.companyData.billing_email}
                    pattern="^\S.*$"
                    // required
                    placeholder=""
                    onChange={e => {
                      props.setCompanyData({
                        ...props.companyData,
                        billing_email: e.target.value,
                      });
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    Email is required.
                  </Form.Control.Feedback>
                </div>
              </div>

              {props.isChecked && (
                <>
                  <div className="mt-3">
                    <h1> Multiple Locations</h1>
                  </div>
                  <div className="general-input-container mt-4">
                    <div className="input-group">
                      <Form.Label controlid="validationCustom01">
                        Title
                      </Form.Label>

                      <Form.Control
                        type="text"
                        value={locationTitle}
                        pattern="^\S.*$"
                        required={location.hasOwnProperty('label')}
                        placeholder=""
                        onChange={e => {
                          setLocationTitle(e.target.value);
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        Title is required.
                      </Form.Control.Feedback>
                    </div>

                    <div className="input-group">
                      <div className="location-title">
                        <Form.Label>Location</Form.Label>
                        <span
                          className="location_plus"
                          onClick={() => {
                            if (
                              location.hasOwnProperty('label') &&
                              locationTitle !== ''
                            ) {
                              locationData();
                            } else {
                              if (
                                locationTitle === '' &&
                                location.hasOwnProperty('label')
                              ) {
                                errorMessage('Please enter  title ');
                              } else if (
                                locationTitle &&
                                !location.hasOwnProperty('label')
                              ) {
                                errorMessage('Please enter  location ');
                              } else {
                                errorMessage(
                                  'Please enter  title and location ',
                                );
                              }
                            }
                          }}>
                          <i className="fa-solid fa-plus"></i>
                        </span>
                      </div>

                      <GooglePlacesAutocomplete
                        location
                        selectProps={{
                          location,
                          onChange: setLocation,
                          name: 'city',
                          placeholder: 'Type city and state',
                          noOptionsMessage: () => null,
                        }}
                      />
                    </div>
                  </div>
                  {Object.entries(props.locationArray).length > 0 && (
                    <div className="mt-2">
                      <h3>Locations</h3>
                      <table className="location-table">
                        <thead>
                          <tr>
                            <th>Title</th>
                            <th>Location</th>
                            <th>Delete</th>
                          </tr>
                        </thead>
                        <tbody>
                          {Object.entries(props.locationArray).length > 0 &&
                            props.locationArray.map((item, index) => (
                              <tr
                                key={`label-${index}`}
                                className="location-item">
                                <td
                                  onClick={() => {
                                    removeLocation(item.latitude);
                                    // setShowModel(true);
                                  }}>
                                  {item.title}
                                </td>
                                <td
                                  onClick={() => {
                                    removeLocation(item.latitude);
                                    // setShowModel(true);
                                  }}>
                                  {item.location}
                                </td>
                                <td>
                                  <i
                                    className="fas fa-times as-link"
                                    onClick={() => {
                                      // removeLocation(item.latitude)
                                      setLocationItem(item.latitude);
                                      props.setShowModel('deleteItem');
                                    }}
                                  />
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </>
              )}
            </div>

            <div className="company-details mt-4">
              <div className="general-top ">
                <h1>Billing POC</h1>
              </div>
              <div className="general-input-container mt-4">
                <div className="input-group">
                  <Form.Label controlid="validationCustom01">Name</Form.Label>

                  <Form.Control
                    type="text"
                    value={props.pocData.name}
                    pattern="^\S.*$"
                    required
                    placeholder=""
                    onChange={e => {
                      props.setPocData({
                        ...props.pocData,
                        name: e.target.value,
                      });
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    Name is required.
                  </Form.Control.Feedback>
                </div>

                <div className="input-group">
                  <Form.Label controlid="validationCustom01">Email</Form.Label>

                  <Form.Control
                    type="email"
                    value={props.pocData.email}
                    pattern="^\S.*$"
                    required
                    placeholder=""
                    onChange={e => {
                      props.setPocData({
                        ...props.pocData,
                        email: e.target.value,
                      });
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    Email is required.
                  </Form.Control.Feedback>
                </div>
              </div>
              <div className="general-input-container mt-4">
                <div className="input-group">
                  <Form.Label controlid="validationCustom01">
                    Phone Number
                  </Form.Label>

                  <Form.Control
                    type="number"
                    value={props.pocData.phone_no}
                    pattern="^\S.*$"
                    required
                    placeholder=""
                    onChange={e => {
                      props.setPocData({
                        ...props.pocData,
                        phone_no: e.target.value,
                      });
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    Phone number is required.
                  </Form.Control.Feedback>
                </div>
                <div className="input-group">
                  <Form.Label> Role</Form.Label>
                  <Dropdown
                    className="select-wrapper"
                    onSelect={(eventKey, event) => {
                      props.setPocData({
                        ...props.pocData,
                        role: eventKey,
                      });
                    }}
                    drop="down">
                    <Dropdown.Toggle variant="primary">
                      {props.pocData.role &&
                        companyRoles?.length > 0 &&
                        companyRoles.find(
                          item => item.id === props.pocData.role,
                        ).role_name}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {companyRoles.map((item, index) => (
                        <Dropdown.Item
                          key={`sessionCategories-${index}`}
                          eventKey={item.id}
                          active={item.id === props.pocData.role}>
                          {item.role_name}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>

            {/* {props.secondaryPOCs.map((poc, index) => (
        <SecondaryPOC
          key={index} // This key is for React's internal reconciliation
          data={poc}
          handleChange={(field, value) =>
            handleSecondaryPOCChange(index, field, value)
          }
          handledeleteSecondaryPOC={(item) => deleteSecondaryPOC(index, item)} // Pass index separately
          companyRoles={companyRoles}
        />
      ))} */}

            <div className="d-flex gap-3">
              {BusinnesProfilePermessions &&
                (BusinnesProfilePermessions === 'allow' ||
                  BusinnesProfilePermessions?.add_poc === true) && (
                  <div className="add-secondary-poc">
                    <span
                      className="poc-btn"
                      onClick={() => {
                        props.setShowComponent('addPocs');
                      }}>
                      Add POC
                    </span>
                  </div>
                )}
              {props.secondaryPOCs.length > 0 && (
                <div className="add-secondary-poc">
                  <span
                    className="poc-btn"
                    onClick={() => {
                      props.setShowComponent('viewPocs');
                    }}>
                    View POCs
                  </span>
                </div>
              )}
            </div>

            {/* {props.secondary && (
            <>
              <div className="company-details mt-4">
                <div className="general-top ">
                  <h1>Secondary POC</h1>
                </div>
                <div className="general-input-container mt-4">
                  <div className="input-group">
                    <Form.Label controlid="validationCustom01">Name</Form.Label>

                    <Form.Control
                      type="text"
                      value={props.secData.name}
                      pattern="^\S.*$"
                      required
                      placeholder=""
                      onChange={e => {
                        props.setSecData({
                          ...props.secData,
                          name: e.target.value,
                        });
                      }}
                    />
                    <Form.Control.Feedback type="invalid">
                      Name is required.
                    </Form.Control.Feedback>
                  </div>

                  <div className="input-group">
                    <Form.Label controlid="validationCustom01">
                      Email
                    </Form.Label>

                    <Form.Control
                      type="text"
                      value={props.secData.email}
                      pattern="^\S.*$"
                      required
                      placeholder=""
                      onChange={e => {
                        props.setSecData({
                          ...props.secData,
                          email: e.target.value,
                        });
                      }}
                    />
                    <Form.Control.Feedback type="invalid">
                      email is required.
                    </Form.Control.Feedback>
                  </div>
                </div>
                <div className="general-input-container mt-4">
                  <div className="input-group">
                    <Form.Label controlid="validationCustom01">
                      Phone Number
                    </Form.Label>

                    <Form.Control
                      type="text"
                      value={props.secData.phone_no}
                      pattern="^\S.*$"
                      required
                      placeholder=""
                      onChange={e => {
                        props.setSecData({
                          ...props.secData,
                          phone_no: e.target.value,
                        });
                      }}
                    />
                    <Form.Control.Feedback type="invalid">
                      Name is required.
                    </Form.Control.Feedback>
                  </div>
                  <div className="input-group">
                    <Form.Label> Role</Form.Label>
                    <Dropdown
                      className="select-wrapper"
                      onSelect={(eventKey, event) => {
                        props.setSecData({
                          ...props.secData,
                          role: eventKey,
                        });
                      }}
                      drop="down">
                      <Dropdown.Toggle variant="primary">
                        {props.secData.role &&
                          companyRoles?.length > 0 &&
                          companyRoles.find(item => item.id == props.secData.role)
                            ?.role_name}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        {companyRoles.map((item, index) => (
                          <Dropdown.Item
                            key={`sessionCategories-${index}`}
                            eventKey={item.id}
                            active={item.id == props.secData.role}>
                            {item.role_name}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              </div>
            </>
          )} */}
          </div>

          <Button
            type="submit"
            style={{display: 'none'}}
            ref={props.buttonRef}></Button>
        </Form>
      </div>
    </>
  );
};

export default General;
