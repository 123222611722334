import React, {useState, useEffect} from 'react';
import {Button, Form} from 'react-bootstrap';

import BadgeCheck from '../../Svg/BadgeCheck';
import {DEFAULT_IMAGE_COMPANYLOGO} from '../../Constants/Images';
import ProfileGallery from '../ProfilePage/ProfileGallery';
import {FormattedText} from '../../Utils/Helpers';
import {labels} from '../../Constants/Translations';
import {ENV_HOST, selected_lang} from '../../Utils/env';
import {rewardStar} from '../../Constants/Images';
import {ShareablesBlackIcon} from '../../Constants/Images';
import commonApi from '../../Services/CommonService';
import Scrollbars from 'react-custom-scrollbars';
import SlidingPanel from '../SlidingPanel';
import {DEFAULT_IMAGE} from '../../Utils/env';
import SnapshotDetail from '../Snapshot/SnapshotDetail';
import TransformDetail from '../Transformation/TransformDetail';
import {useSelector} from 'react-redux';
import SmartChatPage from '../Common/SmartChatPage';
import CustomModal from '../CustomModal';
import {
  arrayColumn,
  chatroomInfo,
  list,
  newSingleChatroom,
  replaceSpaceWithDot,
} from '../../Utils/commonUtils';
import {fixName} from '../../Utils/commonUtils';
import {collectingUsers} from '../../Utils/commonUtils';
import LoaderSpinner from '../Modals/LoaderSpinner';
import {successMessage} from '../../Utils/ToastMessages';
import SocialLinkModel from '../Modals/SocialLinkModel';

const CompanyProfile = props => {
  const [showMoreBio, setShowMoreBio] = useState(false);
  const [showPocs, setShowPocs] = useState([]);
  const [selectedPoc, setSelectedPoc] = useState([]);
  const [snapshotId, setSnapshotId] = useState({});
  const [filterCat, setFilterCat] = useState([]);
  const [categoriesArr, setCategoriesArr] = useState([]);

  const [chatType, setChatType] = useState([]);
  const [chatDetails, setChatDetails] = useState([]);
  const [allChatUsers, setAllChatUsers] = useState([]);
  const [chatRoomName, setChatRoomName] = useState('');
  const [chatRoomId, setChatRoomId] = useState('');
  const [userInfo, setUserInfo] = useState([]);
  const [dataofTransFormation, setDataofTransFormation] = useState([]);
  const snapshotCategories = useSelector(
    state => state.accomplishmentCategories,
  );
  const [prevState, setPrevState] = useState('');
  const [companyProfileData, setCompanyProfileData] = useState({});
  const [showDataOfSingleChat, setShowDataOfSingleChat] = useState([]);
  const showMoreCompanyBio = showMoreBio ? 'show-all' : '';
  const [showLoader, setShowLoader] = useState(true);
  const [userExist, setUserExist] = useState(false);
  const [currentComponent, setCurrentComponent] = useState('');
  useEffect(() => {
    // setShowLoader(true)
    getUserData(props.company.created_by);
    getCompanyData(props.company.id);
  }, [props.company]);

  const [showComponent, setShowComponent] = useState({
    followers: false,
    following: false,
    snapshot: false,
    opportunity: false,
    session: false,
    appointment: false,
    mineAppointment: false,
    praise: false,
    circle: false,
    share: false,
    referOpportunity: false,
    chat: false,
    profilenotes: false,
    personal: false,
    Profile_view: false,
    transformation: false,
    createTransform: false,
    snapshot_filter: false,
    addNewSnapshot: false,
    morePocs: false,
    single_pocs: false,
    poc_chat: false,
    social_links: false,
  });

  // const handleComponents = (data, type) => {
  //   if (type === 'snapshot') {
  //     showSnapshotDetail(data);
  //   } else if (type === 'transformationDetail') {
  //     setDataFroNextComponent(data);
  //     setSecondaryComponent('transformationDetail');
  //   } else if (type === 'snapshot_filter') {
  //     setSecondaryComponent('snapshot_filter');
  //   }
  // };

  const handleSnapshotSelection = event => {
    const {value, checked} = event.target;
    const itemObj = JSON.parse(value);

    if (itemObj.id === 'All') {
      //   setCategoriesName(['All']);
      if (!checked) {
        setCategoriesArr([]);
      } else {
        let categories = arrayColumn(snapshotCategories, 'id');
        setCategoriesArr(categories);
      }
    } else {
      if (checked) {
        // setCategoriesName(prev => [...prev, itemObj.title]);
        setCategoriesArr(prev => [...prev, itemObj.id]);
      } else {
        // setCategoriesName(prev =>
        //   prev.filter(title => title !== itemObj.title),
        // );
        setCategoriesArr(prev => prev.filter(id => id !== itemObj.id));
      }
    }
  };

  const showSnapshotDetail = ssId => {
    var payLoad = {
      accomplishment_id: ssId,
    };
    commonApi
      .detail_accomplishment(payLoad)
      .then(response => {
        if (response.status === 200) {
          setSnapshotId(response.accomplishment_detail);
          setShowComponent({...showComponent, snapshot: true});
        }
      })
      .catch(error => {
        console.log('Err', error);
        return {type: 'error', message: error.message};
      });
  };

  const handleComponents = (id, type) => {
    // setSnapshotId(id);
    if (type === 'snapshot') {
      showSnapshotDetail(id);
    } else if (type === 'referOpportunity') {
      // setOpportunityItemData(id);

      setShowComponent({...showComponent, referOpportunity: true});
      //
    } else if (type === 'transformationDetail') {
      setDataofTransFormation(id);
      setShowComponent({...showComponent, transformation: true});
    } else if (type === 'snapshot_filter') {
      setShowComponent({...showComponent, snapshot_filter: true});
    } else if (type === 'addNewSnapshot') {
      setShowComponent({...showComponent, addNewSnapshot: true});
    } else if (type === 'createTransform') {
      setShowComponent({...showComponent, createTransform: true});
    } else if (type === 'morePocs') {
      setCurrentComponent('morePocs')
      // setShowComponent({...showComponent, morePocs: true});
    } else if (type === 'singlePocs') {
      setShowComponent({...showComponent, single_pocs: true});
    } else if (type === 'Poc_chat_Single') {
      setShowComponent({...showComponent, poc_chat: true});
    }
  };

  const getUserData = id => {
    var data = {user_id: id};
    commonApi
      .user_info(data)
      .then(res => {
        if (res) {
          setUserInfo(res);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const create_personal_group_chat_only = (item, typeOfChat, id) => {
    const Pocids =
      item &&
      item.user_details.length > 0 &&
      item.user_details.map(obj => obj.id);

    const payLoad = {
      poc_alias: item.alias,
      poc_role: item.poc_role,
      type: 'poc_team',
      audience_ids: '',
      chat_room_name:
        'chatroom_gf' + Math.floor(100000 + Math.random() * 900000),
      group_image:
        companyProfileData.company_logo !== ''
          ? companyProfileData.company_logo
          : DEFAULT_IMAGE_COMPANYLOGO,
      group_name: item.title ? item.title : '',
      moderator_ids:
        typeOfChat === 'single'
          ? id
          : Pocids.length > 0
          ? Pocids.toString(',')
          : '',
      private_group: '',
      snap_shot_id: '',
      chat_type: typeOfChat === 'single' ? 'single' : 'group',
    };

    commonApi
      .poc_chat(payLoad)
      .then(response => {
        if (response.status === 200) {
          setCurrentComponent('')
          setPrevState('');
          setShowDataOfSingleChat(response);
          handleComponents('', 'Poc_chat_Single');

          // setCurrentComponent('Poc_chat_Single');
          // if (typeOfChat === 'single') {

          //   // getCompanyDetails('open_caht');
          // } else {
          //   getCompanyDetails('open_caht');
          // }
        }
      })
      .catch(error => {
        console.log('Err', error);
        return {type: 'error', message: error.message};
      });
  };

  const handleCommonChat = (data, type) => {};

  const getCompanyData = id => {
    var payload = {
      company_id: id,
    };

    commonApi
      .get_company_bussiness_profile(payload)
      .then(res => {
        if (res.status === 200) {
          setUserExist(res.user_exist);
          setCompanyProfileData(res.bussiness_profile);

          setShowPocs(res.bussiness_profile.poc_details);
          setTimeout(() => {
            setShowLoader(false);
          }, 300);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const AddUserInCompany = () => {
    const payload = {
      company_name: companyProfileData.company_name,
      user_id: localStorage.getItem('id'),
      inactive: 'true',
    };
    commonApi
      .add_user_in_company(payload)
      .then(res => {
        setUserExist(true);
        successMessage(res.message);
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const companyBioLines = companyProfileData.bio
    ? companyProfileData.bio?.split('\n')
    : '';

  console.log(showComponent, 'showComponentshowComponent');
  return (
    <>
      <SlidingPanel
        hidePanel={() => {
          props.onClose();
        }}>
        <div className="serach-filter-wrapper create-sessionEvents">
          {showLoader && <LoaderSpinner />}
          <Scrollbars>
            <div className="Business_profile_page fix-width-forms pb-3 Business_company_profile_holder">
              <div className="refferal-list-wrapper">
                {/* {showLoader && <LoaderSpinner />} */}

                {/* <Scrollbars autoHide> */}
                <div style={{paddingTop: '10px'}}>
                  <div className="business_profile_top">
                    <div className="company_profile_card mb-4">
                      <div className="company_logo">
                        <img
                          src={
                            companyProfileData.company_logo
                              ? companyProfileData.company_logo
                              : companyProfileData.image
                              ? companyProfileData.image
                              : DEFAULT_IMAGE_COMPANYLOGO
                          }
                          alt=""
                        />

                        <div className="badge-check">
                          <BadgeCheck fill={'#E0A168'} />
                        </div>
                      </div>

                      <div className="company_content">
                        <div className="company_name_flex">
                          <span className="company_title">
                            {companyProfileData.company_name
                              ? companyProfileData.company_name
                              : ''}
                          </span>
                        </div>

                        <div className="company_rating_content">
                          <span className="rating">
                            4.8 <img src={rewardStar} alt="" />
                          </span>

                          <img
                            src={ShareablesBlackIcon}
                            alt=""
                            className="as-link"
                            onClick={() => {
                              // setShowCompanyShareModel('show_model');
                              // social_links
                              setShowComponent({
                                ...showComponent,
                                social_links: true,
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="bio-title mb-3">
                      <FormattedText
                        className={`profile-description ${showMoreCompanyBio}`}
                        content={
                          companyProfileData.bio ? companyProfileData.bio : ''
                        }
                      />

                      {(companyProfileData.bio?.length > 250 ||
                        companyBioLines?.length > 3) && (
                        <>
                          {' '}
                          <span
                            className="view_more_btn"
                            onClick={() => setShowMoreBio(!showMoreBio)}>
                            {!showMoreBio
                              ? labels.view_more[selected_lang]
                              : labels.view_less[selected_lang]}
                            {/* {profile.data.bio.length} */}
                          </span>
                        </>
                      )}
                    </div>

                    <div className="poc_main mb-3">
                      <div className="mb-4 d-flex justify-content-between">
                        <div className="poc_name">POCs</div>
                      </div>

                      {showPocs?.length === 0 && (
                        <div className="no-results-wrapper">
                          <div className="no-results-content">
                            <p>{labels.no_record_found[selected_lang]}</p>
                          </div>
                        </div>
                      )}

                      {showPocs?.length >= 1 && (
                        <div className="customer_care_card">
                          <div className="customer_care_card_content as-link">
                            <div
                              className="d-flex justify-content-between "
                              onClick={() => {
                                setSelectedPoc(showPocs[0]);

                                handleComponents('', 'singlePocs');
                                // setCurrentComponent('singlePocs');
                              }}>
                              <h1>{showPocs[0].title}</h1>
                            </div>
                            <div className="d-flex justify-content-between align-items-end">
                              <div
                                className="customer_imgs poc_w_90"
                                onClick={() => {
                                  setSelectedPoc(showPocs[0]);
                                  handleComponents('', 'singlePocs');
                                  // setCurrentComponent('singlePocs');
                                }}>
                                <>
                                  {Object.entries(showPocs[0].user_details)
                                    .length > 0 &&
                                    showPocs[0].user_details
                                      .slice(0, 3)
                                      .map((item, index) => (
                                        <img
                                          src={
                                            item.profile_img
                                              ? item.profile_img
                                              : DEFAULT_IMAGE
                                          }
                                          alt=""
                                        />
                                      ))}

                                  {showPocs[0].user_details.length > 3 && (
                                    <div className="remaining_div">
                                      +{showPocs[0].user_details.length - 3}
                                    </div>
                                  )}
                                </>
                              </div>

                              <div className="d-flex gap-2 ">
                                <div
                                  className="poc_send_icon as-link"
                                  onClick={() => {
                                    create_personal_group_chat_only(
                                      showPocs[0],
                                      '',
                                      '',
                                    );
                                  }}>
                                  <i className="fa-solid icon-plane"></i>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      {showPocs?.length > 1 && (
                        <div
                          className="view_more_btn mt-3"
                          onClick={() => {
                            handleComponents('', 'morePocs');
                          }}>
                          View More
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="mt-2">
                    <ProfileGallery
                      type={'market'}
                      userId={props.company.created_by}
                      companyData={props.company}
                      handleCommand={handleComponents}
                      filterCat={filterCat}
                      getUserData={getUserData}
                      // permission={BusinnesProfilePermessions}

                      showComponent={showComponent}
                      fromPage={'business'}
                    />
                  </div>
                </div>
                {/* </Scrollbars> */}
              </div>
            </div>
          </Scrollbars>

          {!userExist && (
            <div className="filter-btn-wrapper fix-width-forms">
              <div className="invite-btns  p-0 pt-3 text-center">
                <Button
                  onClick={() => {
                    AddUserInCompany();
                  }}>
                  Add Shop
                </Button>
              </div>
            </div>
          )}
        </div>
      </SlidingPanel>

      {showComponent.social_links && (
        <SocialLinkModel
          link={replaceSpaceWithDot(
            'www.' +
              ENV_HOST +
              'roiforpros.com/' +
              companyProfileData.company_name,
          )}
          onClose={() =>
            setShowComponent({...showComponent, social_links: false})
          }
        />
      )}

      {showComponent.snapshot ? (
        <SnapshotDetail
          snapshotId={snapshotId}
          userData={userInfo.data}
          onClose={() => setShowComponent({...showComponent, snapshot: false})}
          showRefer={true}
          noshow={props.noshow}
        />
      ) : showComponent.chat ? (
        <>
          <SmartChatPage
            allmsg={true}
            type={chatType}
            item={[]}
            chatroomInfo={
              chatDetails.length > 0
                ? chatroomInfo(chatDetails)
                : newSingleChatroom(userInfo.data)
            }
            oppoDetails={chatDetails}
            chatDetails={chatDetails}
            allChatUsers={allChatUsers}
            chatRoomName={chatRoomName}
            chatRoomId={chatRoomId}
            onClose={() => {
              setShowComponent({...showComponent, chat: false});
            }}
            userData={userInfo.data}
            // handleCommonChat={(data, type)=>{handleCommonChat(data, type)}}
          />
        </>
      ) : showComponent.transformation ? (
        <TransformDetail
          item={dataofTransFormation}
          onClose={() => {
            setShowComponent('');
          }}
        />
      ) : showComponent.snapshot_filter ? (
        <CustomModal
          size="medium"
          className={'filter-modal from-otherProfile-Snapshot'}
          style={{height: '85%'}}
          onClose={() => {
            setShowComponent({...showComponent, snapshot_filter: false});
          }}>
          <div className="filter-wrapper custom-modal-filter mb-0">
            <div className="filter-headings">
              <h2>Filter By Category</h2>
            </div>
            <Scrollbars className="custom-scroll">
              <div className="filter-category-list">
                <ul>
                  <li
                    key={`sessionC-100`}
                    className={categoriesArr.length === 8 ? 'active' : ''}>
                    <Form.Label>
                      {'All'}
                      <input
                        type="checkbox"
                        value={'{"id":"All", "title":"All"}'}
                        onChange={handleSnapshotSelection}
                        checked={categoriesArr.length === 8}
                      />
                      <span />
                    </Form.Label>
                  </li>
                  {Object.entries(snapshotCategories).length > 0 &&
                    snapshotCategories.map((item, index) => (
                      <li
                        key={`sessionC-${index}`}
                        className={
                          categoriesArr.includes(item.id) ? 'active' : ''
                        }>
                        <Form.Label>
                          {item.category_name}
                          <input
                            type="checkbox"
                            value={
                              '{"id":"' +
                              item.id +
                              '", "title":"' +
                              item.category_name +
                              '"}'
                            }
                            onChange={handleSnapshotSelection}
                            checked={categoriesArr.includes(item.id)}
                          />
                          <span />
                        </Form.Label>
                      </li>
                    ))}
                </ul>
              </div>
            </Scrollbars>
            <div className="filter-category-list text-center">
              <Button
                className="btn-dark"
                onClick={() => {
                  setFilterCat(categoriesArr);
                  setShowComponent({
                    ...showComponent,
                    snapshot_filter: false,
                  });
                }}>
                Filter
              </Button>
            </div>
          </div>
        </CustomModal>
      ) : showComponent.poc_chat &&
        showDataOfSingleChat &&
        showDataOfSingleChat.chat_detail && (
        <SmartChatPage
          allmsg={true}
          type={'Poc_chat'}
          item={[]}
          chatroomInfo={chatroomInfo(showDataOfSingleChat.chat_detail)}
          oppoDetails={showDataOfSingleChat.chat_detail}
          chatDetails={showDataOfSingleChat.chat_detail}
          allChatUsers={collectingUsers(showDataOfSingleChat.chat_detail)}
          chatRoomName={showDataOfSingleChat.chat_detail.chat_room_name}
          chatRoomId={showDataOfSingleChat.chat_detail.id}
          onClose={() => {
            // setChatButton(false);
            setShowComponent({...showComponent, poc_chat: false});
          }}
          handleCommonChat={(data, type) => {
            handleCommonChat(data, type);
          }}
        />
      ) }
      
      
      
      
      
      
      
      {currentComponent === 'morePocs' &&  (
        <CustomModal
          size="medium"
          style={{height: '85%'}}
          onClose={() => {
            setCurrentComponent('')
            setPrevState('');
          }}>
          <div className="filter-wrapper custom-modal-filter mb-0">
            <div className="filter-headings">
              <h2>POCs</h2>
            </div>
            <Scrollbars>
              <div className="filter-category-list">
                {showPocs &&
                  prevState === '' &&
                  Object.entries(showPocs).length > 0 &&
                  showPocs.map((item, index) => (
                    <div className="customer_care_card">
                      <div className="customer_care_card_content as-link">
                        <div
                          className="d-flex justify-content-between "
                          onClick={() => {
                            setSelectedPoc(item);
                            setPrevState('morePocs');
                            setCurrentComponent('singlePocs');
                          }}>
                          <h1>{item.title}</h1>
                        </div>

                        <div className="d-flex justify-content-between align-items-end">
                          <div
                            className="customer_imgs poc_w_90"
                            onClick={() => {
                              setSelectedPoc(item);
                              setPrevState('morePocs');
                              setCurrentComponent('singlePocs');
                            }}>
                            <>
                              {Object.entries(item.user_details).length > 0 &&
                                item.user_details
                                  .slice(0, 3)
                                  .map((item, index) => (
                                    <img
                                      src={
                                        item.profile_img
                                          ? item.profile_img
                                          : DEFAULT_IMAGE
                                      }
                                      alt=""
                                    />
                                  ))}
                              {item.user_details.length > 3 && (
                                <div className="remaining_div">
                                  +{item.user_details.length - 3}
                                </div>
                              )}
                            </>
                          </div>

                          <div className="d-flex gap-2 ">
                            <div
                              className="poc_send_icon as-link"
                              onClick={() => {
                               
                                create_personal_group_chat_only(item, '', '');
                                // setCurrentComponent('')
                              }}>
                              <i className="fa-solid icon-plane"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}

                {/* {selectedPoc &&
                  prevState === 'singlePocs' &&
                  Object.entries(selectedPoc.user_details).length > 0 &&
                  selectedPoc.user_details.map(
                    (item, index) =>
                      localStorage.getItem('id') !== item.id && (
                        <div className="poc_sent_card mb-2">
                          <div className="poc_card_img">
                            <img
                              src={
                                item.profile_img
                                  ? item.profile_img
                                  : DEFAULT_IMAGE
                              }
                              alt=""
                            />
                          </div>

                          <div className="poc_card_content">
                          
                            <h1> {fixName(item.first_name, item.last_name)}</h1>
                            <span>Head Technician </span>
                          </div>
                          <div
                            className="poc_send_icon as-link d-flex align-items-center "
                            onClick={() => {
                              setShowComponent({
                                ...showComponent,
                                morePocs: false,
                              });
                              create_personal_group_chat_only(
                                '',
                                'single',
                                item.id,
                              );
                            }}>
                            <i className="fa-solid icon-plane"></i>
                          </div>
                        </div>
                      ),
                  )} */}
              </div>
            </Scrollbars>
          </div>
        </CustomModal>
      ) }  
      
      
      {currentComponent === 'singlePocs' && selectedPoc && (
          <CustomModal
            size="medium"
            style={{height: '85%'}}
            onClose={() => {
              setCurrentComponent(prevState);
              setPrevState('');
            }}>
            <div className="filter-wrapper custom-modal-filter mb-0">
              <div className="filter-headings">
                <h2>{selectedPoc.title}</h2>
              </div>
              <Scrollbars>
                <div className="filter-category-list">
                  {selectedPoc &&
                    Object.entries(selectedPoc.user_details).length > 0 &&
                    selectedPoc.user_details.map(
                      (item, index) =>
                        localStorage.getItem('id') !== item.id && (
                          <div className="poc_sent_card mb-2">
                            <div className="poc_card_img">
                              <img
                                src={
                                  item.profile_img
                                    ? item.profile_img
                                    : DEFAULT_IMAGE
                                }
                                alt=""
                              />
                            </div>

                            <div className="poc_card_content">
                              
                              <h1>
                                {' '}
                                {fixName(item.first_name, item.last_name)}
                              </h1>
                              <span>Head Technician </span>
                            </div>
                            <div
                              className="poc_send_icon as-link d-flex align-items-center "
                              onClick={() => {
                               
                                create_personal_group_chat_only(
                                  '',
                                  'single',
                                  item.id,
                                );
                                // setCurrentComponent('')
                              }}>
                              <i className="fa-solid icon-plane"></i>
                            </div>
                          </div>
                        ),
                    )}
                </div>
              </Scrollbars>
            </div>
          </CustomModal>
        
      )}
    </>
  );
};

export default CompanyProfile;
