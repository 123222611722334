import React, {useState, useEffect} from 'react';
import {Button} from 'react-bootstrap';
import commonApi from '../../Services/CommonService';
import Scrollbars from 'react-custom-scrollbars';
import {useSelector, useDispatch} from 'react-redux';
import Moment from 'moment';
import moment from 'moment';
import EditAppointment from './EditAppointment';
import AppointmentCall from '../../Components/AppointmentPage/AppointmentCall';
import {errorMessage, successMessage} from '../../Utils/ToastMessages';
import SlidingPanel from '../SlidingPanel';
import LoaderSpinner from '../Modals/LoaderSpinner';
import {amplitudeEvent} from '../../Services/AmplitudeServices';
import CustomModal from '../CustomModal';
import {fixName, formatAmount, subtractTime} from '../../Utils/commonUtils';
import {setServerTime} from '../../Store/Actions/DeepLinkAction';
import AppointmentPayments from '../Payments/AppointmentPayments';
import OpportunityScreen from '../FrontScreens/OpportunityScreen';
import DatePicker from 'react-datepicker';

const UserAppointments = props => {
  const [appointmentType, setAppointmentType] = useState([]);
  const [appointmentTypeId, setAppointmentTypeId] = useState('1');
  const [appointmentSubType, setAppointmentSubType] = useState([]);
  const [appointmentSubTypeId, setAppointmentSubTypeId] = useState('');
  const [showLoader, setshowLoader] = useState(false);
  const [created, setCreated] = useState('to_me');
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [myAppoinmentData, setMyAppoinmentData] = useState([]);
  const [myAppoinments, setmyAppoinments] = useState([]);
  const [cancelAppoinments, setCancelAppoinments] = useState(false);
  const [deleteAppoinment, setdeleteAppoinment] = useState(false);
  const [showComponent, setShowComponent] = useState('');
  const [conferenceId, setConferenceId] = useState(null);
  const [deductionAmount, setDeductionAmount] = useState('');
  const [appoinmentCancelId, setAppoinmentCancelId] = useState('');
  const [creatorId, setCreatorId] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [endIsOpen, setEndIsOpen] = useState(false);

  const dispatch = useDispatch();
  const serverTime = useSelector(state => state.serverTime);

  // 

  const getAppointmentSubType = () => {
    // setshowLoader(true);
    let payload = {
      appointment_type_id: appointmentTypeId,
      user_id: localStorage.getItem('user'),
    };
    commonApi
      .get_appointment_type(payload)
      .then(res => {
        setAppointmentSubType(res.data);
        setAppointmentSubTypeId(res.data.length > 0 ? res.data[0].id : '');
        getMyAppointments(res.data.length > 0 ? res.data[0].id : '');
        setshowLoader(false);
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  const getAppointmentType = () => {
    commonApi
      .get_appointment_types()
      .then(res => {
        setAppointmentType(res.appointment_type);
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  useEffect(() => {
    getAppointmentType();
    getAppointmentSubType();
    dispatch(setServerTime());
    const interval = setInterval(() => {
      dispatch(setServerTime());
    }, 60000);
    return () => clearInterval(interval);
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    getAppointmentSubType();
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointmentTypeId]);
  useEffect(() => {
    if (showComponent === '') {
      getAppointmentSubType();
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showComponent]);
  useEffect(() => {
    // getTabstatus();
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (appointmentSubTypeId) {
      getMyAppointments(appointmentSubTypeId);
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointmentTypeId, endDate, startDate, appointmentSubTypeId]);

  // const popover = (
  //   <Popover id="popover-basic">
  //     {/* <Popover.Header as="h3">Popover right</Popover.Header> */}
  //     <Popover.Body>
  //       And here's some <strong>amazing</strong> content. It's very engaging.
  //       right?
  //     </Popover.Body>
  //   </Popover>
  // );
  const getMyAppointments = id => {
    var payLoad = {
      created: created,
      creator_id: localStorage.getItem('user'),
      from: moment(startDate).format('YYYY-MM-DD'),
      schedule_id: id,
      // schedule_id: id === '' ? appointmentSubTypeId : id,
      status: 'all',
      to: moment(endDate).format('YYYY-MM-DD'),
    };
    commonApi
      .get_my_appointments(payLoad)
      .then(res => {
        setshowLoader(false);
        if (res.success === true) {
          setmyAppoinments(res.appointments);
          setMyAppoinmentData(res);
          setshowLoader(false);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  let divStyle = {
    color: '#272727',
  };

  const refundAppointment = () => {
    let payLoad = {appointment_id: appoinmentCancelId};
    commonApi
      .refund_appointment(payLoad)
      .then(res => {})
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  const cancelAppointment = id => {
    let payLoad = {
      appointment_id: id ? id : appoinmentCancelId,
      appointment_status: 'canceled',
    };
    commonApi
      .cancel_appointment(payLoad)
      .then(res => {
        if (res.success === true) {
          successMessage(res.message);
          getMyAppointments(appointmentSubTypeId);
          refundAppointment();
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  const makeCallDolby = id => {
    let payLoad = {appointment_id: id, call_type: 'other'};
    commonApi
      .make_call_dolby(payLoad)
      .then(res => {
        setshowLoader(false);
        if (res.success === true) {
          amplitudeEvent('START_APPOINTMENT_CALL');
          // setConferenceId(res.session_id);
          setConferenceId(res);
          setShowComponent('AppointmentCall');
        } else {
          errorMessage(res.message);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  // const endCallDolby = id => {
  //   let payLoad = {appointment_id: id};
  //   commonApi
  //     .end_call_dolby(payLoad)
  //     .then(res => {
  //       setshowLoader(false);
  //       if (res.success === true) {
  //       }
  //     })
  //     .catch(err => {
  //       console.log('Err', err);
  //       return {type: 'error', message: err.message};
  //     });
  // };

  const calculateTransactionFee = item => {
    setAppoinmentCancelId(item.appointment_id);
    if (item.cost) {
      const fee = (item.cost * 2.9) / 100 + 0.3;
      setDeductionAmount(parseFloat(fee.toFixed(2)));
      setCancelAppoinments(true);
    } else {
      setdeleteAppoinment(true);
      // cancelAppointment(item.appointment_id);
    }
    // const fee = (transactionAmount * feePercentage / 100) + fixedFee;
    // setTransactionFee(parseFloat(fee.toFixed(2)));
  };

  const getUserIdOfAppoinment = id => {
    const selectedItem = appointmentSubType.find(item => item.id === id);
    setCreatorId(selectedItem.user_id);
    
  };

  const getTabstatus = () => {
    commonApi
      .get_tab_status()
      .then(res => {
        // setshowLoader(false);
        // setShowSpinner(false);
        // 
        if (res.status === 200) {
          // setShowComponent('firstVist');
          const item = res.tab_status;
          if (item.length === 0) {
            setShowComponent('firstVist');
          } else {
            const newArray = [];
            if (Object.entries(item).length > 0) {
              item.forEach((item, index) => {
                newArray.push(item.tab);
              });
            }



            const found = newArray.find(element => element === 'appointment');
            if (found === 'appointment') {
              // setShowComponent('');
            } else {
              setShowComponent('firstVist');
            }
          }
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const handleChange = e => {
    
    if (endIsOpen === true) {
      setEndDate(e);
      setEndIsOpen(false);
    } else if (e) {
      setStartDate(e);
      setIsOpen(false);
    } else {
      setIsOpen(false);
    }
  };

  const handleClickD = e => {
    
    e.preventDefault();
    setIsOpen(true);
    setEndIsOpen(false);
  };
  const handleClickE = e => {
    
    e.preventDefault();
    setEndIsOpen(true);
    setIsOpen(false);
  };
  return (
    <>
      {/* {showLoader && <LoaderModal show={showLoader} />} */}
      {showLoader && <LoaderSpinner />}

      {showComponent === 'firstVist' && (
        <OpportunityScreen
          onClose={() => {
            setShowComponent('');
          }}
          type={'appointment'}
          setShowComponent={setShowComponent}
        />
      )}
      {showComponent !== 'firstVist' && (
        <SlidingPanel
          hidePanel={() => {
            props.onClose();
          }}>
          <div className="serach-filter-wrapper">
            <div className="sidebar-heading-wrapper mb-2">
              <div
                className="heading"
                onClick={() => {
                  setShowComponent('addAppointment');
                }}>
                <span className="add-new-link">
                  <i className="fas fa-plus" />
                  Appointment Type
                </span>
              </div>

              {/* <span className="close as-link" onClick={() => props.onClose()}>
                  <i className="fas fa-times" />
                </span> */}
            </div>

            <Scrollbars className="custom-scroll">
              <div className="custom-scroll-content">
                <div className="appointment-date-wrapper">
                  <div className="mb-3">
                    <span>Scheduled Appointments</span>
                  </div>

                  <div className="date-wrapper">
                    <span
                      onClick={() => {
                        setStartDate(
                          new Date(Moment(startDate).add(-1, 'days')),
                        );
                      }}
                      className="navs fas fa-chevron-left"
                      style={divStyle}
                    />
                    <span className="selected-date">
                      <span className=" as-link" onClick={handleClickD}>
                        {Moment(startDate).format('MM/DD/YY').toString()}
                      </span>{' '}
                      -{' '}
                      <span className="as-link" onClick={handleClickE}>
                        {Moment(endDate).format('MM/DD/YY').toString()}
                      </span>
                      {/* {Moment(startDate).format('MM/DD/YYYY').toString()} -{' '}
                      {Moment(endDate).format('MM/DD/YYYY').toString()} */}
                    </span>
                    <span
                      className="navs fas fa-chevron-right"
                      onClick={() => {
                        setEndDate(new Date(Moment(endDate).add(1, 'days')));
                      }}
                      style={divStyle}
                    />
                  </div>
                  <div className="calender-wrapper">
                    {isOpen && (
                      <DatePicker
                        selected={startDate}
                        onChange={handleChange}
                        inline
                      />
                    )}
                    {endIsOpen && (
                      <DatePicker
                        selected={endDate}
                        onChange={handleChange}
                        inline
                      />
                    )}
                  </div>
                </div>

                <div className="search-form">
                  <div className="select-wrapper">
                    <select
                      className="form-control"
                      onChange={e => {
                        setAppointmentTypeId(e.target.value);
                      }}>
                      {Object.entries(appointmentType).length > 0 &&
                        appointmentType.map((item, index) => (
                          <option
                            // id={item.id}
                            value={item.id}
                            key={`appointmentType-${index}`}>
                            {item.appointment_type}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
                <br />

                {appointmentSubType.length > 0 && (
                  <div className="search-form">
                    <div className="select-wrapper">
                      <select
                        className="form-control"
                        onChange={e => {
                          setAppointmentSubTypeId(e.target.value);
                          getUserIdOfAppoinment(e.target.value);
                        }}>
                        {Object.entries(appointmentSubType).length > 0 &&
                          appointmentSubType.map((item, index) => (
                            <option
                              // id={item.id}
                              value={item.id}
                              key={`appointmentSubType-${index}`}>
                              {item.title}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                )}

                <div className="appointment-types-wrapper">
                  <div className="edit-link-icons">
                    {appointmentSubType.length > 0 && (
                      <span
                        className="edit-link"
                        onClick={() => {
                          getUserIdOfAppoinment(appointmentSubTypeId);
                          setShowComponent('editAppointment');
                        }}>
                        Edit Appointment Type
                      </span>
                    )}

                    {/* <span className="info-icon">
                      <OverlayTrigger placement="left" overlay={popover}>
                        <span className="icon-info">
                          <span className="path1" />
                          <span className="path2" />
                          <span className="path3" />
                        </span>
                      </OverlayTrigger>
                    </span> */}
                  </div>
                  <div className="event-tabs tabs-style-2 mt-3">
                    <div
                      className="appointment-payment as-link"
                      onClick={() => {
                        setShowComponent('FuturePayment');
                      }}>
                      <span>Future Payment</span>
                      <span>
                        {myAppoinmentData && myAppoinmentData.future
                          ? formatAmount(myAppoinmentData.future)
                          : '$0'}
                      </span>
                    </div>
                    <div className="appointment-payment">
                      <span
                        className="as-link"
                        onClick={() => {
                          setShowComponent('PendingPayment');
                        }}>
                        Pending Payout
                      </span>
                      <span>
                        {' '}
                        {myAppoinmentData && myAppoinmentData.pending
                          ? formatAmount(myAppoinmentData.pending)
                          : '$0'}
                      </span>
                    </div>
                    <div
                      className="appointment-payment as-link"
                      onClick={() => {
                        setShowComponent('CollectedPayment');
                      }}>
                      <span>Collected</span>
                      <span>
                        {myAppoinmentData && myAppoinmentData.collected
                          ? formatAmount(myAppoinmentData.collected)
                          : '$0'}
                      </span>
                    </div>
                  </div>

                  <div className="appointment-type-list">
                    <h3>
                      Next Deposit Scheduled For {myAppoinmentData.next_monday}
                    </h3>

                    {Object.entries(myAppoinments).length > 0 ? (
                      Object.entries(myAppoinments).length > 0 &&
                      myAppoinments.map(item => (
                        <div
                          className="type-list-item"
                          key={`ap-type-${item.id}`}>
                          <div
                            className="list-img as-link"
                            onClick={() => {
                              makeCallDolby(item.appointment_id);
                            }}>
                            <img src={item.user_details.profile_img} alt="" />
                          </div>
                          <div className="list-content-wrapper">
                            <div className="list-headings">
                              <div
                                className="list-title as-link"
                                onClick={() => {
                                  makeCallDolby(item.appointment_id);
                                }}>
                                <h2>
                                  {fixName(
                                    item.user_details.first_name,
                                    item.user_details.last_name,
                                  )}{' '}
                                </h2>
                                {/* <span>{item.appointment_type}</span> */}
                              </div>
                              <div className="list-title-icon">
                                {subtractTime(item.start_date, 30, 'seconds') >
                                  // myAppoinmentData.server_time && (
                                  moment(serverTime).format(
                                    'YYYY-MM-DD hh:mm:ss',
                                  ) && (
                                  <span
                                    className="remove-icon toClose"
                                    onClick={() => {
                                      calculateTransactionFee(item);
                                    }}>
                                    <i class="fa-solid fa-circle-xmark"></i>
                                  </span>
                                )}
                                <span
                                  className="remove-icon"
                                  onClick={() => {
                                    makeCallDolby(item.appointment_id);
                                  }}>
                                  <i className="fa-solid fa-video" />
                                </span>
                                {/* <span
                                  className="remove-icon"
                                  onClick={() => {
                                    makeCallDolby(item.appointment_id);
                                  }}>
                                  <i className="fa-solid fa-phone"></i>
                                </span> */}
                              </div>
                            </div>
                            <div className="list-date-wrapper">
                              <span
                                className="list-date as-link"
                                onClick={() => {
                                  makeCallDolby(item.appointment_id);
                                }}>
                                {moment(item.start_date).format(
                                  'MMM DD, YYYY h:mmA',
                                )}
                              </span>
                              <span className="list-mins">
                                {item.cost ? '$' + item.cost : '$0'}
                              </span>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <p className="text-center">No records found</p>
                    )}
                  </div>
                </div>
              </div>
            </Scrollbars>
          </div>
        </SlidingPanel>
      )}

      {showComponent === 'addAppointment' && (
        <EditAppointment onClose={() => setShowComponent('')} 
        marginTop={true}
        />
      )}
      {showComponent === 'editAppointment' && (
        <EditAppointment
          onClose={() => setShowComponent('')}
          createrId={creatorId}
          scheduleId={appointmentSubTypeId}
          setCreated={setCreated}
        />
      )}
      {showComponent === 'AppointmentCall' && conferenceId && (
        <AppointmentCall
          onClose={() => setShowComponent('')}
          confData={conferenceId}
        />
      )}
      {showComponent === 'PendingPayment' && (
        <AppointmentPayments
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          // pending={pending}
          // getPaymentDetail={getPaymentDetail}
          startDate={startDate}
          endDate={endDate}
          subTabType={'to_me'}
          status={'pending'}
          onClose={() => {
            setShowComponent('');
          }}
        />
      )}
      {showComponent === 'CollectedPayment' && (
        <AppointmentPayments
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          // pending={pending}
          // getPaymentDetail={getPaymentDetail}
          startDate={startDate}
          endDate={endDate}
          subTabType={'to_me'}
          status={'collected'}
          onClose={() => {
            setShowComponent('');
          }}
        />
      )}
      {showComponent === 'FuturePayment' && (
        <AppointmentPayments
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          // pending={pending}
          // getPaymentDetail={getPaymentDetail}
          startDate={startDate}
          endDate={endDate}
          subTabType={'to_me'}
          status={'future'}
          onClose={() => {
            setShowComponent('');
          }}
        />
      )}

      {/* <Modal
        show={cancelAppoinments}
        onHide={() => {
          setCancelAppoinments(false);
        }}>
        <Modal.Header closeButton>
          <Modal.Title>CANCEL APPOINTMENT!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to cancel the appointment? $0.45 will be
          deducted from your account
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setCancelAppoinments(false);
            }}>
            Yes
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              setCancelAppoinments(false);
            }}>
            No
          </Button>
        </Modal.Footer>
      </Modal> */}

      {cancelAppoinments && (
        <CustomModal
          size="medium"
          onClose={() => {
            setCancelAppoinments(false);
          }}>
          <div className="leave-session-content text-left">
            <h3>Cancel Scheduler!</h3>
            <p>
              Are you sure you want to cancel the Scheduler? $
              {deductionAmount} will be deducted from your account.
            </p>

            <div className="">
              <Button
                className="btn-dark"
                onClick={() => {
                  cancelAppointment();
                  setCancelAppoinments(false);
                }}>
                Yes
              </Button>
              <Button
                className="btn-dark"
                onClick={() => {
                  setCancelAppoinments(false);
                }}>
                No
              </Button>
            </div>
          </div>
        </CustomModal>
      )}

{deleteAppoinment && (
        <CustomModal
          size="medium"
          onClose={() => {
            setdeleteAppoinment(false);
          }}>
          <div className="leave-session-content text-left">
            <h3>Cancel Scheduler!</h3>
            <p>
              Are you sure you want to cancel the Scheduler? 
            </p>

            <div className="">
              <Button
                className="btn-dark"
                onClick={() => {
                  cancelAppointment();
                  setdeleteAppoinment(false);
                }}>
                Yes
              </Button>
              <Button
                className="btn-dark"
                onClick={() => {
                  setCancelAppoinments(false);
                }}>
                No
              </Button>
            </div>
          </div>
        </CustomModal>
      )}
    </>
  );
};
export default UserAppointments;
