import React, {useState, useEffect} from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';

import '../Utils/Scripts';
import {RoiLogo} from '../Constants/Images';
import {useSelector} from 'react-redux';
import {getItem} from '../Utils/LocalStorage';

import PageNotFound from '../Pages/PageNotFound';
import Home from '../Pages/Front/Home';
import ProfilePage from '../Pages/Front/ProfilePage';
import Messages from '../Pages/Front/Messages';
import SessionEvents from '../Pages/Front/SessionEvents';
import SessionDetails from '../Pages/Front/SessionDetails';
import OpportunitiesList from '../Pages/Front/OpportunitiesList';

import BuildersDummyPage from '../Pages/Front/StaticPages/Builders';

import FeedBack from '../Components/FeedBack/FeedBack';
import History from '../Components/History/History';
import AppointmentCallPage from '../Components/AppointmentPage/AppointmentCallPage';

import OpportunityDetails from '../Pages/Front/StaticPages/OpportunityDetails';

import ReferralPage from '../Pages/Front/StaticPages/ReferralPage';

import Analytics from '../Pages/Front/StaticPages/Analytics';

import Login from '../Pages/Auth/Login';

import Preferences from '../Pages/Front/Preferences';

import PasteLinks from '../CheckPasteLinks';
import SnapShotUsersList from '../Components/Snapshot/SnapShotUsersList';
import AppointmentPage from '../Components/AppointmentPage/AppointmentPage';
import TechnicianPayment from '../Components/Technician/TechnicianPayment';

import Appointments from '../Pages/LandingPage/Appointments';

import BuildGPT from '../Pages/BUILDGPT/BuildGPT';
import TechnicianTasks from '../Components/Technician/TechnicianTasks';
import RegisterCompany from '../Pages/Starter/RegisterCompany';

import HomeMap from '../Components/Screens/HomeMap';
import RideShareHome from '../Components/RideShare/RideShareHome';
import PickDrop from '../Components/PickAndDrop/PickDrop';
import RetailHome from '../Components/Retail/RetailHome';

import NonProfit from '../Components/Donate/NonProfit';
import HomeTemplate from '../Components/Screens/HomeTemplate';
import uploadFile from '../Components/UplioadFile/uploadFile';
import ShareAbles from '../Pages/Front/ShareAbles';
import Schedule from '../Pages/Front/Schedule';
import PlayCenter from '../Pages/Front/PlayCenter';
import Fleet from '../Pages/Front/Fleet';
import Team from '../Pages/Front/Team';
import Template from '../Pages/Front/Template';
import CallCenter from '../Pages/Front/CallCenter';
import FormPage from '../Pages/Front/FormPage';
import Funnel from '../Pages/Front/Funnel';
import Reward from '../Pages/Front/Reward';
import Announcement from '../Pages/Front/Announcement';
import Catalog from '../Pages/Front/Catalog';
import DispatchScreen from '../Pages/Front/DispatchScreen';
import ProjectManagement from '../Pages/Front/ProjectManagement';
import Customer from '../Pages/Front/Customer';
import HomeService from '../Components/HomeServices/HomeService';
import Projects from '../Pages/Front/Projects';
import LeaderBoard from '../Pages/Front/LeaderBoard';
import ScheduleActivities from '../Pages/Front/ScheduleActivities';
import CustomersList from '../Pages/Front/CustomersList';
import CustomerProduct from '../Components/HomeServices/CustomerProduct';

import LandingPage2 from '../Pages/LandingPage/Newindex';
import CustomerScanQr from '../Components/ScanQrcode/CustomerScanQr';
import CustomerMall from '../Components/HomeServices/CustomerMall';
import Task from '../Pages/Front/Task';
import OKRs from '../Pages/Front/OKRs';
import AgreementProposal from '../Pages/Front/AgreementProposal';
import MarketHome from '../Components/HomeServices/MarketHome';
import UserProfile from '../Pages/Front/UserProfile';
import TimeSheet from '../Pages/TimeSheet/TimeSheet';
import Reputation from '../Pages/Reputation/Reputation';
import Attendence from '../Pages/Front/Attendence';
import BusinessProfile from '../Pages/Front/BusinessProfile';
import CompanyQr from '../Pages/Front/CompanyQr';
import Property from '../Pages/Front/Property';
import Moniter from '../Pages/Front/Moniter';
import MyNotes from '../Pages/Front/MyNotes';
import ShowCase from '../Pages/Front/ShowCase';
import Order from '../Components/Order/Order';
import RoadMap from '../Pages/Front/RoadMap';
import Pipeline from '../Pages/Front/Pipeline';
import UpdateProfile from '../Pages/Starter/UpdateProfile';
import LoaderSpinner from '../Components/Modals/LoaderSpinner';

const RoutesFile = props => {
  const [pageLoader, setPageLoader] = useState(true);
  const [isLogin, setIsLogin] = useState(localStorage.getItem('id'));
  const [isSignUp, setIsSignUp] = useState(localStorage.getItem('signup'));
  const [menuState, setMenuState] = useState(props.menuState);
  // const [currentUrl, setcurrentUrl] = useState('');
  // const location = useLocation();

  const {pathname} = window.location;
  const urlParts = pathname.split('/').filter(Boolean);

  const hitApi = useSelector(state => state.deepLinking);
  const showLoader = useSelector(state => state.companyLoader);
  const companyInfo = useSelector(state => state.userCompanyData.companyDetail);
  const userInfo = useSelector(state => state.userCompanyData.userDetail);
  const registerRoute = useSelector(state => state.registerRouteReducer);
  // const [iamModerator, setiamModerator] = useState(false);
  // const [superModerator, setSuperModerator] = useState(false);
  // useEffect(() => {
  //   const {host, pathname, search} = window.location;
  //   if (host.startsWith('www.')) {
  //     const url = `https://${host.replace(/^www\./, '')}${pathname}${search}`;
  //     window.location.replace(url);
  //   }
  // }, []);

  useEffect(() => {
    // alert('')
    //    setInterval(() => {
    // var urlUser = window.location.pathname;
    // urlUser = urlUser.slice(1);

    // setcurrentUrl(urlUser);
    setIsLogin(localStorage.getItem('id'));
    setIsSignUp(localStorage.getItem('signup'))
    // }, 50);
  }, [isLogin, hitApi]);

  useEffect(() => {
    // if (
    //   location.pathname.includes('session/') ||
    //   location.pathname.includes('/session-details')
    // ) {
    //   history.push('');
    //   window.location.reload(false);
    // }
    if (localStorage.getItem('haha')) {
      localStorage.clear();
    }

    // if (hideCheck == 'yes') {
    //    return false;
    //   localStorage.setItem('session_started', '');
    //   localStorage.setItem('hide_case', '');

    //   joinTheSession(session_started);

    // }
    //old code
    // if (session_started) {
    //   leaveSession(
    //     localStorage.getItem('creator_id'),
    //     localStorage.getItem('session_started'),
    //   );
    // } else {
    //   localStorage.setItem('session_started', '');
    // }
  }, []);

  const hideLoaderScreen = () => {
    const loader = document.getElementById('loader');

    if (loader !== null) {
      var fadeEffect = setInterval(function () {
        if (!loader.style.opacity) {
          loader.style.opacity = 1;
        }
        if (loader.style.opacity > 0) {
          loader.style.opacity -= 0.1;
        } else {
          setPageLoader(false);

          clearInterval(fadeEffect);
        }
      }, 100);
    }
  };

  const hideUrlLoaderScreen = () => {
    const loader = document.getElementById('loader');

    if (loader !== null) {
      var fadeEffect = setInterval(function () {
        if (!loader.style.opacity) {
          loader.style.opacity = 1;
        }
        if (loader.style.opacity > 0) {
          loader.style.opacity -= 0.05;
        } else {
          setPageLoader(false);

          clearInterval(fadeEffect);
        }
      }, 100);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      if (urlParts.length > 1) {
        hideUrlLoaderScreen();
      } else {
        hideLoaderScreen();
      }
    }, 1000);
  }, []);

  useEffect(() => {
    setMenuState(props.menuState);
  }, [props.menuState]);

  useEffect(() => {}, []);

  const menuOpen = menuState ? 'menu-open' : '';
  let infoUrl = window.location.pathname;
  const lowercasePath = infoUrl.toLowerCase();

  console.log(isLogin, 'isLogin');
  console.log(isSignUp, 'isSignUp');

  return (
    <div className={`page-wrapper ${menuOpen}`}>
      {pageLoader && (
        <div id="loader" className="loader">
          <div className="loader-logo">
            <img src={RoiLogo} alt="" />
          </div>
        </div>
      )}

      {isLogin === null && isSignUp === null && !registerRoute ? (
        <Router>
          <PasteLinks />
          <Switch>
            {localStorage.getItem('loginNum') && (
              <Route
                exact
                path="/registercompany"
                component={RegisterCompany}
              />
            )}

            {/* <Route
              exact
              path="/onboarding2"
              component={onBoarding2}
            /> */}

            <Route exact path="/" component={LandingPage2} />
            <Route exact path="/login" component={Login} />
            {/* <Route exact path="/opportunity/:username" component={Login} /> */}
            <Route
              exact
              path="/:companyName/:userId/:type/invite"
              component={Login}
            />

            <Route
              exact
              path="/:companyName/:userId/profile"
              component={Login}
            />
            {/* <Route
              exact
              path="/:companyName/recruitment/invite"
              component={Login}
            /> */}

            <Route exact path="/:companyName/offerings" component={Login} />
            {/* <Route exact path="/:username/:type/invite" component={Login} /> */}
            {/* <Route exact path="/login/:type/:id" component={Login} /> */}

            <Route exact path="/started" component={LandingPage2} />

            {/* <Route exact path="/qrlinks" component={Home} />
            <Route exact path="/qrlinks/:type" component={Home} />
            <Route exact path="/qrlinks/:type/:id" component={Home} /> */}

            {(Object.keys(userInfo).length > 0 ||
              Object.keys(companyInfo).length > 0) && (
              <>
                <Route exact path="/notify_profile" component={Home} />
                <Route exact path="/:username/:pageType" component={Home} />
                {/* <Route exact path="/:companyName" component={Home} /> */}
                <Route exact path="/:id" component={Home} />
              </>
            )}
            {/* {infoUrl !== '/' && !lowercasePath.includes('c') && (
              <Route exact path="/:username/:pageType" component={Home} />
            )} */}
            <Route component={LandingPage2} />
          </Switch>
        </Router>
      ) : isLogin === null && (isSignUp !== null || registerRoute) ? (
        <Router>
          <Switch>
            <Route exact path="/registercompany" component={RegisterCompany} />
            <Route path="*">
              <Redirect to="/registercompany" />
            </Route>
          </Switch>
        </Router>
      ) : (
        <Router>
          <PasteLinks />
          <Switch>
            {/* <Route exact path="/registercompany" component={RegisterCompany} /> */}
            <Route exact path="/updateProfile" component={UpdateProfile} />
            <Route exact path="/landing-page" component={LandingPage2} />

            {/* <Route exact path="/" component={OpportunitiesList} /> */}
            <Route
              id="session_event"
              exact
              path="/"
              component={SessionEvents}
            />
            <Route
              exact
              path="/:companyName/:userId/recruitment/invite"
              component={OpportunitiesList}
            />

            <Route
              exact
              path="/:companyName/:userId/virtual/invite"
              component={SessionEvents}
            />

            <Route
              exact
              path="/:companyName/:userId/in-person/invite"
              component={SessionEvents}
            />
            <Route
              exact
              path="/:companyName/:userId/snapshot/invite"
              component={SessionEvents}
            />

            <Route
              exact
              path="/:companyName/:userId/profile"
              component={SessionEvents}
            />

            <Route
              exact
              path="/:companyName/:userId/schedule/invite"
              component={AppointmentPage}
            />

            <Route
              exact
              path="/:companyName/recruitment/invite"
              component={OpportunitiesList}
            />

            <Route
              exact
              path="/recruitment/:type/:id"
              component={OpportunitiesList}
            />
            <Route exact path="/screens" component={HomeMap} />
            {/* <RouteP
              exact
              path="/opportunity/:username"
              component={OpportunitiesList}
            /> */}
            {/* <Route
              exact
              path="/:username/recruitment/invite"
              component={OpportunitiesList}
            /> */}

            <Route
              exact
              path="/:username/question/invite"
              component={OpportunitiesList}
            />
            <Route
              exact
              path="/:username/event/invite"
              component={SessionEvents}
            />
            <Route
              exact
              path="/:companyName/:userId/meetup/invite"
              component={SessionEvents}
            />

            <Route exact path="/:companyName/offerings" component={Catalog} />
            <Route exact path="/login" component={SessionEvents} />

            {/* <Route exact path="/1-on-1" component={Home} /> */}
            {/* <Route exact path="/qrlinks" component={Home} />
            <Route exact path="/qrlinks/:type" component={Home} />
            <Route exact path="/qrlinks/:type/:id" component={Home} /> */}
            <Route
              exact
              path="/appointment-call"
              component={AppointmentCallPage}
            />
            <Route exact path="/business_profile" component={BusinessProfile} />
            <Route exact path="/profile/:any" component={ProfilePage} />
            <Route exact path="/profile/:type/:any" component={ProfilePage} />
            <Route exact path="/profile/:id" component={ProfilePage} />
            <Route exact path="/messages" component={Messages} />
            <Route exact path="/messages/:id" component={Messages} />

            <Route
              exact
              path="/:companyName/:userId/group-chat/invite"
              component={Messages}
            />
            {/* <Route exact path="/payment" component={Payment} /> */}
            <Route exact path="/payment" component={TechnicianPayment} />
            <Route exact path="/tasks" component={TechnicianTasks} />

            <Route exact path="/sessions-events" component={SessionEvents} />
            <Route
              exact
              path="/sessions-events/:id"
              component={SessionEvents}
            />

            {/* // Roi Routes */}

            <Route exact path="/ride" component={RideShareHome} />
            <Route exact path="/pick&drop" component={PickDrop} />
            <Route
              exact
              path="/schedule_activities"
              component={AppointmentPage}
            />
            <Route exact path="/cart" component={RetailHome} />
            <Route exact path="/donate" component={NonProfit} />
            <Route exact path="/settings" component={HomeTemplate} />
            <Route exact path="/my_notes" component={MyNotes} />
            <Route exact path="/showcase" component={ShowCase} />
            {/* <Route exact path="/scanqr" component={ScanQr} /> */}

            <Route exact path="/scanqr" component={CompanyQr} />
            <Route exact path="/companyqr" component={CompanyQr} />
            <Route exact path="/pipeline" component={Pipeline} />
            <Route exact path="/reward" component={Reward} />
            <Route exact path="/user_profile" component={UserProfile} />
            <Route exact path="/check_in" component={Attendence} />
            <Route exact path="/announcements" component={Announcement} />
            <Route exact path="/customer_services" component={uploadFile} />
            {/* <Route exact path="/home" component={MarketHome} /> */}
            <Route exact path="/home_market" component={HomeService} />
            <Route exact path="/profile" component={ProfilePage} />

            {/* <Route exact path="/business_profile" component={BusinessProfile} /> */}
            {/* <Route exact path="/consulting" component={Consulting} /> */}
            <Route exact path="/okrs" component={OKRs} />
            <Route exact path="/monitor" component={Moniter} />
            <Route exact path="/purchase_orders" component={Order} />

            <Route exact path="/events/:type/:id" component={SessionEvents} />
            {/* <Route exact path="/sessions/:type/:id" component={SessionEvents} /> */}
            <Route
              exact
              path="/session/:sessionId"
              component={SessionDetails}
            />
            <Route exact path="/session-details" component={SessionDetails} />

            <Route exact path="/play_center" component={PlayCenter} />
            <Route exact path="/my_mall" component={MarketHome} />
            <Route exact path="/customer-mall" component={CustomerProduct} />
            <Route exact path="/mymall" component={CustomerMall} />
            {/* <Route exact path="/payment" component={PaymerntsR} /> */}
            <Route exact path="/form" component={FormPage} />
            <Route exact path="/agreements" component={AgreementProposal} />
            <Route exact path="/template" component={Template} />
            <Route exact path="/call_center" component={CallCenter} />
            <Route exact path="/team" component={Team} />
            <Route exact path="/fleet" component={Fleet} />

            <Route exact path="/recruitment" component={OpportunitiesList} />
            <Route exact path="/shareables" component={ShareAbles} />
            <Route exact path="/schedule" component={Schedule} />
            <Route
              exact
              path="/recruitment/:id"
              component={OpportunitiesList}
            />

            <Route exact path="/referrals" component={ReferralPage} />
            <Route exact path="/referrals/:id" component={ReferralPage} />
            <Route exact path="/circle" component={BuildersDummyPage} />

            <Route
              exact
              path="/opportunity/:num"
              component={OpportunityDetails}
            />
            <Route exact path="/timeSheet" component={TimeSheet} />

            <Route exact path="/preferences" component={Preferences} />

            <Route exact path="/chat_gpt" component={BuildGPT} />
            <Route exact path="/reputation" component={Reputation} />
            <Route exact path="/ratings" component={Reputation} />

            <Route exact path="/appointments" component={Appointments} />

            <Route exact path="/feedback" component={FeedBack} />
            <Route exact path="/notification" component={SnapShotUsersList} />
            <Route exact path="/task" component={Task} />
            <Route exact path="/road_map" component={RoadMap} />
            {/* <Route exact path="/my-task" component={Task} /> */}
            {/* <Route exact path="/catalog" component={SalesCart} /> */}
            <Route exact path="/offerings" component={Catalog} />
            <Route exact path="/customer" component={Customer} />
            <Route exact path="/customer-qr" component={CustomerScanQr} />
            <Route exact path="/dispatch_screen" component={DispatchScreen} />
            <Route
              exact
              path="/project_management"
              component={ProjectManagement}
            />
            <Route
              exact
              path="/schedule-activities"
              component={ScheduleActivities}
            />
            <Route exact path="/leaderboard" component={LeaderBoard} />
            <Route exact path="/customers" component={CustomersList} />
            <Route exact path="/property" component={Property} />

            <Route exact path="/projects" component={Projects} />

            <Route exact path="/history" component={History} />
            {/* <Route exact path="/payment" component={Payments} /> */}
            {/* <Route exact path="/gifts" component={GiftsPage} /> */}
            {/* <Route exact path="/qrs" component={Qrs} /> */}
            <Route exact path="/upload_File" component={uploadFile} />
            <Route exact path="/files" component={uploadFile} />
            {/* <Route exact path="/NoRecordFound" component={NoRecordFound} /> */}

            <Route exact path="/analytics" component={Analytics} />

            <Route exact path="/notify_profile" component={Home} />
            <Route exact path="/:username/:pageType" component={Home} />
            <Route exact path="/:companyName" component={Home} />
            <Route exact path="/:id" component={Home} />

            <Route component={PageNotFound} />
          </Switch>
        </Router>
      )}
    </div>
  );
};

const mapStateProps = state => ({
  menuState: state.menuState,
});

export default connect(mapStateProps, {})(RoutesFile);
