import React, {useState, useEffect, useRef} from 'react';
import {Button, Form} from 'react-bootstrap';
import commonApi from '../../Services/CommonService';
import {errorMessage, successMessage, } from '../../Utils/ToastMessages';

import SlidingPanel from '../SlidingPanel';
import Scrollbars from 'react-custom-scrollbars';
import LoaderSpinner from '../Modals/LoaderSpinner';
import CropImage from '../Cropper/CropImage';
import CustomModal from '../CustomModal';
import {useSelector, useDispatch} from 'react-redux';
import {get_my_all_associate_workspaces} from '../../Store/Actions/SessionAction';

const EditUserProfile = ({profile, onClose, setProfileImgScrollBar,getUserData, type,setUserInfo}) => {

  const [instagram, setInstagram] = useState(false);
  const [linkdin, setLinkdin] = useState(false);
  const [twitter, setTwitter] = useState(false);
  const [youtube, setYoutube] = useState(false);
  const [global, setGlobal] = useState(false);
  const [file, setFile] = useState(null);
  const [sliderImage, setSliderImage] = useState([]);
  const [bio, setBio] = useState(localStorage.getItem('bio') === 'null' ? '' : localStorage.getItem('bio'));
  const buttonRef = useRef(null);
  const [instagramName, setInstagramName] = useState(
    localStorage.getItem('instagram'),
  );
  const [linkdinName, setLinkdinName] = useState(
    localStorage.getItem('linkedin'),
  );
  const [twitterName, setTwitterName] = useState(
    localStorage.getItem('twitter'),
  );
  const [youtubeName, setYoutubeName] = useState(
    localStorage.getItem('youtube'),
  );
  const [globalName, setGlobalName] = useState(localStorage.getItem('web'));

  const [img, setImg] = useState(profile.data && profile.data.profile_img);
  const [showLoader, setshowLoader] = useState(false);
  const [showError, setShowError] = useState(false);
  const [validated, setValidated] = useState(false);
  const [showModel, setShowModel] = useState(false);
  const allowedImageTypes = ['image/jpeg', 'image/png', 'image/jpg'];
  const [activeWorkspaces, setActiveWorkspacesId] = useState(
    localStorage.getItem('activeIds')
      ? localStorage.getItem('activeIds').split(',')
      : [],
  );
  // console.log(img)

  const dispatch = useDispatch();

  const listWorspacerator = useSelector(
    state => state.hitAssociateWorkSpace.data,
  );
  const personal = useSelector(state => state.hitAssociateWorkSpace.personal);

  useEffect(() => {
    dispatch(
      get_my_all_associate_workspaces({token: localStorage.getItem('token')}),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // if (props.workSpaceIds.length > 0) {
    //   // Use the filter method to keep only the workspaces with matching IDs
    //   // const userExist = listWorspacerator.filter(workspace =>
    //   //   props.workSpaceIds.includes(workspace.id),
    //   // );

    //   // Extract the 'id' values from 'userExist'
    //   // const updatedIds = userExist.map(workspace => workspace.id);

    //   // if (props.workSpaceIds.includes('personal')) {
    //   //   updatedIds.push('personal'); // Add 'personal' to the ids array if needed
    //   // }

    //   setActiveWorkspacesId(localStorage.getItem('activeIds')
    //   ? localStorage.getItem('activeIds').split(',')
    //   : []);
    // } else {
    // Filter the workspaces with status "active" from the API response
    const userExist = listWorspacerator.filter(
      workspace => workspace.status === 'active',
    );

    // Extract the 'id' values from 'userExist'
    const updatedIds = userExist.map(workspace => workspace.id);

    if (personal === 'yes') {
      updatedIds.push('personal'); // Add 'personal' to the ids array if needed
    }

    setActiveWorkspacesId(updatedIds);
    // props.setWorkSpaceIds(updatedIds);
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listWorspacerator]);

  const handleCheckboxChange = event => {
    const workspaceId = event.target.id;
    if (!activeWorkspaces.includes(workspaceId)) {
      // Workspace is currently active, remove it from the selection

      setActiveWorkspacesId(prevActiveWorkspaces => [
        ...prevActiveWorkspaces,
        workspaceId,
      ]);
    } else {
      if (activeWorkspaces.length === 1) {
        errorMessage('One workspace should be selected.');
      } else {
        setActiveWorkspacesId(prevActiveWorkspaces =>
          prevActiveWorkspaces.filter(id => id !== workspaceId),
        );
      }
      // Workspace is not active, add it to the selection
    }
  };

  const handleUpdateButtonClick = () => {
    // props.setWorkSpaceIds(activeWorkspaces);
    setActiveWorkspacesId(activeWorkspaces);

    // props.onClose();
    setShowModel('');
  };

  const changeTicketImg = e => {
    // If no file selected, return
    if (e.target.files.length === 0) return false;
    const file = e.target.files[0];

    // If no image selected, return
    if (!/^image\//.test(file.type)) {
      errorMessage(`File is not an image.`);
      return false;
    } else if (allowedImageTypes.includes(file.type)) {
      setFile(file);

      setShowModel('modal');

      e.target.value = null;
      // const filePath = e.target.files[0];
      // const objectUrl = URL.createObjectURL(filePath);
      // let reader = new FileReader();
      // reader.readAsDataURL(filePath);
      // reader.onload = e => {
      //   // console.log("hua k anai ", e.target.result);

      //   uploadProfilePicture(e.target.result);
      // };

      // setTicketImg(objectUrl);
    } else {
      errorMessage('The file format is not supported');
      e.target.value = null; // reset the file input
    }
  };
  useEffect(() => {
    localStorage.setItem('profilePic', img);

    return localStorage.setItem('profilePic', img);
  }, [img]);

  const handle = e => {
    // alert("ASDasd")
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      alert('')
      e.preventDefault();
      e.stopPropagation();

      setValidated(true);
    } else {
      // alert('')
      e.preventDefault();

      if (bio === '') {
        
        setShowError(true);
      } else {
        updateProfile();
      }
      setValidated(false);
    }
  };

  console.log(profile, 'userInfouserInfouserInfouserInfo');

  const updateProfile = () => {
    var payload = {
      bio: bio,
      firstName: localStorage.getItem('firstName'),
      lastName: localStorage.getItem('lastName'),
      instagram: instagramName,
      linkedin: linkdinName,
      twitter: twitterName,
      youtube: youtubeName,
      web: globalName,
    };
    commonApi
      .update_profile(payload)
      .then(res => {
        if (res.status === 200) {
          successMessage(res.message)
          if(type==='profileComponent'){
            
            getUserData()
          }
          res.user.bio = bio;
          res.user.instagram = instagramName;
          res.user.linkedin = linkdinName;
          res.user.twitter = twitterName;
          res.user.youtube = youtubeName;
          res.user.web = globalName;
          localStorage.setItem('bio', bio);
          localStorage.setItem('instagram', instagramName);
          localStorage.setItem('linkedin', linkdinName);
          localStorage.setItem('twitter', twitterName);
          localStorage.setItem('youtube', youtubeName);
          localStorage.setItem('web', globalName);
          onClose();
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const handleBio = e => {
    const value = e.target.value;

    // Remove leading spaces
    if (value.startsWith(' ')) {
      setBio(value.trimStart());
    } else {
      setBio(value);
    }
  };

  // const uploadProfilePicture = img => {
  //   setshowLoader(true);
  //   var bodyFormData = new FormData();
  //   bodyFormData.append(
  //     'picture',
  //     img.replace(/^data:image\/[a-z]+;base64,/, ''),
  //   );

  //   commonApi
  //     .upload_profile_picture(bodyFormData)
  //     .then(res => {
  //       if (res.status === 200) {
  //         setImg(res.user.profile_img);
  //         localStorage.setItem('profilePic', res.user.profile_img);
  //       }
  //       setshowLoader(false);
  //       setProfileImgScrollBar(res.user.profile_img);
  //     })
  //     .catch(err => {
  //       console.log('Err', err);
  //       return {type: 'error', message: err.message};
  //     });
  // };

let firstpaddDivStyle={
  padding : '25px 15px 0px 15px'
}
  console.log(type,'type')
  return (
    <>
    <SlidingPanel hidePanel={() =>
      
      {          onClose()
        
        }}
      firstDivStyle={firstpaddDivStyle}
        >
      {showLoader && <LoaderSpinner />}

      <div className="serach-filter-wrapper editProfile">
        <div className="fix-width-forms">
          <div className="sidebar-heading-wrapper mt-0">
            <div className="heading edit-profile-headings">
              <h2>Edit Profile</h2>
            </div>
          </div>
        </div>

        <Scrollbars  className='custom-scroll custom-scroll-user-profile'>
          <div className="card profile-block-wrapper fix-width-forms card-edit-profile-block-wrapp scroll-profile-user-block">
            
            <div className="profile-img-wrapper">
              <div className="profile-img upload-user-img removeCursor upload-edit-user-img">
                <div style={{position: 'relative'}}>
                  {img && <img src={img} alt="" />}
                  <span className="icon icon-camera"></span>
                  <input
                    type="file"
                    name="my-image"
                    id="image"
                    accept="image/*"
                    onChange={e => {
                      changeTicketImg(e);
                    }}
                  />
                </div>
                {/* <div style={{position: 'relative'}}>
                  <Form.Label>Update Profile Picture</Form.Label>
                  <input
                    type="file"
                    name="my-image"
                    id="image"
                    accept="image/*"
                    onChange={e => {
                      changeTicketImg(e);
                    }}
                  />
                </div> */}
              </div>
            </div>
            <Form noValidate validated={validated} onSubmit={handle}>
              <div className="send-refferal-form-wrapper mt-2">
                <Form.Group className="fieldset">
                  <Form.Label className='bio-label'>Bio</Form.Label>
                    <div className="textarea-upper">
                      <Form.Control
                        as="textarea"
                        rows={8}
                        // required
                        maxLength={320}
                        className="textarea-scroll"
                        placeholder="Tell other contacts a little about yourself."
                        value={bio}
                        onChange={e => {
                          // setBio(e.target.value);
                          handleBio(e);
                        }}
                      />
                    </div>
                    {bio && 
                  <span className="info-text">
                    { 320 - bio.length} Maximum characters limit
                  </span>}
                  {/* <Form.Control.Feedback type="invalid">
                    Bio is required
                  </Form.Control.Feedback> */}
                  {bio.length === 0 && showError === true && (
                  <div className="text-danger textforerror">Bio is required.</div>
                )}
                </Form.Group>
              </div>

              <div className="profile-content-infos mb-4">
                <h2>Social Media Platforms</h2>

                <p>
                  {' '}
                  Tap on the icons below to add your different handles or URLs.
                  <br /> <br /> You can add multiple by tapping the icons below.
                  {/* You can add multiple by tapping the icons below. */}
                </p>
              </div>

              <div className="profile-content-infos mb-2">
                <div className="social-icons mb-3">
                  <ul>
                    <li>
                      <i
                        className={`fab fa-instagram as-link ${
                          instagram ? 'icon_color' : ''
                        }`}
                        onClick={() => {
                          setInstagram(true);
                          setLinkdin(false);
                          setGlobal(false);
                          setYoutube(false);
                          setTwitter(false);
                        }}
                      />
                    </li>

                    <li>
                      <i
                        className={`fa-brands fa-linkedin as-link ${
                          linkdin ? 'icon_color' : ''
                        }`}
                        onClick={() => {
                          setLinkdin(true);
                          setGlobal(false);
                          setYoutube(false);
                          setTwitter(false);
                          setInstagram(false);
                        }}></i>
                    </li>

                    <li>
                      <i
                        className={`fab fa-twitter as-link ${
                          twitter ? 'icon_color' : ''
                        }`}
                        onClick={() => {
                          setTwitter(true);
                          setLinkdin(false);
                          setGlobal(false);
                          setYoutube(false);
                          setInstagram(false);
                        }}
                      />
                    </li>

                    <li>
                      <i
                        className={`fab fa-youtube as-link ${
                          youtube ? 'icon_color' : ''
                        }`}
                        onClick={() => {
                          setYoutube(true);
                          setLinkdin(false);
                          setGlobal(false);
                          setTwitter(false);
                          setInstagram(false);
                        }}
                      />
                    </li>

                    <li>
                      <i
                        className={`icon-link as-link ${
                          global ? 'icon_color' : ''
                        }`}
                        onClick={() => {
                          setGlobal(true);
                          setLinkdin(false);
                          setYoutube(false);
                          setTwitter(false);
                          setInstagram(false);
                        }}
                      />
                    </li>
                  </ul>
                </div>

                {instagram && (
                  <Form.Control
                    placeholder="Add instagram handler "
                    value={instagramName}
                    onChange={e => {
                      setInstagramName(e.target.value);
                    }}
                  />
                )}
                {linkdin && (
                  <Form.Control
                    placeholder="Add linkdin handler"
                    value={linkdinName}
                    onChange={e => {
                      setLinkdinName(e.target.value);
                    }}
                  />
                )}
                {twitter && (
                  <Form.Control
                    placeholder="Add twitter handler"
                    value={twitterName}
                    onChange={e => {
                      setTwitterName(e.target.value);
                    }}
                  />
                )}
                {youtube && (
                  <Form.Control
                    placeholder="Add youtube handler or link"
                    value={youtubeName}
                    onChange={e => {
                      setYoutubeName(e.target.value);
                    }}
                  />
                )}
                {global && (
                  <Form.Control
                    placeholder="Add website handler or link"
                    value={globalName}
                    onChange={e => {
                      setGlobalName(e.target.value);
                    }}
                  />
                )}
              </div>
              <Button
                type="submit"
                style={{display: 'none'}}
                ref={buttonRef}></Button>
            </Form>
          </div>
        </Scrollbars>

        <div className="filter-btn-wrapper text-center pt-3" style={{paddingBottom:"20px"}}>
          <Button
            className="btn-dark m-0"
            onClick={() => buttonRef.current.click()}>
            Update Profile
          </Button>
        </div>
      </div>

      {showModel === 'modal' && file && (
        <CustomModal
          size="large"
          onClose={() => {
            setShowModel('');
            setFile(null);
          }}>
          <CropImage
            type="profile"
            ratio={25 / 24}
            file={file}
            setFile={setFile}
            setCropImg={setShowModel}
            setImgUrl={setImg}
            sliderImage={sliderImage}
            setSliderImage={setSliderImage}
            setshowLoader={setshowLoader}
            component={type}
            userInfo={profile}
            setUserInfo={setUserInfo}
            getUserData={getUserData}
            setProfileImgScrollBar={setProfileImgScrollBar}
          />
        </CustomModal>
      )}

    
    </SlidingPanel>
      {showModel === 'work-place-list' && (
        <CustomModal
          className="shareables-workspace-modal"
          size="small"
          onClose={() => setShowModel('')}>
          <div className="filter-wrapper">
            <div className="filter-category-list">
              <div className="filter-category-list">
                <div className="filter-headings">
                  <h2>Associated Workspace</h2>
                </div>
                <ul>
                  <li>
                    <Form.Label>
                      Personal
                      <input
                        type="checkbox"
                        id={'personal'}
                        value={'personal'}
                        onChange={handleCheckboxChange}
                        checked={activeWorkspaces.includes('personal')}
                      />
                      <span />
                    </Form.Label>
                  </li>
                  {Object.entries(listWorspacerator).length > 0 &&
                    listWorspacerator.map((item, index) => (
                      <li key={`sessionC-${index}`}>
                        <Form.Label>
                          {item.name}
                          <input
                            type="checkbox"
                            id={item.id}
                            value={item.id}
                            onChange={handleCheckboxChange}
                            checked={activeWorkspaces.includes(item.id)}
                          />
                          <span />
                        </Form.Label>
                      </li>
                    ))}
                </ul>
                {/* {!props.type && (
                  <div className="invite-persons-heading mt-3">
                    <span
                      className="builder-text as-link"
                      onClick={() => {
                        setShowComponent('WorkSpace');
                      }}>
                      Manage Workspace
                    </span>
                  </div>
                )} */}
                <Button
                  type="submit"
                  className="btn-dark"
                  // disabled={true}
                  // onClick={handleUpdateButtonClick}
                  onClick={() => {
                    handleUpdateButtonClick();
                  }}>
                  {/* {props.type ? 'Select' : 'Update'} */}
                  Select
                </Button>
              </div>
            </div>
          </div>
        </CustomModal>
      )}
      </>
  );
};

export default EditUserProfile;
