import Sidebar from '../../Pages/Sidebar';
import {Button, Form} from 'react-bootstrap';

import React, {useState, useEffect} from 'react';
import {Bandage} from '../../Constants/Images';

import {Pannels} from '../../Components';
import Scrollbars from 'react-custom-scrollbars';

import CreateWorkSpace from '../../Components/WorkSpace/CreateWorkSpace';
import {
  ArrowIcon,
  FireIcon,
  MachineIcon,
  WhitePenIcon,
  timeIcon,
  bagIcon,
  heartIcon,
  ProfileNotesIcon,
} from '../../Constants/Images';
import Slider from 'react-slick';
import {SliderTeam} from '../../Constants/AnalyticsConstants';
import {DiscoverBuilders} from '../../Components';
import commonApi from '../../Services/CommonService';
import {DEFAULT_IMAGE} from '../../Utils/env';
import {
  fixName,
  NoTeamFound,
  newSingleChatroom,
  chatroomInfo,
  truncateTitle,
} from '../../Utils/commonUtils';
import CustomerSearchFilter from '../../Components/Customers/CustomerSearchFilter';
import NorecordFound from '../../Components/NoRecord/NorecordFound';
import ProfileComponent from '../../Components/ProfilePage/ProfileComponent';
import SmartChatPage from '../../Components/Common/SmartChatPage';
const Team = props => {
  const [showComponent, setShowComponent] = useState('');

  const [isFloated, toggleFloatedPanel] = useState(false);
  const [showHiddenPanel, toggleHiddenPanel] = useState(false);

  const [threeDots, setThreeDots] = useState(false);
  const [tabType, setTabType] = useState('team');
  const [currentComponent, setCurrentComponent] = useState('');
  const [refresh, setRefresh] = useState([]);
  const [buildersUserId, setBuildersUserId] = useState('');
  const [analycticsSlider, setAnalycticsSlider] = useState(false);
  const [teamListing, setTeamListing] = useState([]);
  const [page, setPage] = useState(1);
  const [isFetching, setIsFetching] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [filterIds, setFilterIds] = useState([]);
  const [filterList, setFilterList] = useState([]);
  const [listingSearchQry, setListingSearchQry] = useState('');
  const [showModal, setShowModal] = useState('');
  const [teamData, setTeamData] = useState({});

  const [chatType, setChatType] = useState([]);
  const [chatDetails, setChatDetails] = useState([]);
  const [allChatUsers, setAllChatUsers] = useState([]);
  const [chatRoomName, setChatRoomName] = useState('');
  const [chatRoomId, setChatRoomId] = useState('');
  const [seconadaryComponent, setSecondaryComponent] = useState('');

  const rewardSlider = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
  };

  useEffect(() => {
    setShowSpinner(true);
    get_team_list(1, '', filterIds);
    get_user_roles();
  }, []);

  const get_user_roles = () => {
    commonApi
      .get_company_users_role()
      .then(res => {
        console.log(res, 'res');
        if (res.status === 200) {
          if (res.data.length > 0) {
            const newFilterData = res.data.filter(
              item => item.role_type_id !== '1',
            );
            setFilterList(newFilterData);
          } else {
            setFilterList([]);
          }
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const get_team_list = (pageNo, searchQuery, Userfilter) => {
    const payload = {
      role_ids: Userfilter ? Userfilter : filterIds,
      search: searchQuery ? searchQuery : listingSearchQry,
      page: pageNo ? pageNo : page,
    };

    commonApi
      .get_team_list(payload)
      .then(res => {
        console.log(res, 'res');
        if (res.status === 200) {
          if (pageNo > 1 && !searchQuery && !listingSearchQry) {
            const NewPushdATA = [...teamListing, ...res.data];
            setTeamListing(NewPushdATA);
          } else {
            setTeamListing(res.data);
          }
          setPage(pageNo ? pageNo : 1);
          setIsFetching(false);
          setShowSpinner(false);
        }
      })
      .catch(err => {
        console.error('Error fetching customer list:', err);
        // Handle error appropriately, e.g., show error message to user
        return {type: 'error', message: err.message};
      });
  };

  const buildersHandle = (t, id) => {
    setBuildersUserId(id);
    setShowComponent(t);
  };

  const dynamicStyle = {
    padding: '0px 0px 15px',
    // Add other styles as needed
  };

  const checkChatRoom = id => {
    let payLoad = {
      user_id: id,
    };
    commonApi
      .check_chat_room(payLoad)
      .then(response => {
        if (response.status === 200) {
          if (response.exist) {
            const allChatUsers = [];
            allChatUsers.push(response.chat_detail.creator_detail);
            allChatUsers.push(response.chat_detail.user_detail);

            setChatType('personal-single');
            setChatDetails(response.chat_detail);
            setAllChatUsers(allChatUsers);
            setChatRoomName(response.chat_room_name);
            setChatRoomId(response.chat_room_id);

            // setChatType('personal-single');
            // setChatDetails(response.chat_detail);
            // setAllChatUsers(allChatUsers);
            // setChatRoomName(response.chat_room_name);
            // setChatRoomId(response.chat_room_id);
            setSecondaryComponent('single_chat');
          } else {
            setChatType('new-personal-single');
            setChatDetails([]);
            setAllChatUsers([teamData]);
            setChatRoomName('');
            setChatRoomId('');

            setSecondaryComponent('single_chat');

            // setChatType('new-personal-single');
            // setChatDetails([]);
            // setAllChatUsers([userInfo]);
            // setChatRoomName('');
            // setChatRoomId('');
            // setShowComponent('single_chat');
          }
        }
      })
      .catch(error => {
        return {type: 'error', message: error.message};
      });
  };

  const TeamMemberCard = ({teamMember}) => {
    return (
      <div className="payment-card-main as-link">
        <div
          className="payment-content-card"
          onClick={() => {
            buildersHandle('profileComponent', teamMember.user_id);
          }}>
          <div className="task-profile-img">
            <img src={teamMember.profile_img || DEFAULT_IMAGE} alt="" />
          </div>

          <div className="task-content">
            <h1
              style={{
                color: '#000',
                fontFamily: 'Inter',
                fontSize: '15px',
                fontStyle: 'normal',
                fontWeight: '700',
                lineHeight: 'normal',
              }}>
              {fixName(teamMember.first_name, teamMember.last_name) || ''}
            </h1>

            <span
              style={{
                color: '#000',
                fontFamily: 'Inter',
                fontSize: '15px',
                fontStyle: 'normal',
                fontWeight: '500',
                lineHeight: 'normal',
              }}>
              {teamMember.home_location || ''}
            </span>
            <div className="payment-card-3rd-text">
              <span
                className="project-title"
                style={{
                  color: '#7E7B70',
                  fontFamily: 'Inter',
                  fontSize: '13px',
                  fontStyle: 'normal',
                  fontWeight: '400',
                  lineHeight: 'normal',
                  marginBottom: '4px',
                }}>
                {teamMember.projectTitle || 'Prospect'}
              </span>

              <div
                style={{
                  color: '#7E7B70',
                  fontFamily: 'Inter',
                  fontSize: '13px',
                  fontStyle: 'normal',
                  fontWeight: '400',
                }}>
                {teamMember.timeAgo || '5 Days Ago'}
              </div>
            </div>
          </div>
        </div>

        <div className="roi-service-icons">
          {teamMember?.tags && Object.entries(teamMember.tags).length > 0 &&
            teamMember.tags.slice(0, 6).map((item, index) => (
              <div className="service-icon-time" key={index}>
                {item.icon && (
                  <img className="tags_img" src={item.icon} alt="" />
                )}
                {item.title && (
                  <span className="tags_title">
                    {truncateTitle(item.title, 4)}
                  </span>
                )}
              </div>
            ))}
          {teamMember?.tags?.length > 6 && (
            <div className="w-29">
              <i
                className="fa-solid fa-ellipsis"
                style={{color: 'rgb(10, 10, 10)', paddingTop: '15px'}}></i>
            </div>
          )}
        </div>

        <div className="payment-card-bottom">
          <div className="payment-card-icons">
            <div
              className="payment-icon as-link"
              onClick={() => {
                buildersHandle('profileComponent', teamMember.user_id);
              }}>
              <i class="fa-solid fa-phone"></i>
            </div>

            <div
              className="payment-icon as-link"
              onClick={() => {
                setTeamData({
                  id: teamMember.user_id,

                  profile_img: teamMember.profile_img,
                  first_name: teamMember.first_name,
                  last_name: teamMember.last_name,
                });
                checkChatRoom(teamMember.user_id);
              }}>
              <i className="fa-solid icon-plane"></i>
            </div>
          </div>

          <div className="funnel-card-icons">
            {/* <div
                                                    className="p-bottom-icon as-link"
                                                    onClick={() => {
                                                      setCustomerData({
                                                        id: item.user_id,

                                                        profile_img:
                                                          item.profile_img,
                                                        first_name:
                                                          item.first_name,
                                                        last_name:
                                                          item.last_name,
                                                      });

                                                      setSecondaryComponent(
                                                        'followUp',
                                                      );
                                                    }}>
                                                    <i
                                                      class="fa-solid fa-clock"
                                                      style={{
                                                        color: '#262626',
                                                      }}></i>
                                                  </div>

                                                  <div
                                                    className="p-bottom-icon as-link"
                                                    onClick={() => {
                                                      setCustomerData({
                                                        id: item.user_id,

                                                        profile_img:
                                                          item.profile_img,
                                                        first_name:
                                                          item.first_name,
                                                        last_name:
                                                          item.last_name,
                                                      });
                                                      setSecondaryComponent(
                                                        'profileNotes',
                                                      );
                                                    }}>
                                                    <img
                                                      src={ProfileNotesIcon}
                                                      alt=""
                                                    />
                                                  </div> */}

            {/* <div className="p-bottom-icon">
                                                    <img
                                                      src={dispatch}
                                                      alt=""
                                                    />
                                                  </div> */}
          </div>
        </div>
      </div>
    );
  };
  const handleScroll = values => {
    const {clientHeight, scrollHeight, scrollTop} = values.target;
    // // Check if the user has reached the end of the content
    if (clientHeight + 2 + scrollTop >= scrollHeight && !isFetching) {
      // setShowBottomSpinner(true);
      // Increment the page number and call the API
      setIsFetching(true);

      get_team_list(page + 1, listingSearchQry);
    }
  };
  return (
    <>
      <div className="page-wrapper">
        <Sidebar
          location={props.location}
          setShowComponent={setShowComponent}
          setWorkSpaceItem={setRefresh}
        />
        {/* {showComponent === 'Event' && <SessionEvents eventButton={eventButton} />} */}

        <div className="page-content-wrapper">
          <div className="sessions-list-wrapper slider-screen-main">
            <div className="Reward_main analytics_slider_bg">
              <span
                className="analytics_slider_heading "
                style={{color: analycticsSlider ? '' : '#999999'}}
                onClick={() => {
                  setAnalycticsSlider(!analycticsSlider);
                }}>
                Analytics Row
              </span>

              {analycticsSlider && (
                <div className="reward-top mt-3 mb-2">
                  <Slider {...rewardSlider}>
                    {SliderTeam.length > 0 &&
                      SliderTeam.map((item, index) => (
                        <div className="reward-main">
                          <div className="reward-card">
                            <span style={{fontWeight: 700, fontSize: '13px'}}>
                              {item.name}
                            </span>
                            <span
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}>
                              <span style={{fontSize: '17px'}}>
                                {item.money}
                              </span>
                              {item.name === 'Ratings' && (
                                <img src={item.svgIcon} alt="" />
                              )}
                              {item.name === 'Conversion' ? (
                                <i
                                  className="fa-solid fa-arrow-down"
                                  style={{color: '#BD4141'}}></i>
                              ) : (
                                <i
                                  class="fa-solid fa-arrow-up"
                                  style={{color: '#52bd41'}}></i>
                              )}
                            </span>
                            <div class="message-date-info">
                              <span
                                style={{color: '#333'}}
                                class="message-date">
                                {item.time}
                              </span>
                            </div>
                          </div>
                        </div>
                      ))}
                  </Slider>
                </div>
              )}
            </div>
            <div className="add_border"></div>
            <Pannels
              isFloated={isFloated}
              toggleFloatedPanel={toggleFloatedPanel}
              showHiddenPanel={showHiddenPanel}
              updateHiddenPanelState={nState => toggleHiddenPanel(nState)}
              customCss={dynamicStyle}
              primary={
                <>
                  {showComponent !== 'firstVist' &&
                    showComponent !== 'WorkSpace' && (
                      <DiscoverBuilders
                        buildersHandle={buildersHandle}
                        apiHit={refresh}
                        buildersUserId={buildersUserId}
                      />
                    )}

                  <div className="primary_panel_wraper primary_panel_content">
                    <div className="messages-header align_header mb-2">
                      <div className="event-tabs tabs-style-2 mb-0">
                        {' '}
                        <ul>
                          <li>
                            <Button
                              className={tabType === 'team' ? 'active' : ''}
                              onClick={() => {
                                setTabType('team');
                              }}>
                              Team
                            </Button>
                          </li>
                          <li>
                            <Button
                              className={
                                tabType === 'candidate' ? 'active' : ''
                              }
                              onClick={() => {
                                setTabType('candidate');
                              }}>
                              Canddidates
                            </Button>
                          </li>
                        </ul>
                      </div>

                      <div className="search-tabs-icons">
                        <span
                          className={
                            currentComponent === 'createInvoice'
                              ? 'icon active'
                              : 'icon'
                          }>
                          <i
                            className="icon-plus"
                            onClick={() => setCurrentComponent('createInvoice')}
                          />
                        </span>
                        <span
                          onClick={() => {
                            setThreeDots(!threeDots);
                          }}
                          className={
                            threeDots
                              ? 'icon active threeDots'
                              : 'icon threeDots'
                          }>
                          <i className="fas fa-ellipsis-vertical" />
                        </span>
                      </div>
                    </div>
                    {threeDots && (
                      <div className="payment-search-wrapper mb-0 messages-users-wrapper mb-4">
                        <div className="search-form d-flex">
                          <Form.Group className="search-input">
                            <Form.Control
                              type="text"
                              placeholder="Who are you looking for?"
                              value={listingSearchQry}
                              onChange={e => {
                                setListingSearchQry(e.target.value);
                                get_team_list(1, e.target.value);
                              }}
                            />
                          </Form.Group>
                          {filterList?.length > 0 && (
                            <Button>
                              <i
                                className="icon-filter-icon"
                                onClick={() => setShowModal('serach_filter')}
                              />
                            </Button>
                          )}
                        </div>
                      </div>
                    )}
                    <div className="refferal-list-wrapper">
                      <Scrollbars autoHide onScroll={handleScroll}>
                        {teamListing?.length > 0 ? (
                          teamListing.map((teamMember, index) => (
                            <TeamMemberCard
                              key={index}
                              teamMember={teamMember}
                            />
                          ))
                        ) : (
                          <NorecordFound noRecordData={NoTeamFound}
                          className={'pt'} />
                        )}
                      </Scrollbars>
                    </div>
                  </div>
                  {showModal === 'serach_filter' && (
                    <CustomerSearchFilter
                      show={showModal}
                      onClose={() => setShowModal('')}
                      onCloseHitApi={userFilter => {
                        setShowModal('');
                        get_team_list(1, '', userFilter);
                      }}
                      setFilter={setFilterIds}
                      filter={filterIds}
                      filterList={filterList}
                      setFilterList={setFilterList}
                      setshowLoader={setShowSpinner}
                    />
                  )}
                </>
              }
              secondary={
                <>
                  {buildersUserId && showComponent === 'profileComponent' && (
                    <div className="pb-4 fix-width-forms">
                      <ProfileComponent
                        userId={buildersUserId}
                        show={false}
                        onClose={() => {
                          setShowComponent('');
                        }}
                        // hideCloseButton={true}
                        button={''}
                        userData={[]}
                        moderator={false}
                        // setParticipantType={setParticipantType}
                        VoxeetSDK={[]}
                        conferenceInfo={[]}
                        jdSpecialM={[]}
                        jdSpecialA={[]}
                      />
                    </div>
                  )}

                  {seconadaryComponent === 'single_chat' && (
                    <SmartChatPage
                      allmsg={true}
                      type={chatType}
                      item={[]}
                      chatroomInfo={
                        chatDetails.length > 0
                          ? chatroomInfo(chatDetails)
                          : newSingleChatroom(teamData)
                      }
                      oppoDetails={chatDetails}
                      chatDetails={chatDetails}
                      allChatUsers={allChatUsers}
                      chatRoomName={chatRoomName}
                      chatRoomId={chatRoomId}
                      onClose={() => {
                        setSecondaryComponent('');
                      }}
                      userData={teamData}
                      // handleCommonChat={(data, type)=>{handleCommonChat(data, type)}}
                    />
                  )}
                </>
              }
            />

            {showComponent === 'WorkSpace' && (
              <CreateWorkSpace
                onClose={() => {
                  setShowComponent('');
                }}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default Team;
