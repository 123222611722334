import React,{useState} from 'react'
import { registerCompany } from '../../Constants/Images'
import {Button, Form} from 'react-bootstrap';
import CropImage from '../../Components/Cropper/CropImage';
import { CustomModal } from '../../Components';
import { errorMessage } from '../../Utils/ToastMessages';
import LoaderSpinner from '../../Components/Modals/LoaderSpinner';
const CustomerProfile = ({customerData,setCustomerData,buttonRef,validated,profileImg,handleCustomerValidation,setProfileImg,setStep,handleBackbtn,ShowLoader}) => {

    const [showModel, setShowModel] = useState('');
    const allowedImageTypes = ['image/jpeg', 'image/png', 'image/jpg'];
    const [file, setFile] = useState(null);
    const [showLoader, setshowLoader] = useState(false);
    const updateImage = e => {
        // setProfileImg();
        const imgData = e.target.files[0];
        if (!/^image\//.test(imgData.type)) {
          errorMessage(`File is not an image.`);
          return false;
        } else if (allowedImageTypes.includes(imgData.type)) {
          setFile(imgData);
    
          setShowModel('modal');
    
          e.target.value = null;
        } else {
          errorMessage('The file format is not supported');
          e.target.value = null;
        }
    
    
      };

console.log(ShowLoader,'showLoadershowLoader')
  return (
    <>
        <div className="slider-component create_company_form">
          {/* <div className="slider-component-img"> */}
          {/* {!hide && ( */}
          <div
            className="slider-component-img"
            style={{
              backgroundImage: `url(${registerCompany})`,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
            }}>
            {/* <img src={registerCompany} alt="" /> */}

            <div className="slider-component-img-text">
              Welcome to the ROI Family!
            </div>
          </div>
          {/* )} */}

          <div className="slider-component-content refferal-list-wrapper">
            <div className="customer_form_back-btn">
              <Button
                className="new-btn btn-green "
                onClick={() => {
                  handleBackbtn()
                  // setStep('name');
                  // handleFunctionReset();
                }}>
                Back
              </Button>
            </div>
            {ShowLoader && <LoaderSpinner />}
            <div
              className="user-detail slider-content"
              // style={{
              //   textAlign: 'center',
              //   paddingTop: '10vh',
              // }}
            >
              <div className="input-container1 text-left">
                <Form
                  noValidate
                  validated={validated}
                  onSubmit={handleCustomerValidation}>
                  <div className="onboarding-profile create-profile onboarding-customerprofile-holder">
                    <div className="profile-img">
                      <input
                        type="file"
                        accept="image/*"
                        name="profile_img"
                        onChange={e => updateImage(e)}
                      />
                      {profileImg && <img src={profileImg} alt="" />}
                      {/* {!profileImg &&  */}
                      <i className="fas fa-camera" 
                      />
                      {/* } */}

                    </div>
                    <h3>What’s your name?</h3>
                    <p>Yes, we use real names.</p>

                    <Form.Group className="fieldset mt-3">
                      <Form.Label>First Name</Form.Label>
                      <Form.Control
                        name="fname"
                        type="text"
                        // pattern="^\S.*$"
                        value={customerData.firstName}
                        onChange={e =>
                          setCustomerData({
                            ...customerData,
                            firstName: e.target.value,
                          })
                        }
                        placeholder="First Name"
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        First Name is required.
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="fieldset">
                      <Form.Label>Last Name</Form.Label>
                      <Form.Control
                        // pattern="^\S.*$"
                        name="lname"
                        type="text"
                        value={customerData.lastName}
                        onChange={e =>
                          setCustomerData({
                            ...customerData,
                            lastName: e.target.value,
                          })
                        }
                        placeholder="Last Name"
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Last Name is required.
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Button
                      className="new-btn btn-green customer_btn"
                      type="submit"
                      ref={buttonRef}>
                      Next
                    </Button>
                  </div>
                </Form>
              </div>
            </div>

            {showModel === 'modal' && file && (
              <CustomModal
                size="large"
                onClose={() => {
                  setShowModel('');
                  setFile(null);
                }}>
                <CropImage
                  type="profile"
                  component="registerUser"
                  ratio={25 / 24}
                  file={file}
                  setFile={setFile}
                  setCropImg={setShowModel}
                  setImgUrl={setProfileImg}
                  setshowLoader={setshowLoader}
                />
              </CustomModal>
            )}
          </div>
        </div>
      </>
  )
}

export default CustomerProfile