import React from 'react';
import {Button, Col, Row, Form, Dropdown} from 'react-bootstrap';
import {useState, useRef} from 'react';
import {useEffect} from 'react';
import commonApi from '../../Services/CommonService';
import {DEFAULT_IMAGE} from '../../Utils/env';
// import {labels} from '../../Constants/Translations';
// import {selected_lang} from '../../Utils/env';
import ImageLightbox from '../ImageLightbox';
// import {RenderHTMLBlock} from '../../Utils/Helpers';
import OpportunityList from '../OpportunitiesList/OpportunityList';
import AddNewSnapShot from '../Messages/AddNewSnapShot';
import Scrollbars from 'react-custom-scrollbars/lib/Scrollbars';
import {useSelector, useDispatch} from 'react-redux';
import {
  arrayColumn,
  capitalizeFirst,
  fixName,
  // formatAmount,
} from '../../Utils/commonUtils';
import {useHistory} from 'react-router-dom';
import CustomModal from '../CustomModal';
import OpportunityScreen from '../FrontScreens/OpportunityScreen';

import {getOpportunityListingProfile} from '../../Store/Actions/OpportunityAction';
import {
  employeeMenu,
  employeeToEmployeeMenu,
  customerMenu,
  employeeMenuP,
  employeeToEmployeeMenuP,
  customerMenuP,
} from '../../Constants';
import {
  ArrowIcon,
  FireIcon,
  MachineIcon,
  WhitePenIcon,
  timeIcon,
  bagIcon,
  heartIcon,
  Bandage,
} from '../../Constants/Images';
import moment from 'moment';
import ProfileProjects from './ProfileProjects';
import CheckoutForm from '../SessionDetails/CheckoutForm';
import {loadStripe} from '@stripe/stripe-js';

import {
  PaymentElement,
  Elements,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import CustomSlidingButtons from '../CustomSlidingButtons';
import {useImperativeHandle} from 'react';
import ProfileOverview from './ProfileOverview';
import Review from '../SessionDetails/Review';
import ReviewListing from '../SessionDetails/ReviewListing';
import ProfileAppointmentList from '../AppointmentPage/ProfileAppointmentList';
import ProfileFollowUp from './ProfileFollowUp';
import ProfileTask from './ProfileTask';
import ProfileDeals from './ProfileDeals';
import ProfilePipeline from '../PipeLine/ProfilePipeline';
import LoaderSpinner from '../Modals/LoaderSpinner';
import GalleryIcons from './GalleryIcons';
import ProfileMeetup from '../SessionDetails/ProfileMeetup';
import ProfileRecruitment from '../OpportunitiesList/ProfileRecruitment';
import NorecordFound from '../NoRecord/NorecordFound';
import { propertyNoRecordFound } from '../../Utils/NoRecordConstant';
// import OpportunityItem from "../OpportunitiesList/OppotunityItem";

const ProfileGallery = props => {
  // const [gallery, setGallery] = useState();
  // const [oppos, setOppos] = useState();
  // const [sesList, setSesList] = useState(props.sesList);
  const [galleryType, setGalleryType] = useState('general');
  const [popupImages, setPopupImages] = useState([]);
  const [threeDots, setThreeDots] = useState(false);
  const [show, setShow] = useState(false);
  const [current, setCurrent] = useState(0);
  // const [isNewSession, setIsNewSession] = useState(props.isNewSession);

  const [userSnapshots, setSnapshotData] = useState([]);
  // const [showSnapshots, setShowSnapshot] = useState(false);

  const [userUpcomming, setUpcommingData] = useState([]);
  // const [showUpcomming, setShowUpcomming] = useState(false);

  // const [opportunityData, setOpportunityData] = useState([]);
  // const [showOpportunity, setShowOpportunity] = useState(false);

  const [addNewSnapshot, setAddNewSnapshot] = useState(false);
  const [showSnapshotModal, setShowSnapshotModal] = useState(false);
  const [categoriesArr, setCategoriesArr] = useState([]);
  // const [categoriesName, setCategoriesName] = useState(['All']);
  const [upcomingFilter, setUpcomingFilter] = useState('all');
  const [opportunityFilter, setOpportunityFilter] = useState('all');
  // const [showSpinner, setShowSpinner] = useState(false);
  const [showComponent, setShowComponent] = useState('');
  const [transformationList, setTransformationList] = useState([]);
  const [type, setType] = useState((props.fromPage || props.snapShotRefer === true) ? 'credentials' : 'overview');
  const allMine = props.userId === localStorage.getItem('id') ? 'all' : 'mine';
  const [invoices, setInvoices] = useState([]);
  const [invoiceItem, setInvoiceItem] = useState({});
  const [showStripeModal, setShowStripeModal] = useState(false);
  const [selectedPrice, setSelectedPrice] = useState('');
  const [paymentId, setPaymentId] = useState('');
  const [currentComponent, setCurrentComponent] = useState('');
  const [paymentType, setPaymentType] = useState('unpaid');
  const [categoryTypes, setCategoryTypes] = useState([
    {title: 'Pending', id: 1},
    {title: 'Paid', id: 2},
  ]);

  const [showLoader, setshowLoader] = useState(false);
  const [transformation_Filter, setTransformation_Filter] = useState([1]);
  const [dropdownMenu, setDropdownMenu] = useState([]);
  const [open, setOpen] = useState(false);
  const stripePromise = loadStripe(
    'pk_test_51PEB5a2K8W7Cs25henyw4Ahf3eGMQgtDgRztlGV4POXFghfMEH5DDg5PtNpT916JChWA9ELeby2Znh5mu2IjXyuY00TWUVZN1V',
  );
  const opportunityData = useSelector(
    state => state.opportunityListingProfile?.listing,
  );

  const activeCompany = localStorage.getItem('activeWorkspaceItem')
    ? JSON.parse(localStorage.getItem('activeWorkspaceItem'))
    : [];

  const hitApiRef = useRef();
  const history = useHistory();
  const dispatch = useDispatch();
  const [tabs, setTabs] = useState([]);

  const snapshotCategories = useSelector(
    state => state.accomplishmentCategories,
  );

  //

  // state = {
  //     gallery:{} ,
  //     oppos: {},
  //     sesList: {},
  //     galleryType: "Snapshot",
  //     popupImages: [],
  //     show: false,
  //     current: 0,
  //     isNewSession: false,
  // };
  const handleSnapshotSelection = event => {
    const {value, checked} = event.target;
    const itemObj = JSON.parse(value);

    if (itemObj.id === 'All') {
      //   setCategoriesName(['All']);
      if (!checked) {
        setCategoriesArr([]);
      } else {
        let categories = arrayColumn(snapshotCategories, 'id');
        setCategoriesArr(categories);
      }
    } else {
      if (checked) {
        // setCategoriesName(prev => [...prev, itemObj.title]);
        setCategoriesArr(prev => [...prev, itemObj.id]);
      } else {
        // setCategoriesName(prev =>
        //   prev.filter(title => title !== itemObj.title),
        // );
        setCategoriesArr(prev => prev.filter(id => id !== itemObj.id));
      }
    }
  };

  useEffect(() => {
    if (props.fromPage) {
      const dropdownMenu =
        props.userId === localStorage.getItem('id')
          ? employeeMenu
          : props.userData?.user_role === 'Customer'
          ? customerMenu
          : props.userData?.user_role !== 'Customer' &&
            localStorage.getItem('userRole_in_company') !== 'Customer'
          ? employeeToEmployeeMenu
          : employeeMenu;

      if (props.type === 'market') {
        setDropdownMenu(dropdownMenu.slice(0, -1));
      } else {
        setDropdownMenu(dropdownMenu);
      }
    }
  }, []);

  // useEffect(() => {
  //   if (
  //     categoriesArr.length === 0 ||
  //     categoriesArr.length === snapshotCategories.length
  //   ) {
  //     setCategoriesName(['All']);
  //   } else {
  //     const filterData = snapshotCategories.filter(item =>
  //       categoriesArr.includes(item.id),
  //     );
  //     setCategoriesName(arrayColumn(filterData, 'category_name'));
  //   }
  // }, [categoriesArr]);
  const getUserInvoices = type => {
    //props.fromPage
    var payload = {};
    if (props.fromPage == 'business') {
      payload = {
        search_query: '',
        status: paymentType,
      };
      commonApi
        .get_company_invoices(payload)
        .then(res => {
          if (res.status === 200) {
            if (res.data) {
              setInvoices(res.data);
            }
          }
        })
        .catch(err => {
          console.log('Err', err);
          return {type: 'error', message: err.message};
        });
    } else {
      if (props.userId === localStorage.getItem('id')) {
        payload = {
          invoice_type: 'invoice',
          sub_type: '',
          search_query: '',
          list_type: props.selectedPaymentFilter,
        };
      } else {
        payload = {
          invoice_type: 'invoice',
          sub_type: '',
          search_query: '',
          list_type: props.selectedPaymentFilter,
          sender_id: props.userId,
        };
      }

      commonApi
        .get_user_invoices(payload)
        .then(res => {
          if (res.status === 200) {
            if (res.data) {
              setInvoices(res.data);
            }
          }
        })
        .catch(err => {
          console.log('Err', err);
          return {type: 'error', message: err.message};
        });
    }
  };
  const getUserInvoicesFilter = type => {
    //props.fromPage
    var payload = {};
    if (props.fromPage == 'business') {
      payload = {
        search_query: '',
        status: type,
      };
      commonApi
        .get_company_invoices(payload)
        .then(res => {
          if (res.status === 200) {
            if (res.data) {
              setInvoices(res.data);
            }
          }
        })
        .catch(err => {
          console.log('Err', err);
          return {type: 'error', message: err.message};
        });
    } else {
      if (props.userId === localStorage.getItem('id')) {
        payload = {
          invoice_type: 'invoice',
          sub_type: '',
          search_query: '',
          list_type: 'to_me',
        };
      } else {
        payload = {
          invoice_type: 'invoice',
          sub_type: '',
          search_query: '',
          list_type: 'to_me',
          sender_id: props.userId,
          status: type,
        };
      }

      commonApi
        .get_user_invoices(payload)
        .then(res => {
          if (res.status === 200) {
            if (res.data) {
              setInvoices(res.data);
            }
          }
        })
        .catch(err => {
          console.log('Err', err);
          return {type: 'error', message: err.message};
        });
    }
  };
  const getSnapshotData = (cat = '') => {
    var data = {
      user_id: props.companyData ? '' : props.userId,
      categories: cat,
      company_id: props.companyData
        ? props.companyData.id
        : activeCompany[0]?.id
        ? activeCompany[0].id
        : '',
    };
    commonApi
      .get_accomplishment(data)
      .then(res => {
        if (res.status === 200) {
          setSnapshotData(res.accomplishment);
          // setShowSnapshot(true);
          setshowLoader(false);
        }
        // setShowSpinner(false);
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  // let tabs = [];
  const getUserData = () => {
    const data = {user_id: props.userId};
    commonApi
      .get_user_sess_opp_accom(data)
      .then(response => {
        const updatedTabs = [...tabs]; // Create a new array from the existing tabs
        if (response.exist_accomplishment) {
          // alert('ll');
          // setTabs(['Snapshots','Upcoming','Opportunities']);
          updatedTabs.push('Snapshots');
        }
        if (response.exist_session) {
          // alert('ll1');
          // updatedTabs.push('Upcoming');
        }
        if (response.exist_opportunity) {
          // alert('ll2');
          // updatedTabs.push('Opportunities');
        }

        // Set the tabs array to the updatedTabs
        setTabs(updatedTabs);

        // if (updatedTabs.includes('Snapshots')) {
        //   setGalleryType('Snapshots');
        // } else {
        //   if (updatedTabs.includes('Upcoming')) {
        //     setGalleryType('Upcoming');
        //   } else {
        //     if (updatedTabs.includes('Opportunities')) {
        //       setGalleryType('Opportunities');
        //     }
        //   }
        // }

        // Now you can use the updatedTabs array for your logic

        // Continue with your logic here
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const getUpcommingData = (type = 'mine') => {
    // return false;
    var data = {
      user_id: props.userId,
      type: type,
    };
    commonApi
      .get_user_sessions(data)
      .then(res => {
        if (res.status === 200) {
          setUpcommingData(res.user_session);
          // setShowUpcomming(true);
          setTimeout(() => {
            setshowLoader(false);
          }, 500);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  const getOpportunityData = async (type = 'mine') => {
    // return false;
    // setshowLoader(true);
    var data = {
      user_id: props.userId,
      type: type,
    };
    dispatch(getOpportunityListingProfile(data));

    setTimeout(() => {
      setshowLoader(false);
    }, 500);

    // setOpportunityData()
  };

  // useEffect(() => {
  //   setGallery(userSnapshots);
  //   setOppos(opportunityData);
  //   setSesList(userUpcomming);
  //   setIsNewSession(props.isNewSession);

  // }, [props.userId]);

  useEffect(() => {
    // getSnapshotData();
    if (localStorage.getItem('id') === props.userId) {
      setGalleryType('general');
    } else {
      // getUserData();
    }

    // getOpportunityData();
    // getUpcommingData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.userId, addNewSnapshot, props.companyData]);

  useEffect(() => {
    if (!props.fromPage) {
      getUserDataExist();
    }
  }, [props.userData]);

  const getUserDataExist = () => {
    const data = {user_id: props.userId};
    commonApi
      .get_user_sess_opp_accom(data)
      .then(response => {
        let updatedMenu = [];

        const dropdownMenu =
          props.userId === localStorage.getItem('id')
            ? employeeMenuP
            : props.userData?.user_role === 'Customer'
            ? customerMenuP
            : props.userData?.user_role !== 'Customer' &&
              localStorage.getItem('userRole_in_company') !== 'Customer'
            ? employeeToEmployeeMenuP
            : employeeMenuP;
        updatedMenu = [...dropdownMenu];
        props.userData?.schedules &&
          updatedMenu.push({id: '6', name: 'Appointments', tab: 'appointment'});

        if (response.exist_session) {
          updatedMenu.push({
            id: '7',
            name: 'Meetups (Virtual / In-Person)',
            tab: 'meetup',
          });
        }
        if (response.exist_opportunity) {
          updatedMenu.push({id: '8', name: 'Recruitment', tab: 'recruitment'});
        }

        updatedMenu.push(
          {id: '9', name: 'Follow ups', tab: 'followUp'},
          {id: '10', name: 'Task', tab: 'tasks'},
          {id: '10', name: 'Review', tab: 'review'},
        );

        if (
          localStorage.getItem('id') === props.userId &&
          (props.userData.pipeline_permission.create_pipeline ||
            props.userData.pipeline_permission.associate_pipeline ||
            props.userData.pipeline_permission.show_pipeline)
        ) {
          updatedMenu.push({id: '11', name: 'Pipeline', tab: 'pipeline'});
        }
        if (
          localStorage.getItem('id') !== props.userId &&
          (props.userData.deals_permission.create ||
            props.userData.deals_permission.read)
        ) {
          updatedMenu.push({id: '12', name: 'Deals', tab: 'deals'});
        }
        if (dropdownMenu.length > 1) {
          if (type !== 'deals') {
            setType(props.snapShotRefer === true ? 'credentials': 'overview');
          }
        }
        setDropdownMenu(updatedMenu);
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  useEffect(() => {
    if (type === 'credentials') {
      getSnapshotData(props.filterCat);
    } else if (galleryType === 'Upcoming') {
      if (allMine === 'all') {
        // getUpcommingData(upcomingFilter);
      } else {
        // getUpcommingData(allMine);
      }
    } else if (galleryType === 'Opportunities') {
      if (allMine === 'all') {
        // getOpportunityData(opportunityFilter);
      } else {
        // getOpportunityData(allMine);
      }
    }

    // getOpportunityData();
    // getUpcommingData();
    // active={galleryType}
    // tabs={['Snapshots', 'Upcoming', 'Opportunities']}
    //

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    galleryType,
    props.showComponentOpp,
    props.showComponent,
    tabs,
    props.filterCat,
    props.companyData,
  ]);

  useEffect(() => {
    // getOpportunityData(allMine);
    // getUpcommingData(allMine);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.userId]);

  // UNSAFE_componentWillMount = () => {
  //     this.setState({
  //         gallery: this.props.gallery,
  //         oppos: this.props.oppos,
  //         sesList: this.props.sesList,
  //         isNewSession: this.props.isNewSession,
  //     });
  // };

  //    const  componentDidUpdate=(prevProps)=>{
  //         if (this.props.userId !== prevProps.userId) {
  //             this.setState({
  //                 gallery: this.props.gallery,
  //                 oppos: this.props.oppos,
  //                 sesList: this.props.sesList,
  //                 isNewSession: this.props.isNewSession,
  //             });
  //         }
  //     }

  const setGalleryTypeee = type => {
    setGalleryType(type);
  };

  // const showLightbox = (imagez, current) => {
  //

  //     const tempImgs = [];

  //     for (let i = 0; i < imagez.length; i++) {
  //         tempImgs.push({
  //             id: i,
  //             image: imagez[i].images[0],
  //         });
  //     }

  //     this.setState({
  //         show: true,
  //         popupImages: tempImgs,
  //         current,
  //     });
  // };

  const handleClickLink = link => {
    // perform some action

    // navigate to a specific page using history object
    history.push(link);
  };

  const _renderCreatorImg = person => {
    return (
      <div className="img-item">
        {
          <span className="owner-icon">
            <i className="fas fa-crown" />
          </span>
        }
        <img
          src={person.creator_img === '' ? DEFAULT_IMAGE : person.creator_img}
          alt=""
        />
      </div>
    );
  };
  const _renderAudiImg = person => {
    return (
      <div key={`person-id-${person.id}`} className="img-item">
        <img
          src={person.profile_img === '' ? DEFAULT_IMAGE : person.profile_img}
          alt=""
        />
      </div>
    );
  };

  useEffect(() => {
    getUserInvoices();
    // getTabstatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedPaymentFilter]);

  useEffect(() => {
    if (type === 'recruitment') {
      getOpportunityData(allMine);
    }
     else if (type === 'meetup') {
      // getUpcommingData(allMine);
      setTimeout(() => {
        setshowLoader(false);
      }, 1500);
    } 
    else if (type === 'credentials') {
      getSnapshotData();
    }
  }, [type]);

  // useEffect(() => {
  //  if(type ==='deals'){
  //   setType(type)
  //  }
  // }, [])

  const reanderImages = images => {
    return (
      <div className="gallery-imgs my-snapshots pb-5">
        <Row className="gallery-snapshot-row-holder profile-gallery-block">
          {Object.entries(images).length > 0 ? (
            Object.entries(images).length > 0 &&
            images.map((item, index) => {
              return (
                <Col md={4} sm={4} key={`gallery-img-${index}`}>
                  <div
                    className="gallery-item"
                    onClick={() => {
                      props.getUserData && props.getUserData(item.created_by);
                      props.handleCommand(item.id, 'snapshot');
                      // this.showLightbox(images, index);
                    }}>
                    {/* <span className="zoom-icon">
                                            <i className="fas fa-magnifying-glass" />
                                        </span> */}
                    <img src={item.images[0]} alt="" />
                  </div>
                </Col>
              );
            })
          ) : props.userId === localStorage.getItem('user') ? (
            <div className="no-results-wrapper">
              <div className="img-bg-wrapper">
                <img
                  src={require('../../Assets/images/norecord/no-snapshot.png')}
                  alt=""
                />
              </div>

              <div className="no-results-content">
                {props.permission &&
                (props.permission === 'allow' ||
                  props.permission?.edit_company === true) ? (
                  <>
                    <h2>Manage Credentials</h2>
                    <p>
                      Create shareable one-pagers for your business(es),
                      affiliations and more
                    </p>
                  </>
                ) : (
                  <h2>No Credentials Found</h2>
                )}

                {props.permission &&
                  (props.permission === 'allow' ||
                    props.permission?.edit_company === true) && (
                    <div className="btn-wrapper mt-3">
                      <Button
                        className="btn btn-dark"
                        onClick={() => {
                          props.handleCommand('', 'addNewSnapshot');
                        }}>
                        Add Credentials
                      </Button>
                    </div>
                  )}
              </div>
            </div>
          ) : (
            <p className="text-center">No record found</p>
          )}
        </Row>
      </div>
    );
  };

  // const OpportunityItem = item => {
  //   const heartClass = item.like ? 'fas' : 'far';
  //   return (
  //     <div
  //       // onClick={() => {
  //       //     props.handleCommand(item, "opportunity");
  //       // }}
  //       className="opportunity-item card">
  //       <h2>{item.title}</h2>
  //       <div className="oppo-share-wrapper">
  //         <span className="category">{item.opportunity_type_name}</span>
  //         <span className="oppo-share-icon">
  //           <i className="fas fa-share" />
  //         </span>
  //       </div>

  //       <RenderHTMLBlock className="oppo-desc" content={item.description} />

  //       <div className="oppo-address">
  //         <span className="address">
  //           <i className="icon-map-pin" /> {item.address}
  //         </span>
  //         <span className="injeaction-text">
  //           <i className="icon-injection" />{' '}
  //           {labels.partnership_text[selected_lang]}
  //           <b>
  //             {item.amount &&
  //               formatAmount(item.amount ? item.amount : '') + '/hr'}
  //           </b>
  //         </span>
  //         <span className="hearticon">
  //           <i className={`${heartClass} fa-heart`} />
  //         </span>
  //       </div>
  //     </div>
  //   );
  // };

  const SessionItem = item => {
    var totalMem = item.total_members + 1;
    var totalMode = item.total_moderators + 1;
    return (
      <div
        onClick={() => {
          props.handleCommand(item, 'session');
        }}
        className="session-item card as-link">
        <div className="session-heading">
          <h2>{item.session_title}</h2>
          <span className="users-text">
            <i className="fas fa-circle-user" /> {`${totalMem}/${totalMode}`}
          </span>
          <span className="category-text">{item.category_name}</span>
        </div>

        <div className="persons-img-list">
          {item && _renderCreatorImg(item)}
          {item.audience_ids &&
            item.audience_list &&
            Object.entries(item.audience_list).length > 0 &&
            item.audience_list.map((person, index) => _renderAudiImg(person))}
        </div>
        <div className="persons-name-list">
          <ul>
            {
              <li key={`person-name-creator`}>
                {
                  <span className="owner-icon">
                    <i className="fas fa-crown" />
                  </span>
                }
                {fixName(item.creator_first_name, item.creator_last_name)}
              </li>
            }
            {item.audience_ids &&
              item.audience_list &&
              Object.entries(item.audience_list).length > 0 &&
              item.audience_list.map((person, index) => (
                <li key={`person-name-${index}`}>
                  {/* {person.owner && (
                                        <span className="owner-icon">
                                            <i className="fas fa-crown" />
                                        </span>
                                    )}
                                    {!person.owner && person.speaker && (
                                        <span className="speaker-icon">
                                            <i className="fas fa-microphone" />
                                        </span>
                                    )} */}
                  {fixName(person.first_name, person.last_name)}
                </li>
              ))}
          </ul>
          {/* <ul>
                        {Object.entries(item.persons).length > 0 &&
                            item.persons.map((person, index) => (
                                <li key={`person-name-${index}`}>
                                    {person.owner && (
                                        <span className="owner-icon">
                                            <i className="fas fa-crown" />
                                        </span>
                                    )}
                                    {!person.owner && person.speaker && (
                                        <span className="speaker-icon">
                                            <i className="fas fa-microphone" />
                                        </span>
                                    )}
                                    {person.name}
                                </li>
                            ))}
                    </ul> */}
        </div>
        {item.address && (
          <div className="person-address-place" style={{fontSize: 15}}>
            <i className="fas fa-location-dot" /> {item.address}
          </div>
        )}
      </div>
    );
  };
  const OnselectValue = eventKey => {
    setType(eventKey);
    if (eventKey == 'payment') {
      getUserInvoices();
    }
  };
  const getTabstatus = () => {
    commonApi
      .get_tab_status()
      .then(res => {
        // setshowLoader(false);
        // setShowSpinner(false);
        //
        if (res.status === 200) {
          // setShowComponent('firstVist');
          const item = res.tab_status;
          if (item.length === 0) {
            setShowComponent('firstVist');
          } else {
            const newArray = [];

            if (Object.entries(item).length > 0) {
              item.forEach((item, index) => {
                newArray.push(item.tab);
              });
            }
            const found = newArray.find(element => element === 'snapshot');
            if (found === 'snapshot') {
              // setShowComponent('');
            } else {
              if (props.userId === localStorage.getItem('id')) {
                setShowComponent('firstVist');
              }
            }
          }
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  // const generateTabs = () => {
  //   if (
  //     userSnapshots.length > 0 &&
  //     userUpcomming.length > 0 &&
  //     opportunityData.length > 0
  //   ) {
  //     return ['Snapshots', 'Upcoming', 'Opportunities'];
  //   } else if (userSnapshots.length > 0 && userUpcomming.length > 0) {
  //     return ['Snapshots', 'Upcoming'];
  //   } else if (userSnapshots.length > 0 && opportunityData.length > 0) {
  //     return ['Snapshots', 'Opportunities'];
  //   } else if (userUpcomming.length > 0 && opportunityData.length > 0) {
  //     return ['Upcoming', 'Opportunities'];
  //   } else if (userSnapshots.length > 0) {
  //     return ['Snapshots'];
  //   } else if (userUpcomming.length > 0) {
  //     return ['Upcoming'];
  //   } else if (opportunityData.length > 0) {
  //     return ['Opportunities'];
  //   } else {
  //     return []; // No tabs when none of the arrays have length
  //   }
  // };

  useImperativeHandle(props.hitApiRef, () => ({
    getInvoice() {
      getUserInvoicesFilter('unpaid');
    },

    getFollowUps() {
      hitApiRef.current.getFollowUps();
    },
    getTasks() {
      hitApiRef.current.getTasks();
    },
  }));

  console.log(type,'lkANSIastype')

  const handleToggle = (isOpen) => {
    setOpen(isOpen);
  };

  return (
    <>
      {showComponent === 'firstVist' &&
        props.userId === localStorage.getItem('id') && (
          <OpportunityScreen
            onClose={() => {
              setShowComponent('');
            }}
            type={'snapshot'}
            setAddNewSnapshot={setAddNewSnapshot}
            handleSnapshot={() => {
              props.handleCommand('', 'addNewSnapshot');
            }}
          />
        )}

      <div
        className={`profile-gallery-wrapper menus_gallery my-menus-gallery ${
          props.userData ? 'profile-gallery' : ''
        }`}>
        {showComponent !== 'firstVist' && (
          <>
            {/* {showSpinner && <LoaderSpinner />} */}

            <div className="">
              <>
                <div
                  className="dropdown-search-icon d-flex mb-2 gallery_dropdown"
                  style={{marginLeft: '0.15rem'}}>
                  <div className="select-wrapper"
                  onClick={()=>{
                    handleToggle(!open)
                  }}>

                    <Dropdown
                     show={open}
                     onToggle={handleToggle}
                      onSelect={(eventKey, event) => {
                        props.setSessionFilterValue &&  props.setSessionFilterValue('all')
                        OnselectValue(eventKey);
                        if (
                          eventKey === 'credentials' ||
                          eventKey === 'recruitment' ||
                          eventKey === 'meetup'
                        ) {
                          setshowLoader(true);
                        }
                      }}
                      drop="down">
                      <Dropdown.Toggle variant="primary">
                        {type
                          ? dropdownMenu.find(item => item.tab === type)?.name
                          : ' '}
                           <i
           
          />
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        {dropdownMenu.map((item, index) => (
                          <Dropdown.Item
                            key={`st-${index}`}
                            eventKey={item.tab}
                            active={item.tab === type}>
                            {item.name}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>

                  <GalleryIcons
                    userId={props.userId}
                    galleryType={galleryType}
                    type={type}
                    profileGalleryType={props.type ? props.type : null}
                    handleCommand={props.handleCommand}
                    handleBlock={props.handleBlock}
                    userData={props.userData}
                    setThreeDots={setThreeDots}
                    threeDots={threeDots}
                    permission={props.permission ? props.permission : null}
                    fromPage={props.fromPage}
                  />

                  {(type === 'credentials' ||
                    (type === 'payment' && props.fromPage !== 'business')) && (
                    <div className="filter-icon filter-profile-gallery-icon gallery-custom-filter-new-icon">
                      <Button className="gal-custom-filter-btn">
                        <i
                          className="icon-filter-icon"
                          onClick={() => {
                            // setShowSnapshotModal(true);
                            if (type === 'credentials') {
                              props.handleCommand('', 'snapshot_filter');
                            } else if (type === 'payment') {
                              props.handleCommand('', 'payment_filter');
                            }
                          }}
                        />
                      </Button>
                    </div>
                  )}
                </div>

                <div className="refferal-list-wrapper refferal-business-list-wrapper">
                  {showLoader && (
                    <div className="loader_hieght">
                      {showLoader && <LoaderSpinner />}
                    </div>
                  )}

                  {!showLoader && (
                    <>
                      {galleryType === 'Upcoming' &&
                        props.userId === localStorage.getItem('id') && (
                          <div className="fieldset mt-3">
                            <div className="select-wrapper ">
                              <select
                                className="form-control"
                                value={upcomingFilter}
                                onChange={e => {
                                  setUpcomingFilter(e.target.value);
                                  getUpcommingData(e.target.value);
                                }}>
                                {['all', 'mine', 'others'].map(
                                  (item, index) => (
                                    <option
                                      value={item}
                                      key={`sesCate-${index}`}>
                                      {capitalizeFirst(item)}
                                    </option>
                                  ),
                                )}
                              </select>
                            </div>
                          </div>
                        )}

                      {galleryType === 'Opportunities' &&
                        props.userId === localStorage.getItem('id') && (
                          <div className="fieldset mt-3">
                            <div className="select-wrapper ">
                              <select
                                className="form-control"
                                value={opportunityFilter}
                                onChange={e => {
                                  setOpportunityFilter(e.target.value);
                                  getOpportunityData(e.target.value);
                                }}>
                                {['all', 'mine', 'others'].map(
                                  (item, index) => (
                                    <option
                                      value={item}
                                      key={`oppoCate-${index}`}>
                                      {capitalizeFirst(item)}
                                    </option>
                                  ),
                                )}
                              </select>
                            </div>
                          </div>
                        )}

                      <div className="img-gallery-wrapper px-0">
                        {type === 'payment' && (
                          <>
                            {/* {props && props?.fromPage == 'business' && */}
                            {props &&
                              (props.userId != localStorage.getItem('id') ||
                                props?.fromPage == 'business') && (
                                <div className="tranformation_filter category-btns-wrapper category-btns-wrapper-slider mt-3 mb-3">
                                  <CustomSlidingButtons
                                    items={categoryTypes}
                                    activeButtons={transformation_Filter}
                                    onItemClick={e => {
                                      if (e.target.value == 'Pending') {
                                        setPaymentType('unpaid');
                                        setTransformation_Filter([1]);
                                        getUserInvoicesFilter('unpaid');
                                      } else {
                                        setTransformation_Filter([2]);
                                        setPaymentType('paid');
                                        getUserInvoicesFilter('paid');
                                      }
                                      // alert(e.target.value)
                                      // handleClick(e);
                                    }}
                                    showItems={2}
                                  />
                                </div>
                              )}
                            {/* } */}

                            <div className="mt-2">
                              {Object.entries(invoices).length > 0 ? (
                                invoices.map((item, index) => (
                                  <div className="payment-card-main">
                                    <div
                                      className="as-link"
                                      onClick={() => {
                                        setInvoiceItem(item);
                                        setCurrentComponent('detailInvoice');
                                      }}>
                                      <div className="payment-content-card">
                                        <div className="task-profile-img">
                                          {/* <img
                                        src="https://s3.amazonaws.com/build-dev/event/slider/1692340229593.jpg"
                                        alt=""
                                      /> */}
                                          <img
                                            src={
                                              item.profile_img
                                                ? item.profile_img
                                                : DEFAULT_IMAGE
                                            }
                                            alt=""
                                          />
                                        </div>

                                        <div className="task-content">
                                          <h1
                                            style={{
                                              color: '#000',
                                              fontFamily: 'Inter',
                                              fontSize: '15px',
                                              fontStyle: 'normal',
                                              fontWeight: '700',
                                              lineHeight: 'normal',
                                            }}>
                                            {item.first_name +
                                              ' ' +
                                              item.last_name}
                                            {/* {fixName(item.first_name, item.last_name)} */}
                                            {/* Samantha Ellington */}
                                          </h1>

                                          <span
                                            style={{
                                              color: '#000',
                                              fontFamily: 'Inter',
                                              fontSize: '15px',
                                              fontStyle: 'normal',
                                              fontWeight: '500',
                                              lineHeight: 'normal',
                                            }}>
                                            {item.invoice_title
                                              ? item.invoice_title
                                              : ''}
                                          </span>
                                          <span
                                            className="project-title"
                                            style={{
                                              color: '#707070',
                                              fontFamily: 'Inter',
                                              fontSize: '14px',
                                              fontStyle: 'normal',
                                              fontWeight: '400',
                                              lineHeight: 'normal',
                                            }}>
                                            Invoice
                                          </span>
                                        </div>
                                      </div>

                                      <div className="payment-service-icons">
                                        <div className="roi-service-icons">
                                          <div className="service-icon">
                                            <img src={WhitePenIcon} alt="" />
                                          </div>

                                          <div className="service-icon-time">
                                            <img
                                              className=""
                                              src={timeIcon}
                                              alt=""
                                            />
                                            <span>5D</span>
                                          </div>

                                          <div className="service-icon w-25">
                                            <img src={FireIcon} alt="" />
                                          </div>

                                          <div className="service-icon w-29">
                                            <img src={MachineIcon} alt="" />
                                          </div>

                                          <div className="service-icon w-29">
                                            <img src={ArrowIcon} alt="" />
                                          </div>

                                          <div className="service-icon w-29">
                                            <img src={Bandage} alt="" />
                                          </div>

                                          <div className="service-icon w-28">
                                            <img src={heartIcon} alt="" />
                                          </div>

                                          <div className="service-icon w-29">
                                            <img src={bagIcon} alt="" />
                                          </div>
                                          <div className="w-29">
                                            <i
                                              className="fa-solid fa-ellipsis"
                                              style={{
                                                color: '#0a0a0a',
                                                paddingTop: '15px',
                                              }}></i>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="payment-card-bottom">
                                      <div
                                        className="payment-card-icons"
                                        style={{
                                          color: '#262626',

                                          fontFamily: 'Inter',
                                          fontSize: '15px',
                                          fontStyle: 'normal',
                                          fontWeight: '600',
                                          lineHeight: 'normal',
                                          paddingTop: '11px',
                                        }}>
                                        {moment(item?.due_date)
                                          .format('MM/DD/YY')
                                          .toString()}
                                      </div>
                                      <div className="pay-btn">
                                        <span
                                          style={{
                                            color: '#262626',
                                            fontFamily: 'Inter',
                                            fontSize: '15px',
                                            fontStyle: 'normal',
                                            fontWeight: '600',
                                            lineHeight: 'normal',
                                          }}>
                                          {' '}
                                          ${item.total}
                                        </span>

                                        {props &&
                                          props?.fromPage != 'business' &&
                                          item.action_status !== 'paid' &&
                                          props.selectedPaymentFilter !==
                                            'from_me' && (
                                            <Button
                                              className="new-btn btn-green ml-3 "
                                              onClick={() => {
                                                props.handleCommand(
                                                  {
                                                    price: item.total,
                                                    paymentId: item.payment_id,
                                                  },
                                                  'stripeModal',
                                                );
                                                // setSelectedPrice(item.total);
                                                // setPaymentId(item.payment_id);
                                                // setShowStripeModal(true);
                                              }}>
                                              Pay
                                            </Button>
                                          )}
                                      </div>
                                    </div>
                                  </div>
                                ))
                              ) : (
                                <div className="no-results-wrapper mt-5">
                                  <div className="no-results-content mt-5">
                                    <span>No Payments found</span>
                                    {props.fromPage !== 'business' && (
                                      <div className="btn-wrapper mt-3">
                                        <Button
                                          className="btn btn-dark"
                                          onClick={() => {
                                            props.handleCommand(
                                              '',
                                              'create_invoice',
                                            );
                                          }}>
                                          Create Invoice
                                        </Button>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              )}
                            </div>
                          </>
                        )}
                        {type === 'credentials' &&
                          !addNewSnapshot &&
                          reanderImages(userSnapshots)}
                        {type === 'meetup' ? (
                          <>

                        
                          <ProfileMeetup
                          userId={props.userId}
                          setshowLoader={setshowLoader}
                          handleBlock={props.handleBlock}
                          userInfo={props.userData}
                          handleCommand={props.handleCommand}
                          hitApiRef={hitApiRef}
                          threeDots={threeDots}
                          sessionFilterValue={props.sessionFilterValue}
                          />
                          
                            {/* {Object.entries(userUpcomming).length > 0 ? (
                              Object.entries(userUpcomming).length > 0 &&
                              userUpcomming.map((item, index) => (
                                <div key={`ses-item-${index}`}>
                                  {item && SessionItem(item)}
                                </div>
                              ))
                            ) : props.userId ===
                              localStorage.getItem('user') ? (
                              <div className="no-results-wrapper">
                               

                                <div className="no-results-content">
                                  <h2>
                                    You don't have any upcoming activities.
                                  </h2>
                                  <p>
                                    Grow your personal and professional circles
                                    by creating and attending group sessions and
                                    events.
                                  </p>

                                  <div className="btn-wrapper mt-3">
                                    <Button
                                      className="btn btn-dark"
                                      onClick={() => {
                                        handleClickLink('/');
                                      }}>
                                      Explore Sessions and Events
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <></>
                            )} */}
                          </>
                        ) : (
                          <></>
                        )}
                        {type === 'recruitment' ? (
                          <>

                          <ProfileRecruitment
                          userId={props.userId}
                          setshowLoader={setshowLoader}
                          handleBlock={props.handleBlock}
                          userInfo={props.userData}
                          handleCommand={props.handleCommand}
                          hitApiRef={hitApiRef}
                          threeDots={threeDots}
                          sessionFilterValue={props.sessionFilterValue}
                          />
                            {/* <div className="opportunities-list-wrapper">
                              {Object.entries(opportunityData).length > 0 ? (
                                Object.entries(opportunityData).length > 0 &&
                                opportunityData.map((item, index) => (
                                  <OpportunityList
                                    key={`opportunity-item-${index}`}
                                    item={item}
                                    handleCommand={props.handleCommand}
                                  />
                                ))
                              ) : props.userId ===
                                localStorage.getItem('user') ? (
                                <div className="no-results-wrapper">
                                 

                                  <div className="no-results-content">
                                    <h2>
                                      You are not participating in any
                                      opportunities.
                                    </h2>
                                    <p>
                                      Create or explore opportunities to
                                      accelerate your growth. Find partners,
                                      seek funding, invest and so much more.
                                    </p>

                                    <div className="btn-wrapper mt-3">
                                      <Button
                                        className="btn btn-dark"
                                        onClick={() => {
                                          handleClickLink('/recruitment');
                                        }}>
                                        Explore Opportunities
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <></>
                              )}
                            </div> */}
                          </>
                        ) : (
                          <></>
                        )}
                        {/* {galleryType && galleryType === "snapshot" && this.reanderImages(gallery.snapshots)}
                        {galleryType && galleryType === "upcoming" && this.reanderImages(gallery.upcoming)}
                        {galleryType && galleryType === "opportunity" && this.reanderImages(gallery.opportunities)} */}
                      </div>

                      {type === 'projects' && (
                        <>
                          {/* <div className={`payment-user-item no-link`}>
                      <h1>Before</h1>
                    </div>
                    <div className={`payment-user-item no-link`}>
                      <div
                        className="invoice-img as-link"
                        style={{width: ' 145px', height: '137px'}}></div>

                      <div
                        className="invoice-img as-link"
                        style={{width: ' 145px', height: '137px'}}></div>
                    </div> */}
                          <ProfileProjects
                            handleTransform={props.handleCommand}
                            userData={props.userData}
                            userId={props.userId}
                            companyData={props.companyData}
                            setTransformationList={setTransformationList}
                            transformationList={transformationList}
                            threeDots={threeDots}
                            showComponent={props.showComponent}
                            Profile={props.fromPage}
                          />
                        </>
                      )}

                      {type === 'overview' && (
                        <ProfileOverview
                          userInfo={props.userData}
                          handleCommand={props.handleCommand}
                          handleBlock={props.handleBlock}
                        />
                      )}

                      {type === 'review' &&
                        (props.userId !== localStorage.getItem('id') ? (
                          <Review
                            //  setShowComponent={setShowComponent}
                            userData={props.userData?.data}
                            userInfo={props.userData}
                          />
                        ) : (
                          props.userId === localStorage.getItem('id') && (
                            <ReviewListing userData={props.userData?.data} />
                          )
                        ))}

                      {type === 'appointment' && (
                        <ProfileAppointmentList
                          userData={props.userData.data}
                          handleBlock={props.handleBlock}
                          userInfo={props.userData}
                          handleCommand={props.handleCommand}
                          hitApiRef={hitApiRef}
                          threeDots={threeDots}
                          selectedFilterValue={props.selectedFilterValue}
                        />
                      )}

                      {type === 'followUp' && (
                        <ProfileFollowUp
                          handleCommand={props.handleCommand}
                          userData={props.userData}
                          hitApiRef={hitApiRef}
                        />
                      )}

                      {type === 'tasks' && (
                        <ProfileTask
                          handleCommand={props.handleCommand}
                          userData={props.userData}
                          hitApiRef={hitApiRef}
                          threeDots={threeDots}
                          filterOptionValue={props.filterOptionValue}
                        />
                      )}
                      {type === 'property' && (
                       <NorecordFound   noRecordData={propertyNoRecordFound}/>
                      )}

                      {type === 'deals' && (
                        <ProfileDeals
                          handleCommand={props.handleCommand}
                          userData={props.userData}
                        />
                      )}

                      {type === 'pipeline' && (
                        <ProfilePipeline
                          handleCommand={props.handleCommand}
                          userData={props.userData}
                        />
                      )}
                      
                    </>
                  )}
                </div>
              </>
            </div>
            {show && (
              <ImageLightbox
                show={show}
                current={current}
                images={popupImages}
                setPopupImages={setPopupImages}
                onClose={() => {
                  // this.setState({ show: false, current: 0 });
                  setShow(false);
                  setCurrent(0);
                }}
              />
            )}

            {showSnapshotModal && (
              <CustomModal
                size="medium"
                className={'filter-modal from-otherProfile-Snapshot'}
                style={{height: '85%'}}
                onClose={() => setShowSnapshotModal(false)}>
                <div className="filter-wrapper custom-modal-filter mb-0">
                  <div className="filter-headings text-center">
                    <h2>Filter By Category</h2>
                  </div>
                  <Scrollbars>
                    <div className="filter-category-list">
                      <ul>
                        <li
                          key={`sessionC-100`}
                          className={
                            categoriesArr.length === 8 ? 'active' : ''
                          }>
                          <Form.Label>
                            {'All'}
                            <input
                              type="checkbox"
                              value={'{"id":"All", "title":"All"}'}
                              onChange={handleSnapshotSelection}
                              checked={categoriesArr.length === 8}
                            />
                            <span />
                          </Form.Label>
                        </li>
                        {Object.entries(snapshotCategories).length > 0 &&
                          snapshotCategories.map((item, index) => (
                            <li
                              key={`sessionC-${index}`}
                              className={
                                categoriesArr.includes(item.id) ? 'active' : ''
                              }>
                              <Form.Label>
                                {item.category_name}
                                <input
                                  type="checkbox"
                                  value={
                                    '{"id":"' +
                                    item.id +
                                    '", "title":"' +
                                    item.category_name +
                                    '"}'
                                  }
                                  onChange={handleSnapshotSelection}
                                  checked={categoriesArr.includes(item.id)}
                                />
                                <span />
                              </Form.Label>
                            </li>
                          ))}
                      </ul>
                    </div>
                  </Scrollbars>
                  <div className="filter-category-list text-center">
                    <Button
                      className="btn-dark"
                      onClick={() => {
                        getSnapshotData(categoriesArr);
                        setShowSnapshotModal(false);
                      }}>
                      Filter
                    </Button>
                  </div>
                </div>
              </CustomModal>
            )}
          </>
        )}
      </div>

      {addNewSnapshot === true && (
        <AddNewSnapShot
          onClose={() => {
            setAddNewSnapshot(false);
          }}
        />
      )}

      {showStripeModal && (
        <CustomModal
          size="small"
          onClose={() => {
            setShowStripeModal(false);
            setSelectedPrice('');
            setPaymentId('');
          }}
          className="availability-modal">
          <div
            className="new-session-modal-wrapper text-center  "
            style={{width: '-webkit-fill-available'}}>
            <div className="new-session-modal estimate_name_modal">
              <Elements
                stripe={stripePromise}
                options={{
                  mode: 'payment',
                  amount: 10 * 100,
                  currency: 'usd',
                  paymentMethodTypes: ['card'],
                  payment_method_types: ['card'],
                }}>
                <CheckoutForm
                  price={selectedPrice}
                  paymentId={paymentId}
                  onClose={() => {
                    setShowStripeModal(false);
                    setSelectedPrice('');
                    setPaymentId('');
                  }}
                />
              </Elements>
            </div>
          </div>
        </CustomModal>
      )}

      {}
    </>
  );
};

export default ProfileGallery;
