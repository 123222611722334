import React, {useState, useEffect, useRef} from 'react';
import {GoogleMap, Marker} from '@react-google-maps/api';
import commonApi from '../../Services/CommonService';
import Slider from 'react-slick';
import {useSelector, useDispatch} from 'react-redux';
import OpportunityMessageList from '../OpportunitiesList/OpportunityMessageList';
import SlidingPanel from '../SlidingPanel';
import Scrollbars from 'react-custom-scrollbars';
import OpportunityDetail from '../OpportunitiesList/OpportunityDetail';
import {getOpportunityListing} from '../../Store/Actions/OpportunityAction';
import {chatroomInfoItem, chatroomInfoOppo} from '../../Utils/commonUtils';
import SmartChatPage from '../Common/SmartChatPage';
import {getItem, setItem} from '../../Utils/LocalStorage';
import {DEFAULT_LAT, DEFAULT_LNG} from '../../../src/Utils/env';

const mapContainerStyle = {
  width: '100%',
  height: 500,
};

const center = {
  lat: DEFAULT_LAT,
  lng: DEFAULT_LNG,
};

const options = {
  disableDefaultUI: true,
  zoomControl: true,
};

const OpportunityMap = props => {
  const [allData, setAllData] = useState([]);
  // const [showLoader, setshowLoader] = useState(false);
  const sliderRef = useRef(null);
  const [opportunityDataMap, setOpportunityDataMap] = useState([]);
  const [showComponent, setShowComponent] = useState([]);
  const [opportunityType, setOpportunityType] = useState(
    'available_opportunities',
  );

  const [map, setMap] = useState(null);
  const [currentLocation, setCurrentLocation] = useState(null);
  // const [centerLocation, setCenterLocation] = useState(null);
  const [markers, setMarkers] = useState(null);
  const [allMarkers, setAllMarkers] = useState(null);

  const [chatRoomStatus, setChatRoomStatus] = useState('pending');
  const [chatRoomName, setChatRoomName] = useState('');
  const [chatRoomId, setChatRoomId] = useState('');
  const [allChatUsers, setAllChatUsers] = useState([]);
  const [oppoDetails, setOppoDetails] = useState([]);

  const dispatch = useDispatch();
  const opportunityData = useSelector(state => state.opportunityListing);
  // const questionData = useSelector(state => state.questionListing);

  const handle = item => {
    // const currentSlideItem = sliderRef.current.innerSlider.state.currentSlide;
    setItem('onlyZoom', 'yes');
    allData?.forEach((element, index) => {
      if (item === index) {
        setAllMarkers([
          {
            id: element.id,
            latitude: parseFloat(element.latitude),
            longitude: parseFloat(element.longitude),
          },
        ]);
        map.panTo({
          lat: parseFloat(element.latitude),
          lng: parseFloat(element.longitude),
        });
        map.setZoom(15);
      }
    });

    // // setOppr(item)
  };
  const markupClick = (event, item) => {
    // setSelectedMarker(i);
    //
    // const currentSlideItem = sliderRef.current.innerSlider.state.currentSlide;
    if (allMarkers && allMarkers.length > 1) {
      setItem('onlyZoom', 'yes');
      if (item.id === 'mine') {
      } else {
        allData?.forEach((element, index) => {
          if (item.id === element.id) {
            sliderRef.current.slickGoTo(index);
          }
        });
      }
      map.panTo({lat: item.latitude, lng: item.longitude});
      map.setZoom(15);
    } else if (allMarkers && allMarkers.length === 1 && item.id === 'mine') {
      map.panTo({lat: item.latitude, lng: item.longitude});
      map.setZoom(15);
    }
  };

  const settingsWeb = {
    dots: false,
    arrows: true,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    afterChange: handle,
  };

  const onMapLoad = map => {
    // mapRef.current = map;
    setMap(map);
  };

  const eventListLocation = (
    lng = DEFAULT_LNG,
    latt = DEFAULT_LAT,
    qry = '',
  ) => {
    var work = {
      latitude: latt,
      longitude: lng,
      page: 1,
      radius: 100,
    };
    //

    commonApi
      .get_opportuntiy_location(work)
      .then(res => {
        //
        if (res.status === 200) {
          if (res.data) {
            setAllData(res.data);
            let markerArr = [];
            for (let index = 0; index < res.data.length; index++) {
              const element = res.data[index];
              markerArr = [
                ...markerArr,
                {
                  id: element.id,
                  latitude: parseFloat(element.latitude),
                  longitude: parseFloat(element.longitude),
                },
              ];
            }
            setMarkers(markerArr);
            // if (qry) {
            //   map.panTo({
            //     lat: parseFloat(res.data[0].latitude),
            //     lng: parseFloat(res.data[0].longitude),
            //   });
            // }
          }

          // setshowLoader(false);

          // //
          // setlug(parseFloat(res.data[0].longitude));
          // //
          // //

          // setlat(parseFloat(res.data[0].latitude));
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const onMapChange = ({center, zoom}) => {
    // setMapData({
    //   center,
    //   zoom,
    // });
    // setCenterLocation({
    //   id: 'center',
    //   latitude: center.lat,
    //   longitude: center.lng,
    // });

    // Send center and zoom values to API
    // ...
    if (getItem('onlyZoom') && getItem('onlyZoom') === 'yes') {
      setItem('onlyZoom', 'no');
    } else {
      eventListLocation(center.lng, center.lat);
    }
  };

  useEffect(() => {
    setItem('onlyZoom', 'no');
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          setCurrentLocation({
            id: 'mine',
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
          // setCenterLocation({
          //   id: 'center',
          //   latitude: position.coords.latitude,
          //   longitude: position.coords.longitude,
          // });
        },
        error => {
          // setCenterLocation({
          //   id: 'center',
          //   latitude: DEFAULT_LAT,
          //   longitude: DEFAULT_LNG,
          // });
          console.log(error);
        },
      );
    } else {
      // setCenterLocation({
      //   id: 'center',
      //   latitude: DEFAULT_LAT,
      //   longitude: DEFAULT_LNG,
      // });
      console.log('Geolocation is not supported by this browser.');
    }
    eventListLocation();
  }, []);

  useEffect(() => {
    if (map) {
      const listener = map.addListener('center_changed', () => {
        onMapChange({
          center: {
            lat: map.getCenter().lat(),
            lng: map.getCenter().lng(),
          },
          zoom: map.getZoom(),
        });
      });
      return () => new window.google.maps.event.removeListener(listener);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map]);

  useEffect(() => {
    if (currentLocation && markers) {
      setAllMarkers([currentLocation, ...markers]);
    } else if (currentLocation) {
      setAllMarkers([currentLocation]);
    } else if (markers) {
      setAllMarkers(markers);
    }
  }, [markers, currentLocation]);

  const handleItem = item => {
    setOpportunityDataMap(item);

    //

    if (opportunityType === 'available_opportunities') {
      if (parseInt(item.created_by) === parseInt(localStorage.getItem('id'))) {
        setShowComponent('mine-opportunity');
      } else if (item.team_member_details.length > 0) {
        const found = item.team_member_details.find(
          objs => parseInt(objs.id) === parseInt(localStorage.getItem('id')),
        );
        if (typeof found === 'object') {
          setShowComponent('mine-opportunity');
        } else if (item.chat_detail.length === 0) {
          setShowComponent('respond-opportunity');
        }
      } else if (item.chat_detail.length === 0) {
        setShowComponent('respond-opportunity');
      } else if (Object.entries(item.chat_detail).length > 0) {
        setShowComponent('other-opportunity');
      }
    } else {
      if (Object.entries(item.chat_detail).length > 0) {
        setShowComponent('other-opportunity');
      } else {
        setShowComponent('new-opportunity');
      }
    }
  };

  const PersonWebItem = ({item}) => {
    let distance = item.distance?.slice(0, 4);
    return (
      <div
        className="person-web-item"
        onClick={() => {
          handleItem(item);
        }}>
        <br />
        <div className="person-web-link">
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a rel="noopener noreferrer">{item.title}</a>
          <br />
          <span>{item.business_name}</span>
          <br />
          <span>{distance}mi away</span>
        </div>
      </div>
    );
  };

  return (
    <>
      <SlidingPanel hidePanel={() => props.onClose()}>
        <div className="serach-filter-wrapper">
          {/* <div className="headings fix-width-forms">
            <h2>&nbsp;</h2>
          </div> */}
          {/* <div className="search-tabs-wrapper fix-width-forms">
            <div className="search-form d-flex mt-0 text-center">
              <Form.Group className="search-input">
                <Form.Control
                  onChange={e => {
                    setSearchBar(e.target.value);
                    eventListLocation(
                      centerLocation.longitude,
                      centerLocation.latitude,
                      e.target.value,
                    );
                  }}
                  value={searchBar}
                  type="text"
                  placeholder="Serach for an event"
                />
              </Form.Group>
              <Button>
                <i
                  className="icon-filter-icon"
                  onClick={() => setSearchFilterModal(true)}
                />
              </Button>
            </div>
          </div> */}
          <Scrollbars>
            <div className="pb-4 fix-width-forms recruitment_slider_main">
              {Object.entries(allData).length > 0 ? (
                Object.entries(allData).length > 0 && (
                  <div className="session-website-slider recruitment_slider as-link ">
                    <Slider {...settingsWeb} ref={sliderRef}>
                      {allData.map((item, index) => (
                        <PersonWebItem
                          key={`website-adasd-${index}`}
                          item={item}
                        />
                      ))}
                    </Slider>
                  </div>
                )
              ) : (
                <p className="text-center">No record found</p>
              )}

              <GoogleMap
                mapContainerStyle={mapContainerStyle}
                zoom={10}
                center={center}
                options={options}
                onLoad={onMapLoad}
                onUnmount={() => setMap(null)}>
                {allMarkers &&
                  Object.entries(allMarkers).length > 0 &&
                  allMarkers.map((marker, index) => (
                    <Marker
                      key={index}
                      position={{lat: marker.latitude, lng: marker.longitude}}
                      icon={{
                        url:
                          marker.id === 'mine'
                            ? 'https://maps.google.com/mapfiles/ms/icons/red-dot.png'
                            : 'https://maps.google.com/mapfiles/ms/icons/green-dot.png',
                      }}
                      onClick={event => markupClick(event, marker)}
                    />
                  ))}
              </GoogleMap>
            </div>
          </Scrollbars>
        </div>
      </SlidingPanel>

      {showComponent === 'other-opportunity' && (
        <SlidingPanel
          hidePanel={() => {
            dispatch(getOpportunityListing(opportunityData.payload));
            setShowComponent('');
          }}>
          <SmartChatPage
            type={'other-opportunity'}
            allmsg={true}
            chatroomInfo={chatroomInfoOppo(opportunityDataMap, 'opportunity')}
            item={[]}
            oppoDetails={opportunityDataMap}
            chatDetails={opportunityDataMap.chat_detail}
            allChatUsers={
              opportunityDataMap.hasOwnProperty('creator_details')
                ? [opportunityDataMap.creator_details]
                : [opportunityDataMap.creator_detail]
            }
            chatRoomName={
              opportunityDataMap.chat_detail.hasOwnProperty('chat_room_name')
                ? opportunityDataMap.chat_detail.chat_room_name
                : ''
            }
            chatRoomId={
              opportunityDataMap.chat_detail.hasOwnProperty('id')
                ? opportunityDataMap.chat_detail.id
                : ''
            }
            onClose={() => {
              dispatch(getOpportunityListing(opportunityData.payload));
              setShowComponent('');
            }}
            userData={
              opportunityDataMap.hasOwnProperty('creator_details')
                ? opportunityDataMap.creator_details
                : opportunityDataMap.creator_detail
            }
            chatRoomStatus={
              opportunityDataMap.chat_detail.hasOwnProperty('status')
                ? opportunityDataMap.chat_detail.status
                : 'pending'
            }
            // handleCommonChat={(data, type)=>{handleCommonChat(data, type)}}
          />
        </SlidingPanel>
      )}

      {showComponent === 'new-opportunity' && (
        <SlidingPanel
          hidePanel={() => {
            dispatch(getOpportunityListing(opportunityData.payload));

            setShowComponent('');
          }}>
          <SmartChatPage
            type={'new-opportunity'}
            allmsg={true}
            item={[]}
            oppoDetails={opportunityDataMap}
            chatDetails={opportunityDataMap.chat_detail}
            chatroomInfo={chatroomInfoItem(opportunityDataMap)}
            allChatUsers={
              opportunityDataMap.hasOwnProperty('creator_details')
                ? [opportunityDataMap.creator_details]
                : [opportunityDataMap.creator_detail]
            }
            chatRoomName={
              opportunityDataMap.chat_detail.hasOwnProperty('chat_room_name')
                ? opportunityDataMap.chat_detail.chat_room_name
                : ''
            }
            chatRoomId={
              opportunityDataMap.chat_detail.hasOwnProperty('id')
                ? opportunityDataMap.chat_detail.id
                : ''
            }
            onClose={() => {}}
            userData={
              opportunityDataMap.hasOwnProperty('creator_details')
                ? opportunityDataMap.creator_details
                : opportunityDataMap.creator_detail
            }
            chatRoomStatus={
              opportunityDataMap.chat_detail.hasOwnProperty('status')
                ? opportunityDataMap.chat_detail.status
                : 'pending'
            }
            // handleCommonChat={(data, type)=>{handleCommonChat(data, type)}}
          />
        </SlidingPanel>
      )}
      {showComponent === 'respond-opportunity' && (
        <OpportunityDetail
          item={opportunityDataMap}
          userData={opportunityDataMap.creator_details}
          onClose={() => {
            setShowComponent('');
          }}
        />
      )}
      {/* {chatRoomName !== '' &&
        allChatUsers.length > 0 &&
        showComponent === 'show-chats' && (
          <SlidingPanel
            hidePanel={() => {
              setChatRoomName('');
              //   setShowButton(true);
              setShowComponent('mine-opportunity');
            }}>
            <SmartChatPage
              allmsg={true}
              type={'mine-opportunity'}
              item={opportunityDataMap}
              chatroomInfo={chatroomInfoOppoMsgList(oppoDetails, 'opportunity')}
              oppoDetails={oppoDetails}
              allChatUsers={allChatUsers}
              chatDetails={oppoDetails.chat_detail}
              chatRoomName={chatRoomName}
              chatRoomId={chatRoomId}
              chatRoomStatus={chatRoomStatus}
              onClose={() => {
                setChatRoomName('');
                // setShowButton(true);
                setShowComponent('mine-opportunity');
              }}
            />
          </SlidingPanel>
        )} */}
      {showComponent === 'mine-opportunity' && (
        <OpportunityMessageList
          item={opportunityDataMap}
          setOppoDetails={setOppoDetails}
          oppoDetails={oppoDetails}
          setAllChatUsers={setAllChatUsers}
          allChatUsers={allChatUsers}
          setChatRoomId={setChatRoomId}
          chatRoomId={chatRoomId}
          setChatRoomName={setChatRoomName}
          chatRoomName={chatRoomName}
          setChatRoomStatus={setChatRoomStatus}
          setOpportunityType={setOpportunityType}
          chatRoomStatus={chatRoomStatus}
          onClose={() => {
            dispatch(getOpportunityListing(opportunityData.payload));
            setShowComponent('');
          }}
          // onEditOppo={() => {
          //   setProperComponent('fromOppoMsg'); // temparary majbori
          //   // setInviteUser(true);
          //   setCreateSessionEvent(false);
          // }}
          // onCreateSessionEvent={() => {
          //   setCreateSessionEvent(true);
          //   // setInviteUser(false);
          //   setProperComponent('');
          // }}
          onShowChat={() => {
            setShowComponent('show-chats');
          }}
        />
      )}
    </>
  );
};
export default OpportunityMap;
