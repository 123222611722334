import React, { useEffect, useState} from 'react';
import {Button} from 'react-bootstrap';
import Slider from 'react-slick';
import {generateId} from '../Utils/Helpers';

const CustomSlidingTabs = ({
  items,
  onItemClick,
  activeTab,
  showItems,
  component,
}) => {
  const [tabId, setTabId] = useState('');
  //const [slidesToShow] =useState(showItems);
  const settings = {
    arrows: true,
    dots: false,
    infinite: false,
    speed: 500,
    // slidesToShow: showItems ? showItems : 2,
    slidesToShow: showItems ? showItems : 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    const tabId = generateId();
    setTabId(tabId);
  }, []);
 
  return (
    <Slider {...settings}>
      {items &&
        items.map((item, index) => {
          return (
            <>
              {item?.img ? (
                <Button
                  key={`custom-tabs-${tabId}-${index}`}
                  className={
                    activeTab === item.key ? 'active product-sliding-btn' : ''
                  }
                  onClick={() => onItemClick(item.key)}>
                  {item.img}
                </Button>
              ) : item?.icon ? (
                <Button
                  key={`custom-tabs-${tabId}-${index}`}
                  className={`${
                    component === 'productComponent' ? 'sliding-icons' : ''
                  } ${activeTab === item.key ? 'active' : ''}`}
                  onClick={() => onItemClick(item.key)}>
                  <i className={item.icon}></i>
                </Button>
              ) : (
                <Button
                  key={`custom-tabs-${tabId}-${index}`}
                  className={activeTab === item.key ? 'active' : ''}
                  onClick={() => onItemClick(item.key)}>
                  {item.label}
                </Button>
              )}
            </>
          );
        })}
    </Slider>
  );
};

export default CustomSlidingTabs;
