import {labels} from '../../Constants/Translations';
import {selected_lang} from '../../Utils/env';
import {RenderHTMLBlock} from '../../Utils/Helpers';
import React, {useState, useEffect} from 'react';

import commonApi from '../../Services/CommonService';
import {successMessage} from '../../Utils/ToastMessages';
import {formatAmount} from '../../Utils/commonUtils';
import {useHistory} from 'react-router-dom';
import { fixName } from '../../Utils/commonUtils';
import { favouriteRecruitment } from '../../Store/Reducers/DeepLinkReducer';
import {useDispatch, useSelector} from 'react-redux';
import {  manageFavRecruitmentItem } from '../../Store/Actions/DeepLinkAction';

const OpportunityItem = ({
  item,
  setShowButton,
  handleItem,
  onShare,
  setChatData,
  hideRefer,
  filterFavroiuteList,
  type,
  AddUserInCompany,
  handleLoginModal
  // AddUserInCompany,
}) => {
  // const [showButton, setShowButton] = useState(false);
  // const [newUserId, setNewUserId] = useState('');
  const history = useHistory();
  const [favourite, setFavourite] = useState(item.like);
  const heartClass = favourite ? 'fas' : 'far';
  const newFavourite = useSelector(state => state.favouriteRecruitment);
  const [showMore, toggleShowMore] = useState(false);
  const showMoreClass = showMore ? 'show-all' : '';
  const dispatch = useDispatch();
  
  useEffect(() => {
    item.like = favourite;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [favourite]);


  useEffect(() => {
    if (item.id === newFavourite.id) {
     
      setFavourite(prev => !prev);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newFavourite]);

  const favouriteOpportunity = () => {
    let payload = {
      opportunity_id: item.id,
    };
    dispatch(manageFavRecruitmentItem(item.id));
    commonApi
      .manage_favourite_opportunity(payload)
      .then(res => {
        if (res.status === 200) {
          // if (res.success) {
          //   item.like = true;
          //   setFavourite(true);
          // } else {
          //   item.like = false;
          //   setFavourite(false);
          // }
          successMessage(res.message);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const getOpportunityData = (userData, id) => {
    var data = {
      user_id: userData,
      type: 'mine',
    };
    commonApi
      .get_user_available_opportunities(data)
      .then(res => {
        if (res) {
          res.user_available_opportunity.forEach(item => {
            if (item.id === id) {
              setChatData(item.chat_detail);
              // setChatData(item.chat_detail.id);
            }
          });

          // setChatData(res.user_available_opportunity.chat_detail);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  // const functionId = userData => {
  //   setNewUserId(userData);
  // };

  const {pathname} = window.location;
  const urlParts = pathname.split('/').filter(Boolean);

  const searchParams = new URLSearchParams();
  searchParams.append('id', item.id);
  const url = urlParts[1]
    ? `/${
        urlParts[0] + '/' + urlParts[1]
      }/recruitment/invite?${searchParams.toString()}`
    : `/${
        urlParts[0] + '/' + urlParts[1]
      }/recruitment/invite?${searchParams.toString()}`;

      
   

  return (
    <>
      <div className="opportunity-item card">
        <h2
          onClick={() => {
            // setShowButton(true);
            if (type === 'link_Profile') {
              AddUserInCompany(item, url);
            } else {
              handleItem(item);
              getOpportunityData(item.created_by, item.id);
            }
          }}>
          {item.title}
        </h2>
        <div className="oppo-share-wrapper as-link ">
        <div
        className=""
        style={{flexBasis:'50%'}}
       >
        
        <span className='creator_name'>
        {fixName(item.creator_details.first_name, item.creator_details.last_name)}  
        </span>
           
          
          
          
       
      </div>
          <span
            className="category as-link "
            onClick={() => {
              if (type === 'link_Profile') {

                // AddUserInCompany(item, url);
               handleLoginModal( item, url)
              } else {
                handleItem(item);
                getOpportunityData(item.created_by, item.id);
              }
              // setShowButton(true);
            }}>
            {item.business_name}
          </span>
          {!hideRefer && (
            <span className="oppo-share-icon">
              <i
                className="fas fa-share"
                onClick={() => {
                  if (type === 'link_Profile') {
                    AddUserInCompany(item, url);
                  } else {
                    onShare();
                    handleItem(item, 'refer-opportunity');
                  }
                }}
              />
            </span>
          )}
        </div>


     

        <div
          className="oppo-desc as-link mb-1"
          onClick={() => {
            if (type === 'link_Profile') {
              AddUserInCompany(item, url);
            } else {
              handleItem(item);
              getOpportunityData(item.created_by, item.id);
            }
            // setShowButton(true);
          }}>
          <RenderHTMLBlock
            className={`oppo-desc-content ${showMoreClass}`}
            content={item.description}
          />
        </div>

        {item.description.length > 100 && (
          <div className="oppo-desc as-link ">
            <span
              className="read-more"
              onClick={() => toggleShowMore(!showMore)}>
              {!showMore
                ? labels.read_more[selected_lang]
                : labels.read_less[selected_lang]}
            </span>
          </div>
        )}

        <div className="oppo-address as-link mt-2">
          {item.address && (
            <span
              className="address oppo_item_address"
              onClick={() => {
                if (type === 'link_Profile') {
                  AddUserInCompany(item, url);
                } else {
                  // setShowButton(true);
                  handleItem(item);
                  getOpportunityData(item.created_by, item.id);
                }
              }}>
              <i className="icon-map-pin" /> {item.address}
            </span>
          )}

          <div className='d-flex gap-1 align-items-center justify-content-center'>
          <span
            className="injeaction-text" style={{marginBottom:'0px'}}
            onClick={() => {
              if (type === 'link_Profile') {
                AddUserInCompany(item, url);
              } else {
                // setShowButton(true);
                handleItem(item);
                getOpportunityData(item.created_by, item.id);
              }
            }}>
            {item.vaccinate === 'yes' && <i className="icon-injection" />}{' '}
            {item.opportunity_type_name}
            <b>
              {item.amount &&
                formatAmount(item.amount ? item.amount : '') + '/hr'}
            </b>
          </span>
          {!hideRefer && (
            <span
              className="hearticon"
              onClick={() => {
                if (filterFavroiuteList) {
                  filterFavroiuteList(item.id);
                }
                favouriteOpportunity();
                setFavourite(!favourite)
              }}>
              <i className={`${heartClass} fa-heart as-link`} />
            </span>
          )}
          </div>
          
        </div>
      </div>
      {/* {showButton === true && <OpportunityDetail  item={item} userData={item.creator_details
} onClose={()=>{setShowButton(false);} }/>} */}
    </>
  );
};

export default OpportunityItem;
