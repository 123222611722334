import React, {useState, useEffect} from 'react';

import Sidebar from '../Sidebar';
import {successMessage} from '../../Utils/ToastMessages';
import Scrollbars from 'react-custom-scrollbars';
import commonApi from '../../Services/CommonService';
import {Pannels} from '../../Components';
import LoaderSpinner from '../../Components/Modals/LoaderSpinner';
import {DEFAULT_IMAGE, selected_lang} from '../../Utils/env';
import {labels} from '../../Constants/Translations';
import {getItem} from '../../Utils/LocalStorage';
import {ENV_HOST} from '../../Utils/env';
import CreateWorkSpace from '../../Components/WorkSpace/CreateWorkSpace';
import {scan_Qr} from '../../Constants/Images';
import CustomTabs from '../../Components/CustomTabs';
import QRCode from 'react-qr-code';
import {Dropdown, Button} from 'react-bootstrap';
import {replaceSpaceWithDot} from '../../Utils/commonUtils';
import EditProfile from '../../Components/ProfilePage/EditProfile';

const CompanyQr = props => {
  const [activeCompany, setActiveCompany] = useState({});
  const [sessionCategories, setSessionCategories] = useState('');
  // const [qrImage, setQrImage] = useState(getItem('qrCode'));
  const [itemType, setItemType] = useState('profile');
  const [opportunity, setOpportunity] = useState('');
  const [consult, setConsult] = useState([]);

  const [showLoader, setshowLoader] = useState(true);
  const [showComponent, setshowComponent] = useState('');
  const [multipleProfileData, setMultipleProfileData] = useState([]);

  const [instagramName, setInstagramName] = useState('');
  const [linkdinName, setLinkdinName] = useState('');
  const [twitterName, setTwitterName] = useState('');
  const [youtubeName, setYoutubeName] = useState('');
  const [website, setWebsite] = useState('');
  const [location, setLocation] = useState('');
  const [bio, setBio] = useState('');

  const [company, setCompany] = useState(
    localStorage.getItem('activeWorkspaceItem')
      ? JSON.parse(localStorage.getItem('activeWorkspaceItem'))
      : [],
  );
  // const [link, setLink] = useState(
  //   'www.roiforpros.com/' + getItem('userName') + '/consults',
  // );

  const [link, setLink] = useState(
    'www.roiforpros.com/' + activeCompany.company_name + '/' + getItem('id'),
  );

  const [isFloated, toggleFloatedPanel] = useState(false);
  const [showHiddenPanel, toggleHiddenPanel] = useState(false);
  const [wrkSpaceItem, setWorkSpaceItem] = useState([]);
  const [hideLink, setHideLink] = useState(false);
  const [userEditPermession, setUserEditPermession] = useState(false);

  const [tabType, setTabType] = useState('general');
  const [type, setType] = useState('');

  const [companies, setCompanies] = useState([]);

  const [qrType, setQrType] = useState('');
  const [companyId, setCompanyId] = useState('');

  const [qrName, setQrName] = useState('profile');
  const [dropdownList, setDropdownList] = useState([]);

  const ticketTabs = [
    labels.qr_type_business[selected_lang],
    labels.qr_type_personal[selected_lang],
  ];

  const updateProfileBio = (
    bio,
    instagram,
    linkdin,
    twitter,
    youtube,
    global,
    location,
    longitude,
    latitude,
  ) => {
    var payload = {
      available_on_market: false,
      public_announcement: '',
      banner: '',
      show_rating: false,
      bio: bio,
      linkedin: linkdin,
      twitter: twitter,
      youtube: youtube,
      instagram: instagram,
      website: global,
      location: location,
      longitude: longitude,
      latitude: latitude,
      company_id: companyId,
    };
    commonApi
      .add_multiple_profiles(payload)
      .then(res => {
        successMessage(res.message);
        setHideLink(true);
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const ProfileMenu = [
    {
      id: '1',
      name: 'Profile',
      value: 'profile',
    },
    {
      id: '2',
      name: 'Meetups',
      value: 'meetup',
    },
    {
      id: '3',
      name: 'Recruitments',
      value: 'recruitment',
    },
    {
      id: '4',
      name: 'Consults',
      value: 'consult',
    },
  ];

  const newLink = 'www.' + ENV_HOST + 'roiforpros.com/' + getItem('id');
  const active_company = localStorage.getItem('activeWorkspaceItem')
    ? JSON.parse(localStorage.getItem('activeWorkspaceItem'))
    : '';

  const check_market_user_bio = typeId => {
    // setshowLoader(true);
    let Payload = {
      company_id: typeId.id,
    };
    commonApi
      .check_market_user_bio(Payload)
      .then(res => {
        if (res.status === 200) {
          setCompanyId(typeId.id);
          setHideLink(res.user_bio_exist);
          setUserEditPermession(res.edit_company);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const handleCategories = type => {
    if (type === 'consult') {
      setQrType('');
      setDropdownList(consult);
    } else if (type === 'meetup') {
      setQrType('');
      setDropdownList(sessionCategories);
    } else if (type === 'recruitment') {
      setQrType('');
      setDropdownList(opportunity);
    }
  };

  const getLinkForConsultMeetupRecruitment = typeId => {
    if (typeId) {
      setLink(
        'www.' +
          ENV_HOST +
          'roiforpros.com/' +
          activeCompany.company_name +
          '/' +
          getItem('id') +
          '?' +
          itemType +
          '=' +
          typeId,
      );
    } else {
      handleType();
    }
  };

  // const handleTypeid = (typeId) => {
  //   if (itemType === 'consult') {
  //     setLink(
  //       'www.' +
  //         ENV_HOST +
  //         'roiforpros.com/' +
  //         activeCompany.company_name +
  //         '/' +
  //         getItem('id') +
  //         '?consult=' +
  //         typeId,
  //     );
  //   } else if (itemType === 'meetup') {
  //     setLink(
  //       'www.' +
  //         ENV_HOST +
  //         'roiforpros.com/' +
  //         activeCompany.company_name +
  //         '/' +
  //         getItem('id') +
  //         '?meetup=' +
  //         typeId,
  //     );
  //   }
  //   // else if (itemType === 'event') {
  //   //   setLink(
  //   //     'www.' + ENV_HOST + 'roiforpros.com/' + getItem('userName') + '/events',
  //   //   );
  //   // }
  //   else if (itemType === 'recruitment') {
  //     setLink(
  //       'www.' +
  //         ENV_HOST +
  //         'roiforpros.com/' +
  //         activeCompany.company_name +
  //         '/' +
  //         getItem('id') +
  //         '?recruitment=' +
  //         typeId,
  //     );
  //   } else if (itemType === 'profile') {
  //     setQrImage(getItem('qrCode'));
  //     setLink(
  //       'www.' +
  //         ENV_HOST +
  //         'roiforpros.com/' +
  //         activeCompany.company_name +
  //         '/' +
  //         getItem('id'),
  //     );
  //   }
  // };

  useEffect(() => {
    getLinkForConsultMeetupRecruitment(qrType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [qrType]);

  useEffect(() => {
    // setLink(
    //   'www.' +
    //     ENV_HOST +
    //     'roiforpros.com/' +
    //     activeCompany.company_name +
    //     '/' +
    //     getItem('id'),
    // );

    const url = link.split('/').filter(Boolean);
    url[1] = activeCompany.company_name;

    const updatedUrl = url.join('/');

    setLink(updatedUrl);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCompany]);

  // const getAllSessionOpportunityConsult = () => {
  //   commonApi
  //     .get_all_session_opportunity_consult()
  //     .then(res => {
  //       if (res.status === 200) {
  //         setConsult(res.consult);
  //         // setshowLoader(false);
  //       }
  //     })
  //     .catch(err => {
  //       console.log('Err', err);
  //       return {type: 'error', message: err.message};
  //     });
  // };

  const getConsult = companyName => {
    const paylaod = {
      user_id: getItem('id'),
      company_name: companyName
        ? companyName
        : active_company
        ? active_company[0].company_name
        : '',
      appointment_type_id: '1',
    };

    commonApi
      .get_companyuser_appointment(paylaod)
      .then(res => {
        if (res.success === true) {
          setConsult(res.data.appointment_types);
          if (companyName) {
            // setQrType('');
            setDropdownList(res.data.appointment_types);
          }
          // setshowLoader(false);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  const getOpportunityType = () => {
    commonApi
      .get_opportunity_types()
      .then(res => {
        if (res.status === 200) {
          setOpportunity(res.opportunity_types);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  const getSesionCategories = () => {
    commonApi
      .session_categories()
      .then(res => {
        if (res.status === 200) {
          setSessionCategories(res.data);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const handleType = () => {
    if (itemType === 'consult') {
      setLink(
        'www.' +
          ENV_HOST +
          'roiforpros.com/' +
          activeCompany.company_name +
          '/' +
          getItem('id') +
          '?consult',
      );
    } else if (itemType === 'meetup') {
      setLink(
        'www.' +
          ENV_HOST +
          'roiforpros.com/' +
          activeCompany.company_name +
          '/' +
          getItem('id') +
          '?meetup',
      );
    }
    // else if (itemType === 'event') {
    //   setLink(
    //     'www.' + ENV_HOST + 'roiforpros.com/' + getItem('userName') + '/events',
    //   );
    // }
    else if (itemType === 'recruitment') {
      setLink(
        'www.' +
          ENV_HOST +
          'roiforpros.com/' +
          activeCompany.company_name +
          '/' +
          getItem('id') +
          '?recruitment',
      );
    } else if (itemType === 'profile') {
      // setQrImage(getItem('qrCode'));
      setLink(
        'www.' +
          ENV_HOST +
          'roiforpros.com/' +
          activeCompany.company_name +
          '/' +
          getItem('id'),
      );
    }
  };

  const handleActiveCompany = id => {
    const profileCompany = companies.filter(item => item.id === id);
    check_market_user_bio(profileCompany[0]);
    // console.log(profileCompany,'profileCompany')
    setActiveCompany(profileCompany[0]);

    getConsult(profileCompany[0].company_name);
  };

  useEffect(() => {
    // getAllSessionOpportunityConsult();
    getConsult();
    getSesionCategories();
    getOpportunityType();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (itemType === 'profile') {
      handleType();
    } else {
      getLinkForConsultMeetupRecruitment('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemType]);

  useEffect(() => {
    setshowLoader(true);
    setTimeout(() => {
      getCompany();
      check_market_user_bio(company[0]);
    }, 1000);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wrkSpaceItem]);

  const getCompany = () => {
    var payload = {
     company_users:false
    };
    commonApi
      .get_company(payload)
      .then(res => {
        if (res.data?.length > 0) {
          setCompanies(res.data);
          const activeCompany = res.data.filter(
            item => item.user_status === 'active',
          );

          setType(activeCompany[0].id);

          setActiveCompany(activeCompany[0]);
          setshowLoader(false);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const containerStyle = {
    position: 'relative',
    border: '1px solid #dddddd', // Lightened border color
    borderRadius: '8px', // Rounded corners
    backgroundColor: '#f8f8f8', // Lightened background color
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Shadow effect
    padding: '10px',
  };

  // const closeIconStyle = {
  //   position: 'absolute',
  //   top: '5px',
  //   right: '5px', // Aligning to the right
  //   cursor: 'pointer',
  // };
  return (
    <div className="page-wrapper">
      <Sidebar
        location={props.location}
        setShowComponent={setshowComponent}
        setWorkSpaceItem={setWorkSpaceItem}
        setCompanies={setCompanies}
        setActiveCompany={setActiveCompany}
        setType={setType}
        showComponent={showComponent}
      />
      <div className="page-content-wrapper">
        <div className="sessions-list-wrapper">
          <Pannels
            isFloated={isFloated}
            toggleFloatedPanel={toggleFloatedPanel}
            showHiddenPanel={showHiddenPanel}
            updateHiddenPanelState={nState => toggleHiddenPanel(nState)}
            primary={
              <>
                <div className="company_qr_page">
                  {showLoader && <LoaderSpinner />}
                  <div
                    className="d-flex align-items-center justify-content-bewtween fix-width-forms w-100
                  justify-content-center">
                    <div className="create-ticket-tabs w-100">
                      <CustomTabs
                        darkmode
                        tabs={ticketTabs}
                        active={tabType}
                        onSelect={item => setTabType(item)}
                      />
                    </div>
                  </div>

                  <Scrollbars style={{height: 'calc(100vh - 115px)'}} autoHide>
                    {tabType === labels.qr_type_business[selected_lang] && (
                      <>
                        <div className="refferal-list-wrapper">
                          {!showLoader && (
                            <div className="business_qr_content">
                              <div className="company_qr_dropdown mb-5 mt-2">
                                <div className="select-wrapper">
                                  <Dropdown
                                    onSelect={(eventKey, event) => {
                                      setType(eventKey);
                                      handleActiveCompany(eventKey);
                                    }}
                                    drop="down">
                                    <Dropdown.Toggle variant="primary">
                                      {type
                                        ? companies.find(
                                            item => item.id === type,
                                          )?.company_name
                                        : 'Select Role'}
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                      {companies.map((item, index) => (
                                        <Dropdown.Item
                                          key={`st-${index}`}
                                          eventKey={item.id}
                                          active={item.id === type}>
                                          {item.company_name}
                                        </Dropdown.Item>
                                      ))}
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </div>
                              </div>

                              <div className="qr_flex_div">
                                <div className="company_profile_img">
                                  <img
                                    src={
                                      activeCompany.image
                                        ? activeCompany.image
                                        : DEFAULT_IMAGE
                                    }
                                    alt=""
                                  />
                                </div>

                                <h1>{activeCompany.company_name} </h1>
                              </div>

                              <div className="company_qr_dropdown mb-4 mt-4 ">
                                <div className="select-wrapper">
                                  <Dropdown
                                    onSelect={(eventKey, event) => {
                                      setItemType(eventKey);
                                      setQrName(eventKey);
                                      handleCategories(eventKey);
                                    }}
                                    drop="down">
                                    <Dropdown.Toggle variant="primary">
                                      {itemType
                                        ? ProfileMenu.find(
                                            item => item.value === itemType,
                                          ).name
                                        : 'Select Role'}
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                      {ProfileMenu.map((item, index) => (
                                        <Dropdown.Item
                                          key={`st-${index}`}
                                          eventKey={item.value.toString()}
                                          active={item.value === itemType}>
                                          {item.name}
                                        </Dropdown.Item>
                                      ))}
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </div>
                              </div>

                              {qrName !== 'profile' && (
                                <>
                                  {qrName === 'consult' &&
                                    consult.length > 0 && (
                                      <div className="company_qr_dropdown mb-4 mt-4 ">
                                        <div className="select-wrapper">
                                          <Dropdown
                                            onSelect={(eventKey, event) => {
                                              setQrType(eventKey);
                                            }}
                                            drop="down">
                                            <Dropdown.Toggle variant="primary">
                                              {qrType
                                                ? dropdownList.find(
                                                    item => item.id === qrType,
                                                  ).title
                                                : 'All'}
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                              <Dropdown.Item
                                                active={
                                                  qrType === '' ? true : false
                                                }
                                                eventKey={''}>
                                                All
                                              </Dropdown.Item>
                                              {dropdownList.map(
                                                (item, index) => (
                                                  <Dropdown.Item
                                                    key={`st-${index}`}
                                                    eventKey={item.id.toString()}
                                                    active={item.id === qrType}>
                                                    {item.title}
                                                  </Dropdown.Item>
                                                ),
                                              )}
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </div>
                                      </div>
                                    )}

                                  {qrName === 'meetup' && (
                                    <div className="company_qr_dropdown mb-4 mt-4 ">
                                      <div className="select-wrapper">
                                        <Dropdown
                                          onSelect={(eventKey, event) => {
                                            setQrType(eventKey);
                                          }}
                                          drop="down">
                                          <Dropdown.Toggle variant="primary">
                                            {qrType
                                              ? sessionCategories.find(
                                                  item => item.id === qrType,
                                                ).ses_category_name
                                              : 'All'}
                                          </Dropdown.Toggle>

                                          <Dropdown.Menu>
                                            <Dropdown.Item
                                              active={
                                                qrType === '' ? true : false
                                              }
                                              eventKey={''}>
                                              All
                                            </Dropdown.Item>
                                            {sessionCategories.map(
                                              (item, index) => (
                                                <Dropdown.Item
                                                  key={`st-${index}`}
                                                  eventKey={item.id.toString()}
                                                  active={item.id === qrType}>
                                                  {item.ses_category_name}
                                                </Dropdown.Item>
                                              ),
                                            )}
                                          </Dropdown.Menu>
                                        </Dropdown>
                                      </div>
                                    </div>
                                  )}

                                  {qrName === 'recruitment' && (
                                    <div className="company_qr_dropdown mb-4 mt-4 ">
                                      <div className="select-wrapper">
                                        <Dropdown
                                          onSelect={(eventKey, event) => {
                                            setQrType(eventKey);
                                          }}
                                          drop="down">
                                          <Dropdown.Toggle variant="primary">
                                            {qrType
                                              ? opportunity.find(
                                                  item => item.id === qrType,
                                                ).type_name
                                              : 'All'}
                                          </Dropdown.Toggle>

                                          <Dropdown.Menu>
                                            <Dropdown.Item
                                              active={
                                                qrType === '' ? true : false
                                              }
                                              eventKey={''}>
                                              All
                                            </Dropdown.Item>
                                            {opportunity.map((item, index) => (
                                              <Dropdown.Item
                                                key={`st-${index}`}
                                                eventKey={item.id.toString()}
                                                active={item.id === qrType}>
                                                {item.type_name}
                                              </Dropdown.Item>
                                            ))}
                                          </Dropdown.Menu>
                                        </Dropdown>
                                      </div>
                                    </div>
                                  )}
                                </>
                              )}

                              {hideLink && (
                                <div className="qr_flex_div">
                                  <div className="company_qr_img ">
                                    <QRCode
                                      style={{width: '50%', height: 'auto'}}
                                      value={link && replaceSpaceWithDot(link)}
                                    />
                                  </div>

                                  <div className="qr-img text-center mt-2 p-0">
                                    <div className="qr-link m-0">
                                      {link && replaceSpaceWithDot(link)}
                                    </div>
                                  </div>
                                  <div className="invite-persons-heading">
                                    <span
                                      className="builder-text as-link font-bold" // Add 'font-bold' class here
                                      onClick={() => {
                                        navigator.clipboard.writeText(
                                          replaceSpaceWithDot(link),
                                        );
                                        successMessage(
                                          labels.copy_link[selected_lang],
                                        );
                                      }}>
                                      Copy Link{' '}
                                    </span>
                                  </div>

                                  {/* <div className="scan_qr_logo">
                                  <span> Scan QR</span>

                                  <img className="" src={scan_Qr} alt="" />
                                </div> */}
                                </div>
                              )}
                              {!hideLink && userEditPermession && (
                                <>
                                  <div className="text-center">
                                    <p style={{margin: '0'}}>
                                      Please update your company bio
                                    </p>
                                  </div>
                                  <div className="btn-wrapper mt-3">
                                    <Button
                                      className="btn btn-dark"
                                      onClick={() => {
                                        setshowComponent('showBio');
                                      }}>
                                      Update Bio
                                    </Button>
                                  </div>
                                </>
                              )}
                              {!hideLink && !userEditPermession && (
                                <div style={containerStyle}>
                                  <p style={{margin: '0'}}>
                                    Please contact with your compamny
                                    administrator
                                  </p>

                                  {/* Placed after the message */}
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </>
                    )}

                    {tabType === labels.qr_type_personal[selected_lang] && (
                      <div className="personal_qr_content">
                        <div className="qr_flex_div mt-4">
                          <div className="personal_img">
                            <img
                              src={
                                localStorage.getItem('profilePic')
                                  ? localStorage.getItem('profilePic')
                                  : DEFAULT_IMAGE
                              }
                              alt=""
                            />
                          </div>

                          <h1>
                            {localStorage.getItem('firstName') +
                              ' ' +
                              localStorage.getItem('lastName')}{' '}
                          </h1>
                        </div>

                        <div
                          className="qr_flex_div mt-4"
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                          }}>
                          <div className="company_qr_img ">
                            <QRCode
                              style={{width: '50%', height: 'auto'}}
                              value={newLink && replaceSpaceWithDot(newLink)}
                            />
                          </div>

                          <div className="qr-img text-center mt-2 p-0">
                            <div className="qr-link m-0">
                              {newLink && replaceSpaceWithDot(newLink)}
                            </div>
                          </div>
                          <div className="invite-persons-heading">
                            <span
                              className="builder-text as-link font-bold" // Add 'font-bold' class here
                              onClick={() => {
                                navigator.clipboard.writeText(
                                  replaceSpaceWithDot(newLink),
                                );
                                successMessage(labels.copy_link[selected_lang]);
                              }}>
                              Copy Link{' '}
                            </span>
                          </div>

                          {/* <div className="scan_qr_logo">
                            <span>Scan QR</span>
                            <img className="" src={scan_Qr} alt="" />
                          </div> */}
                        </div>
                      </div>
                    )}
                  </Scrollbars>
                </div>
                {showComponent === 'showBio' && (
                  <EditProfile
                    profileBio={bio}
                    setProfileBio={setBio}
                    updateBusinessProfile={updateProfileBio}
                    setInstagramValue={setInstagramName}
                    setLinkdinValue={setLinkdinName}
                    setTwitterValue={setTwitterName}
                    setYoutubeValue={setYoutubeName}
                    setWebsite={setWebsite}
                    instagramValue={instagramName}
                    linkdinValue={linkdinName}
                    twitterValue={twitterName}
                    youtubeValue={youtubeName}
                    website={website}
                    setLocationValue={setLocation}
                    locationValue={location}
                    multipleProfileData={multipleProfileData}
                    setMultipleProfileData={setMultipleProfileData}
                    onClose={() => {
                      setshowComponent('');
                      setBio('');
                    }}
                  />
                )}
              </>
            }
            secondary={<></>}
          />
          {showComponent === 'WorkSpace' && (
            <CreateWorkSpace
              onClose={() => {
                setshowComponent('');
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default CompanyQr;
