import React, {useState} from 'react';

import Sidebar from '../../Pages/Sidebar';
import {DiscoverBuilders, Pannels} from '../../Components';
import {Button,  Form} from 'react-bootstrap';
import Scrollbars from 'react-custom-scrollbars';
import CreateWorkSpace from '../../Components/WorkSpace/CreateWorkSpace';
import {MoniterImg, rewardStar} from '../../Constants/Images';
import Slider from 'react-slick';
// import {moniter1} from '../../Constants/Images';

const Moniter = props => {

  const [isFloated, toggleFloatedPanel] = useState(false);
  const [showHiddenPanel, toggleHiddenPanel] = useState(false);
  const [showComponent, setShowComponent] = useState(false);
  const [tabType, setTabType] = useState('announcements');

  const [threeDots, setThreeDots] = useState(false);

  const [refresh, setRefresh] = useState([]);

  const [analycticsSlider, setAnalycticsSlider] = useState(false);

  const announcementData = [
    {
      name: 'Cupcake Catering',
      price: 'Starting $1,250',
    },

    {
      name: 'Caller Headsets',
      price: 'Starting $2,100',
    },
    {
      name: 'Cupcake Catering',
      price: 'Starting $1,250',
    },

    {
      name: 'Caller Headsets',
      price: 'Starting $2,100',
    },
  ];

  const buildersHandle = (t, id) => {
    // setBuildersUserId(id);
    // setShowComponent(t);
  };

  const rewardSlider = {
  
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
   
  };

  const rewardSliderData = [
    {
      name: 'Alarms',
      money: '23',
      time: 'versus last month',
      svgIcon: rewardStar,
    },

    {
      name: 'Devices',
      money: '23',
      time: 'versus last month',
      svgIcon: rewardStar,
      icon: 'fa-solid fa-star',
    },

    {
      name: 'Uptime',
      money: '23%',
      time: 'versus last month',
      svgIcon: rewardStar,
    },
    {
      name: 'Rating',
      money: '4.8',
      time: 'versus last month',
      svgIcon: rewardStar,
    },
  ];

  const dynamicStyle = {
    padding: '0',
  };

  return (
    <>
      <div className="page-wrapper">
        <Sidebar
          location={props.location}
          setShowComponent={setShowComponent}
          setWorkSpaceItem={setRefresh}
          showComponent={showComponent}
        />
        <div className="page-content-wrapper">
          <div className="sessions-list-wrapper slider-screen-main">
            <div className="Reward_main analytics_slider_bg">
              <span
                className="analytics_slider_heading "
                style={{color: analycticsSlider ? '' : '#999999'}}
                onClick={() => {
                  setAnalycticsSlider(!analycticsSlider);
                }}>
                Analytics Row
              </span>

              {analycticsSlider && (
                <div className="reward-top mt-3 mb-2">
                  <Slider {...rewardSlider}>
                    {rewardSliderData.length > 0 &&
                      rewardSliderData.map((item, index) => (
                        <div className="reward-main">
                          <div className="reward-card">
                            <span style={{fontWeight: 700, fontSize: '13px'}}>
                              {item.name}
                            </span>
                            <span
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}>
                              <span style={{fontSize: '17px'}}>
                                {item.money}
                              </span>
                              {item.name === 'Ratings' && (
                                <img src={item.svgIcon} alt="" />
                              )}
                              {item.name === 'Conversion' ? (
                                <i
                                  className="fa-solid fa-arrow-down"
                                  style={{color: '#BD4141'}}></i>
                              ) : (
                                <i
                                  class="fa-solid fa-arrow-up"
                                  style={{color: '#52bd41'}}></i>
                              )}
                            </span>
                            <div class="message-date-info">
                              <span
                                style={{color: '#333'}}
                                class="message-date">
                                {item.time}
                              </span>
                            </div>
                          </div>
                        </div>
                      ))}
                  </Slider>
                </div>
              )}
            </div>
            <div className="add_border"></div>

            <Pannels
              isFloated={isFloated}
              toggleFloatedPanel={toggleFloatedPanel}
              showHiddenPanel={showHiddenPanel}
              updateHiddenPanelState={nState => toggleHiddenPanel(nState)}
              customCss={dynamicStyle}
              primary={
                <div className="non-profit-page"  style={{display:'flex',flexDirection:'column',flex:1}}>
                  <DiscoverBuilders
                    buildersHandle={buildersHandle}
                    apiHit={refresh}
                  />

                  <div className="primary_panel_wraper primary_panel_content">
                    <div className="messages-header mb-2">
                     
                      <div className="event-tabs tabs-style-2 mb-2">
                        {' '}
                        <ul>
                          <li>
                            <Button
                              className={
                                tabType === 'announcements' ? 'active' : ''
                              }
                              style={{color: '#000'}}
                              onClick={() => {
                                // setGalleryType('chats');
                                setTabType('announcements');
                                // setShowSpinner(true);
                              }}>
                              Monitor
                            </Button>
                          </li>
                        </ul>
                      </div>
                      <div className="search-tabs-icons">
                        <span
                          onClick={() => {
                            setThreeDots(!threeDots);
                          }}
                          className={
                            threeDots
                              ? 'icon active threeDots'
                              : 'icon threeDots'
                          }>
                          <i className="fas fa-ellipsis-vertical" />
                        </span>
                      </div>
                    </div>
                    {threeDots && (
                      <>
                        <div className="person-search-wrapper mb-3 mt-0 messages-users-wrapper">
                          <div className="search-form d-flex">
                            <Form.Group className="search-input">
                              <Form.Control type="text" placeholder="Search" />
                            </Form.Group>
                            <Button>
                              <i className="icon-filter-icon" />
                            </Button>
                          </div>
                        </div>
                      </>
                    )}

                    <div className="refferal-list-wrapper">
                      <Scrollbars autoHide className="custom-scroll custom-refferal-vertical-scrollbar">
                        <div className="news mt-3">
                          {Object.entries(announcementData).length > 0 &&
                            announcementData.map((item, index) => (
                              <div className="news-card mb-4">
                                <div>
                                  <img
                                    style={{
                                      height: '178.522px',
                                      borderRadius: '5.01px',
                                      // background: '#d9d9d9',
                                      width: '342px',
                                      objectFit: 'cover',
                                    }}
                                    src={MoniterImg}
                                    alt=""
                                  />
                                </div>

                                <span className="news-title">Camera 1</span>
                              </div>
                            ))}
                        </div>
                      </Scrollbars>
                    </div>
                  </div>
                </div>
              }
              secondary={
                <>
                  <Scrollbars className="custom-scroll"></Scrollbars>
                </>
              }
            />
            {showComponent === 'WorkSpace' && (
              <CreateWorkSpace
                onClose={() => {
                  setShowComponent('');
                }}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default Moniter;
