import React from 'react';
import {Button} from 'react-bootstrap';

export default function NorecordFound({noRecordData, ButtonAction,buttonPermesion,className,firstDivStyle}) {
  return (
    <div className={`no-results-wrapper ${className ? className : ''} ${className ? 'pt-5' : ''}`} style={firstDivStyle}>
      {noRecordData?.img && (
        <div className="img-bg-wrapper">
          <img src={noRecordData.img} alt="no-session" />
        </div>
      )}
      {noRecordData?.title ||
        (noRecordData?.description && (
          <div className="no-results-content">
            {noRecordData?.title && <h2>{noRecordData.title}</h2>}
            {noRecordData?.description && <p>{noRecordData.description}</p>}
          {noRecordData.button && buttonPermesion && 
            <div className="btn-wrapper mt-3">
              <Button
                className="btn btn-dark"
                onClick={() => {
                  ButtonAction &&  ButtonAction();
                }}>
                {noRecordData.button}
              </Button>
            </div> }
          </div>
        ))}
    </div>
  );
}
