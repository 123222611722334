import React, {useState, useEffect, useRef} from 'react';
import {Button, Col, Form} from 'react-bootstrap';
import {successMessage, errorMessage} from '../../Utils/ToastMessages';
import commonApi from '../../Services/CommonService';
import CreateSnapShot from './CreateSnapShot';
import {labels} from '../../Constants/Translations';
import {selected_lang} from '../../Utils/env';
import LoaderSpinner from '../Modals/LoaderSpinner';
import CustomTabs from '../CustomTabs';
import SlidingPanel from '../SlidingPanel';
import Scrollbars from 'react-custom-scrollbars';
import CustomModal from '../CustomModal';
import {amplitudeEvent} from '../../Services/AmplitudeServices';
import CircleListBuilders from '../../Pages/Front/StaticPages/CircleListBuilders';
import {useSelector} from 'react-redux';
import {callOpportunityApi} from '../../Store/Actions/OpportunityAction';
import {updatePersonalChat, callChatApi} from '../../Store/Actions/ChatAction';
import {useDispatch} from 'react-redux';
import {list} from '../../Utils/commonUtils';
import SendInvitation from '../../Pages/Front/StaticPages/SendInvitation';
import Invite from '../SingleComponent/Invite';
import PersonalCreateModel from '../Modals/PersonalCreateModel';
import CropImage from '../Cropper/CropImage';
import {get_my_all_associate_workspaces} from '../../Store/Actions/SessionAction';
import {UserProfileImg} from '../../Constants/Images';

const CreateGroup = props => {
  const [imgModal, setImgModal] = useState(false);

  const [showModalButtons, setShowModalButtons] = useState(false);
  const [showModelDecesion, setShowModelDecesion] = useState('');

  const [showModel, setShowModel] = useState(false);
  const [file, setFile] = useState(null);
  const [sliderImage, setSliderImage] = useState([]);
  const [imgUrl, setImgUrl] = useState();

  const [showLoaderSpinner, setShowLoaderSpinner] = useState(false);

  const [chooseCircle, setChooseCircle] = useState(false);
  const [tabType, setTabType] = useState(
    labels.sessions_type_general[selected_lang],
  );

  const [subTabs, setSubTabs] = useState('moderator');
  const [images, setImages] = useState([]);

  const [title, setTitle] = useState('');
  const [modrator, setModrator] = useState([]);
  const [audience, setAudience] = useState([]);
  const [validated, setValidated] = useState(false);
  // const [selectedMod, setSelectedMod] = useState(false);
  const [selectedModData, setSelectedModData] = useState([]);
  const [selectedAudData, setSelectedAudData] = useState([]);
  // const [selectedAud, setSelectedAud] = useState(false);
  const [newButton, setNewButton] = useState(false);
  const [hostId, setHostId] = useState('');
  const [imageCreate, setImageCreate] = useState([]);
  // const [change, setChange] = useState(true);
  const [opprAudience, setOpprAudience] = useState([]);

  const [alreadyAddedMod, setAlreadyAddedMod] = useState([]);
  const [alreadyAddedResponse, setAlreadyAddedResponse] = useState([]);
  const [mustAudience, setMustAudience] = useState([]);
  const [opportunityLeftAudience, setOpportunityLeftAudience] = useState([]);
  const [idsForCompare, setIdsForCompare] = useState([]);
  const [hideCreator, setHideCreator] = useState([]);
  const [showComponent, setShowComponent] = useState([]);
  const RadioButtonName = ['Invite Only', 'Share on The Market'];
  const [invitesOptionvalue, setInvitesOptionvalue] = useState(
    'Share on The Market',
  );

  const [originalDataOfMod, setOriginalDataOfMod] = useState([]);
  const [originalDataOfAud, setOriginalDataOfAud] = useState([]);
  const [workSpaceIds, setWorkSpaceIds] = useState([]);
  const [contact, setContact] = useState([]);
  const allowedImageTypes = ['image/jpeg', 'image/png', 'image/jpg'];
  const buttonRef = useRef(null);
  const [topIcons, setTopIcons] = useState({
    focus: false,
    location: false,
    vaccinate: false,
    dollar: false,
    store: true,
    bullseye: false,
    personal: false,
  });

  // Invite States

  const [inviteLoader, setInviteLoader] = useState(true);
  const [inviteFollowers, setInviteFollowers] = useState([]);
  const [followersList, setFollowersList] = useState([]);
  const [inviteQry, setInviteQry] = useState('');

  const dispatch = useDispatch();
  const opportunityDetail = useSelector(state => state.opportunityChatDetail);

  const listWorspacerator = useSelector(
    state => state.hitAssociateWorkSpace.data,
  );
  const personal = useSelector(state => state.hitAssociateWorkSpace.personal);

  // const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      get_my_all_associate_workspaces({token: localStorage.getItem('token')}),
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (workSpaceIds.length > 0) {
    } else {
      // Filter the workspaces with status "active" from the API response
      const userExist = listWorspacerator.filter(
        workspace => workspace.status === 'active',
      );

      // Extract the 'id' values from 'userExist'
      const updatedIds = userExist.map(workspace => workspace.id);

      if (personal === 'yes') {
        updatedIds.push('personal'); // Add 'personal' to the ids array if needed
      }

      // setActiveWorkspacesId(updatedIds);
      setWorkSpaceIds(updatedIds);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listWorspacerator]);

  useEffect(() => {}, [props.groupChat]);

  useEffect(() => {
    if (props.groupChat) {
      if (props.groupChat?.workspace_ids) {
        setWorkSpaceIds(props.groupChat.workspace_ids.split(','));
      }

      if (props.groupChat?.personal === 'yes') {
        // alert('@@@@@@@@@@@')
        setTimeout(() => {
          setWorkSpaceIds(previous => [...previous, 'personal']);
          // workSpaceIds.push('personal');
        }, 1500);
      }

      if (props.groupChat.opportunity_id) {
        getOpportunityDetails(props.groupChat.opportunity_id);
        alreadyAddedResponse.push(
          props.groupChat && props.groupChat.user_id && props.groupChat.user_id,
        );
        setTitle(props.groupChat.group_name);
        setImageCreate(props.groupChat.group_image);
        setImgUrl(props.groupChat.group_image);
        // setTicketImg(props.groupChat.group_image);
        if (
          props.groupChat.snap_shot_id &&
          props.groupChat.snap_shot_id !== '0'
        ) {
          setHostId(props.groupChat.snap_shot_id);
          // setTopIcons({
          //   ...topIcons,
          //   ['focus']: true,
          // });
        }

        if (props.groupChat.hasOwnProperty('team_member_details')) {
          if (
            props.groupChat.other_persons_opportunity_chat_room &&
            props.groupChat.other_persons_opportunity_chat_room.length > 0
          ) {
            let item = props.groupChat.other_persons_opportunity_chat_room;
            item.forEach((miniItem, index) => {
              if (miniItem.status !== 'left') {
                setAudience(previous => [...previous, miniItem.id]);
                setIdsForCompare(previous => [...previous, miniItem.id]);
                setSelectedAudData(previous => [...previous, miniItem]);
                setOriginalDataOfAud(previous => [...previous, miniItem]);
              }
            });
          }
          if (
            props.groupChat.team_member_details &&
            props.groupChat.team_member_details.length > 0
          ) {
            let item = props.groupChat.team_member_details;
            item.forEach((miniItem, index) => {
              setModrator(previous => [...previous, miniItem.id]);
              setSelectedModData(previous => [...previous, miniItem]);
              setOriginalDataOfMod(previous => [...previous, miniItem]);
            });
          }
          setHideCreator(previous => [...previous, props.groupChat.creator_id]);
        } else {
          setHideCreator(previous => [
            ...previous,
            opportunityDetail.created_by,
          ]);
          if (
            opportunityDetail.other_persons_opportunity_chat_room &&
            opportunityDetail.other_persons_opportunity_chat_room.length > 0
          ) {
            let item = opportunityDetail.other_persons_opportunity_chat_room;
            item.forEach((miniItem, index) => {
              if (miniItem.status !== 'left') {
                setAudience(previous => [...previous, miniItem.id]);
                setIdsForCompare(previous => [...previous, miniItem.id]);
                setSelectedAudData(previous => [...previous, miniItem]);
                setOpprAudience(previous => [...previous, miniItem.id]);
                // setOriginalDataOfAud(previous => [...previous, miniItem]);
              }
            });
          }
          if (
            opportunityDetail.team_member_details &&
            opportunityDetail.team_member_details.length > 0
          ) {
            let item = opportunityDetail.team_member_details;
            item.forEach((miniItem, index) => {
              setModrator(previous => [...previous, miniItem.id]);
              setSelectedModData(previous => [...previous, miniItem]);
              setAlreadyAddedMod(previous => [...previous, miniItem.id]);
              // setOriginalDataOfMod(previous => [...previous, miniItem]);
            });
          }
        }

        // if (props.groupChat.private_group === 'yes') {
        //   setTopIcons(pre => ({
        //     ...pre,
        //     ['vaccinate']: true,
        //   }));
        // }
      } else {
        setHideCreator(previous => [...previous, props.groupChat.creator_id]);
        setTitle(props.groupChat.group_name);

        setImgUrl(props.groupChat.group_image);
        setImageCreate(props.groupChat.group_image);
        // setTicketImg(props.groupChat.group_image);
        if (
          props.groupChat.snap_shot_id &&
          props.groupChat.snap_shot_id !== '0'
        ) {
          setHostId(props.groupChat.snap_shot_id);
          // setTopIcons({
          //   ...topIcons,
          //   ['focus']: true,
          // });
        }

        if (
          props.groupChat.audience_detail &&
          props.groupChat.audience_detail.length > 0
        ) {
          let item = props.groupChat.audience_detail;
          item.forEach((miniItem, index) => {
            setAudience(previous => [...previous, miniItem.id]);
            setSelectedAudData(previous => [...previous, miniItem]);
            setIdsForCompare(previous => [...previous, miniItem.id]);
            setAlreadyAddedResponse(previous => [...previous, miniItem.id]);
            setOriginalDataOfAud(previous => [...previous, miniItem]);
          });
        }
        if (
          props.groupChat.moderator_detail &&
          props.groupChat.moderator_detail.length > 0
        ) {
          let item = props.groupChat.moderator_detail;
          item.forEach((miniItem, index) => {
            setModrator(previous => [...previous, miniItem.id]);
            setAlreadyAddedMod(previous => [...previous, miniItem.id]);
            setSelectedModData(previous => [...previous, miniItem]);
            setIdsForCompare(previous => [...previous, miniItem.id]);
            setAlreadyAddedResponse(previous => [...previous, miniItem.id]);
            setOriginalDataOfMod(previous => [...previous, miniItem]);
          });
        }
        if (props.groupChat.private_group === 'yes') {
          // setTopIcons(pre => ({
          //   ...pre,
          //   ['vaccinate']: true,
          // }));
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handle = event => {
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
    } else if (modrator.length === 0 && audience.length === 0) {
      event.preventDefault();
      event.stopPropagation();
      errorMessage('Select User');
    } else if (imageCreate.length === 0) {
      event.preventDefault();
      event.stopPropagation();
      errorMessage('Select Image');
    }
    // else if (workSpaceIds.length == 0) {
    //   event.preventDefault();
    //   event.stopPropagation();
    //   errorMessage('Please select workspace');
    // }
    else {
      event.preventDefault();
      setShowLoaderSpinner(true);
      if (props.chatGroupType === 'create') {
        createPersonalgroupChatOnly();
      } else if (props.groupChat.opportunity_id) {
        updateOpportunityGroupChat();
      } else {
        updatePersonalGroupChat();
      }

      setValidated(false);
    }
  };

  // const uploadGroupPicture = img => {
  //   //
  //   setShowLoaderSpinner(true);
  //   var bodyFormData = new FormData();
  //   bodyFormData.append(
  //     'image',
  //     img.replace(/^data:image\/[a-z]+;base64,/, ''),
  //   );

  //   commonApi
  //     .upload_group_picture(bodyFormData)
  //     .then(res => {
  //       if (res.status === 200) {
  //         setImageCreate(res.image);
  //         setTimeout(() => {
  //           //
  //           setShowLoaderSpinner(false);
  //         }, 2000);
  //       }
  //     })
  //     .catch(err => {
  //       console.log('Err', err);
  //       return {type: 'error', message: err.message};
  //     });
  // };

  useEffect(() => {
    getAccomplishment();
  }, []);

  useEffect(() => {
    // getAccomplishment();

    images.forEach(item => {
      if (hostId === item.id) {
        setImageCreate(item.images[0]);
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newButton]);

  const updateOpportunityGroupChat = () => {
    let left_removed_users_arr =
      typeof props.groupChat.left_removed_users === 'string' &&
      props.groupChat.left_removed_users === ''
        ? []
        : typeof props.groupChat.left_removed_users === 'string' &&
          props.groupChat.left_removed_users !== '' &&
          props.groupChat.left_removed_users.split(',');
    let children_arr = idsForCompare;

    if (audience.length > 0) {
      audience.forEach(item => {
        if (
          left_removed_users_arr.length > 0 &&
          left_removed_users_arr.includes(item)
        ) {
          left_removed_users_arr = left_removed_users_arr.filter(
            id => id !== item,
          );
        }
        children_arr = children_arr.filter(id => id !== item);
      });
    }

    if (modrator.length > 0) {
      modrator.forEach(item => {
        if (left_removed_users_arr && left_removed_users_arr.includes(item)) {
          left_removed_users_arr = left_removed_users_arr.filter(
            id => id !== item,
          );
        }
        children_arr = children_arr.filter(id => id !== item);
      });
    }

    let aud = audience.toString();
    let mod = modrator.toString();
    let left = left_removed_users_arr
      ? [...left_removed_users_arr, ...children_arr]
      : children_arr;
    // let filterLeftUser = left.filter(x => !audience.includes(x));
    left = left.toString();
    // let result = array1.filter(x => !array2.includes(x));

    var payLoad = {
      audience_ids: aud,
      chat_id: props.groupChat.id
        ? props.groupChat.id
        : props.groupChat.chat_id
        ? props.groupChat.chat_id
        : props.groupChat.chat_room_id,
      creator_id: props.groupChat.creator_id
        ? props.groupChat.creator_id
        : props.groupChat.creator_detail.id,
      group_image: imageCreate,
      group_name: title,
      left_removed_moderator: '',
      left_removed_users: left,
      moderator_ids: mod,
      private_group: topIcons.vaccinate ? 'yes' : 'no',
      snap_shot_id: hostId,
      // creator_id: props.groupChat.creator_id,
      workspace_ids: workSpaceIds.toString(','),
    };

    commonApi
      .update_opportunity_group_chat(payLoad)
      .then(res => {
        //
        setShowLoaderSpinner(false);
        if (res.status === 200) {
          if (props.hasOwnProperty('setGroupChat')) {
            props.setGroupChat(res.group_chat_detail);
          }
          props.setOppoData(res.group_chat_detail);
          props.groupChat.group_image = imageCreate;
          props.groupChat.group_name = title;
          dispatch(callOpportunityApi());
          dispatch(callChatApi());
          successMessage(res.message);
          props.onClose();
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  const updatePersonalGroupChat = () => {
    let left_removed_users_arr = props.groupChat.left_removed_users;
    // let left_removed_users_arr = props.groupChat.left_removed_users;
    let audience_ids_arr = props.groupChat.audience_ids;
    let moderator_ids_arr = props.groupChat.moderator_ids;
    let children_Aud_arr = audience_ids_arr;
    let children_Mod_arr = moderator_ids_arr;
    if (audience.length > 0) {
      audience.forEach(item => {
        if (left_removed_users_arr.includes(item)) {
          left_removed_users_arr = left_removed_users_arr.filter(
            id => id !== item,
          );
        }
        children_Aud_arr = children_Aud_arr.filter(id => id !== item);
      });
    }
    if (modrator.length > 0) {
      modrator.forEach(item => {
        if (left_removed_users_arr.includes(item)) {
          left_removed_users_arr = left_removed_users_arr.filter(
            id => id !== item,
          );
        }
        children_Mod_arr = children_Mod_arr.filter(id => id !== item);
      });
    }
    let result = moderator_ids_arr.filter(x => !modrator.includes(x));

    let aud = audience.toString();
    let mod = modrator.toString();
    let leftAud = [...left_removed_users_arr, ...children_Aud_arr];
    // let leftMod = [...left_removed_users_arr, ...children_Mod_arr];
    leftAud = leftAud.toString();
    // leftMod = leftMod.toString();
    var payLoad = {
      audience_ids: aud,
      chat_id: props.groupChat.id
        ? props.groupChat.id
        : props.groupChat.chat_room_id,
      creator_id: props.groupChat.creator_id
        ? props.groupChat.creator_id
        : props.groupChat.creator_detail.id,
      group_image: imageCreate,
      group_name: title,
      left_removed_moderator: result.toString(),
      left_removed_users: leftAud,
      moderator_ids: mod,
      private_group: topIcons.vaccinate ? 'yes' : 'no',
      snap_shot_id: hostId,
      workspace_ids: workSpaceIds.toString(','),
    };

    commonApi
      .update_personal_group_chat(payLoad)
      .then(res => {
        setShowLoaderSpinner(false);
        if (res.status === 200) {
          props.groupChat.group_image = imageCreate;
          props.groupChat.group_name = title;
          dispatch(updatePersonalChat());
          if (props.groupChat && props.groupChat.chat_room_type === 'single') {
            dispatch(callChatApi());
            successMessage('Create group chat successfully.');
            // props.createGroup()
          } else {
            successMessage(res.message);
          }

          props.onClose();
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const createPersonalgroupChatOnly = () => {
    let aud = audience.toString();
    let mod = modrator.toString();
    var payLoad = {
      audience_ids: aud,
      chat_room_name:
        'chatroom_gf' + Math.floor(100000 + Math.random() * 900000),
      group_image: imageCreate,
      group_name: title,
      moderator_ids: mod,
      private_group: topIcons.vaccinate ? 'yes' : 'no',
      snap_shot_id: hostId,
      workspace_ids: workSpaceIds.toString(','),
    };
    commonApi
      .create_personal_group_chat_only(payLoad)
      .then(res => {
        amplitudeEvent('CREATE_GROUP_CHAT');

        setShowLoaderSpinner(false);
        if (res.status === 200) {
          successMessage(res.message);
          props.onClose();
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const getAccomplishment = () => {
    var payLoad = {
      categories: [],
      user_id: localStorage.getItem('user'),
    };
    commonApi
      .get_accomplishment(payLoad)
      .then(res => {
        if (res.status === 200) {
          if (res.accomplishment.length > 0) {
            setImages(res.accomplishment);
            setHostId(res.accomplishment[0].id);
            // setImageCreate(res.accomplishment[0].images[0]);
          } else {
          }
          // setImages(res.accomplishment);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  // const changeTicketImg = e => {
  //   const filePath = e.target.files[0];
  //   const objectUrl = URL.createObjectURL(filePath);
  //   let reader = new FileReader();
  //   reader.readAsDataURL(filePath);
  //   reader.onload = e => {
  //     uploadGroupPicture(e.target.result);
  //   };

  //   setTicketImg(objectUrl);
  // };

  const changeTicketImg = e => {
    // If no file selected, return
    if (e.target.files.length === 0) return false;
    const file = e.target.files[0];

    // If no image selected, return
    if (!/^image\//.test(file.type)) {
      errorMessage(`File is not an image.`);
      return false;
    } else if (allowedImageTypes.includes(file.type)) {
      setFile(file);

      setShowModel('modal');

      e.target.value = null;
      // const filePath = e.target.files[0];
      // const objectUrl = URL.createObjectURL(filePath);
      // let reader = new FileReader();
      // reader.readAsDataURL(filePath);
      // reader.onload = e => {
      //

      //   uploadProfilePicture(e.target.result);
      // };

      // setTicketImg(objectUrl);
    } else {
      errorMessage('The file format is not supported');
      e.target.value = null; // reset the file input
    }
  };

  const CircleUserListData = item => {
    if (item.length > 0) {
      if (subTabs === 'moderator') {
        item.forEach((miniItem, index) => {
          if (
            !modrator.includes(miniItem.id) &&
            !audience.includes(miniItem.id) &&
            !opprAudience.includes(miniItem.id) &&
            !mustAudience.includes(miniItem.id) &&
            !opportunityLeftAudience.includes(miniItem.id) &&
            !hideCreator.includes(miniItem.id)
          ) {
            setModrator(previous => [...previous, miniItem.id]);
            setSelectedModData(previous => [...previous, miniItem]);
            setOriginalDataOfMod(previous => [...previous, miniItem]);
          }
        });
      }

      if (subTabs === 'audience') {
        item.forEach((miniItem, index) => {
          if (
            !modrator.includes(miniItem.id) &&
            !audience.includes(miniItem.id) &&
            !opprAudience.includes(miniItem.id) &&
            !mustAudience.includes(miniItem.id) &&
            !opportunityLeftAudience.includes(miniItem.id) &&
            !hideCreator.includes(miniItem.id)
          ) {
            setAudience(previous => [...previous, miniItem.id]);
            setSelectedAudData(previous => [...previous, miniItem]);
            setOriginalDataOfAud(previous => [...previous, miniItem]);
          }
        });
      }
    }
  };

  const getOpportunityDetails = id => {
    var payLoad = {
      opportunity_id: id,
      page: 1,
      search_query: '',
      status: 'pending',
    };
    commonApi
      .get_opportunity_details(payLoad)
      .then(response => {
        if (response.status === 200) {
          if (response.opportunity_details) {
            response.all_opportunity_audience.forEach(item => {
              setMustAudience(previous => [...previous, item.id]);
            });
          }
          if (
            response.available_opportunity.length === 1 &&
            response.available_opportunity[0]
              .other_persons_opportunity_chat_room
          ) {
            response.available_opportunity[0].other_persons_opportunity_chat_room.forEach(
              item => {
                if (item.status === 'left') {
                  setOpportunityLeftAudience(previous => [
                    ...previous,
                    item.id,
                  ]);
                }
              },
            );
          }
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const handleCircle = () => {
    setChooseCircle(true);
  };

  const handleInviteOption = e => {
    // const {id, checked} = e.target;
    // setOpportunity([...opportunity, id]);
    setInvitesOptionvalue(e.target.value);
  };
  useEffect(() => {
    if (contact.length > 0) {
      setInviteLoader(true);
      setInviteQry('');
    }
  }, [contact]);

  console.log(props.groupChat, 'props.groupChat');

  return (
    <>
      {/* {showLoader && <LoaderModal show={showLoader} />} */}
      {showLoaderSpinner && <LoaderSpinner show={showLoaderSpinner} />}

      <SlidingPanel
        hidePanel={() => {
          props.onClose();
        }}>
        <div className="serach-filter-wrapper create-group referal_page_content">
          {/* <div className="ticket-icons-options">
            <ul>
              <li
                
                className={'active'}
                onClick={() => {
                  setTopIcons({
                    ...topIcons,
                    ['personal']: !topIcons.personal,
                  });
                  setShowComponent('persnal-model');
                }}>
              
                <PersnalSvg fill={'white'} />
                
              </li>
              <li
                className={topIcons.focus ? 'active' : ''}
                onClick={() => {
                  if (hostId) {
                  } else {
                    setShowModelDecesion('snapshot');
                    setShowModalButtons(true);
                  }
                }}>
                <i className="icon-store" />
              </li>

              <li
                className={topIcons.vaccinate ? 'active' : ''}
                onClick={() => {
                  setShowModelDecesion('visibility');
                  setShowModalButtons(true);
                }}>
                <i className="fas fa-lock" />
              </li>

              <li
                            className="text-right"
                            onClick={() => props.onClose()}>
                            <i className="fas fa-times" />
                          </li>
            </ul>
          </div> */}
          {/* bs */}

          {/* <Scrollbars> */}

          <div className="m-0 w-100 d-flex align-items-center justify-content-between fix-width-forms create_group_tabs">
            <div className="d-flex">
              <p className="p-bold f_600_h">
                {' '}
                {props.groupChat && props.groupChat.chat_room_type !== 'single'
                  ? 'Update Group'
                  : 'Create Group'}
              </p>
            </div>
            <div className="d-flex"></div>
          </div>

          <div className=" custom_tabs d-flex align-items-center justify-content-center fix-width-forms  w-100">
            <div className="create-ticket-tabs">
              <div>
                <CustomTabs
                  darkmode
                  active={tabType}
                  onSelect={item => {
                    setTabType(item);
                    if (
                      item === labels.sessions_type_general[selected_lang] &&
                      inviteQry !== ''
                    ) {
                      setInviteFollowers(followersList);
                      setInviteQry('');
                    }
                  }}
                  tabs={[
                    labels.sessions_type_general[selected_lang],
                    labels.sessions_type_invites[selected_lang],
                  ]}
                />
              </div>
            </div>
          </div>

          <div className="messages-users-list">
            <Scrollbars>
              <div className="create-ticket-form fix-width-forms ">
                <Form noValidate validated={validated} onSubmit={handle}>
                  {tabType === labels.sessions_type_general[selected_lang] && (
                    <div>
                      <div className="fieldset">
                        <Form.Label controlid="validationCustom01">
                          Convo Name
                        </Form.Label>
                        <Form.Control
                          name="title"
                          value={title}
                          pattern="^\S.*$"
                          required
                          placeholder="Name your Convo"
                          onChange={e => {
                            setTitle(e.target.value);
                          }}
                        />
                        <Form.Control.Feedback type="invalid">
                          Convo name is required.
                        </Form.Control.Feedback>
                      </div>
                      {!topIcons.focus && (
                        <Col sm={2}>
                          <div className="ticket-img-wrapper mt-4 mb-3">
                            {imgUrl && (
                              <p
                                onClick={() => {
                                  setImgModal(true);
                                }}>
                                Onclick
                              </p>
                            )}
                            <input
                              type="file"
                              accept="image/*"
                              // required
                              onChange={e => {
                                changeTicketImg(e);
                              }}
                            />
                            {imgUrl && <img src={imgUrl} alt="" />}
                            <i className="icon-camera" />
                          </div>
                        </Col>
                      )}

                      {/* {topIcons.focus && ( */}
                      {/* <div className="invite-persons-wrapper mt-0">
                        <div className="invite-persons-list p-0">
                          <div className="fieldset">
                            <Row>
                              <Col md={4} xl={3}>
                                {Object.entries(images).length > 0 &&
                                  images.map((item, index) => (
                                    <div key={index}>
                                      {item.id === hostId ? (
                                        <div className="profile-img upload-user-img">
                                          <img
                                            src={
                                              item.id === hostId
                                                ? imageCreate
                                                : imageCreate
                                            }
                                            alt=""
                                            onChange={() => {
                                              setImageCreate(
                                                item.id === hostId
                                                  ? imageCreate
                                                  : imageCreate,
                                              );
                                            }}
                                          />
                                          <span className="icon icon-camera"></span>
                                          <input
                                            type="file"
                                            name="my-image"
                                            id="image"
                                            accept="image/*"
                                            onChange={e => {
                                              changeTicketImg(e);
                                              e.target.value = null;
                                            }}
                                          />
                                        </div>
                                      ) : (
                                        <div className="stage-list-item">
                                          <div
                                            className="stage-person-img"
                                            type="checkbox">
                                            <img
                                              src={
                                                item.id === hostId
                                                  ? imageCreate
                                                  : imageCreate
                                              }
                                              alt=""
                                              onChange={() => {
                                                setImageCreate(
                                                  item.id === hostId
                                                    ? item.images[0]
                                                    : imageCreate,
                                                );
                                              }}
                                            />
                                          </div>
                                          <Col sm={2}>
                                            <div
                                              className="ticket-img-wrapper mb-3"
                                              style={{
                                                maxWidth: '35px',
                                                maxHeight: '35px',
                                              }}>
                                              <input
                                                type="file"
                                                accept="image/*"
                                                onChange={e => {
                                                  changeTicketImg(
                                                    e,
                                                  );
                                                }}
                                              />
                                              <i className="icon-camera" />
                                            </div>
                                          </Col>
                                        </div>
                                      
                                      )}
                                    </div>
                                  ))}
                              </Col>
                            </Row>
                          </div>
                          <div className="fieldset">
                            {imageCreate && imageCreate.length > 0 && (
                              <label
                                className="as-link mt-0 form-label"
                                onClick={() => {
                                  setImgModal(true);
                                }}>
                                {' '}
                                Preview
                              </label>
                            )}
                            <div className="select-wrapper">
                              <select
                                onChange={e => {
                                  setHostId(e.target.value);
                                  setChange(!change);
                                }}>
                                {Object.entries(images).length > 0 ? (
                                  Object.entries(images).length > 0 &&
                                  images.map((item, index) => (
                                    // { (index === '') ?
                                    //   setData(index[0])
                                    // :()
                                    // }
                                    <option
                                      id={hostId}
                                      value={item.id}
                                      key={`cat-${index}`}>
                                      {item.title}
                                    </option>
                                  ))
                                ) : (
                                  <></>
                                )}
                              </select>
                            </div>
                          </div>
                        </div>
                      </div> */}
                      {/* )} */}

                      <Button
                        className="btn-dark"
                        type="submit"
                        style={{display: 'none'}}
                        ref={buttonRef}>
                        {props.groupChat &&
                        props.groupChat.chat_room_type !== 'single'
                          ? 'Update Convo'
                          : 'Create Convo'}
                      </Button>
                    </div>
                  )}
                </Form>

                {tabType === labels.sessions_type_invites[selected_lang] && (
                  <>
                    <div className="refferal-list-wrapper">
                      {inviteLoader && (
                        <div className="invite_loader_hieght">
                          <LoaderSpinner />
                        </div>
                      )}
                      {!inviteLoader && (
                        <div
                          className="d-flex invitation_outside as-link align-items-center mb-2"
                          onClick={() => {
                            setShowComponent('inviteUser');
                          }}>
                          <div className="add_contact_img">
                            <img alt="" src={UserProfileImg} />
                          </div>
                          <p className="p-reg text-gey">New Contact</p>
                        </div>
                      )}
                      {/* <div className="CreateSession-invite-radio-btns ">
                      {Object.entries(RadioButtonName).length > 0 &&
                        RadioButtonName.map((item, index) => (
                          <Form.Check
                            type="radio"
                            // name="radioGroup"
                            label={item}
                            id={item}
                            value={item}
                            checked={invitesOptionvalue === item}
                            onChange={handleInviteOption}
                          />
                        ))}
                    </div> */}

                      <Invite
                        moderator={modrator}
                        setModerator={setModrator}
                        selectedModData={selectedModData}
                        setSelectedModData={setSelectedModData}
                        audience={audience}
                        setAudience={setAudience}
                        selectedAudData={selectedAudData}
                        setSelectedAudData={setSelectedAudData}
                        contact={contact}
                        setContact={setContact}
                        originalDataOfMod={originalDataOfMod}
                        setOriginalDataOfMod={setOriginalDataOfMod}
                        originalDataOfAud={originalDataOfAud}
                        setOriginalDataOfAud={setOriginalDataOfAud}
                        opprAudience={opprAudience}
                        mustAudience={mustAudience}
                        opportunityLeftAudience={opportunityLeftAudience}
                        hideCreator={hideCreator}
                        subTabs={subTabs}
                        setSubTabs={setSubTabs}
                        alreadyAddedMod={alreadyAddedMod}
                        handleCircle={handleCircle}
                        onClick={() => {
                          setShowComponent('inviteUser');
                          setTimeout(() => {
                            if (contact.length === 0 && inviteQry !== '') {
                              setInviteFollowers(followersList);
                              setInviteQry('');
                            }
                          }, 1000);
                        }}
                        forApiHit={showComponent}
                        showLoader={inviteLoader}
                        setshowLoader={setInviteLoader}
                        followers={inviteFollowers}
                        setFollowers={setInviteFollowers}
                        setFollowersList={setFollowersList}
                        followersList={followersList}
                        setSearchQry={setInviteQry}
                        searchQry={inviteQry}
                      />
                    </div>
                  </>
                )}
              </div>
            </Scrollbars>
          </div>

          {tabType === labels.sessions_type_general[selected_lang] && (
            <div className="filter-btn-wrapper fix-width-forms">
              <div className="invite-btn text-center">
                <Button
                  className="btn-dark"
                  type="submit"
                  onClick={() => {
                    buttonRef.current.click();
                  }}>
                  {props.groupChat &&
                  props.groupChat.chat_room_type !== 'single'
                    ? 'Update Convo'
                    : 'Create Convo'}
                </Button>
              </div>
            </div>
          )}
          {/* </Scrollbars> */}
        </div>
      </SlidingPanel>
      {chooseCircle === true ? (
        <CircleListBuilders
          onClose={() => {
            setChooseCircle(false);
          }}
          hide={true}
          CircleUserListData={CircleUserListData}
        />
      ) : (
        <>
          {newButton === true ? (
            <CreateSnapShot
              images={images}
              setImages={setImages}
              setHostId={setHostId}
              hostId={hostId}
              onClose={() => {
                setNewButton(false);
              }}
            />
          ) : (
            <>
              {/* {audience.length > 0 && selectedAud === true ? (
                // &&
                // props.chatGroupType === 'create'
                <SelectedUsers
                  onClose={() => setSelectedAud(false)}
                  selectedAllUser={selectedAudData}
                  setSelectedAllUser={setSelectedAudData}
                  setUsers={setAudience}
                  users={audience}
                  alreadyAddedUser={alreadyAddedResponse}
                  mustUsers={mustAudience}
                  //   enterSession={enterSession}
                />
              ) : (
                <>
                  {modrator.length > 0 && selectedMod === true && (
                    // &&
                    // props.chatGroupType === 'create'
                    <SelectedUsers
                      onClose={() => setSelectedMod(false)}
                      selectedAllUser={selectedModData}
                      setSelectedAllUser={setSelectedModData}
                      setUsers={setModrator}
                      users={modrator}
                      alreadyAddedUser={alreadyAddedResponse}
                      mustUsers={mustModerators}
                    />
                  )}
                </>
              )} */}
            </>
          )}
        </>
      )}

      {showComponent === 'inviteUser' && (
        <SendInvitation
          setContact={setContact}
          onClose={() => {
            setShowComponent('');
          }}
        />
      )}
      {showComponent === 'persnal-model' && (
        <PersonalCreateModel
          list={list}
          setWorkSpaceIds={setWorkSpaceIds}
          workSpaceIds={workSpaceIds}
          onClose={() => {
            setShowComponent('');
            // setTopIcons({
            //   ...topIcons,
            //   ['personal']: !topIcons.personal,
            // });
          }}
          //Temporary state for remove Warning
          setShowModelDecesion={setShowModelDecesion}
          setTopIcons={setTopIcons}
        />
      )}
      {imgModal && (
        <CustomModal
          size="medium"
          // position="bottom"
          onClose={() => setImgModal(false)}>
          <div className="chat-img-preview">
            <img src={imageCreate} alt="" />
          </div>
        </CustomModal>
      )}
      {showModalButtons && (
        <CustomModal size="small" onClose={() => setShowModalButtons(false)}>
          <div className="permission-modal-content text-center">
            {showModelDecesion === 'snapshot' && (
              <h3>
                <i className="icon-store"></i>
                Host
              </h3>
            )}
            {/* {showModelDecesion === 'dollar' && (
              <h3>
                <i className="icon-dollar-sign" />
                Paid
              </h3>
            )} */}
            {/* {showModelDecesion === 'focus' && (
              <h3>
                <i className="icon-tickets" />
                Event
              </h3>
            )} */}
            {showModelDecesion === 'visibility' && (
              <h3>
                <i className="fas fa-lock" />
                Private
              </h3>
            )}
            {/* {showModelDecesion === 'location' && (
              <h3>
                <i className="fas fa-map-marker-alt" />
                Location
              </h3>
            )} */}

            {/* {showModelDecesion === 'dollar' && (
              <p>This feature is required payments.</p>
            )} */}
            {showModelDecesion === 'snapshot' && (
              <p>Are you sure you want to enable this feature?</p>
            )}
            {/* {showModelDecesion === 'focus' && (
              <p>This will convert your session to an event.</p>
            )} */}
            {showModelDecesion === 'visibility' && (
              <p>This feature is for invites only.</p>
            )}
            {/* {showModelDecesion === 'location' && (
              <p>This feature is enable location.</p>
            )} */}

            <div className="event-btn-wrapper">
              <Button
                className="btn-dark"
                onClick={() => {
                  if (showModelDecesion === 'snapshot') {
                    // setTopIcons({
                    //   ...topIcons,
                    //   ['focus']: true,
                    // });
                    setNewButton(true);
                    // setShowComponent('CreateSnapShot');
                  }
                  // if (showModelDecesion === 'focus') {
                  //   setTopIcons({
                  //     ...topIcons,
                  //     ['focus']: !topIcons.focus,
                  //     ['location']: topIcons.focus ? false : true,
                  //     ['store']: topIcons.focus ? true : false,
                  //   });
                  // }
                  // if (showModelDecesion === 'dollar') {
                  //   setTopIcons({
                  //     ...topIcons,
                  //     ['dollar']: !topIcons.dollar,
                  //   });
                  // }
                  // if (showModelDecesion === 'visibility') {
                  //   setTopIcons({
                  //     ...topIcons,
                  //     ['vaccinate']: !topIcons.vaccinate,
                  //   });
                  //   // privatee();
                  // }
                  // if (showModelDecesion === 'location') {
                  //   setTopIcons({
                  //     ...topIcons,
                  //     ['location']: !topIcons.location,
                  //   });
                  //   // clearAll();

                  // }

                  setShowModalButtons(false);
                }}>
                {hostId === '' && showModelDecesion === 'snapshot' && ' Enable'}
                {/* {topIcons.focus && showModelDecesion === 'focus'
                  ? 'Disable'
                  : !topIcons.focus &&
                    showModelDecesion === 'focus' &&
                    ' Enable'} */}
                {/* {topIcons.dollar && showModelDecesion === 'dollar'
                  ? 'Disable'
                  : !topIcons.dollar &&
                    showModelDecesion === 'dollar' &&
                    ' Enable'} */}
                {topIcons.vaccinate && showModelDecesion === 'visibility'
                  ? 'Disable'
                  : !topIcons.vaccinate &&
                    showModelDecesion === 'visibility' &&
                    ' Enable'}
                {topIcons.location && showModelDecesion === 'location'
                  ? 'Disable'
                  : !topIcons.location &&
                    showModelDecesion === 'location' &&
                    ' Enable'}
              </Button>
              <div className=" text-center mt-2 as-link">
                <span
                  onClick={() => {
                    setShowModalButtons(false);
                  }}>
                  Nevermind
                </span>
              </div>
              {/* {showModelDecesion === 'dollar' && 
              <div className=" text-center mt-2 as-link">
                <span
                  onClick={() => {
                    setShowModalButtons(false);
                  }}>
                  Update Banking Details
                </span>
              </div>} */}
            </div>
          </div>
        </CustomModal>
      )}

      {showModel === 'modal' && file && (
        <CustomModal
          size="large"
          onClose={() => {
            setShowModel('');
            setFile(null);
          }}>
          <CropImage
            type="group"
            ratio={0 / 0}
            file={file}
            setFile={setFile}
            setCropImg={setShowModel}
            setImgUrl={setImgUrl}
            setImageCreate={setImageCreate}
            sliderImage={sliderImage}
            setSliderImage={setSliderImage}
            setshowLoader={setShowLoaderSpinner}
            // setProfileImgScrollBar={setProfileImgScrollBar}
          />
        </CustomModal>
      )}
    </>
  );
};
export default CreateGroup;
