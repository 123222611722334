import React, {useState, useEffect} from 'react';
import {Form, Button, Alert} from 'react-bootstrap';
import {TagsInput} from 'react-tag-input-component';

import CustomDropdown from '../CustomDropdown';
import EditPencilSvg from '../../Svg/EditPencilSvg';
import {successMessage} from '../../Utils/ToastMessages';
import commonApi from '../../Services/CommonService';

function Fields(props) {
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [relationid, setRelationid] = useState('');
  const [stageid, setStageid] = useState('Initial');
  const [selected, setSelected] = useState([]);
  //   const [emailList, setEmailList] = useState([]);
  const [editIndex, setEditIndex] = useState(null);
  const [validated, setValidated] = useState(false);
  const [RelationshipArray, setRelationshipArray] = useState([]);

  //   const RelationshipArray = [
  //     {id: 'Friend', title: 'Friend'},
  //     {id: 'Family', title: 'Family'},
  //   ];
  const StageArray = [
    {id: 'Initial', title: 'Initial'},
    {id: 'Final', title: 'Final'},
  ];

  useEffect(() => {
    if (props.editData?.id) {
        addEditValue();
      }
    get_contact_relation_types();
  }, []);
  const handleSubmit = event => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      //   alert('1');
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
    } else {
      event.preventDefault();
      //   alert('2');

      setValidated(false);
      if (props.editData.id) {
        update_user_fields_edit();
      } else {
        update_user_fields();
      }
    }
  };

  const update_user_fields = () => {
    const paylaod = {
      email: email,
      phone: phoneNumber,
      relation: relationid,
      stage: stageid,
      tag: selected,

      user_id: props.userId,
    };

    commonApi
      .update_user_fields(paylaod)
      .then(res => {
        if (res.status) {
          props.setFieldListing(prevFieldListing => [
            res.field_detail,
            ...prevFieldListing,
          ]);
          props.onClose();
          resetStates();
          successMessage(res.successMessage);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const update_user_fields_edit = () => {
    const paylaod = {
      email: email,
      phone: phoneNumber,
      relation: relationid,
      stage: stageid,
      tag: selected,
      id: props.editData.id,

      user_id: props.userId,
    };

    commonApi
      .update_user_fields(paylaod)
      .then(res => {
        if (res.status) {
          props.setFieldListing(prevFieldListing =>
            prevFieldListing.map(field =>
              field.id === props.editData.id ? res.field_detail : field,
            ),
          );
          props.onClose();
          resetStates();
          successMessage(res.message);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const addEditValue = () => {
    setEmail(props.editData.email);
    setPhoneNumber(props.editData.phone);
    setRelationid(props.editData.relation);
    setStageid(props.editData.stage);
    setSelected(props.editData.stage.split(','));
  };
  const resetStates = () => {
    setEmail('');
    setPhoneNumber('');
    setRelationid('');
    setStageid('');
    setSelected([]);
  };

  const get_contact_relation_types = () => {
    commonApi
      .get_contact_relation_types()
      .then(res => {
        if (res.status) {
          const NewData = res.data;
          if (NewData.length > 0) {
            setRelationshipArray(NewData);
            if (!props.editData?.id) {
                setRelationid(NewData[0].id);
            }
            }
           
         
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  return (
    <div className="message-notes-full-width-hold">
      <div className="messages-notes-field-form-block">
        {/* <div className="message-day-saparator">Today February 2, 2024</div> */}
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Form.Group className="fieldset">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter email"
              value={email}
              onChange={e => setEmail(e.target.value)}
              // required
            />
            <Form.Control.Feedback type="invalid">
              Please provide a valid email.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="fieldset">
            <Form.Label>Phone number</Form.Label>
            <Form.Control
              type="tel"
              min={'11'}
              placeholder="Enter your phone number"
              value={phoneNumber}
              onChange={e => setPhoneNumber(e.target.value)}
                pattern="^\d{11}$"
              // required
            />
            <Form.Control.Feedback type="invalid">
              Please provide a valid phone number.
            </Form.Control.Feedback>
          </Form.Group>
          <div className="fieldset">
            <Form.Label>Relationship</Form.Label>
            <CustomDropdown
              setDropdownId={setRelationid}
              dropdownId={relationid}
              DropDownValues={RelationshipArray}
              itemName={['relations']}
            />
          </div>
          <div className="fieldset">
            <Form.Label>Stage</Form.Label>
            <CustomDropdown
              setDropdownId={setStageid}
              dropdownId={stageid}
              DropDownValues={StageArray}
              itemName={['title']}
            />
          </div>
          <div className="fieldset TageInput">
            <Form.Label>Tags</Form.Label>
            <div className="form-control">
              <TagsInput
                value={selected}
                onChange={setSelected}
                name="tags"
                placeHolder="Enter your tag"
              />
            </div>
          </div>

          <div className="filter-btn-wrapper mt-3">
            <div className="invite-btn text-center">
              <Button className="btn-dark" type="submit">
                {editIndex !== null ? 'Update' : 'Add Fields'}
              </Button>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
}

export default Fields;
