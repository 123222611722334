import React, {useState, useRef, useEffect} from 'react';
import {Button, Col, Form, Row} from 'react-bootstrap';
import commonApi from '../../Services/CommonService';
import {successMessage} from '../../Utils/ToastMessages';
import Modal from 'react-bootstrap/Modal';
import {DEFAULT_IMAGE} from '../../Utils/env';
import SlidingPanel from '../SlidingPanel';
import Scrollbars from 'react-custom-scrollbars';
import {fixName} from '../../Utils/commonUtils';

import ShowMore from '../Common/ShowMore';
import LoaderSpinner from '../Modals/LoaderSpinner';
import CustomModal from '../CustomModal';

import moment from 'moment/moment';
import ProfileComponent from '../ProfilePage/ProfileComponent';
import CustomDropdown from '../CustomDropdown';

const AddPraiseCaution = props => {
  const [plusType, setType] = useState(props.category);
  const [selectOne, setSelectOne] = useState([]);
  // const [zzz, setZzz] = useState({
  //   path: '',
  //   type: '',
  // });
  const [buttonType, setButtonType] = useState('discription');
  const [ticketImg, setTicketImg] = useState('');
  const [imagePath, setImagePath] = useState([]);
  // const [showLoader, setshowLoader] = useState(false);
  const [discriptions, setDiscription] = useState('');
  const [topic, setTopic] = useState('');
  const [category, setCategory] = useState('');
  const [discriptionErr, setDiscriptionErr] = useState(false);
  const [userData, setUserData] = useState({});
  const [show, setShow] = useState(false);
  // const [blobURL, setblobURL] = useState('');
  const [subTabs, setSubTabs] = useState('praise');
  const [viewCategory, setViewCategory] = useState('meetup(in-person)');

  const reviewTypes = [
    {id: 'event', value: 'event', title: 'Meetup (In-person)'},
    {id: 'consult', value: 'consult', title: 'Consult'},
    {id: 'session', value: 'session', title: 'Meetup (Virtual)'},
    {id: 'opportunity', value: 'opportunity', title: 'Recruitment'},
  ];
  const start = () => {
    // setrecordstate(RecordState.START);
  };
  const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);
  const stop = () => {
    // setrecordstate(RecordState.STOP);
  };

  // const onStops = audioData => {

  //   setblobURL(audioData.blob);
  //   setZzz({...zzz, ['path']: audioData.blob, ['type']: 'audio'});

  // };

  const intervalRef = useRef(null);
  // const [videoLimit, setVideoLimit] = useState('');
  const ref = useRef(null);
  let countTime = 0;
  const resetCounter = () => {
    clearInterval(intervalRef.current);
    intervalRef.current = null;
  };

  // audio video

  const submitData = event => {
    event.preventDefault();

    if (buttonType === 'discription' && discriptions.length > 0) {
      boiya();
    }
    if (buttonType === 'discription' && discriptions.length === 0) {
      setDiscriptionErr(true);
    }
    // } else if (
    //   buttonType === 'audio' &&
    //   topic.length > 0 &&
    //   zzz.type === 'audio'
    // ) {
    //   boiya();
    // } else if (
    //   buttonType === 'video' &&
    //   topic.length > 0 &&
    //   zzz.type === 'video'
    // ) {
    //   boiya();
    // } else {
    //   errorMessage('Please select options ');
    // }
  };

  // const changeTicketImg = e => {
  //   // If no file selected, return
  //   if (e.target.files.length === 0) return false;
  //   const file = e.target.files[0];

  //   // If no image selected, return
  //   if (!/^image\//.test(file.type)) {
  //     errorMessage(`File is not an image.`);
  //     return false;
  //   } else {
  //     const filePath = e.target.files[0];
  //     const objectUrl = URL.createObjectURL(filePath);
  //     let reader = new FileReader();
  //     reader.readAsDataURL(filePath);
  //     reader.onload = e => {
  //       //uploadPraiseCoution(e.target.result);
  //     };

  //     setTicketImg(objectUrl);
  //   }

  //   const imgTypes = ['image/gif', 'image/jpeg', 'image/png'];
  // };
  const addFeedBcak = (type, p, audioVideo) => {
    var payloadAddFeedBcak = {
      feedback_category: buttonType,
      feedback_description: discriptions === '' ? topic : discriptions,
      feedback_id: '',
      feedback_type: type,
      file_url: audioVideo,
      images: imagePath,
      status: 'feedback',
      type: p,
      type_id: category,
      user_id: props.userData.id,
    };
    commonApi
      .add_feedback(payloadAddFeedBcak)
      .then(res => {
        if (res.status === 200) {
          successMessage(res.message);
          props.onClose();
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  const getConsultSession = pType => {
    var payloadGetConsultSession = {
      user_id: props.userData.id,
      type: pType,
    };
    commonApi
      .get_consult_session_for_praise(payloadGetConsultSession)
      .then(res => {
        if (res.status === 200) {
          setSelectOne(res.data);
          setCategory(res.data[0].id);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  // const uploadPraiseCoution = img => {
  //   setshowLoader(true);
  //   var bodyFormData = new FormData();
  //   bodyFormData.append(
  //     'images',
  //     img.replace(/^data:image\/[a-z]+;base64,/, ''),
  //   );

  //   commonApi
  //     .upload_praise_caution(bodyFormData)
  //     .then(res => {
  //       if (res.status === 200) {
  //         setImagePath(current => [...current, res.image_path]);

  //         setshowLoader(false);
  //       }
  //     })
  //     .catch(err => {
  //       console.log('Err', err);
  //       return {type: 'error', message: err.message};
  //     });
  // };

  useEffect(() => {
    getUserData();
    getConsultSession(plusType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plusType]);
  useEffect(() => {}, []);

  // const {
  //   // status,
  //   // startRecording,
  //   // stopRecording,
  //   // mediaBlobUrl,
  //   // previewStream,
  //   // clearBlobUrl,
  // } = null;
  // const [showStream, setShowStream] = useState('');
  // const videoRef = useRef();
  // console.log("wwwwwwwwwwwwwwww",blob)
  // useEffect(() => {
  //   if (videoRef.current && previewStream) {
  //     videoRef.current.srcObject = previewStream;
  //   } else {
  //   }
  // }, [previewStream]);

  const getUserData = () => {
    var data = {user_id: props.userData.id};
    commonApi
      .user_info(data)
      .then(res => {
        if (res) {
          setUserData(res.data);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  // const uploadVideo = () => {
  //   fetch(mediaBlobUrl)
  //     .then((r) => r.blob())
  //     .then((blob) => {

  //       setZzz({ ...zzz, ["path"]: blob, ["type"]: "video" });
  //
  //
  //     });
  // };

  const boiya = () => {
    addFeedBcak(subTabs, plusType, '');
    return false;

    // var bodyFormData = new FormData();
    // bodyFormData.append(zzz.type, zzz.path);

    // commonApi
    //   .upload_praise_caution(bodyFormData)
    //   .then(res => {
    //     addFeedBcak(
    //       props.subTabs,
    //       plusType,
    //       zzz.type === 'video' ? res.video_path : res.audio_path,
    //     );
    //   })
    //   .catch(err => {
    //     console.log('Err', err);
    //     return {type: 'error', message: err.message};
    //   });
  };

  const handleViewCategory = value => {
    if (value === 'event') {
      setViewCategory('meetup(in-person)');
    } else if (value === 'consult') {
      setViewCategory(value);
    } else if (value === 'session') {
      setViewCategory('meetup(virtual)');
    } else if (value === 'opportunity') {
      setViewCategory('recruitment');
    }
  };

  useEffect(() => {
    // props.setCategory(category);
    handleViewCategory(props.category);
    setType(props.category);
  }, [props.category]);

  return (
    <>
      {/* {showLoader && <LoaderSpinner show={showLoader} />} */}

      {/* <SlidingPanel hidePanel={() => props.onClose()}
        //temporary state for remove warnings
        setButtonType={setButtonType}
        setTopic={setTopic}
        ticketImg={ticketImg}
        >
          <Scrollbars> */}
      <div className="fix-width-forms">
        <div className="sidebar-heading-wrapper">
          <div className="heading">
            <h2>
              {/* {capitalizeFirst(props.userData.first_name)}  {capitalizeFirst(props.userData.last_name)} */}
              {userData &&
                userData.first_name &&
                fixName(userData.first_name, userData.last_name)}
            </h2>
            {/* <span>{props.category}</span> */}

            <span>{viewCategory}</span>
          </div>
          <div className="with-user-img">
            <div className="user-profile-img">
              <img
                src={
                  userData &&
                  userData.profile_img &&
                  userData.profile_img === ''
                    ? DEFAULT_IMAGE
                    : userData.profile_img
                }
                alt=""
              />
            </div>
            {/* <span
                    className="close as-link"
                    onClick={() => props.setPlusButton(false)}>
                    <i className="fas fa-times" />
                  </span> */}
          </div>
        </div>

        <div className="add-praise-form-wrapper">
          <Form>
            <span className="question">
              <i className="icon-applaud" /> Add Feedback
            </span>
            <span className="question">
              This is me{' '}
              {/* {capitalizeFirst(localStorage.getItem('firstName'))}{' '}
                    {capitalizeFirst(localStorage.getItem('lastName'))} */}
              {fixName(
                localStorage.getItem('firstName'),
                localStorage.getItem('lastName'),
              )}{' '}
              saying something amazing about{' '}
              {userData &&
                userData.first_name &&
                fixName(userData.first_name, userData.last_name)}
              working with me.
            </span>
            <div className="event-tabs tabs-style-2 mb-0">
              <ul>
                <li>
                  <Button
                    className={subTabs === 'praise' ? 'active' : ''}
                    onClick={() => {
                      setSubTabs('praise');
                    }}>
                    <i className="icon-like-icon" />
                  </Button>
                </li>
                <li>
                  <Button
                    className={subTabs === 'caution' ? 'active' : ''}
                    onClick={() => {
                      setSubTabs('caution');
                    }}>
                    <i className="icon-unlike" />
                  </Button>
                </li>
              </ul>
            </div>
            <Form.Group className="fieldset">
              <Form.Label>Choose a feedback type</Form.Label>
              {/* <div className="search-form">
                <div className="select-wrapper">
                  <select
                    value={props.category}
                    className="form-control"
                    onChange={e => {
                      props.setCategory(e.target.value);
                      handleViewCategory(e.target.value);
                      
                      setType(e.target.value);
                    }}>
                    <option value="event">Meetup (In-person)</option>
                    <option value="consult">Consult</option>
                    <option value="session">Meetup (Virtual)</option>
                    <option value="opportunity">Recruitment</option>
                  </select>
                </div>
              </div> */}

              <CustomDropdown
                setDropdownId={props.setCategory}
                dropdownId={props.category}
                DropDownValues={reviewTypes}
                itemName={['title']}
                // className={'mb-3 recruitment_dropdown'}
              />
            </Form.Group>
            <Form.Group className="fieldset">
              {selectOne.length > 0 && (
                <>
                  <CustomDropdown
                    setDropdownId={setCategory}
                    dropdownId={category}
                    DropDownValues={selectOne}
                    itemName={['title']}
                    // className={'mb-3 recruitment_dropdown'}
                  />
                  {/* <div className="search-form">
                    <div className="select-wrapper">
                      <select
                        onChange={e => {
                          setCategory(e.target.value);
                        }}>
                        {Object.entries(selectOne).length > 0 ? (
                          Object.entries(selectOne).length > 0 &&
                          selectOne.map((item, index) => (
                            <option
                              id={item.id}
                              value={item.id}
                              key={`selectOne-${index}`}>
                              {item.title}
                            </option>
                          ))
                        ) : (
                          <></>
                        )}
                      </select>
                    </div>
                  </div> */}
                  {/* <Form.Label>Choose a feedback type</Form.Label> */}
                </>
              )}
            </Form.Group>
            {/* {buttonType === "audio" ||  buttonType === "video" ? (
              <>
                <div className="invite-page-search">
                  <div className="search-form">
                    <Form.Group className="search-input">
                      Topic
                      <Form.Control
                        type="text"
                        placeholder=""
                        value={topic}
                        onChange={(e) => {
                          setTopic(e.target.value);
                          
                        }}
                      />
                    </Form.Group>
                  </div>
                </div>
              </>
            ) : (
              <></>
            )} */}

            {/* <div className="event-tabs tabs-style-2 mb-0">
                  <ul>
                    <li>
                      <Button
                        className={buttonType === "discription " ? "active" : ""}
                        onClick={() => setButtonType("discription")}
                      >
                        <i className="icon-bars-2" />
                      </Button>
                    </li>
                    <li>
                      <Button
                        className={buttonType === "audio" ? "active" : ""}
                        onClick={() => {setButtonType("audio");
                        setDiscription(""); setTopic("");}}
                        
                      >
                        <i className="icon-sound-bars" />
                      </Button>
                    </li>
                    <li>
                      <Button
                        className={buttonType === "video" ? "active" : ""}
                        onClick={() => {setButtonType("video");
                        setDiscription(""); setTopic("");}}
                      >
                        <i className="icon-video-camera" />
                      </Button>
                    </li>
                  </ul>
                </div> */}

            {buttonType === 'discription' ? (
              <>
                <Form.Group className="fieldset">
                  {/* <Form.Label>Choose a {plusType}</Form.Label> */}
                  <textarea
                    required
                    rows={8}
                    className="form-control"
                    placeholder="Share your experience"
                    onChange={e => {
                      setDiscription(e.target.value);
                    }}></textarea>
                  {discriptions.length === 0 && discriptionErr === true && (
                    <div className="text-danger textforerror">
                      Description is required.
                    </div>
                  )}
                </Form.Group>
              </>
            ) : (
              <></>
            )}

            {/* <Form.Group className="fieldset">
                <div className="images-list">
                  <div className="images-list-item">
                    <div className="camera-icon">
                      <i className="icon-camera-outline" />
                      <input type="file" name="my-image" id="image" accept="image/*"  onChange={(e) => {changeTicketImg(e);
                      e.target.value=null}} />
  
                     
                    </div>
                   {imagePath.length > 0 &&
                    <span onClick={handleShow}>Preview Images</span>
                  } 
                  </div>
                  
         
                              
                </div>
              </Form.Group> */}

            <Form.Group className="fieldset">
              {props.subTabs === 'caution' ? (
                <>
                  <Button
                    className="btn-dark"
                    type="submit"
                    disabled={selectOne.length > 0 && discriptions === ''}
                    onClick={submitData}>
                    Submit
                  </Button>
                </>
              ) : (
                <></>
              )}
              {props.subTabs === 'praise' ? (
                <>
                  <Button
                    className="btn-dark"
                    disabled={selectOne.length > 0 && discriptions === ''}
                    onClick={submitData}>
                    Submit
                  </Button>
                </>
              ) : (
                <></>
              )}
            </Form.Group>
          </Form>
          {buttonType === 'video' ? (
            <>
              {' '}
              <div>
                Video
                <div>
                  <p ref={ref} id="mastId">
                    {/* {status} */}
                  </p>

                  <button
                    onClick={() => {
                      // startRecording();
                      intervalRef.current = setInterval(() => {
                        if (ref && ref.current.innerText === 'recording') {
                          countTime++;

                          if (countTime === 30) {
                            // stopRecording();
                            resetCounter();
                            // uploadVideo();
                          }
                        }
                      }, 1000);
                    }}>
                    Start Recording
                  </button>
                  <button
                    onClick={() => {
                      // stopRecording();
                      resetCounter();

                      // uploadVideo();
                    }}>
                    Stop Recording
                  </button>
                  {/* <button onClick={clearBlobUrl}>
                  Delete Recording
                </button> */}
                  {/* {!(previewStream && previewStream.active) && (
                          <video src={mediaBlobUrl} controls autoPlay loop />
                        )} */}
                  {/* {previewStream && previewStream.active && (
                          <video
                            ref={videoRef}
                            width={300}
                            height={300}
                            autoPlay
                            controls
                          />
                        )} */}
                </div>
              </div>
            </>
          ) : (
            <></>
          )}

          {buttonType === 'audio' ? (
            <>
              <div className="m-4">
                <Form.Label>Record Audio</Form.Label>
                <button onClick={start}>Start</button>
                <button
                  onClick={() => {
                    stop();
                  }}>
                  Stop
                </button>
                <div className="col-2">
                  {/* <AudioReactRecorder
                          state={recordstate}
                          onStop={onStops}
                        /> */}
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
      {/* </Scrollbars>
        </SlidingPanel> */}

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Images</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <div className="gallery-imgs">
              <Row>
                {Object.entries(imagePath).length > 0 ? (
                  Object.entries(imagePath).length > 0 &&
                  imagePath.map((item, index) => {
                    return (
                      <Col md={4} sm={4} key={`image-${index}`}>
                        <span
                          className="text-center ml-5 pl-5"
                          onClick={() => {
                            setImagePath(prevImgs => prevImgs.slice(1));
                            setTicketImg('');

                            imagePath.length === 0 && handleClose();
                          }}>
                          <i className="fas fa-times" />
                        </span>
                        <div className="gallery-item">
                          <div className="mt-5">
                            <img src={item} alt="" />
                          </div>
                        </div>
                      </Col>
                    );
                  })
                ) : (
                  <p className="text-center">No Images Found</p>
                )}
              </Row>
            </div>
          </>
        </Modal.Body>
      </Modal>
    </>
  );
};

const Review = props => {
  const [subTabs, setSubTabs] = useState('praise');
  // const [searchQry, setSearchQry] = useState("event");
  const [feedBack, setFeedBack] = useState('');
  const [countCoution, setCountCoution] = useState('');

  const [allImages, setAllImages] = useState([]);
  const [countPraise, setCountPraise] = useState('');
  const [category, setCategory] = useState(props.type ? props.type : 'event');
  // const [image, setImage] = useState('');
  const [imageButton, setImageButton] = useState(false);
  const [plusButton, setPlusButton] = useState(
    props.userInfo.can_consult_add_feedback !== '' ||
      props.userInfo.can_even_add_feedback !== '' ||
      props.userInfo.can_oppor_add_feedback !== '' ||
      props.userInfo.can_sess_add_feedback !== '',
  );
  const [showLoader, setshowLoader] = useState(false);
  const [responseAvrg, setResponseAvrg] = useState('');
  const [error, setError] = useState('');
  // const [response, setResponse] = useState('');
  // const [imageData, setImageData] = useState('');
  const [show, setShow] = useState(false);
  const [pathVideo, setPathVideo] = useState(false);
  const [showAudio, setShowAudio] = useState(false);
  const [showVideo, setShowVideo] = useState(false);
  const [modalVideo, setModalVideo] = useState('');
  const [plusiconHide, setPlusiconHide] = useState('');
  const [showComponent, setShowComponent] = useState('');
  const [profileId, setProfileId] = useState('');
  // const [addFeedback, setAddFeedback] = useState({
  //   can_consult_add_feedback: false,
  //   can_even_add_feedback: false,
  //   can_oppor_add_feedback: false,
  //   can_sess_add_feedback: false,
  // });

  const handleCloseVideo = () => setShowVideo(false);
  // const handleShowVideo = () => setShowVideo(true);

  const handleCloseAudio = () => setShowAudio(false);
  // const handleShowAudio = () => setShowAudio(true);

  const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);

  // const [showMore, setShowMore] = useState(false);
  // const showMoreClass = showMore ? 'show-all' : '';

  const handle = () => {
    plusButton ? setPlusButton(false) : setPlusButton(true);
  };
  // const onFeedbackClick = () => {
  //   if (addFeedback.can_consult_add_feedback) {
  //     setSelectedType('consult');
  //     setSelectedId(addFeedback.can_consult_add_feedback);
  //     setComponentToShow('addFeedback');
  //   } else if (addFeedback.can_even_add_feedback) {
  //     setSelectedType('event');
  //     setSelectedId(addFeedback.can_even_add_feedback);
  //     setComponentToShow('addFeedback');
  //   } else if (addFeedback.can_oppor_add_feedback) {
  //     setSelectedType('opportunity');
  //     setSelectedId(addFeedback.can_oppor_add_feedback);
  //     setComponentToShow('addFeedback');
  //   } else if (addFeedback.can_sess_add_feedback) {
  //     setSelectedType('session');
  //     setSelectedId(addFeedback.can_sess_add_feedback);
  //     setComponentToShow('addFeedback');
  //   }
  // }

  const handleProfileComponent = userId => {
    setProfileId(userId);
    setShowComponent('profileComponent');
  };
  const getFeedback = (type, qry) => {
    // alert('inside praise coution',  props.userData.id)
    setshowLoader(true);
    var payloadFeedback = {
      search_query: qry,
      type: type,
      user_id: props.userData.id,
    };
    commonApi
      .get_feedback(payloadFeedback)
      .then(res => {
        if (res.status === 200) {
          if (
            (res.can_consult_add_feedback !== '' ||
              res.can_even_add_feedback !== '' ||
              res.can_oppor_add_feedback !== '' ||
              res.can_sess_add_feedback !== '') &&
            props.userData.id !== localStorage.getItem('id')
          ) {
            // alert('set State true')
            setPlusButton(true);
          } else {
            setPlusButton(false);
          }
          // setAddFeedback(res);
          setFeedBack(res.feedbacks);
          setError(res.feedbacks);
          setPlusiconHide(res.can_add_feedback);
          // setImage(res.feedbacks.reply_detail);
          setCountPraise(res.total_praise);
          // setResponse(res.feedbacks.reply_detail);
          setCountCoution(res.total_caution);
          setResponseAvrg(res.average);

          setTimeout(() => {
            setshowLoader(false);
          }, 2000);

          // setImageData(res.feedbacks.images);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  useEffect(() => {}, []);

  useEffect(() => {
    getFeedback(subTabs, category);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subTabs, category]);
  useEffect(() => {}, [feedBack]);

  // const decision = () => {
  //   imageButton === true ? setImageButton(false) : setImageButton(true);
  // };

  console.log(category, 'category========>');
  return (
    <>
      {plusButton === true &&
      (props.userInfo.can_consult_add_feedback !== '' ||
        props.userInfo.can_even_add_feedback !== '' ||
        props.userInfo.can_oppor_add_feedback !== '' ||
        props.userInfo.can_sess_add_feedback !== '') ? (
        <AddPraiseCaution
          subTabs={subTabs}
          setPlusButton={setPlusButton}
          userData={props.userData}
          getFeedback={getFeedback}
          category={category}
          setCategory={setCategory}
          onClose={() => {
            setPlusButton(false);
            props.setShowComponent(oldArr => [{...oldArr, praise: false}]);
          }}
        />
      ) : (
        <div className="text-center">
          You cannot give review to this profile
        </div>
      )}

      {showComponent === 'profileComponent' && profileId && (
        <ProfileComponent
          userId={profileId}
          show={false}
          closeModal={() => {
            setShowComponent('');
          }}
          onClose={() => {
            setShowComponent('');
          }}
          userData={[]}
          moderator={false}
          // setParticipantType={setParticipantType}
          VoxeetSDK={[]}
          conferenceInfo={[]}
          jdSpecialM={[]}
          jdSpecialA={[]}
          setAllImages={setAllImages}
          setModalVideo={setModalVideo}
          setImageButton={setImageButton}
          setPathVideo={setPathVideo}
        />
      )}

      {show && (
        <CustomModal onClose={() => handleClose()}>
          <div className="gallery-imgs">
            <Row>
              {Object.entries(allImages).length > 0 &&
                allImages.map((item, index) => {
                  return (
                    <Col md={4} sm={4} key={`image-${index}`}>
                      <div className="gallery-item">
                        <img src={item} alt="" />
                      </div>
                    </Col>
                  );
                })}
            </Row>
          </div>
        </CustomModal>
      )}

      {showAudio && (
        <CustomModal onClose={() => handleCloseAudio()}>
          <div className="gallery-imgs">
            <Row>
              <audio controls>
                <source src={pathVideo} type="audio/mpeg" />
              </audio>
            </Row>
          </div>
        </CustomModal>
      )}

      {showVideo && (
        <CustomModal onClose={() => handleCloseVideo()}>
          <div className="gallery-imgs">
            <Row>
              <video controls>
                <source src={modalVideo} type="video/mp4" />
              </video>
            </Row>
          </div>
        </CustomModal>
      )}

      {/* <Modal show={showAudio} onHide={handleCloseAudio}>
                <Modal.Header closeButton>
                  <Modal.Title>Audio</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <>
                    <div className="gallery-imgs">
                      <Row>
                        <audio controls>
                          <source src={pathVideo} type="audio/mpeg" />
                        </audio>
                      </Row>
                    </div>
                  </>
                </Modal.Body>
              </Modal> */}
    </>
  );
};

export default Review;
