import React, { useEffect, useState} from 'react';
import {Button} from 'react-bootstrap';
import Slider from 'react-slick';
import {generateId} from '../Utils/Helpers';

const CustomSlidingButtons = ({
  items,
  onItemClick,
  activeButtons,
  showItems,
  label,
}) => {
  const [tabId, setTabId] = useState('');

  const settings = {
    arrows: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: showItems ? showItems : 3,
    slidesToScroll: 1,
    variableWidth: false,
    initialSlide: 0,
  };

  useEffect(() => {
    const tabId = generateId();
    setTabId(tabId);
  }, []);

  return (
    <Slider {...settings}>
      {items &&
        items.map((item, index) => {
          return (
            <Button
              key={`custom-tabs-${tabId}-${index}`}
              id={item.id}
              // value={activeButtons}
              value={item.title}
              className={
                activeButtons.includes(item.id) ? 'cate-btn active' : ''
              }
              onClick={onItemClick}>
              {label
                ? item[label]
                : item.title
                ? item.title
                : item.category_title
                ? item.category_title
                : item}
            </Button>
          );
        })}
    </Slider>
  );
};

export default CustomSlidingButtons;
