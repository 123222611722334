import React, {useState, useEffect, useRef} from 'react';
import {Button, Form, Dropdown} from 'react-bootstrap';
import commonApi from '../../../Services/CommonService';
import Scrollbars from 'react-custom-scrollbars';
import {errorMessage, successMessage} from '../../../Utils/ToastMessages';
import {SlidingPanel} from '../../../Components';
// import {DEFAULT_IMAGE} from '../../../Utils/env';
import SelectedReferUser from '../../../Components/Referral/SelectedReferUser';
import CustomTabs from '../../../Components/CustomTabs';
import {labels} from '../../../Constants/Translations';
import {selected_lang} from '../../../Utils/env';
import LoaderSpinner from '../../../Components/Modals/LoaderSpinner';

const SendInvitation = props => {
  const [validated, setValidated] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [PhoneNo, setPhonenNo] = useState('');
  const [participantArray, setParticipantArraytPhonenNo] = useState([]);
  const [showComponent, setShowComponent] = useState('');
  const [tabType, setTabType] = useState('business');
  const [contactRelations, setContactRelations] = useState([]);
  const [pocName, setPocName] = useState('');
  const [relationType, setRelationType] = useState('1');
  const [showLoader, setShowLoader] = useState(false);

  const buttonRef = useRef(null);

  const ticketTabs = [
    labels.qr_type_business[selected_lang],
    labels.type_individual[selected_lang],
  ];

  const handle = event => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();

      setValidated(true);
    } else {
      event.preventDefault();

      // referOutside();
      // alert('d')
      checkInputType();

      //
    }
  };
  //

  const checkInputType = () => {
    // if (PhoneNo.match(/^\d+$/) || PhoneNo.match(/^\+\d+$/)) {
    //   // alert('Data contains only numbers.');
    //   checkExist(PhoneNo);
    // } else if (PhoneNo.match(/^[A-Za-z]+$/)) {
    //   // alert('Data contains only words (letters).');
    //   referOutside();
    // } else if (PhoneNo.match(/^[A-Za-z\d]+$/)) {
    //   // alert('Data contains both words and numbers.');
    //   referOutside();
    // } else {
    // alert('Data contains other characters or is empty.');
    referOutside();
    // }
  };
  const referOutside = type => {
    let Payload = {
      poc_name: pocName,
      contact: PhoneNo,
      fname: firstName,
      relationship_id: relationType,
      user_type: tabType === 'Business' ? 'business' : 'individual',
    };

    if (type === 'number') {
      if (PhoneNo.includes('+')) {
        // setParticipantArraytPhonenNo(previous => [...previous, Payload]);
        sendnvitationOutIside(Payload);
      } else {
        // alert('')
        let PayloadData = {
          poc_name: pocName,
          contact: '+' + PhoneNo,
          fname: firstName,
          relationship_id: relationType,
          user_type: tabType === 'Business' ? 'business' : 'individual',
        };
        // setParticipantArraytPhonenNo(previous => [...previous, PayloadData]);

        sendnvitationOutIside(PayloadData);
      }
    } else {
      sendnvitationOutIside(Payload);
    }
    // alert('2')
    if (props.contact) {
      props.setContact(previous => [...previous, Payload]);
    }

    setFirstName('');
    setPhonenNo('');

    setPocName('');
    setValidated(false);
    if (props.type === 'schedule') {
      const UpdatedArray = [...participantArray, Payload];
      props.setContact(previous => [...previous, Payload]);
      sendnvitationOutIside(UpdatedArray);
    }

    // setAllBuilders(previous => [...previous, item]);
    // commonApi
    //   .refer_outside(Payload)
    //   .then(res => {
    //     if (res) {
    //       if (res.success === true) {
    //         successMessage(res.message);

    //         props.onClose();
    //       } else {
    //         errorMessage(res.message);
    //       }
    //     }
    //   })
    //   .catch(err => {
    //     console.log('Err', err);
    //     return {type: 'error', message: err.message};
    //   });
  };

  const sendnvitationOutIside = Updated => {
    var Payload;
    // props.setContact(previous => [...previous, Updated]);
    if (props.type === 'schedule') {
      Payload = {
        contacts: [Updated],
      };
    } else {
      Payload = {
        contacts: [Updated],
      };
    }

    commonApi
      .send_invitation_outside(Payload)
      .then(res => {
        if (res.status === 200) {
          successMessage(res.message);
          if(props.type ==='Catalog'){
            props.onCloseHitApi && props.onCloseHitApi()
          }
          if (props.setContact) {
            // participantArray.map((item, index) => {
            props.setContact(previous => [...previous, Updated]);
            // });
            setParticipantArraytPhonenNo([]);
            
          }
          if (props.setCheckUserHitApi) {
            props.setCheckUserHitApi(!props.checkUserHitApi);
          }
        
          // props.onClose();
        }
        if (res.status === false) {
          errorMessage(res.message);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  useEffect(() => {
    setShowLoader(true)
    getContactRelationTypes();
  }, []);
  useEffect(() => {
    setFirstName('');
    setPhonenNo('');
    setPocName('');
  }, [tabType]);

  // const checkExist = number => {
  //   var Payload = {
  //     number: number,
  //   };
  //   commonApi
  //     .check_this_number_exist(Payload)
  //     .then(res => {
  //       if (!res.exist) {
  //         referOutside('number');
  //       } else {
  //         errorMessage(res.message);
  //       }
  //     })
  //     .catch(err => {
  //       console.log('Err', err);
  //       return {type: 'error', message: err.message};
  //     });
  // };

  const getContactRelationTypes = () => {
    commonApi
      .get_contact_relation_types()
      .then(res => {
        if (res.data.length > 0) {
          setContactRelations(res.data);
          setTimeout(() => {
            setShowLoader(false)
          }, 1200);
        
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };



  // how to check bootstrap validtaion on form when user press enter burron in react js
  return (
    <>
      <SlidingPanel
        hidePanel={() => {
          props.onClose();
        }}>
          {showLoader && <LoaderSpinner/> }
        <div className="referal_page_content">
          <div className="refer_outside add-contacts referal_page_content">
            <div className="sidebar-heading-wrapper fix-width-forms">
              <h2>
                {props.snapshotIds ? 'Refer Outside ROI' : 'Add Contact'}
              </h2>
            </div>

            <div className="d-flex align-items-center justify-content-center fix-width-forms w-100">
              <div className="create-ticket-tabs w-100">
                <CustomTabs
                  darkmode
                  tabs={ticketTabs}
                  active={tabType}
                  onSelect={item => {
                    setTabType(item);
                    setValidated(false);
                  }}
                />
              </div>
            </div>
            <Scrollbars>
              {tabType === labels.qr_type_business[selected_lang] && (
                <div className="send-refferal-form-wrapper  fix-width-forms mt-2">
                  <Form noValidate validated={validated} onSubmit={handle}>
                    <div className="roi-form-input">
                      <Form.Label controlid="validationCustom01">
                        Company Name
                      </Form.Label>
                      <Form.Control
                        type="text"
                        value={firstName}
                        pattern="^\S.*$"
                        required
                        placeholder="Enter Company Name"
                        onChange={e => {
                          setFirstName(e.target.value);
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        Company Name is required.
                      </Form.Control.Feedback>
                    </div>

                    <div className="roi-form-dropdown mb-3">
                      <Form.Label controlid="validationCustom01">
                        Relationship
                      </Form.Label>
                      <div className="select-wrapper">
                        <Dropdown
                          onSelect={(eventKey, event) => {
                            setRelationType(eventKey);
                          }}
                          drop="down">
                          <Dropdown.Toggle variant="primary">
                            {relationType
                              ? contactRelations.find(
                                  item => item.id === relationType,
                                )?.relations
                              : 'Select Role'}
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            {contactRelations.map((item, index) => (
                              <Dropdown.Item
                                key={`st-${index}`}
                                eventKey={item.id.toString()}
                                active={item.id === relationType}>
                                {item.relations}
                              </Dropdown.Item>
                            ))}
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>

                    <div className="roi-form-input">
                      <Form.Label controlid="validationCustom01">
                        POC Name
                      </Form.Label>
                      <Form.Control
                        type="text"
                        value={pocName}
                        pattern="^\S.*$"
                        // required
                        placeholder="Enter Name"
                        onChange={e => {
                          setPocName(e.target.value);
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        Name is required.
                      </Form.Control.Feedback>
                    </div>

                    <div className="roi-form-input">
                      <Form.Label controlid="validationCustom01">
                        POC Phone or Email
                      </Form.Label>
                      <Form.Control
                        type="text"
                        value={PhoneNo}
                        pattern="^\S.*$"
                        // required
                        placeholder="Enter a phone or email "
                        onChange={e => {
                          setPhonenNo(e.target.value);
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        POC No is required.
                      </Form.Control.Feedback>
                    </div>

                    {/* <Form.Group className="fieldset">
                  <span className="use-my-contacts">Use My Contacts</span>
                </Form.Group> */}

                    {/* <div className="invite-persons-heading">
                    <span
                      className={
                        participantArray.length > 0
                          ? 'builder-text as-link'
                          : 'builder-text'
                      }
                      onClick={() => {
                        if (participantArray.length > 0) {
                          setShowComponent('SelectedUsers');
                        }
                      }}>
                      {participantArray.length} Selected
                    </span>
                  </div> */}

                    <Button
                      type="submit"
                      style={{display: 'none'}}
                      ref={buttonRef}>
                      {props.snapshotIds ? 'Send Refferral' : 'Add Contact'}
                    </Button>

                    {/* <input type="submit" value="Submit" onKeyDown={(e) => {if (e.key === 'Enter') handle(e)}} />  */}
                  </Form>
                </div>
              )}

              {tabType === labels.type_individual[selected_lang] && (
                <div className="send-refferal-form-wrapper fix-width-forms mt-2">
                  <Form noValidate validated={validated} onSubmit={handle}>
                    <div className="roi-form-input">
                      <Form.Label controlid="validationCustom01">
                        Name
                      </Form.Label>
                      <Form.Control
                        type="text"
                        value={firstName}
                        pattern="^\S.*$"
                        required
                        placeholder="Enter Name"
                        onChange={e => {
                          setFirstName(e.target.value);
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        Name is required.
                      </Form.Control.Feedback>
                    </div>

                    <div className="roi-form-dropdown mb-3">
                      <Form.Label controlid="validationCustom01">
                        Relationship
                      </Form.Label>
                      <div className="select-wrapper">
                        <Dropdown
                          onSelect={(eventKey, event) => {
                            setRelationType(eventKey);
                          }}
                          drop="down">
                          <Dropdown.Toggle variant="primary">
                            {relationType
                              ? contactRelations.find(
                                  item => item.id === relationType,
                                )?.relations
                              : 'Select Role'}
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            {contactRelations.map((item, index) => (
                              <Dropdown.Item
                                key={`st-${index}`}
                                eventKey={item.id.toString()}
                                active={item.id === relationType}>
                                {item.relations}
                              </Dropdown.Item>
                            ))}
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>

                    <div className="roi-form-input">
                      <Form.Label controlid="validationCustom01">
                        Phone or Email
                      </Form.Label>
                      <Form.Control
                        type="text"
                        value={PhoneNo}
                        pattern="^\S.*$"
                        // required
                        placeholder="Enter a phone or email "
                        onChange={e => {
                          setPhonenNo(e.target.value);
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        Phone or Email is required.
                      </Form.Control.Feedback>
                    </div>

                    {/* <Form.Group className="fieldset">
                  <span className="use-my-contacts">Use My Contacts</span>
                </Form.Group> */}

                    {/* <div className="invite-persons-heading">
                    <span
                      className={
                        participantArray.length > 0
                          ? 'builder-text as-link'
                          : 'builder-text'
                      }
                      onClick={() => {
                        if (participantArray.length > 0) {
                          setShowComponent('SelectedUsers');
                        }
                      }}>
                      {participantArray.length} Selected
                    </span>
                  </div> */}

                    <Button
                      type="submit"
                      style={{display: 'none'}}
                      ref={buttonRef}>
                      {props.snapshotIds ? 'Send Refferral' : 'Add Contact'}
                    </Button>

                    {/* <input type="submit" value="Submit" onKeyDown={(e) => {if (e.key === 'Enter') handle(e)}} />  */}
                  </Form>
                </div>
              )}
            </Scrollbars>

            <div className="filter-btn-wrapper fix-width-forms">
              <div className="invite-btns  p-0 pt-3 text-center">
                <Button
                  type="submit"
                  onClick={() => {
                    buttonRef.current.click();
                  }}>
                  {props.snapshotIds ? 'Send Refferral' : tabType === labels.qr_type_business[selected_lang] ? 'Invite Business'   : 'Add Contact'}
                </Button>
              </div>
            </div>
          </div>

          {participantArray.length > 0 && props.id && !props.type && (
            <div className="filter-btn-wrapper pt-3">
              <div className="invite-btns text-center p-0">
                <Button
                  disabled={participantArray.length > 0 ? false : true}
                  onClick={() => {
                    participantArray.forEach((item, index) => {
                      props.setContact(previous => [...previous, item]);
                    });
                    sendnvitationOutIside();

                    // successMessage(' Succesfully add to recipient');
                  }}>
                  Add to recipient
                </Button>
              </div>
            </div>
          )}

          {/* {participantArray.length > 0 && !props.id && !props.type && (
              <div className="filter-btn-wrapper pt-3">
                <div className="invite-btns text-center p-0">
                  <Button
                    disabled={participantArray.length > 0 ? false : true}
                    onClick={() => {
                      if (props.setContact) {
                        participantArray.map((item, index) => {
                          props.setContact(previous => [...previous, item]);
                        });
                      }

                      sendnvitationOutIside();
                    }}>
                    Add Contact
                  </Button>
                </div>
              </div>
            )} */}
        </div>
      </SlidingPanel>

      {showComponent === 'SelectedUsers' && (
        <SelectedReferUser
          selectedAllUser={[]}
          users={[]}
          setUsers={[]}
          setSelectedAllUser={[]}
          onClose={() => setShowComponent('')}
          alreadyAddedUser={[]}
          referOutSide={participantArray}
          setReferOutSide={setParticipantArraytPhonenNo}
        />
      )}
    </>
  );
};

export default SendInvitation;
