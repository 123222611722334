export const PipelineNoRecordFound = {
    img: null,
    title: null,
    description: 'No Pipeline found.',
    button: 'Create PipeLine',
  };
export const NoOfferingsFound = {
    img: null,
    title: null,
    description: 'No offerings available.',
    button: 'Add Offering',
  };
export const NoProjectsFound = {
    img: null,
    title: null,
    description: 'No projects available.',
    button: 'Add projects',
  };
export const NoTagsFound = {
    img: null,
    title: null,
    description: 'No tags found.',
    button: 'Add tags',
  };


  export const NoExpenseFound = {
    img: null,
    title: null,
    description: 'No expense found.',
    button: 'Create Expense',
  };


  export const OverviewNoRecordFound = {
    img: null,
    title: null,
    description: 'No record found.',
    button: null,
  };
  export const propertyNoRecordFound = {
    img: null,
    title: null,
    description: 'No record found.',
    button: null,
  };


  export const DraggableDealsNoRecord = {
    img: null,
    title: null,
    description: 'No deals available.',
    button: 'Add Deal',
  };


