import {useState, useEffect} from 'react';
import {Button} from 'react-bootstrap';
import Scrollbars from 'react-custom-scrollbars';
import Moment from 'react-moment';
import commonApi from '../../Services/CommonService';
import {successMessage} from '../../Utils/ToastMessages';
import { fixName } from '../../Utils/commonUtils';
import { CountSessionHistoryUsers } from '../../Utils/commonUtils';

const SessionNotes = props => {
  const [sessionNotes, setSessionNotes] = useState([]);
  
  useEffect(() => {
    getsessionNotes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getsessionNotes = () => {
    var data = {
      conference_id: props.item.session_id,
    };
    commonApi
      .get_session_notes(data)
      .then(res => {
        if (res.status === 200) {
          setSessionNotes(res.data);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  return (
    <div className="serach-filter-wrapper">
      <div className="sidebar-heading-wrapper with-panel">
        <div className="heading">
          <div className="persons-img-list mb-0">
            <div className="img-item mb-0">
              <img src={props.item.creator_img} alt="" />
            </div>
            {` `} Moderator -{' '}
            {fixName(
              props.item.creator_first_name,
              props.item.creator_last_name,
            )}
          </div>
        </div>
        {/* <span className="close as-link" onClick={()=>{ setDocument(true)}}><i className="fa-sharp fa-solid fa-book"></i></span> */}
      </div>

      <Scrollbars>
        <div className="">
          <div className="session-heading">
            <div className="event-titles">
              <h2>{props.item.session_title}</h2>
              <div className="event-date">
                {props.item.total_user_session_time} -{' '}
                <Moment format="MM/DD/YY H:mmA">
                  {props.item.session_start_time}
                </Moment>
              </div>
              <div className="event-users-cate">
                <span className="users-text">
                  <i className="fas fa-circle-user" />{' '}
                  {/* {props.item.total_members + 1}/
                  {props.item.total_moderators + 1} */}
                  {CountSessionHistoryUsers(props.item.all_join_user_in_session)}
                </span>
                <span className="category-text">
                  {props.item.session_category}
                </span>
              </div>
            </div>
          </div>

          <div>
            {Object.entries(props.item.session_notes).length > 0 &&
              props.item.session_notes.map((miniItem, index) => (
                <p>{miniItem.note_detail}</p>
              ))}
          </div>
        </div>
      </Scrollbars>

      <div className="filter-btn-wrapper text-center fix-width-forms">
        <Button
          className="btn btn-dark "
          type="submit"
          onClick={() => {
            navigator.clipboard.writeText(sessionNotes.note_detail);

            successMessage('Notes copied successfully');
          }}
          disabled={props.item.session_notes.length > 0 ? false : true}>
          Copy Notes
        </Button>
      </div>
    </div>
  );
};

export default SessionNotes;
